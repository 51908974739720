import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useParams } from 'react-router';
import {
  deleteData,
  ECOADMIN_PENDING_INVITES_ROUTE,
  getData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { primary } from 'theme';
import { DeleteRounded } from '@mui/icons-material';
import { ECOADMIN_PENDING_INVITES_DELETE_ROUTE } from 'utils/requests';
import Loading from 'components/common/Loading';

interface IInvitationList {}

const InvitationList: FunctionComponent<IInvitationList> = (props) => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [pendingInvites, setPendingInvites] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchPendingInvites();
  }, []);

  async function fetchPendingInvites() {
    setIsFetchLoading(true);

    try {
      const data = await getData(ECOADMIN_PENDING_INVITES_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setPendingInvites(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsFetchLoading(false);
  }

  async function deleteInvite(id: number) {
    setIsLoading(true);

    try {
      const data = await deleteData(ECOADMIN_PENDING_INVITES_DELETE_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'id',
          value: id,
        },
      ]);
      setPendingInvites(
        pendingInvites.filter((invite: any) => invite.id !== id),
      );
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function renderRequests() {
    if (!pendingInvites) return null;

    return pendingInvites.map((pendingInvite: any, i: number) => {
      const { inviteBy, inviteTo, deadline, id } = pendingInvite;

      return (
        <Box
          key={`request-${i}`}
          style={{
            borderBottom:
              i !== pendingInvite.length - 1
                ? '1px solid ${primary.natural6}'
                : 'none',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginX: '1rem',
              padding: '2rem 0rem 1rem 0.75rem',
              borderBottom:
                i !== pendingInvite.length - 1
                  ? '1px solid ${primary.natural6}'
                  : 'none',
            }}>
            <Box sx={{ width: '18rem' }}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  lineHeight: '1.21rem',
                }}
                textOverflow='ellipsis'
                maxWidth='315px'>
                {inviteBy}
              </Typography>
            </Box>
            <Box sx={{ width: '18rem' }}>
              <Typography
                variant={'body1'}
                textOverflow='ellipsis'
                maxWidth='315px'>
                {inviteTo}
              </Typography>
            </Box>
            <Box sx={{ width: '18rem' }}>
              <Typography variant={'body1'} marginRight='1rem'>
                {deadline}
              </Typography>
            </Box>
            <Box sx={{ justifySelf: 'flex-end', marginLeft: '3.5rem' }}>
              <IconButton aria-label='delete' onClick={() => deleteInvite(id)}>
                {isLoading ? (
                  <Loading
                    color={primary.mainBlackFont}
                    height={24}
                    size={20}
                  />
                ) : (
                  <DeleteRounded sx={{ color: primary.mainBlackFont }} />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
      );
    });
  }

  return (
    <Box>
      <Typography
        sx={{ fontSize: '2.5rem', fontWeight: 600, lineHeight: '3.75rem' }}>
        List of active invitations
      </Typography>
      <Box
        sx={{
          width: '66rem',
          boxShadow: '0px 20px 27px 0px #0000000D',
          borderRadius: '4px',
          backgroundColor: primary.pureWhite,
          marginTop: '2rem',
        }}>
        <Box sx={{ paddingLeft: '1.75rem', paddingTop: '2rem' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '1.25rem',
              lineHeight: '1.875rem',
              color: primary.natural2,
            }}>
            List of active invitations
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderBottom: `1px solid ${primary.natural6}`,
            marginX: '1rem',
            padding: '2rem 0rem 1rem 0.75rem',
          }}>
          <Box sx={{ width: '18rem' }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
                color: primary.natural2,
              }}>
              Invited by:
            </Typography>
          </Box>
          <Box sx={{ width: '18rem' }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
                color: primary.natural2,
              }}>
              Sent to:
            </Typography>
          </Box>
          <Box sx={{ width: '18rem' }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
                color: primary.natural2,
              }}>
              Active until:
            </Typography>
          </Box>
        </Box>
        {isFetchLoading ? (
          <Loading color={primary.mainBlackFont} />
        ) : (
          renderRequests()
        )}
      </Box>
    </Box>
  );
};

export default InvitationList;
