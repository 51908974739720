import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Fade, Grid, useTheme } from '@mui/material';
import Announcements from 'components/Home/Announcements';
import UpcomingEvents from 'components/Home/UpcomingEvents';
import HomeSearch from 'components/Home/HomeSearch';
import { ANNOUNCEMENTS_ROUTE, getData, PROFILE_ROUTE } from 'utils/requests';
import { useParams } from 'react-router';
import { IAnnouncement, IProfile } from 'utils/models';
import Loading from 'components/common/Loading';
import {
  getAccount,
  getStoredSkipProfileCompletion,
  getStoredMyProfile,
  storeMyProfile,
  getStoredAnnouncements,
  storeAnnouncements,
} from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';
import ProfileCompletionPopup from 'components/common/ProfileCompletitionPopup';
import { Box } from '@mui/system';
import CardTag from 'components/common/CardTag';
import TimeZoneDetector from 'components/common/TimeZoneDetector';

interface IHomeProps {
  main: HTMLDivElement | null;
}

const Home: FunctionComponent<IHomeProps> = (props) => {
  const { main } = props;
  const { isMobile } = useMobileContext();
  const { ecosystemName } = useParams();
  const { userId } = getAccount();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  // const [home, setHome] = useState<IHome | null>(getStoredHome());
  const [announcements, setAnnouncements] = useState<IAnnouncement[]>(
    getStoredAnnouncements(),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showProfileCompletionPopup, setShowProfileCompletionPopup] =
    useState<boolean>(false);
  const [member, setMember] = useState<IProfile | null>(getStoredMyProfile());

  useEffect(() => {
    if (!announcements || announcements.length === 0) {
      fetchAnnouncements();
    }
  }, []);

  useEffect(() => {
    storeAnnouncements(announcements);
  }, [announcements]);

  useEffect(() => {
    if (getStoredMyProfile() === null) {
      fetchMember();
    } else {
      setMember(getStoredMyProfile());
    }
  }, [userId]);

  useEffect(() => {
    if (!member) return;

    if (!member.iAm || !member.askMeAbout || member.usedTags.length === 0) {
      setShowProfileCompletionPopup(true);
    }
  }, [member]);

  async function fetchMember() {
    try {
      const memberData = await getData(PROFILE_ROUTE, [
        { name: 'profileOwnerId', value: userId },
        { name: 'ecosystemName', value: ecosystemName as string },
      ]);
      // const memberDataParsed = JSON.parse(JSON.stringify(memberData));
      setMember(memberData);
      storeMyProfile(memberData);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function fetchAnnouncements() {
    setLoading(true);

    try {
      const data = await getData(ANNOUNCEMENTS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setAnnouncements(data);
      storeAnnouncements(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderHome() {
    if (loading) return <Loading marginTop={90} />;

    return (
      <Fade
        in={true}
        timeout={1000}
        style={{
          border: '1px solid #fff',
          marginTop: '-3rem',
          backgroundColor: '#fff',
        }}>
        <Box>
          {isMobile && (
            <Box sx={{ marginLeft: '0.3rem', marginTop: '3rem' }}>
              <HomeSearch main={main} />
            </Box>
          )}
          <Box
            sx={{
              marginX: { xs: '1.5rem', md: '4.375rem' },
              marginTop: { xs: '2rem', md: '5rem' },
              display: 'flex',
              flexDirection: { xs: 'column' },
              alignItems: { sm: 'flex-start' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}>
            <CardTag text={'Announcement'} />

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={8}
                lg={6}
                sx={{ pr: { xs: '0rem', md: '1rem', lg: '2rem' } }}>
                <Announcements
                  announcements={announcements}
                  setAnnouncements={setAnnouncements}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={6}>
                {!isMobile && (
                  <Box
                    sx={{
                      marginTop: '-3rem',
                      marginBottom: '4.5rem',
                      marginLeft: '-0.7rem',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <HomeSearch main={main} />
                  </Box>
                )}
                <UpcomingEvents />
              </Grid>
            </Grid>
            <ProfileCompletionPopup
              open={
                showProfileCompletionPopup
                  ? !getStoredSkipProfileCompletion()
                  : false
              }
              onClose={() => {
                setShowProfileCompletionPopup(false);
              }}
            />
          </Box>
          <TimeZoneDetector />
        </Box>
      </Fade>
    );
  }

  return <div>{renderHome()}</div>;
};

export default Home;
