import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade, Grid } from '@mui/material';
import CompanyHeader from 'components/Companies/ViewCompany/CompanyHeader';
import CompanyEditProfile from 'components/Companies/EditCompany/CompanyEditProfile';
import Loading from 'components/common/Loading';
import {
  getCompanyPrivateStatus,
  setCompanyPrivateStatus,
} from 'utils/storage';
import CompanyMain from 'components/Companies/ViewCompany/CompanyMain';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { Box } from '@mui/system';
import CommonButton from 'components/common/CommonButton';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { primary } from 'theme';
import CompanySideSection from 'components/Companies/ViewCompany/CompanySideSection';
import Back from 'components/common/Back';
import { trackUmamiEvent } from 'utils/utils';
import CompanyPrivateProfile from 'components/Companies/Workspace/CompanyPrivateProfile';
import CompanyPeople from 'components/Companies/ViewCompany/CompanyPeople';
interface ICompanyProps {}

const Company: FunctionComponent<ICompanyProps> = (props) => {
  const { companyProfile, editProfile, loading } = useCompanyContext();
  const storedPrivateStatus = getCompanyPrivateStatus();
  const [showPrivate, setShowPrivate] = useState<boolean>(storedPrivateStatus);

  useEffect(() => {
    setCompanyPrivateStatus(showPrivate);
  }, [showPrivate]);

  function openPDF() {
    trackUmamiEvent('Generate Company PDF');
    const currentUrl = window.location.href;
    const newUrl = `${currentUrl}/pdf`;
    window.open(newUrl, '_blank');
  }

  if (loading || !companyProfile) return <Loading marginTop={90} />;

  function renderPublicProfile() {
    return (
      <>
        <Grid item xs={12} md={12} lg={8}>
          <CompanyHeader openPdf={openPDF} />
          <CompanyMain />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          {companyProfile!.canSeeCompanyWorkspace && (
            <CommonButton
              text='Go to workspace'
              icon={<KeyboardDoubleArrowRight />}
              onClick={() => setShowPrivate(!showPrivate)}
              sx={{
                width: '95%',
                margin: '1.5rem 1rem',
                backgroundColor: primary.dark,
                '&:hover': {
                  backgroundColor: primary.natural1,
                  color: primary.mainBlackFont,
                },
              }}
            />
          )}
          <CompanyPeople people={companyProfile!.team} />
          <CompanySideSection />
        </Grid>
      </>
    );
  }

  function renderPrivateProfile() {
    return (
      <>
        <CompanyHeader openPdf={openPDF} />
        <CompanyPrivateProfile />
        <Grid item xs={3}>
          <CommonButton
            text='Back'
            icon={<KeyboardDoubleArrowLeft />}
            onClick={() => setShowPrivate(!showPrivate)}
            sx={{
              width: '15rem',
              margin: '1.5rem 1rem',
              backgroundColor: primary.dark,
              '&:hover': {
                backgroundColor: primary.natural1,
                color: primary.mainBlackFont,
              },
            }}
          />
        </Grid>
      </>
    );
  }

  function renderContent() {
    if (editProfile) return <CompanyEditProfile />;

    if (showPrivate) return renderPrivateProfile();

    return renderPublicProfile();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}>
      <Box
        sx={{
          width: { xs: '10rem', md: '8%' },
        }}>
        <Back text='Back' />
      </Box>
      <Box
        sx={{
          minHeight: '80vh',
          maxWidth: '120rem',
          width: { xs: '100%', md: '80vw' },
          margin: 'auto',
        }}>
        <Fade in={true} timeout={1000}>
          <Grid container spacing={1.5}>
            {renderContent()}
          </Grid>
        </Fade>
      </Box>
    </Box>
  );
};

export default Company;
