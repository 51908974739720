import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import GetFileIcon from 'components/common/GetFileIcon';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';

interface IFileDisplayProps {
  file: any;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const IFileDisplay: FunctionComponent<IFileDisplayProps> = (props) => {
  const { file, setFiles } = props;

  const removeFile = (name: string) => {
    setFiles((prev) => prev.filter((file) => file.name !== name));
  };

  return (
    <FlexBox
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '1rem',
      }}>
      <FlexBox
        sx={{
          gap: '0.5rem',
        }}>
        <Box sx={{ img: { height: '1.7rem' } }}>
          <GetFileIcon file={file.name.split('.').pop()} />
        </Box>
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
                textDecorationColor: primary.natural4,
              },
            },
          }}>
          <a
            href={URL.createObjectURL(file)}
            target='_blank'
            rel='noopener noreferrer'>
            <Typography
              sx={{
                ':hover': {
                  color: primary.natural2,
                },
              }}>
              {file.name}
            </Typography>
          </a>
        </Box>
      </FlexBox>
      <CloseModalButton
        handleClose={() => removeFile(file.name)}
        color={primary.mainBlackFont}
        sx={{ boxShadow: 'none' }}
      />
    </FlexBox>
  );
};

export default IFileDisplay;
