import React, { FunctionComponent, useState } from 'react';
import AdminSideMenu, {
  AdminMenuKeys,
} from 'components/Admin/AdminSideMenu';
import ManageJourneys from 'components/Admin/ManageJourneys/ManageJourneys';
import JourneysOverview from 'components/Admin/ManageJourneys/JourneysOverview';
import AssignJourneys from 'components/Admin/ManageJourneys/AssignJourneys';
import { ISideMenuSection } from 'utils/models';
import { Assignment, AssignmentTurnedIn } from '@mui/icons-material';
import { Box } from '@mui/system';
import { getEcosystemInfo } from 'utils/storage';

interface OwnProps {}

const AdminManageJourneys: FunctionComponent<OwnProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Journey_Templates,
  );

  const { accessLevel } = getEcosystemInfo();

  function setMenuOption(menuKey: AdminMenuKeys) {
    setSelectedOption(menuKey);
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Journey_Templates,
      text: 'Journey Templates',
      icon: <Assignment />,
      onClick: () => setMenuOption(AdminMenuKeys.Journey_Templates),
      hasAccess: accessLevel.canManageJourneys,
    },
    {
      key: AdminMenuKeys.Assign_Journey,
      text: 'Assign Journeys',
      icon: <AssignmentTurnedIn />,
      onClick: () => setMenuOption(AdminMenuKeys.Assign_Journey),
      hasAccess: accessLevel.canManageJourneys,
    },
  ];

  function renderSection() {
    if (selectedOption === AdminMenuKeys.Journey_Templates) {
      return <JourneysOverview />;
    }

    if (selectedOption === AdminMenuKeys.Assign_Journey) {
      return <AssignJourneys />;
    }

    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'start',
        minHeight: 'calc(100vh - 4.4rem)',
        marginTop: '-3rem',
      }}>
      <Box>
        <AdminSideMenu menuItems={sections} selectedOption={selectedOption} />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginLeft: '6rem' }}>
        <ManageJourneys section={renderSection()} />
      </Box>
    </Box>
  );
};

export default AdminManageJourneys;
