import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainButton, { ButtonType } from '../common/MainButton';
import { acterioTheme } from 'theme';
import Modal from '../common/Modal';
import InputField from '../common/InputField';
import { postData, REQUEST_TO_JOIN_ECOSYSTEM_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.6rem 1.25rem',

    '& h5': {
      fontSize: '1.45rem !important',
    },

    '& h6': {
      fontSize: '1.15rem !important',
      marginBottom: '0.4rem !important',
    },

    '& p': {
      marginTop: '1.25rem !important',
      marginBottom: '1.5rem !important',
      fontWeight: 400,
    },
  },
}));

interface IRequestJoinEcosystem {}

const RequestJoinEcosystem: FunctionComponent<IRequestJoinEcosystem> = (
  props,
) => {
  const { isMobile } = useMobileContext();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [ecosystemName, setEcosystemName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function requestToJoinEcosystem() {
    setLoading(true);

    try {
      await postData(REQUEST_TO_JOIN_ECOSYSTEM_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Email sent to ${ecosystemName}`,
      });
      setOpenDialog(false);
      setEcosystemName('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div className={classes.main}>
        <Typography variant={'h5'}>Request to join an ecosystem</Typography>
        <Typography variant={'body1'}>
          Please enter the name of the ecosystem you wish to join
        </Typography>
        <Typography variant={'h6'}>Ecosystem name</Typography>
        <InputField
          value={ecosystemName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setEcosystemName(event.target.value)
          }
          placeholder={'Acterio'}
          readonly={loading}
        />
      </div>
    );
  }

  return (
    <div>
      <MainButton
        onClick={() => setOpenDialog(true)}
        text={'Request to join an ecosystem'}
        type={ButtonType.FILLED}
        style={{
          height: '32px !important',
          minHeight: '2rem !important',
          width: { xs: '80vw', md: '16.875rem', xl: '18.75rem' },
          backgroundColor: `${acterioTheme.main} !important`,
          color: `${acterioTheme.pureWhite} !important`,
          borderRadius: '6px !important',
          fontWeight: 500,
          fontSize: '1.05rem !important',
          margin: '10px 0 !important',
          marginTop: isMobile ? '1rem !important' : '5px !important',
          lineHeight: '1 !important',
        }}
      />
      <Modal
        open={openDialog}
        close={() => {
          setOpenDialog(false);
          setEcosystemName('');
        }}
        width={isMobile ? 300 : 600}
        loading={loading}
        backgroundColor={'white'}
        backdropStrength={0.9}
        noHeader={true}
        disabledSave={!ecosystemName}
        showFooter={true}
        largeFooter={true}
        saveAction={() => requestToJoinEcosystem()}
        footerButtonsFlexStart={true}
        saveButtonText={'Send'}
        reverseRow>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default RequestJoinEcosystem;
