import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material';

interface ISideMenu {
  width?: number;
  children: React.ReactNode;
}

const SideMenu: FunctionComponent<ISideMenu> = (props) => {
  const { width, children } = props;
  const theme = useTheme();

  return (
    <aside
      style={{
        height: '100%',
        // minHeight: `calc(100vh - ${theme.mixins.toolbar.height})`,
        // maxHeight: `calc(100vh - ${theme.mixins.toolbar.height})`,
        backgroundColor: '#FFFFFF',
        borderRight: '1px solid #E0E0E0',
        left: 0,
        top: theme.mixins.toolbar.height,
        width: `${width ?? 20}rem`,
        minWidth: `${width ?? 20}rem`,
        padding: '2rem 1.5rem',
        paddingRight: '0',
        paddingTop: '1rem',
      }}>
      {children}
    </aside>
  );
};

export default SideMenu;
