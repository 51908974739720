import { Card, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { IApplicant } from 'utils/models';
import { formatDateYMD } from 'utils/utils';
import DateRangeIcon from '@mui/icons-material/CalendarMonth';
import { StarRating } from 'components/common/StarRating';
import { useNavigate } from 'react-router';
import { primary } from 'theme';
import { Box } from '@mui/system';
import { ApplicantRatingModal } from './ApplicantRatingModal';

interface IApplicantCard {
  applicant: IApplicant;
  statusName?: string;
  statusColor?: string;
}

const ApplicantCard: FunctionComponent<IApplicantCard> = (props) => {
  const { applicant, statusName, statusColor } = props;
  const navigate = useNavigate();

  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false);
  const [applicantAverageRating, setApplicantAverageRating] = useState<number>(
    applicant.averageRating,
  );
  const [numberOfReviews, setNumberOfReviews] = useState<number>(
    applicant.numberOfReviews,
  );

  function handleRatingModalClick(event: React.MouseEvent) {
    event.stopPropagation();
    if (!applicant?.id) return;
    setRatingModalOpen(true);
  }

  return (
    <>
      <Card
        onClick={() => navigate(`${applicant.id}`)}
        sx={{
          width: '100%',
          height: '8.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          cursor: 'pointer',
          paddingX: '0.75rem',
          paddingY: '0.75rem',
          border: `1px solid ${primary.additionalGrey}`,
          borderLeft: `0.8rem solid ${
            statusColor ? statusColor : primary.additionalGrey
          }`,
          borderRadius: '12px',
          backgroundColor: `${primary.pureWhite}`,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            color: `${primary.natural2}`,
          }}>
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: '400',
              textWrap: 'nowrap',
              paddingX: '0.75rem',
              paddingY: '0.125rem',
              color: `${statusColor ? statusColor : primary.natural2}`,
              borderRadius: '6px',
              backgroundColor: primary.whiteHighlight,
            }}>
            {statusName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '0.5rem',
              alignContent: 'center',
              alignItems: 'center',
              color: `${primary.natural2}`,
            }}>
            <DateRangeIcon sx={{ fontSize: '1rem' }} />
            <Typography sx={{ fontSize: '0.8rem' }}>
              {formatDateYMD(new Date(applicant.submitDate))}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}>
          <Typography variant='h5' sx={{ fontSize: '1.125rem' }}>
            {applicant.companyName}
          </Typography>
          <Box
            onClick={(event) => handleRatingModalClick(event)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
              color: primary.additionalDarkOrange,
            }}>
            <StarRating
              applicant={applicant}
              stars={applicantAverageRating}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  opacity: '0.5',
                },
              }}
            />
            {applicant.numberOfReviews !== undefined && (
              <Typography>({numberOfReviews})</Typography>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: `${primary.natural2}`,
              marginLeft: 'auto',
            }}>
            {applicant.applicantName}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
              color: `${primary.natural2}`,
              marginLeft: 'auto',
            }}>
            {applicant.email}
          </Typography>
        </Box>
      </Card>
      <ApplicantRatingModal
        setApplicantAverageRating={setApplicantAverageRating}
        setNumberOfReviews={setNumberOfReviews}
        applicantId={applicant.id}
        isOpen={ratingModalOpen}
        onClose={() => setRatingModalOpen(false)}
      />
    </>
  );
};

export default ApplicantCard;
