import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { formatDateDD } from 'utils/utils';
import Meeting from './Meeting';
import { IMeeting } from 'utils/models';

interface MeetingListProps {
  meetings: IMeeting[];
  setMeetings: (meetings: any) => void;
  isAttendingCalendar: boolean;
}

const MeetingList: FunctionComponent<MeetingListProps> = (props) => {
  const { meetings, setMeetings, isAttendingCalendar } = props;

  function renderDays() {
    let lastDay: Date | null = null;
    return (
      <Box padding={4}>
        {meetings.length ? (
          meetings.map((meeting, index) => {
            const isANewDate = !(
              new Date(meeting.date).setHours(0, 0, 0, 0) ===
              (lastDay === null ? lastDay : lastDay.setHours(0, 0, 0, 0))
            );
            if (isANewDate) lastDay = new Date(meeting.date);
            return (
              <Box key={`${meeting.id} + ${index}`}>
                {isANewDate && (
                  <Typography fontWeight='bold' fontSize={18}>
                    {lastDay && formatDateDD(lastDay)}
                  </Typography>
                )}
                <Meeting meeting={meeting} setMeetings={setMeetings} isAttendingCalendar={isAttendingCalendar} />
              </Box>
            );
          })
        ) : (
          <Typography>There are no upcoming meetings...</Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: 'inherit',
        padding: 0,
        margin: 2,
        borderRadius: 0.2,
        width: '100%',
      }}>
      <Box marginTop={-2} marginLeft={-2}>
        {renderDays()}
      </Box>
    </Box>
  );
};

export default MeetingList;
