import React, { FunctionComponent, useState } from 'react';
import AdminSideMenu, { AdminMenuKeys } from 'components/Admin/AdminSideMenu';
import ManageTheme from 'components/Admin/ManageTheme/ManageTheme';
import EcosystemLogos from 'components/Admin/ManageTheme/EcosystemLogos';
import EcosystemColors from 'components/Admin/ManageTheme/EcosystemColors';
import EcosystemTags from 'components/Admin/ManageTheme/TagGroups/EcosystemTags';
import { TagGroupProvider } from 'components/Admin/ManageTheme/TagGroups/TagGroupContext';
import { ISideMenuSection } from 'utils/models';
import { Label, RadioButtonUnchecked } from '@mui/icons-material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Box } from '@mui/system';
import { getEcosystemInfo } from 'utils/storage';

interface OwnProps {}

const AdminManageTheme: FunctionComponent<OwnProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Logo,
  );

  const { accessLevel } = getEcosystemInfo();

  function setMenuOption(menuKey: AdminMenuKeys) {
    setSelectedOption(menuKey);
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Logo,
      text: 'Logo',
      icon: <RadioButtonUnchecked />,
      onClick: () => setMenuOption(AdminMenuKeys.Logo),
      hasAccess: accessLevel.canCustomizeEcosystemDisplay,
    },
    {
      key: AdminMenuKeys.Colors,
      text: 'Colors',
      icon: <ColorLensIcon />,
      onClick: () => setMenuOption(AdminMenuKeys.Colors),
      hasAccess: accessLevel.canCustomizeEcosystemDisplay,
    },
    {
      key: AdminMenuKeys.Tags,
      text: 'Tags',
      icon: <Label />,
      onClick: () => setMenuOption(AdminMenuKeys.Tags),
      hasAccess: accessLevel.canCustomizeEcosystemDisplay,
    },
  ];

  function renderSection() {
    if (selectedOption === AdminMenuKeys.Logo) {
      return <EcosystemLogos />;
    }

    if (selectedOption === AdminMenuKeys.Colors) {
      return <EcosystemColors />;
    }

    if (selectedOption === AdminMenuKeys.Tags) {
      return <EcosystemTags />;
    }

    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: 'calc(100vh - 4.4rem)',
        marginTop: '-3rem',
      }}>
      <Box>
        <AdminSideMenu menuItems={sections} selectedOption={selectedOption} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          marginLeft: '3.125rem',
          width: '65%',
        }}>
        <TagGroupProvider>
          <ManageTheme section={renderSection()} />
        </TagGroupProvider>
      </Box>
    </Box>
  );
};

export default AdminManageTheme;
