import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Theme, Typography, useTheme } from '@mui/material';
// import InputField from '../../common/InputField';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteData,
  ECOADMIN_DELETE_USER_ROUTE,
  ECOADMIN_INVITE_LINK_ROUTE,
  ECOADMIN_INVITE_ROUTE,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { removeStoredMembers } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import SearchUsers from 'components/common/SearchUsers';
import { acterioTheme, colorPallete, primary } from 'theme';
import SelectAccessLevels from 'components/common/SelectAccessLevels';
import CopyField from 'components/common/CopyField';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
  },
  section: {
    padding: '2rem',

    '& h6': {
      fontSize: '1.25rem !important',
      marginBottom: '0.5rem !important',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '10px',
        height: 35,
        width: 35,
        marginTop: '-3px',
      },
    },

    '& p': {
      fontSize: '0.95rem !important',
    },
  },
  inputCont: {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',

    '& button': {
      fontWeight: 600,
      fontSize: '1.1rem !important',
      maxWidth: '110px !important',
    },
  },
  emailList: {
    marginTop: '10px',
  },
  emailItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  emailText: {
    marginRight: '10px',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  textareaInput: {
    width: '100%',
    border: '1px solid #e0e0e0',
    padding: '8px',
    fontSize: '1rem !important',
    resize: 'none',
  },
}));

interface IAddRemoveFromEcosystem {}

const AddRemoveFromEcosystem: FunctionComponent<IAddRemoveFromEcosystem> = (
  props,
) => {
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
  const [loadingRemove, setLoadingRemove] = useState<boolean>(false);
  const [inviteEmails, setInviteEmails] = useState<string[]>([]);
  const [removeEmail, setRemoveEmail] = useState<string>('');
  const [emailText, setEmailText] = useState<string>('');

  const [pastedEmails, setPastedEmails] = useState<string>('');

  const [selectedAccessLevel, setSelectedAccessLevel] = useState<number[]>([]);
  const [sharableLink, setSharableLink] = useState<string>('Generated link');

  const addMemberEmail = () => {
    const emails = emailText.split(/[\n\s]+/);
    const updatedInviteEmails = [...inviteEmails];
    emails.forEach((email) => {
      if (email.trim() !== '' && !updatedInviteEmails.includes(email.trim())) {
        updatedInviteEmails.push(email.trim());
      }
    });
    setInviteEmails(updatedInviteEmails);
    setEmailText('');
  };

  const removeInviteEmail = (email: string) => {
    const updatedEmails = inviteEmails.filter((e) => e !== email);
    setInviteEmails(updatedEmails);
  };

  async function invite() {
    setLoadingInvite(true);

    try {
      const response = await postData(ECOADMIN_INVITE_ROUTE, getParams());

      if (response.length === inviteEmails.length) {
        response.forEach((res: any) => {
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: res.message,
          });
        });
      } else if (response.length) {
        response.forEach((res: any) => {
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: res.message,
          });
        });

        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message:
            'Emails partially send out! Invitation was unsuccesful for following emails: ',
        });

        const emailsFromResponse = response.map(({ email }: any) => email);

        setInviteEmails(emailsFromResponse);
      } else {
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Invitation sucessful',
        });

        setInviteEmails([]);
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoadingInvite(false);
  }

  async function remove() {
    setLoadingRemove(true);

    try {
      await deleteData(ECOADMIN_DELETE_USER_ROUTE, getParams(true));

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `${removeEmail} removed`,
      });

      setRemoveEmail('');
      removeStoredMembers();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoadingRemove(false);
  }

  function getParams(remove?: boolean) {
    return [
      {
        name: remove ? 'email' : 'inviteToEmails',
        value: remove ? removeEmail : inviteEmails,
      },
      {
        name: 'ecosystemName',
        value: ecosystemName,
      },
    ];
  }

  useEffect(() => {
    // if (pastedEmails.trim() !== '') {
    //   const emails = pastedEmails.split('\n');
    //   const validEmails = emails
    //     .map((email) => email.trim())
    //     .filter((email) => email.length > 0);

    //   const uniqueEmails = Array.from(
    //     new Set([...inviteEmails, ...validEmails]),
    //   );

    //   setInviteEmails(uniqueEmails);
    //   setPastedEmails('');
    // }
    setEmailText(pastedEmails);
    addMemberEmail();
  }, [pastedEmails]);

  async function requestSharableLink() {
    setLoadingInvite(true);

    try {
      const data = await postData(ECOADMIN_INVITE_LINK_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        {
          name: 'accessLevelId',
          value: selectedAccessLevel[0],
        },
      ]);

      setSharableLink(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Link generated!',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoadingInvite(false);
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h5'}>Add/remove from ecosystem</Typography>
      <div className={classes.cont}>
        <div
          className={classes.section}
          style={{ borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant={'h6'}>
            <PersonAddIcon sx={{ color: acterioTheme.main }} /> Add member
          </Typography>
          <Typography variant={'body2'}>
            Enter an email address here and click invite to invite someone to
            join your ecosystem.
          </Typography>
          <Typography variant={'body2'}>
            We will send them an email. The invitation will be&nbsp;
            <span style={{ fontWeight: 600 }}>active for 10 days</span>
          </Typography>
          <div className={classes.emailList}>
            {inviteEmails.map((email, index) => (
              <div key={index} className={classes.emailItem}>
                <Typography variant='body2' className={classes.emailText}>
                  {email}
                </Typography>
                <CloseIcon
                  className={classes.deleteIcon}
                  onClick={() => removeInviteEmail(email)}
                />
              </div>
            ))}
          </div>
          <div className={classes.inputCont}>
            <TextField
              multiline={true}
              className={classes.textareaInput}
              value={emailText}
              placeholder={'Please enter email address here'}
              onChange={(e) => setEmailText(e.target.value)}
              onPaste={(e) => {
                setPastedEmails(e.clipboardData.getData('text/plain'));
              }}
              disabled={loadingInvite || loadingRemove}
              sx={{
                '.MuiInputBase-root': {
                  height: '2.5rem',
                  marginRight: '1rem',
                },
              }}
            />

            <CommonButton
              text='Add'
              onClick={addMemberEmail}
              isLoading={loadingInvite}
              bgcolor={theme.palette.primary.dark}
              color={primary.pureWhite}
              isDisabled={loadingRemove || loadingInvite || !emailText}
            />
          </div>
          {inviteEmails.length > 0 && (
            <div
              className={classes.inputCont}
              style={{ justifyContent: 'space-between' }}>
              <CommonButton
                text='Clear All'
                variant={ButtonVariant.OUTLINED}
                onClick={() => setInviteEmails([])}
                isLoading={loadingInvite}
                isDisabled={loadingRemove || loadingInvite}
              />

              <CommonButton
                text='Invite All'
                variant={ButtonVariant.FILLED}
                onClick={() => invite()}
                isLoading={loadingInvite}
                isDisabled={loadingRemove || loadingInvite}
              />
            </div>
          )}

          <div style={{ marginTop: '1.5rem' }}>
            <Typography variant={'body2'}>
              Select the access level group for the person you are inviting,
              then click 'Generate Link'. <br />
              An invitation link will be created,{' '}
              <span style={{ fontWeight: 600 }}>active for 10 days,&nbsp;</span>
              allowing the invitee to join the specified group.
            </Typography>
            <div
              style={{
                marginTop: '0.5rem',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                style={{
                  width: '100%',
                  marginRight: '1rem',
                }}>
                <SelectAccessLevels
                  accessLevelIds={selectedAccessLevel}
                  onAccessGroupChange={setSelectedAccessLevel}
                  isOneSelection
                />
              </div>
              <CommonButton
                text='Generate link'
                variant={ButtonVariant.FILLED}
                onClick={requestSharableLink}
                isLoading={loadingInvite}
                isDisabled={
                  loadingRemove || loadingInvite || !selectedAccessLevel.length
                }
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <CopyField text={sharableLink} onChange={setSharableLink} />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.cont} style={{ marginTop: '2rem' }}>
        <div className={classes.section}>
          <Typography variant={'h6'}>
            <PersonRemoveIcon sx={{ color: acterioTheme.warningRed }} /> Remove
            member
          </Typography>
          <Typography variant={'body2'}>
            Enter an email address here and click <span>remove</span> to remove
            someone from your ecosystem.
          </Typography>
          <div className={classes.inputCont}>
            <SearchUsers
              onSearch={(value) => setRemoveEmail(value.emailEcoPreferred)}
              fullWidth={true}
              displayEmail={true}
            />

            <CommonButton
              text='Remove'
              onClick={() => remove()}
              bgcolor={acterioTheme.warningRed}
              isLoading={loadingRemove}
              isDisabled={
                loadingInvite || loadingRemove || removeEmail.length === 0
              }
              sx={{
                marginLeft: '1rem',
                '&:hover': {
                  bgcolor: colorPallete.additionalDarkPink,
                  color: primary.pureWhite,
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRemoveFromEcosystem;
