import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, Theme, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #eee',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
    paddingRight: '5px',

    '& button': {
      minWidth: '2.5rem',
      width: '2.5rem',
    },

    '& svg': {
      height: '20px',
      width: '20px',
    },
  },
  main: {
    padding: '5px 15px',
  },
  title: {
    display: 'flex',
  },
  iconSpot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '25px',
    marginRight: '7.5px',
    marginTop: '-1.5px',
    color: (props: any) => props.color,
  },
  seeMore: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      textTransform: 'capitalize !important',
      padding: '0 !important',
      paddingLeft: '5px !important',
      fontSize: '0.8rem !important',
      color: `${theme.palette.primary.dark} !important`,
      fontWeight: 800,
      borderRadius: '4px',
    },
  },
  action: {
    marginTop: '-12.5px',
    marginBottom: '10px',

    '& button': {
      fontSize: '0.75rem',
      color: `${theme.palette.primary.dark} !important`,
      padding: '5px',
      fontWeight: 800,
      borderRadius: '4px',
      marginLeft: '-7.5px',
      textTransform: 'capitalize',

      '& svg': {
        height: '18px',
        width: '18px',
        color: `${theme.palette.primary.dark} !important`,
        marginRight: '5px',
      },
    },
  },
}));

interface ICollapseList {
  title: string;
  color: string;
  icon?: JSX.Element;
  action?: JSX.Element;
  seeMore?: boolean;
  seeMoreAction?: () => any;
  children: JSX.Element;
  defaultExpanded?: boolean;
}

const CollapseList: FunctionComponent<ICollapseList> = (props) => {
  const {
    title,
    color,
    icon,
    children,
    action,
    seeMore,
    seeMoreAction,
    defaultExpanded,
  } = props;
  const classes = useStyles({ color });

  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false);

  function renderSeeMore() {
    if (!seeMore) return null;

    return (
      <div className={classes.seeMore}>
        <Button onClick={seeMoreAction}>
          See More <ChevronRightIcon />
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <div className={classes.iconSpot}>{icon ?? null}</div>
          <Typography variant={'h6'} color={color}>
            {title}
          </Typography>
        </div>
        <Button onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <ExpandLessIcon stroke={color} />
          ) : (
            <ExpandMoreIcon stroke={color} />
          )}
        </Button>
      </div>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <div className={classes.main}>
          {action && <div className={classes.action}>{action}</div>}
          {children}
          {renderSeeMore()}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseList;
