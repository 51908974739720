import React, { FunctionComponent } from 'react';
import { Box, Button, Fade, Typography } from '@mui/material';
import CompanyProfilePreview from './CompanyProfilePreview';
import { useAssignCompanyProfilesContext } from './AssignCompanyProfileContext';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { acterioTheme } from 'theme';

interface ICompanyProfilePreview {
  selectable?: boolean;
}

const CompanyProfilePreviews: FunctionComponent<ICompanyProfilePreview> = (
  props,
) => {
  const { selectable } = props;
  const { companyProfileTemplates } = useAssignCompanyProfilesContext();

  const navigate = useNavigate();
  return (
    <Fade in={true} timeout={600}>
      <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box
            onClick={() => navigate('new')}
            sx={{
              cursor: 'pointer',
              width: '12.5rem',
              height: '12.5rem',
              borderRadius: '12px',
              border: '#CCCCCC solid 1px',
              backgroundColor: '#FFFFFF',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 2px 4px 0px #15151514',
              margin: '1rem 1rem 0 0',
              '&:hover': {
                border: `2px solid ${acterioTheme.main}`,
              },
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.25rem',
                lineHeight: '1.375rem',
                marginTop: '1rem',
              }}>
              Create template
            </Typography>
            <Button>
              <Add sx={{ fontSize: '3.5rem', color: acterioTheme.main }} />
            </Button>
          </Box>
          {companyProfileTemplates.map((profile) => (
            <Box key={profile.id}>
              <CompanyProfilePreview
                companyProfileTemplate={profile}
                selectable={selectable}
              />
            </Box>
          ))}
        </Box>
      </div>
    </Fade>
  );
};

export default CompanyProfilePreviews;
