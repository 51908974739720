import React, { FunctionComponent } from 'react';
import InformationModal from '../../common/Modal/InformationModal';
import { FlexBox } from '../../../utils/styledComponents';
import { Typography } from '@mui/material';

interface IWarningDeleteModalProps {
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actionOnConfirm: () => void;
}

const WarningDeleteModal: FunctionComponent<IWarningDeleteModalProps> = (
  props,
) => {
  const { isWarningModalOpen, setIsWarningModalOpen, actionOnConfirm } = props;

  return (
    <InformationModal
      isOpen={isWarningModalOpen}
      handleClose={() => setIsWarningModalOpen(false)}
      headerText={'Confirm action'}
      primaryText={'Confirm'}
      primaryOnClick={actionOnConfirm}
      secondaryText={'Cancel'}
      secondaryOnClick={() => setIsWarningModalOpen(false)}
      headerSize={'2rem'}
      buttonSx={{
        height: '3rem',
      }}>
      <FlexBox
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'start',
        }}>
        <Typography>
          Changing the field type or deleting this section/question will result
          in data loss from already received applications. If you'd like to
          preserve that data, please use 'hide' icon to the left of delete.
          Hiding will allow you to preserve existing answers, and hide the
          question for the future applicants.
        </Typography>

        <Typography>
          If you still would like to proceed with deleting, please confirm.
        </Typography>
      </FlexBox>
    </InformationModal>
  );
};

export default WarningDeleteModal;
