import React, { FunctionComponent } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import { Box } from '@mui/system';
import SideMenuSections from '../SideMenu/SideMenuSections';
import { ISideMenuSection } from 'utils/models';
import SideMenuBackButton from '../Admin/AdminBackButton';

export enum BookingMenuKeys {
  People = 'People',
  Rooms = 'Rooms',
  People_Bookings = 'People_bookings',
  Room_Bookings = 'Room_bookings',
  Requests = 'Requests',
  People_Availability = 'People_availability',
  Manage_Rooms = 'Rooms_manage',
  Room_Availability = 'Room_Availability',
  Connect = 'Connect',
}

interface IBookingSideMenu {
  menuItems: ISideMenuSection[];
  selectedOption: BookingMenuKeys;
}

const BookingSideMenu: FunctionComponent<IBookingSideMenu> = (props) => {
  const { menuItems, selectedOption } = props;

  return (
    <SideMenu>
      <SideMenuBackButton text={'Back'} />
      <Box sx={{ mt: '2rem' }}>
        <SideMenuSections
          menuItems={menuItems}
          selectedOption={selectedOption}
        />
      </Box>
    </SideMenu>
  );
};

export default BookingSideMenu;
