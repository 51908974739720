import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { CompanyTemplateSectionType, ITagGroup } from 'utils/models';
import SelectDropdown from 'components/common/SelectDropdown';
import useFetchTagGroups from 'hooks/Common/useFetchTagGroups';
import { useCompanyTemplateContext } from '../CompanyTemplateContext';
import { useTheme } from '@mui/material';
import ColorPicker from 'components/common/ColorPicker';
import { naturalPallete } from 'theme';

export const CompanyTemplateTagGroup = () => {
  const theme = useTheme();
  const { companyTemplate, setCompanyTemplate, removeSectionField } =
    useCompanyTemplateContext();

  const { tagGroups } = useFetchTagGroups();
  const tagGroupsOptions = [...tagGroups.map((group) => group.name), 'No tags'];
  const [selectedTagGroup, setSelectedTagGroup] = useState<ITagGroup | null>(
    null,
  );

  useEffect(() => {
    if (!companyTemplate) return;
    setSelectedTagGroup(companyTemplate.tagGroup);
  }, [companyTemplate]);

  function updateTagGroupField(tagGroup: ITagGroup) {
    if (!companyTemplate) return;

    const updatedTemplate = {
      ...companyTemplate,
      tagGroup: tagGroup,
    };
    setCompanyTemplate(updatedTemplate);
  }

  function handleTagGroupChange(selectedGroupName: string) {
    if (selectedGroupName === 'No tags') {
      setSelectedTagGroup(null);
      removeSectionField(CompanyTemplateSectionType.HEADER, 'Tag group');
    } else {
      const selectedGroup = tagGroups.find(
        (group) => group.name === selectedGroupName,
      );
      if (selectedGroup) {
        setSelectedTagGroup(selectedGroup);
        updateTagGroupField(selectedGroup);
      }
    }
  }

  function handleColorChange(color: string) {
    if (!companyTemplate) return;

    const updatedCompanyTemplate = {
      ...companyTemplate,
      color: color,
    };
    setCompanyTemplate(updatedCompanyTemplate);
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem ',
      }}>
      <SelectDropdown
        value={selectedTagGroup?.name || 'No tags'}
        onChange={(e) => handleTagGroupChange(e)}
        options={tagGroupsOptions}
        getOptionLabel={(option) => option}
      />
      <ColorPicker
        noMargin={true}
        sxOuterBox={{
          width: '9.875rem',
          height: '2.25rem',
          borderRadius: '6px',
          padding: '0.301rem',
          border: '0.6px solid',
          borderColor: naturalPallete.natural6,
          justifySelf: 'flex-end',
        }}
        sxColorBox={{
          width: '3.75rem',
          height: '1.688rem',
          borderRadius: '6px',
        }}
        sxTypography={{
          fontWeight: 400,
          fontSize: '0.603rem',
          lineHeight: '1rem',
        }}
        color={companyTemplate?.color || '#EBF1F9'}
        onSelectedColor={handleColorChange}
      />
    </Box>
  );
};
