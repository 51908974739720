import { SxProps, useTheme } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';

interface ICommonSwitch {
  checked?: boolean;
  onClick?: () => void;
  sx?: SxProps;
  height?: number;
  width?: number;
  switchSize?: number;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CommonSwitch: FunctionComponent<ICommonSwitch> = (props) => {
  const {
    checked,
    onClick,
    sx,
    height = 22,
    width = 42,
    switchSize = 18, // Recommended: height - 4px
  } = props;

  const theme = useTheme();

  const acterioSwitchStyles = {
    // I leave here the excess of styles to have control over that in the future in case we need it
    mr: '0.5rem',
    width: width,
    height: height,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: `translateX(${width - switchSize - 4}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: switchSize,
      height: switchSize,
    },
    '& .MuiSwitch-track': {
      borderRadius: `calc (${height} / 2)`,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  };

  if (!checked)
    return (
      <IOSSwitch onClick={onClick} sx={{ ...acterioSwitchStyles, ...sx }} />
    );

  return (
    <IOSSwitch
      checked
      onClick={onClick}
      sx={{ ...acterioSwitchStyles, ...sx }}
    />
  );
};

export default CommonSwitch;
