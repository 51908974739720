import React, { FunctionComponent } from 'react';
import { IEvent } from 'utils/models';
import { Grid, Typography } from '@mui/material';
import Day from './Day';
import { Box } from '@mui/system';
import { primary } from 'theme';

interface MonthProps {
  events: IEvent[];
  calendarDays: any;
  monthIndex: number;
}

const Month: FunctionComponent<MonthProps> = (props) => {
  const { events, calendarDays, monthIndex } = props;
  const arr = new Array(
    calendarDays[calendarDays.length - 1][0].$M !== monthIndex ? 5 : 6,
  ).fill([]);
  const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  return (
    <Box marginTop={3}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 80,
          border: 1,
          borderColor: primary.natural6,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          bgcolor: 'white',
        }}>
        {weekDays.map((weekDay: any, index) => (
          <Grid
            key={index}
            item
            xs={12 / 7}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography fontWeight='bold' fontSize={28}>
              {weekDay}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {arr.map((week: any, index: number) => (
        <Grid
          key={index}
          container
          sx={{
            borderRight: 1,
            borderLeft: 1,
            borderColor: primary.natural6,
          }}>
          {calendarDays[index].map((day: any, index: number) => {
            const dayEvents = events.filter((event) => {
              const eventDate = new Date(event.startDate);
              return eventDate.getDate() === day.$D;
            });
            return (
              <Grid key={index} item xs={12 / 7}>
                <Day day={day} monthIndex={monthIndex} events={dayEvents} />
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default Month;
