import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import { useTheme } from '@mui/material';

interface IStaticQuestion {
  text: string;
  number: number;
}

const ApplicationBuilderStaticQuestion: FunctionComponent<IStaticQuestion> = (
  props,
) => {
  const { text, number } = props;

  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          paddingLeft: '3.125rem',
          marginTop: '1.25rem',
        }}>
        <FlexBox
          sx={{
            gap: '1.563rem',
          }}>
          <FlexBox
            className={'center'}
            sx={{
              width: '30px',
              height: '30px',
              backgroundColor: theme.palette.primary.main,
              color: primary.pureWhite,
              borderRadius: '100%',
            }}>
            {number.toString()}
          </FlexBox>
          <FlexBox
            sx={{
              flexDirection: 'column',
              alignItems: 'start',
              borderRadius: '12px',
              boxShadow: theme.shadows[1],
              padding: '1.563rem 1.875rem',
              width: '100%',
            }}>
            <FlexBox
              className={'between'}
              sx={{
                width: '100%',
              }}>
              <InputField
                width={'31.25rem'}
                readonly
                sx={{
                  '& .MuiOutlinedInput-root': {
                    opacity: '200%',
                    '& .MuiInputBase-input': {
                      WebkitTextFillColor: primary.mainBlackFont,
                    },
                  },
                }}
                value={text}
                clearBackground
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Box>
    </Box>
  );
};

export default ApplicationBuilderStaticQuestion;
