import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import {
  CalendarTodayRounded,
  Close,
  LocationOn,
  PeopleAlt,
} from '@mui/icons-material';
import { acterioTheme, primary } from 'theme';
import { formatDateString } from 'utils/utils';
import CommonModal from 'components/common/Modal/CommonModal';
import { useMobileContext } from 'services/contexts/MobileContext';
import RichTextDisplay from 'components/common/RichTextDisplay';
import CloseModalButton from 'components/common/Modal/CloseModalButton';

interface MeetingDetailsProps {
  isOpen: boolean;
  handleClose: () => void;
  startBooking?: () => void;
  isBooked?: boolean;
  cancelBooking?: () => void;
  room: any;
  isPreview?: boolean;
}

const RoomDetails: FunctionComponent<MeetingDetailsProps> = (props) => {
  const {
    isOpen,
    handleClose,
    startBooking,
    room,
    isBooked,
    cancelBooking,
    isPreview,
  } = props;

  const theme = useTheme();

  const { isMobile } = useMobileContext();

  function renderHeader() {
    return (
      <Box
        sx={{
          width: 'calc(100% + 6rem)',
          marginX: '-3rem',
          marginTop: '-4rem',
          marginBottom: '2rem',
          position: 'relative',
          height: '24.688rem',
        }}>
        <img
          src={room.picture}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            left: '1rem',
            top: '2rem',
            width: '3.75rem',
            height: '3.75rem',
            bgcolor: 'white',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '2px 0px 8px 0px #0000001A',
          }}>
          <PeopleAlt sx={{ color: acterioTheme.main }} />
          <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
            {room.capacity}
          </Typography>
        </Box>
        <CloseModalButton
          handleClose={handleClose}
          color={acterioTheme.mainBlackFont}
          background={true}
          bgColor={acterioTheme.pureWhite}
          sx={{
            position: 'absolute',
            top: '2rem',
            right: '1rem',
            zIndex: '9',
          }}
        />
      </Box>
    );
  }

  function renderTitle() {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography
          gutterBottom={isBooked}
          sx={{ fontSize: '1.5rem', fontWeight: 600 }}>
          {room.title}
        </Typography>
        <Box sx={{ display: 'flex', marginTop: '1rem' }}>
          <PeopleAlt sx={{ color: acterioTheme.main }} />
          <Typography
            sx={{ fontWeight: 600, fontSize: '1rem', marginLeft: '0.5rem' }}>
            {room.capacity} people
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '1rem' }}>
          <LocationOn
            sx={{ color: theme.palette.primary.main, marginRight: 1 }}
          />
          <Typography>{room.location}</Typography>
        </Box>
      </Box>
    );
  }

  {
    /*  function renderDetails() {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          marginY: '1rem',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            marginBottom: '1rem',
          }}>
         <Box
            sx={{ display: 'flex', flexDirection: 'row', marginRight: '2rem' }}>
            <CalendarMonth sx={{ color: acterioTheme.main, marginRight: 1 }} />
            <Typography color={acterioTheme.main}>Availability</Typography>
            <Typography
              sx={{
                color: acterioTheme.dark,
                marginLeft: 2,
                bgcolor: acterioTheme.lightHighlight,
                borderRadius: 0.6,
                paddingY: 0.3,
                paddingX: 1.2,
              }}>
              {`${room.startTime} - ${room.endTime}`} 
            </Typography>
          </Box> 
        </Box>
      </Box>
    );
  }*/
  }

  function renderDescription() {
    return (
      <Box
        sx={{
          width: '100%',
          marginTop: 4,
        }}>
        <Box
          sx={{
            bgcolor: theme.palette.primary.main,
            position: 'relative',
            width: '11.313rem',
            height: '1.875rem',
            borderRadius: '6px',
            padding: '0.5rem 1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: -17.5,
            left: 0,
          }}>
          <Typography
            color={primary.pureWhite}
            sx={{ fontWeight: 500, fontSize: '1.25rem', lineHeight: '1.5rem' }}>
            Description
          </Typography>
        </Box>
        <Box
          sx={{
            height: 'max-content',
            overflowWrap: 'break-word',
          }}>
          <RichTextDisplay text={room.description} />
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <>
        {isBooked && (
          <Typography fontSize={18} gutterBottom>
            {`${room.title} is booked for you at: ${room.startTime} - ${
              room.endTime
            } on ${formatDateString(room.date)}`}
          </Typography>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1rem',

            justifyContent: 'flex-end',
          }}>
          {isBooked ? (
            <Button
              sx={{
                bgcolor: '#FFE7E6',
                borderRadius: '6px',
                border: '1px solid #DB9F9D',
                '&:hover': { bgcolor: '#DB9F9D' },
              }}
              onClick={cancelBooking}>
              <Close sx={{ color: '#D25B58', marginRight: 1 }} />
              <Typography sx={{ color: '#D25B58', marginRight: 1 }}>
                Cancel your booking
              </Typography>
            </Button>
          ) : isPreview ? (
            <Typography color={primary.natural2}>
              Book this room in the 'Book a room' tab
            </Typography>
          ) : (
            <Button
              sx={{
                bgcolor: theme.palette.primary.dark,
                borderRadius: '6px',
                '&:hover': { bgcolor: theme.palette.primary.contrastText },
              }}
              onClick={startBooking}>
              <CalendarTodayRounded
                sx={{ color: primary.pureWhite, marginRight: 1 }}
              />
              <Typography sx={{ color: primary.pureWhite, marginRight: 1 }}>
                Start Booking
              </Typography>
            </Button>
          )}
        </Box>
      </>
    );
  }

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'relative',
          top: '5%',
          left: { xs: 0, md: '50%' },
          transform: { md: 'translate(-50%, 0%)' },
          width: isMobile ? '100%' : '43.75rem',
          bgcolor: '#FFFFFF',
          boxShadow: 24,
          padding: '3rem',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          overflow: 'auto',
        }}>
        {renderHeader()}
        {renderTitle()}
        {renderDescription()}
        {renderButtons()}
      </Box>
    </CommonModal>
  );
};

export default RoomDetails;
