import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent, useState } from 'react';
import HeaderButton from './HeaderButton';
import { useNavigate, useParams } from 'react-router';

interface ISwitch {
  firstPage: React.ReactElement;
  secondPage: React.ReactElement;
  firstPageName: string;
  secondPageName: string;
  firstIcon: JSX.Element;
  secondIcon: JSX.Element;
  boxContent?: React.ReactElement;
  isFirstInitiallySelected: boolean;
  sx?: SxProps;
}

const PageSwitch: FunctionComponent<ISwitch> = (props) => {
  const {
    firstPage,
    secondPage,
    firstPageName,
    secondPageName,
    firstIcon,
    secondIcon,
    boxContent,
    isFirstInitiallySelected,
    sx,
  } = props;

  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  const [isFirstSelected, setIsFirstSelected] = useState<boolean>(
    isFirstInitiallySelected,
  );

  function handleFirstClick() {
    setIsFirstSelected(true);
    const url = `/ecosystem/${ecosystemName}/${firstPageName.toLocaleLowerCase()}`;
    navigate(url);
  }

  function handleSecondClick() {
    setIsFirstSelected(false);
    const url = `/ecosystem/${ecosystemName}/${secondPageName.toLocaleLowerCase()}`;
    navigate(url);
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: { xs: '2rem', sm: '1rem' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
          ...sx,
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <HeaderButton
            text={firstPageName}
            onClick={handleFirstClick}
            isSelected={isFirstSelected}
            icon={firstIcon}
            sx={{ mr: '1rem' }}
          />
          <HeaderButton
            text={secondPageName}
            onClick={handleSecondClick}
            isSelected={!isFirstSelected}
            icon={secondIcon}
          />
        </Box>
        <Box>{boxContent && boxContent}</Box>
      </Box>
      {isFirstSelected ? firstPage : secondPage}
    </>
  );
};

export default PageSwitch;
