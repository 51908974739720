import React, { FunctionComponent } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, UseFormClearErrors } from 'react-hook-form';
import { IApplicationQuestion } from '../../../utils/models';
import { colorPallete } from '../../../theme';
import { FormForSend } from './FormWithHook';

interface ApplicationRadioProps {
  answerPath: string;
  control: any;
  pathToQuestions: IApplicationQuestion;
  clearErrors: UseFormClearErrors<FormForSend>;
}

const ApplicationRadio: FunctionComponent<ApplicationRadioProps> = (props) => {
  const { answerPath, control, pathToQuestions, clearErrors } = props;

  const radioAnswerPath = `${answerPath}.radio` as keyof FormForSend;

  const radios = pathToQuestions.dropdownOptions!;

  const radiosName = radios.map((item) => item.name);

  const findElementByName = (name: string) => {
    return radios.find((item) => item.name === name);
  };

  return (
    <FormControl>
      <Controller
        defaultValue={''}
        control={control}
        name={radioAnswerPath}
        rules={{ required: 'Please, pick an option' }}
        render={({ field, fieldState }) => (
          <RadioGroup
            {...field}
            value={field.value.name || ''}
            onChange={(event) => {
              const selectRadio = findElementByName(event.target.value);
              field.onChange(selectRadio);
              clearErrors(radioAnswerPath);
            }}>
            {radiosName.map((item) => {
              return (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={
                    <Radio
                      sx={{
                        color: fieldState.error
                          ? colorPallete.warningRed
                          : 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={item}
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default ApplicationRadio;
