import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import Modal from 'components/common/Modal';
import { Theme, Tooltip, Typography, useTheme } from '@mui/material';
import InputField from 'components/common/InputField';
import { AddToDrive } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import LinkIcon from '@mui/icons-material/Link';
import { postData, RESOURCE_ROUTE } from 'utils/requests';
import { useParams } from 'react-router';
import { IResource } from 'utils/models';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { MeetingRoom, MenuBook } from '@mui/icons-material';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  resourcesContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
    minWidth: '12.5rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    gap: '1.875rem',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '1px',
    gap: '10px',
    paddingTop: '10px',
    marginBottom: (props: any) => (props.isMobile ? '5rem' : '-0.625rem'),
  },
  resourceIcon: {
    padding: '10px',
    background: '#f5f5f5',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '50px!important',
    width: '50px!important',
  },
  selected: {
    background: theme.palette.primary.light,
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '50px!important',
    width: '50px!important',
  },
}));

interface OwnProps {
  showNewResource: boolean;
  updateResources: (resource: IResource, remove: boolean) => any;
  setShowNewResource: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewResourceModal: FunctionComponent<OwnProps> = (props) => {
  const { showNewResource, updateResources, setShowNewResource } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [newResourceName, setNewResourceName] = useState<string>('');
  const [newResourceIcon, setNewResourceIcon] = useState<string>('book');
  const [newResourceUrl, setNewResourceUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const isEmailChosesn = newResourceIcon === 'email-link';

  async function saveNewResource() {
    setLoading(true);

    try {
      const data = await postData(RESOURCE_ROUTE, [
        {
          name: 'resourceName',
          value: newResourceName,
        },
        {
          name: 'resourceUrl',
          value: newResourceUrl,
        },
        {
          name: 'icon',
          value: newResourceIcon,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setNewResourceName('');
      setNewResourceIcon('');
      setNewResourceUrl('');
      updateResources(data, false);
      setShowNewResource(false);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Resource added',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div>
        <InputField
          value={newResourceName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNewResourceName(event.target.value)
          }
          readonly={loading}
          placeholder={'How to...'}
          label={'Resource Name'}
        />
        <InputField
          value={newResourceUrl}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNewResourceUrl(event.target.value)
          }
          readonly={loading}
          placeholder={isEmailChosesn ? 'email@email.com' : 'https://'}
          label={`Resource ${isEmailChosesn ? 'E-mail' : 'URL'}`}
        />
        <Typography variant={'h6'}>Resource icon</Typography>
        <div className={classes.iconContainer}>
          <Tooltip title='Reading resource'>
            <MenuBook
              className={
                newResourceIcon === 'book'
                  ? classes.selected
                  : classes.resourceIcon
              }
              fill={theme.palette.primary.dark}
              onClick={() => setNewResourceIcon('book')}
            />
          </Tooltip>
          <Tooltip title='Google drive'>
            <AddToDrive
              className={
                newResourceIcon === 'google-drive'
                  ? classes.selected
                  : classes.resourceIcon
              }
              fill={theme.palette.primary.dark}
              onClick={() => setNewResourceIcon('google-drive')}
            />
          </Tooltip>
          <Tooltip title='Hyperlink'>
            <LinkIcon
              className={
                newResourceIcon === 'hyperlink'
                  ? classes.selected
                  : classes.resourceIcon
              }
              sx={{ fill: theme.palette.primary.dark }}
              onClick={() => setNewResourceIcon('hyperlink')}
            />
          </Tooltip>
          <Tooltip title='Folder'>
            <FolderIcon
              className={
                newResourceIcon === 'folder'
                  ? classes.selected
                  : classes.resourceIcon
              }
              sx={{ fill: theme.palette.primary.dark }}
              onClick={() => setNewResourceIcon('folder')}
            />
          </Tooltip>
          <Tooltip title='Book meeting room'>
            <MeetingRoom
              className={
                newResourceIcon === 'email-link'
                  ? classes.selected
                  : classes.resourceIcon
              }
              sx={{ fill: theme.palette.primary.dark }}
              onClick={() => setNewResourceIcon('email-link')}
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={showNewResource}
      close={() => setShowNewResource(false)}
      title={'Add a new resource'}
      largeHeader={true}
      backgroundColor={'white'}
      backdropStrength={0.9}
      width={isMobile ? 320 : 670}
      headerColor={theme.palette.primary.light}
      saveAction={() => saveNewResource()}
      cancelButtonText={'Cancel'}
      saveButtonText={'Save'}
      showFooter={true}
      largeFooter={true}
      loading={loading}
      footerButtonsFlexStart={false}
      hideFooterBorder={true}
      footerColor={'none'}>
      {renderContent()}
    </Modal>
  );
};

export default NewResourceModal;
