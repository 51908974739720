import React, {
  createContext,
  FunctionComponent,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { ICompanyProfileTemplate } from 'utils/models';
import {
  CUSTOM_COMPANY_PROFILE_ROUTE,
  deleteData,
  getData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { postData } from 'utils/requests';

const useAssignCompanyProfiles = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [selectedTemplates, setSelectedTemplates] = useState<
    ICompanyProfileTemplate[]
  >([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const [companyProfileTemplates, setCompanyProfileTemplates] = useState<
    ICompanyProfileTemplate[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchcompanyProfileTemplates();
  }, [ecosystemName]);

  async function fetchcompanyProfileTemplates() {
    if (!ecosystemName) return null;
    setIsLoading(true);

    try {
      const data = await getData(CUSTOM_COMPANY_PROFILE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setCompanyProfileTemplates(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function handleDeleteCompanyTemplate(id: number | undefined) {
    if (id === undefined) return;
    deleteCompanyTemplate(id);
  }

  async function deleteCompanyTemplate(id: number) {
    setIsLoading(true);

    try {
      const res = await deleteData(CUSTOM_COMPANY_PROFILE_ROUTE, [
        { name: 'id', value: id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setCompanyProfileTemplates(res);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Company template deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        title: 'Can not delete',
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function addCompany(template: ICompanyProfileTemplate) {
    setSelectedTemplates([...selectedTemplates, template]);
  }

  function removeCompany(template: ICompanyProfileTemplate) {
    const updateTempaltes = selectedTemplates.filter(
      (selectedTemplate) => selectedTemplate.id !== template.id,
    );

    setSelectedTemplates(updateTempaltes);
  }

  function clearAllTemplates() {
    setSelectedTemplates([]);
  }

  function addTemplateId(id: number) {
    setSelectedTemplateId(id);
  }

  function removeTemplateId() {
    setSelectedTemplateId(null);
  }

  function isSelectedTemplate(id: number) {
    return !!selectedTemplateId && selectedTemplateId === id;
  }

  const handleDuplicateTemplate = async (id: number) => {
    setIsLoading(true);

    try {
      const duplicatedTemplate: ICompanyProfileTemplate = await postData(CUSTOM_COMPANY_PROFILE_ROUTE, [
        { name: 'id', value: id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Company template duplicated',
      });
      setCompanyProfileTemplates((prev) => [...prev, duplicatedTemplate]);
    } catch (err: any) {
      console.error('Error:', err);
      addAlert({
        type: ALERT_TYPES.ERROR,
        title: 'Can not duplicate',
        message: err.message,
      });
    }
    setIsLoading(false);
  };

  return {
    isEditing,
    selectedTemplates,
    selectedTemplateId,
    setIsEditing,
    addCompany,
    removeCompany,
    isSelectedTemplate,
    clearAllTemplates,
    addTemplateId,
    removeTemplateId,
    handleDeleteCompanyTemplate,
    deleteCompanyTemplate,
    isLoading,
    companyProfileTemplates,
    setCompanyProfileTemplates,
    setIsLoading,
    setIsDuplicate,
    handleDuplicateTemplate,
  };
};

const AssignCompanyProfilesContext = createContext(
  {} as ReturnType<typeof useAssignCompanyProfiles>,
);

export const AssignCompanyProfilesProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const assignCompanyProfilesContext = useAssignCompanyProfiles();

  return (
    <AssignCompanyProfilesContext.Provider value={assignCompanyProfilesContext}>
      {children}
    </AssignCompanyProfilesContext.Provider>
  );
};

export const useAssignCompanyProfilesContext = () =>
  useContext(AssignCompanyProfilesContext);
