import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import React, { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { IApplicationTemplate } from '../../../utils/models';
import { primary } from '../../../theme';

const DatePickerStyled = styled(DatePicker)(
  ({ errors }: { errors: FieldErrors<IApplicationTemplate> }) => ({
    maxWidth: '10rem',
    '& input': {
      padding: '0.625rem',
      fontSize: '0.9rem',
    },
    '& .MuiIconButton-root': {
      marginLeft: '-0.625rem',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: errors ? primary.warning : 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: errors ? primary.warning : undefined,
    },
  }),
);

const ForwardedDatePickerStyled = forwardRef<HTMLInputElement, any>(
  (props, ref) => (
    <DatePickerStyled
      {...props}
      inputFormat='dd/MM/yyyy'
      format='dd/MM/yyyy'
      inputRef={ref}
    />
  ),
);

ForwardedDatePickerStyled.displayName = 'DatePickerStyled';

export { ForwardedDatePickerStyled as DatePickerStyled };
