import {
  Button,
  Grid,
  Typography,
  ButtonBase,
  Link,
  Popover,
  Divider,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent, useState } from 'react';
import { IEvent } from 'utils/models';
import { primary, acterioTheme } from 'theme';
import MoreOptionsOverlay from '../common/MoreOptionsButton/MoreOptionsOverlay';
import {
  CalendarMonthOutlined,
  LocationOn,
  People,
  WatchLater,
  EventRounded,
  DeleteRounded,
  ControlPointDuplicate,
  ModeRounded,
  DevicesRounded,
  Work,
  Google,
  Drafts,
  CheckCircleOutlineRounded,
} from '@mui/icons-material';
import CommonButton, {
  ButtonVariant,
  ButtonSize,
} from '../common/CommonButton';
import { useNavigate, useParams } from 'react-router';
import { getAccount, getStoredEvents } from 'utils/storage';
import { defaultEventPhoto } from 'utils/utils';
import EventAttendees from './EventAttendees';
import TagDisplay from '../common/Tags/TagDisplay';
import Loading from '../common/Loading';
import CommonModal from '../common/Modal/CommonModal';
import CloseModalButton from '../common/Modal/CloseModalButton';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import RichTextDisplay from '../common/RichTextDisplay';
import CardTag from '../common/CardTag';
import DateTag from './DateTag';
import { IListButton } from 'utils/models';
import MoreOptionsButtons from '../common/MoreOptionsButton/MoreOptionsButtons';
import EventBackgroundBlur from 'assests/EventBackgroundBlur';
import ConfirmationForm from '../common/ConfirmationForm';
import useDeleteEvent from 'hooks/Events/useDeleteEvent';
import TimezoneOffsetDisplay from '../common/TimezoneOffsetDisplay';

interface EventDetailsProps {
  isOpen: boolean;
  handleClose: () => void;
  event: IEvent;
}

const EventDetails: FunctionComponent<EventDetailsProps> = (props) => {
  const { isOpen, handleClose, event } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const userId = getAccount().userId;

  const {
    putIsAttending,
    isAttendingLoading,
    setChosenEvent,
    setIsShowEdit,
    setIsDuplicate,
  } = useEventsContext();

  const storageEvents = getStoredEvents();
  const storedEvent =
    storageEvents && storageEvents.find((e) => e.id === event.id);

  const eventAttendingList = storedEvent?.attendees || event.attendees;

  const isAttending = eventAttendingList
    ? eventAttendingList.some((person) => person.id === userId)
    : false;

  const [isShowAttendess, setIsShowAttendees] = useState<boolean>(false);
  const [popoverAnchorEl1, setPopoverAnchorEl1] = useState<HTMLElement | null>(
    null,
  );
  const [popoverAnchorEl2, setPopoverAnchorEl2] = useState<HTMLElement | null>(
    null,
  );
  const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false);
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteProps = { event, setIsLoading, handleClose };
  const deleteEvent = useDeleteEvent({ ...deleteProps });

  const googleMapsLocation = `https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`;

  function callShowEditEvent(isDuplicate: boolean) {
    setIsShowEdit(true);
    setChosenEvent(event);
    setPopoverAnchorEl1(null);
    if (!isDuplicate) return;
    setIsDuplicate(true);
  }

  function openConfrimDelte() {
    setIsShowDeleteConfirm(true);
    setPopoverAnchorEl1(null);
  }

  function convertToUTCDateTime(startDate: string, time?: string) {
    if (!startDate) return '';

    const tTime = time || '00:00';
    const [hours, minutes] = tTime.split(':').map(Number);

    const eventDate = new Date(startDate);
    eventDate.setHours(hours, minutes, 0, 0);

    return eventDate.toISOString().replace(/\.\d{3}Z$/, 'Z'); // Ensures consistent format
  }

  function generateCalendarUrl(
    baseURL: string,
    event: IEvent,
    dateFormat: (dateTimeStr: string) => string,
  ): string {
    const {
      title,
      description,
      startDate,
      startTime,
      multiDay,
      endDate,
      endTime,
      location,
    } = event;

    const startDateTime = convertToUTCDateTime(startDate, startTime);
    const endDateTime = multiDay
      ? convertToUTCDateTime(endDate, endTime)
      : convertToUTCDateTime(startDate, endTime);

    const formattedStart = dateFormat(startDateTime);
    const formattedEnd = dateFormat(endDateTime);

    return `${baseURL}&subject=${encodeURIComponent(
      title,
    )}&startdt=${encodeURIComponent(formattedStart)}&enddt=${encodeURIComponent(
      formattedEnd,
    )}&body=${encodeURIComponent(description)}&location=${encodeURIComponent(
      location,
    )}`;
  }

  function generateGoogleCalendarUrl(event: IEvent): string {
    const {
      title,
      description,
      startDate,
      startTime,
      multiDay,
      endDate,
      endTime,
      location,
    } = event;

    function convertToUTCDateTime(
      startDate: string | undefined,
      time: string | undefined,
    ): string {
      if (startDate === undefined) {
        return '';
      }

      const tTime = time ?? '00:00';

      const [dateOfStart, startTimeStr] = [startDate, tTime];

      const [hours, minutes] = startTimeStr.split(':').map(Number);

      const eventDate = new Date(dateOfStart);
      eventDate.setHours(hours);
      eventDate.setMinutes(minutes);

      const year = eventDate.getUTCFullYear();
      const month = String(eventDate.getUTCMonth() + 1).padStart(2, '0');
      const day = String(eventDate.getUTCDate()).padStart(2, '0');
      const hoursUTC = String(eventDate.getUTCHours()).padStart(2, '0');
      const minutesUTC = String(eventDate.getUTCMinutes()).padStart(2, '0');

      return `${year}${month}${day}T${hoursUTC}${minutesUTC}00Z`;
    }

    const startDateTime = convertToUTCDateTime(startDate, startTime);
    const endDateTime = multiDay
      ? convertToUTCDateTime(endDate, endTime)
      : convertToUTCDateTime(startDate, endTime);

    const dateTime = `${startDateTime}/${endDateTime}`;

    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title,
    )}&dates=${dateTime}&details=${encodeURIComponent(
      description,
    )}&location=${encodeURIComponent(location)}&sf=true&output=xml`;

    return googleCalendarUrl;
  }

  function generateOutlookCalendarUrl(event: IEvent) {
    const formatDateTime = (dt: any) => dt; // Outlook uses ISO string directly
    return generateCalendarUrl(
      'https://outlook.live.com/owa/?path=/calendar/action/compose',
      event,
      formatDateTime,
    );
  }

  function generateBusinessOutlookCalendarUrl(event: IEvent) {
    const formatDateTime = (dt: any) => dt; // Outlook uses ISO string directly
    return generateCalendarUrl(
      'https://outlook.office.com/owa/?path=/calendar/action/compose',
      event,
      formatDateTime,
    );
  }

  function renderHeaderContainer() {
    return (
      <>
        <img
          src={event.photo ?? defaultEventPhoto}
          alt='Event'
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderTopLeftRadius: '0.2rem',
            borderTopRightRadius: '0.2rem',
          }}
        />
        <EventBackgroundBlur />
        <Box
          sx={{
            position: 'absolute',
            bottom: '1.25rem',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem',
            marginX: '1.25rem',
          }}>
          <DateTag
            date={event.startDate}
            fontSizeMonth={'1rem'}
            fontSizeDay={'1.25rem'}
            sx={{
              width: '5rem',
              height: '5rem',
            }}
          />
          <Typography
            variant='h5'
            color={acterioTheme.pureWhite}
            maxWidth={'500px'}
            width={'100%'}
            lineHeight={'normal'}
            fontSize={'1.875rem'}>
            {event.title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.875rem',
            }}>
            {event.location !== '' && event.location !== null && (
              <Box display='flex' alignItems='center'>
                <LocationOn
                  sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    marginRight: 1.5,
                  }}
                />
                <Link
                  onClick={() => {
                    setIsShowConfirmForm(true);
                  }}
                  fontSize='1rem'
                  color={acterioTheme.pureWhite}
                  sx={{
                    cursor: 'pointer',
                  }}>
                  {event.location}
                </Link>
              </Box>
            )}
            <Box display='flex' alignItems='center'>
              {event.multiDay ? (
                <CalendarMonthOutlined
                  sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    marginRight: 1.5,
                  }}
                />
              ) : (
                <WatchLater
                  sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    marginRight: 1.5,
                  }}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  color={acterioTheme.pureWhite}
                  sx={{
                    fontSize: '1rem',
                  }}>
                  {event.multiDay
                    ? `${event.startTime ? event.startTime : ''} ${
                        event.startDateString
                      } - ${event.endTime ? event.endTime : ''} ${
                        event.endDateString
                      }`
                    : event.time ?? `${event.startTime} - ${event.endTime}`}
                </Typography>
                <TimezoneOffsetDisplay color={primary.pureWhite} />
              </Box>
            </Box>
            <Box display='flex' alignItems='center'>
              <ButtonBase onClick={() => setIsShowAttendees(true)}>
                <People
                  sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    marginRight: 1,
                  }}
                />
                <Box
                  height='100%'
                  width={`calc(${
                    eventAttendingList?.length
                      ? eventAttendingList.length * 1.5
                      : 0
                  }rem)`}
                  position='relative'
                  marginLeft={eventAttendingList?.length ? 2 : 0.5}>
                  {eventAttendingList
                    ?.slice(0, 5)
                    .map((attendee: any, i: number) => (
                      <Box
                        key={`${attendee.id}-${i}`}
                        position='absolute'
                        top='-1rem'
                        left={`${i * 1.5}rem`}
                        height='2rem'
                        width='2rem'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        border='0.5px solid #fff'
                        borderRadius='50%'
                        zIndex={eventAttendingList.length + i}
                        marginLeft='-0.75rem'>
                        <img
                          src={attendee.profilePic}
                          alt={attendee.fullName}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    ))}
                </Box>
                <Typography
                  fontSize={'1rem'}
                  color={acterioTheme.pureWhite}
                  whiteSpace='nowrap'
                  sx={{
                    textDecoration: 'underline',
                  }}>
                  {eventAttendingList?.length === 1
                    ? '1 person going'
                    : `${eventAttendingList?.length} people going`}
                </Typography>
              </ButtonBase>
              {isShowAttendess && (
                <EventAttendees
                  isOpen={isShowAttendess}
                  handleClose={() => setIsShowAttendees(false)}
                  attendees={eventAttendingList}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: '440px',
              display: 'flex',
              flexWrap: 'wrap',
              overflow: 'hidden',
            }}>
            <TagDisplay tags={event.tags} oneRow maxTags={3} />
          </Box>
        </Box>
      </>
    );
  }

  function handleIsGoing() {
    putIsAttending(event, userId);
  }

  function renderActionButtons() {
    function renderPopover() {
      return (
        <Popover
          open={Boolean(popoverAnchorEl2)}
          anchorEl={popoverAnchorEl2}
          onClose={() => setPopoverAnchorEl2(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
            <Button
              variant='contained'
              onClick={() =>
                window.open(generateGoogleCalendarUrl(event), '_blank')
              }
              sx={{
                justifyContent: 'start',
                color: primary.pureWhite,
                bgcolor: theme.palette.primary.dark,
                marginRight: '1rem',
                borderRadius: '6px',
                minWidth: 200,
                marginBottom: 0.4,
                marginTop: 0.4,
                '&:hover': {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
              }}>
              <Google sx={{ marginRight: 1 }} />
              <Typography color='inherit'>Google calendar</Typography>
            </Button>
            <Button
              variant='contained'
              onClick={() =>
                window.open(generateOutlookCalendarUrl(event), '_blank')
              }
              sx={{
                justifyContent: 'start',
                color: primary.pureWhite,
                bgcolor: theme.palette.primary.dark,
                marginRight: '1rem',
                borderRadius: '6px',
                minWidth: 200,
                marginBottom: 0.4,
                '&:hover': {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
              }}>
              <Drafts sx={{ marginRight: 1 }} />
              <Typography color={'inherit'}>Outlook calendar</Typography>
            </Button>
            <Button
              variant='contained'
              onClick={() =>
                window.open(generateBusinessOutlookCalendarUrl(event), '_blank')
              }
              sx={{
                justifyContent: 'start',
                color: primary.pureWhite,
                bgcolor: theme.palette.primary.dark,
                marginRight: '1rem',
                borderRadius: '6px',
                minWidth: 200,
                '&:hover': {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                },
              }}>
              <Work sx={{ marginRight: 1 }} />
              <Typography color='inherit'>Outlook office</Typography>
            </Button>
          </Box>
        </Popover>
      );
    }
    return (
      <Box
        sx={{
          width: { xs: '20rem', lg: '43.75rem' },
          height: { xs: '9.4rem', lg: '100%' },
          paddingX: { lg: '1rem' },
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          alignItems: {
            lg: 'flex-end',
          },
          justifyContent: { lg: 'flex-end' },
          mt: { xs: 'none', lg: '6rem' },
        }}>
        <CommonButton
          text={'Add to calendar'}
          onClick={(event) => setPopoverAnchorEl2(event.currentTarget)}
          variant={ButtonVariant.BLANK}
          size={{ xs: ButtonSize.SMALL, lg: ButtonSize.LARGE }}
          sx={{
            marginX: '0.5rem',
            width: '13rem',
            marginY: { xs: '0.5rem', md: 0 },
          }}
          icon={<EventRounded />}
        />
        {renderPopover()}
        {event.url && (
          <CommonButton
            text={'Access virtual meeting'}
            onClick={() => window.open(`${event.url}`, '_blank')}
            variant={ButtonVariant.BLANK}
            size={{ xs: ButtonSize.SMALL, lg: ButtonSize.LARGE }}
            sx={{
              marginX: '0.5rem',
              width: '13rem',
              marginY: { xs: '0.5rem', lg: 0 },
            }}
            icon={<DevicesRounded />}
          />
        )}
        {isAttending ? (
          <CommonButton
            text={'Going'}
            onClick={() => handleIsGoing()}
            isLoading={isAttendingLoading}
            isDisabled={isAttendingLoading}
            size={{ xs: ButtonSize.SMALL, lg: ButtonSize.LARGE }}
            icon={<CheckCircleOutlineRounded />}
            sx={{
              marginX: '0.5rem',
              width: '13rem',
              marginY: { xs: '0.5rem', lg: 0 },
            }}
          />
        ) : (
          <CommonButton
            text={'Confirm attendance'}
            onClick={() => handleIsGoing()}
            isLoading={isAttendingLoading}
            isDisabled={isAttendingLoading}
            size={{ xs: ButtonSize.SMALL, lg: ButtonSize.LARGE }}
            sx={{
              marginX: '0.5rem',
              width: '13rem',
              marginY: { xs: '0.5rem', lg: 0 },
            }}
          />
        )}
      </Box>
    );
  }

  function renderContactPerson() {
    return (
      <>
        {event.contactPerson && (
          <Grid
            item
            sx={{
              marginTop: { xs: '1rem', lg: '' },
              width: { xs: '20rem', lg: 'fit-content' },
            }}>
            <Box sx={{ marginBottom: { xs: '1rem', lg: '0' } }}>
              <CardTag text='Coordinator' />
              <Box
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  event.contactPerson !== undefined &&
                  navigate(
                    `/ecosystem/${ecosystemName}/members/${event.contactPerson.id}`,
                  )
                }>
                <img
                  src={event.contactPerson.profilePic}
                  style={{
                    height: '3rem',
                    width: '3rem',
                    borderRadius: '50%',
                    marginRight: '1rem',
                  }}
                />
                <div>
                  <Typography variant={'h6'}>
                    {event.contactPerson.fullName}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    sx={{
                      paddingTop: '0.5rem',
                      marginTop: '-0.375rem',
                      color: '#9e9e9e',
                    }}>
                    {event.contactPerson.accessLevelName}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Grid>
        )}
      </>
    );
  }

  const buttonList: IListButton[] = [
    {
      name: 'Edit',
      onClick: () => callShowEditEvent(false),
      icon: ModeRounded,
    },
    {
      name: 'Duplicate',
      onClick: () => callShowEditEvent(true),
      icon: ControlPointDuplicate,
    },
    {
      name: 'Delete',
      onClick: openConfrimDelte,
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  function renderDescription() {
    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <CardTag text='About the event' />
          {event.canUserEdit && (
            <MoreOptionsOverlay
              popoverAnchorEl={popoverAnchorEl1}
              setPopoverAnchorEl={setPopoverAnchorEl1}>
              <MoreOptionsButtons buttonList={buttonList} />
            </MoreOptionsOverlay>
          )}
        </Box>
        <RichTextDisplay text={event.description} />
        <TagDisplay tags={event.tags} />
        {isShowConfirmForm && (
          <ConfirmationForm
            isOpen={isShowConfirmForm}
            handleClose={() => {
              setIsShowConfirmForm(false);
            }}
            funcExe={() => window.open(`${googleMapsLocation}`, '_blank')}
            text={'open the link leading outside of the website?'}
            isLoading={isLoading}
          />
        )}
        {isShowDeleteConfirm && (
          <ConfirmationForm
            isOpen={isShowDeleteConfirm}
            handleClose={() => {
              setIsShowDeleteConfirm(false);
            }}
            funcExe={deleteEvent}
            text={'to delete this post?'}
            isLoading={isLoading}
            isWarning
          />
        )}
      </>
    );
  }

  return (
    <CommonModal
      open={isOpen}
      onClose={handleClose}
      disableScrollLock={true}
      sx={{ overflow: 'scroll' }}>
      <Box
        sx={{
          position: 'relative',
          top: { md: '5vh' },
          left: { xs: 0, md: '50%' },
          transform: { md: 'translate(-50%, 0%)' },
          maxWidth: { xs: '100%', md: '744px', lg: '1060px' },
          overflow: 'hidden',
          bgcolor: '#FFFFFF',
          boxShadow: 24,
          p: 4,
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}>
        <CloseModalButton
          handleClose={handleClose}
          color={acterioTheme.mainBlackFont}
          background={true}
          bgColor={acterioTheme.pureWhite}
          hoverColor={theme.palette.primary.contrastText}
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            zIndex: '9',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            height: { xs: '315px', md: '450px' },
            marginTop: -4,
            marginX: -4,
          }}>
          {renderHeaderContainer()}
        </Box>
        {isLoading ? (
          <Loading height={200} />
        ) : (
          <>
            <Box sx={{ height: '100%', marginTop: '1rem', minHeight: '9rem' }}>
              {renderDescription()}
            </Box>
            <Divider sx={{ color: primary.natural2, mt: '1rem' }} />
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  lg: 'row',
                },
                alignItems: 'center',
                justifyContent: {
                  xs: 'center',
                  lg: 'space-between',
                },
              }}>
              <Box>{renderContactPerson()}</Box>
              <Box>{renderActionButtons()}</Box>
            </Box>
          </>
        )}
      </Box>
    </CommonModal>
  );
};

export default EventDetails;
