import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ApplicationQuestionTypes,
  IApplicationQuestion,
  IDropdownOptions,
  ITag,
} from '../../../utils/models';
import { Box } from '@mui/system';
import { Controller, UseFormClearErrors } from 'react-hook-form';
import SelectMultipleDropdown from '../../common/SelectMultipleDropdown';
import { Typography } from '@mui/material';
import { FormForSend } from './FormWithHook';
import { colorPallete, naturalPallete } from '../../../theme';
import { get } from 'lodash';

interface ApplicationSelectProps {
  control: any;
  pathToQuestions: IApplicationQuestion;
  answerPath: string;
  type: ApplicationQuestionTypes;
  clearErrors: UseFormClearErrors<FormForSend>;
  filledForm: FormForSend | null;
}

const ApplicationSelect: FunctionComponent<ApplicationSelectProps> = (
  props,
) => {
  const {
    control,
    answerPath,
    pathToQuestions,
    type,
    clearErrors,
    filledForm,
  } = props;
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  let selectAnswerPath = '' as keyof FormForSend;

  let options: ITag[] | IDropdownOptions[] = [];

  if (type === ApplicationQuestionTypes.TAGS) {
    options = pathToQuestions.tagGroup!.tags;
    selectAnswerPath = `${answerPath}.tags` as keyof FormForSend;
  } else if (type === ApplicationQuestionTypes.CHECKBOX) {
    options = pathToQuestions.dropdownOptions!;
    selectAnswerPath = `${answerPath}.selectedOptions` as keyof FormForSend;
  }

  const namesForDropdown = options.map((item) => item.name);

  useEffect(() => {
    const valueAtPath = get(filledForm, selectAnswerPath) as unknown as
      | ITag[]
      | IDropdownOptions[];
    if (valueAtPath && valueAtPath.length > 0) {
      const initialValues = valueAtPath.map(
        (item: ITag | IDropdownOptions) => item.name,
      );
      setSelectedFields(initialValues);
    } else {
      setSelectedFields([]);
    }
  }, []);

  const findElementsByNames = (names: string[]) => {
    return names
      .map((name) => options.find((item) => item.name === name))
      .filter((tag) => tag !== undefined);
  };

  return (
    <Box>
      <Controller
        control={control}
        name={selectAnswerPath}
        rules={{ required: 'Please, pick an option' }}
        render={({ field, fieldState }) => (
          <SelectMultipleDropdown
            {...field}
            placeholder={'Select'}
            isEmpty
            isClearButtonOff
            value={selectedFields}
            onChange={(value) => {
              setSelectedFields(value);
              const selectedTags = findElementsByNames(value);
              field.onChange(selectedTags);
              clearErrors(selectAnswerPath);
            }}
            options={namesForDropdown}
            menuStyles={{
              maxHeight: '14.25rem',
              top: '-0.7rem',
              '& .MuiMenuItem-root': {
                backgroundColor: 'transparent',
                '&.Mui-selected, &:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiCheckbox-root': {
                  color: 'black',
                },
              },
            }}
            sx={{
              borderRadius: '0px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${
                  fieldState.error
                    ? colorPallete.warningRed
                    : naturalPallete.natural3
                }`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: `1px solid ${
                    fieldState.error
                      ? colorPallete.warningRed
                      : naturalPallete.natural3
                  }`,
                },
            }}
          />
        )}
      />
      <Typography
        sx={{ fontStyle: 'italic', color: 'grey', marginTop: '1rem' }}>
        {selectedFields.join(', ')}
      </Typography>
    </Box>
  );
};

export default ApplicationSelect;
