import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  IJourney,
  IJourneyGoal,
  IJourneyMilestone,
  IJourneyResources,
  IJourneyStage,
} from 'utils/models';
import Loading from '../common/Loading';
import useJourney from 'hooks/Journey/useJourney';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import {
  getData,
  JOURNEY_COMPANY_EMPLOYEES,
  JOURNEY_COMPANY_SET_CUSTOM_GOAL_RESOURCES,
  JOURNEY_COMPANY_SET_GOAL_PROGRESS_ROUTE,
  JOURNEY_SET_DEADLINE_ROUTE,
  JOURNEY_SET_LOCK_STATUS,
  JOURNEY_SET_PEOPLE_ROUTE,
  JOURNEY_UPDATE_STAGE_ROUTE,
  postData,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import {
  getProgressKey,
  INITIAL_STAGE_PROGRESS,
  StageProgress,
} from './JourneyProgressBar';
import { IJourneyPerson } from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';

const useJourneyTemplate = (
  loadedJourney: IJourney | null,
  companyName: string | null,
) => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [companyMembers, setCompanyMembers] = useState<IJourneyPerson[]>([]);
  const [journey, setJourney] = useState<IJourney | null>(loadedJourney);
  const [stage, setStage] = useState<IJourneyStage | null>(
    !journey ? null : journey.stages[0],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSmallLoading, setIsSmallLoading] = useState<boolean>(false);
  const [updatingDeadlines, setUpdatingDeadlines] = useState<number[]>([]);

  const [isEditJourneyContext, setIsEditJourneyContext] =
    useState<boolean>(false);
  const [isEditWarningWindowOpen, setIsEditWarningWindowOpen] =
    useState<boolean>(false);
  const [savedStageCopy, setSavedStageCopy] = useState<IJourneyStage | null>(
    null,
  );

  const stages = journey?.stages || [];
  const activeStageId = stage?.id;

  useEffect(() => {
    if (loadedJourney) {
      getCompanyMembers();
      setJourney(loadedJourney);
      setStage(loadedJourney.stages[0]);
    }
  }, [loadedJourney]);

  useEffect(() => {
    if (stage !== null) {
      setSavedStageCopy(JSON.parse(JSON.stringify(stage)));
    }
  }, [isEditJourneyContext]);

  function revertChanges() {
    if (savedStageCopy !== null) {
      setStage(JSON.parse(JSON.stringify(savedStageCopy)));
    }
    setIsEditJourneyContext(false);
  }

  async function updateStageGoalProgress(
    themeIndex: number,
    milestoneIndex: number,
    goalId: number | undefined,
    goalProgress: StageProgress,
    isCustom: boolean | undefined,
    isCustomMilestone: boolean | undefined,
  ) {
    if (!stage || !companyName) return;

    const customBoolean = isCustom ?? false;
    const customMilestoneBoolean = isCustomMilestone ?? false;
    try {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];

      if (customBoolean && customMilestoneBoolean) {
        const milestones =
          updatedTheme.customMilestones &&
          updatedTheme.customMilestones[milestoneIndex];
        if (milestones === undefined) return;
        milestones.customGoals = milestones.customGoals?.map((goal) => {
          if (goal.id === goalId) goal.progress = goalProgress;
          return goal;
        });
      } else if (isCustom) {
        const milestones = updatedTheme.milestones[milestoneIndex];
        milestones.customGoals =
          milestones.customGoals &&
          milestones.customGoals.map((goal) => {
            if (goal.id === goalId) goal.progress = goalProgress;
            return goal;
          });
      } else {
        const milestones = updatedTheme.milestones[milestoneIndex];
        milestones.goals = milestones.goals.map((goal) => {
          if (goal.id === goalId) goal.progress = goalProgress;
          return goal;
        });
      }

      setStage({ ...stage, themes: updatedThemes });

      await postData(JOURNEY_COMPANY_SET_GOAL_PROGRESS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'goalId', value: goalId },
        { name: 'goalProgress', value: goalProgress },
        { name: 'custom', value: customBoolean },
      ]);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function updateCustomGoalResources(
    isCustomMilestone: boolean | undefined,
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
    resources: IJourneyResources,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];
    let updatedMilestone;

    if (isCustomMilestone && updatedTheme.customMilestones) {
      updatedMilestone = updatedTheme.customMilestones[milestoneIndex];
    } else {
      updatedMilestone = updatedTheme.milestones[milestoneIndex];
    }

    if (updatedMilestone.customGoals) {
      updatedMilestone.customGoals[goalIndex] = {
        ...updatedMilestone.customGoals[goalIndex],
        resources,
      };
    }
    setStage({ ...stage, themes: updatedThemes });
  }

  async function putCustomGoalResources(
    isCustomMilestone: boolean | undefined,
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
    goalId: number | undefined,
    resources: IJourneyResources,
  ) {
    if (!stage || !companyName) return;
    try {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      let updatedMilestone;

      if (isCustomMilestone && updatedTheme.customMilestones) {
        updatedMilestone = updatedTheme.customMilestones[milestoneIndex];
      } else {
        updatedMilestone = updatedTheme.milestones[milestoneIndex];
      }

      if (updatedMilestone.customGoals) {
        updatedMilestone.customGoals[goalIndex] = {
          ...updatedMilestone.customGoals[goalIndex],
          resources,
        };
      }

      setStage({ ...stage, themes: updatedThemes });

      await postData(
        JOURNEY_COMPANY_SET_CUSTOM_GOAL_RESOURCES,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'companyName', value: encodeURIComponent(companyName) },
          { name: 'goalId', value: goalId },
        ],
        resources,
      );
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Changes saved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function isStageCompleted() {
    if (!stage) return false;

    const { themes } = stage;
    const stageProgressCopy = { ...INITIAL_STAGE_PROGRESS };

    themes.forEach(({ milestones }) =>
      milestones.forEach(({ goals }) => {
        if (!goals || goals.length === 0) return;

        goals.forEach(({ progress }) => {
          const key = getProgressKey(progress);
          stageProgressCopy[key] = stageProgressCopy[key] + 1;
        });
      }),
    );

    const { todo, inProgress, finished } = stageProgressCopy;
    const totalGoals = todo + inProgress + finished;
    return totalGoals === 0 ? false : finished === totalGoals;
  }

  async function putDeadline(
    deadlineDate: Date | null,
    goalId: number | undefined,
    isCustom: boolean | undefined,
  ) {
    if (!deadlineDate || !goalId || !companyName) {
      return;
    }
    setUpdatingDeadlines((prev) => [...prev, goalId]);
    setIsSmallLoading(true);
    const dateString = formatDateToString(deadlineDate);

    try {
      const customBoolean = isCustom ?? false;
      await putData(JOURNEY_SET_DEADLINE_ROUTE, [
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'goalId', value: goalId },
        { name: 'localDate', value: dateString },
        { name: 'custom', value: customBoolean },
      ]);

      if (!journey || !stage) return;

      // Function to update goals with the new deadline
      const updateGoals = (
        goals: IJourneyGoal[] | undefined,
        deadlineString: string,
      ): IJourneyGoal[] => {
        return (
          goals?.map((goal) => {
            if (goal.id === goalId) {
              return { ...goal, deadline: deadlineString };
            }
            return goal;
          }) || []
        );
      };

      // Update function for both custom and standard milestones
      const updateMilestones = (
        milestones: IJourneyMilestone[] | undefined,
        goalsKey: 'goals' | 'customGoals',
      ): IJourneyMilestone[] => {
        return (
          milestones?.map((milestone) => ({
            ...milestone,
            [goalsKey]: updateGoals(milestone[goalsKey], dateString),
          })) || []
        );
      };

      // Update the journey and stage
      const updatedJourney: IJourney = {
        ...journey,
        stages: journey.stages.map((s) => ({
          ...s,
          themes: s.themes.map((theme) => ({
            ...theme,
            milestones: updateMilestones(theme.milestones, 'goals'),
            customMilestones: customBoolean
              ? updateMilestones(theme.customMilestones, 'customGoals')
              : theme.customMilestones,
          })),
        })),
      };

      setJourney(updatedJourney);

      const updatedStage: IJourneyStage = {
        ...stage,
        themes: stage.themes.map((theme) => ({
          ...theme,
          milestones: updateMilestones(theme.milestones, 'goals'),
          customMilestones: customBoolean
            ? updateMilestones(theme.customMilestones, 'customGoals')
            : theme.customMilestones,
        })),
      };

      setStage(updatedStage);

      setUpdatingDeadlines((prev) => prev.filter((item) => item !== goalId));
      addAlert({ type: ALERT_TYPES.SUCCESS, message: 'Deadline Updated' });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsSmallLoading(false);
  }

  // Helper function to format Date to string
  const formatDateToString = (date: Date | null): string => {
    if (!date) return '';
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    );
  };

  async function putPeople(
    goalId: number,
    people: IJourneyPerson[],
    isCustom: boolean | undefined,
    close: () => void,
  ) {
    if (!companyName) return;

    setIsSmallLoading(true);
    const userIdList = people.map((person) => person.id);

    try {
      const customBoolean = isCustom ?? false;

      await putData(JOURNEY_SET_PEOPLE_ROUTE, [
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'goalId', value: goalId },
        { name: 'userIdList', value: userIdList },
        { name: 'custom', value: customBoolean },
      ]);

      if (!journey || !stage) return;

      const updateGoals = (
        goals: IJourneyGoal[] | undefined,
      ): IJourneyGoal[] => {
        return (
          goals?.map((goal) => {
            if (goal.id === goalId) {
              return { ...goal, people };
            }
            return goal;
          }) || []
        );
      };

      const updateMilestones = (
        milestones: IJourneyMilestone[] | undefined,
        key: 'goals' | 'customGoals',
      ): IJourneyMilestone[] => {
        return (
          milestones?.map((milestone) => ({
            ...milestone,
            [key]: updateGoals(milestone[key]),
          })) || []
        );
      };

      const updatedPeopleJourney: IJourney = {
        ...journey,
        stages: journey.stages.map((s) => ({
          ...s,
          themes: s.themes.map((theme) => ({
            ...theme,
            milestones: updateMilestones(theme.milestones, 'goals'),
            customMilestones: customBoolean
              ? updateMilestones(theme.customMilestones, 'customGoals')
              : theme.customMilestones,
          })),
        })),
      };

      setJourney(updatedPeopleJourney);

      const updatedPeopleStage: IJourneyStage = {
        ...stage,
        themes: stage.themes.map((theme) => ({
          ...theme,
          milestones: updateMilestones(theme.milestones, 'goals'),
          customMilestones: customBoolean
            ? updateMilestones(theme.customMilestones, 'customGoals')
            : theme.customMilestones,
        })),
      };

      setStage(updatedPeopleStage);

      close();

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Assigned Members Updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsSmallLoading(false);
  }

  async function getCompanyMembers() {
    if (!companyName) return;

    try {
      const data = await getData(JOURNEY_COMPANY_EMPLOYEES, [
        {
          name: 'companyName',
          value: encodeURIComponent(companyName),
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setCompanyMembers(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function addCustomMilestone(themeIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];

    const newCustomMilestone: IJourneyMilestone = {
      name: '',
      goals: [],
      customGoals: [],
    };

    if (!updatedTheme.customMilestones) {
      updatedTheme.customMilestones = [newCustomMilestone];
    } else {
      updatedTheme.customMilestones.push(newCustomMilestone);
    }

    setStage({ ...stage, themes: updatedThemes });
  }

  function editCustomMilestone(
    themeIndex: number,
    milestoneIndex: number,
    newName: string,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];

    if (
      updatedTheme.customMilestones &&
      updatedTheme.customMilestones.length > milestoneIndex
    ) {
      const updatedMilestone = updatedTheme.customMilestones[milestoneIndex];
      updatedMilestone.name = newName;
    }

    setStage({ ...stage, themes: updatedThemes });
  }

  function deleteCustomMilestone(themeIndex: number, milestoneIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];

    if (
      updatedTheme.customMilestones &&
      updatedTheme.customMilestones.length > milestoneIndex
    ) {
      updatedTheme.customMilestones.splice(milestoneIndex, 1);
    }

    setStage({ ...stage, themes: updatedThemes });
  }

  function addCustomGoal(
    themeIndex: number,
    milestoneIndex: number,
    isCustom: boolean | undefined,
  ) {
    if (!stage) return;

    if (isCustom) {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      if (updatedTheme.customMilestones !== undefined) {
        const updatedMilestone = {
          ...updatedTheme.customMilestones[milestoneIndex],
        };

        const newCustomGoal: IJourneyGoal = {
          name: '',
          resources: {
            files: [],
            links: [],
            persons: [],
          },
        };

        if (!updatedMilestone.customGoals) {
          updatedMilestone.customGoals = [newCustomGoal];
        } else {
          updatedMilestone.customGoals = [
            ...updatedMilestone.customGoals,
            newCustomGoal,
          ];
        }

        updatedTheme.customMilestones[milestoneIndex] = updatedMilestone;
        updatedThemes[themeIndex] = updatedTheme;

        setStage({ ...stage, themes: updatedThemes });
      }
    } else {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      const updatedMilestone = { ...updatedTheme.milestones[milestoneIndex] };

      const newCustomGoal: IJourneyGoal = {
        name: '',
        resources: {
          files: [],
          links: [],
          persons: [],
        },
      };

      if (!updatedMilestone.customGoals) {
        updatedMilestone.customGoals = [newCustomGoal];
      } else {
        updatedMilestone.customGoals = [
          ...updatedMilestone.customGoals,
          newCustomGoal,
        ];
      }

      updatedTheme.milestones[milestoneIndex] = updatedMilestone;
      updatedThemes[themeIndex] = updatedTheme;

      setStage({ ...stage, themes: updatedThemes });
    }
  }

  function editCustomGoal(
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
    newName: string,
    isCustomMilestone: boolean | undefined,
  ) {
    if (!stage) return;

    if (isCustomMilestone) {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      if (updatedTheme.customMilestones !== undefined) {
        const updatedMilestone = updatedTheme.customMilestones[milestoneIndex];

        if (
          updatedMilestone.customGoals &&
          updatedMilestone.customGoals.length > goalIndex
        ) {
          const updatedGoal = updatedMilestone.customGoals[goalIndex];
          updatedGoal.name = newName;
        }

        setStage({ ...stage, themes: updatedThemes });
      }
    } else {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      const updatedMilestone = updatedTheme.milestones[milestoneIndex];

      if (
        updatedMilestone.customGoals &&
        updatedMilestone.customGoals.length > goalIndex
      ) {
        const updatedGoal = updatedMilestone.customGoals[goalIndex];
        updatedGoal.name = newName;
      }

      setStage({ ...stage, themes: updatedThemes });
    }
  }

  function deleteCustomGoal(
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
    isCustomMilestone: boolean | undefined,
  ) {
    if (!stage) return;

    if (isCustomMilestone) {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      if (updatedTheme.customMilestones !== undefined) {
        const updatedMilestone = updatedTheme.customMilestones[milestoneIndex];
        if (
          updatedMilestone.customGoals &&
          updatedMilestone.customGoals.length > goalIndex
        ) {
          updatedMilestone.customGoals.splice(goalIndex, 1);
        }

        setStage({ ...stage, themes: updatedThemes });
      }
    } else {
      const updatedThemes = [...stage.themes];
      const updatedTheme = updatedThemes[themeIndex];
      const updatedMilestone = updatedTheme.milestones[milestoneIndex];

      if (
        updatedMilestone.customGoals &&
        updatedMilestone.customGoals.length > goalIndex
      ) {
        updatedMilestone.customGoals.splice(goalIndex, 1);
      }

      setStage({ ...stage, themes: updatedThemes });
    }
  }

  async function putUpdatedStage() {
    setIsLoading(true);
    if (!companyName) return;
    try {
      const data = await putData(
        JOURNEY_UPDATE_STAGE_ROUTE,
        [
          {
            name: 'companyName',
            value: encodeURIComponent(companyName),
          },
          { name: 'ecosystemName', value: ecosystemName },
        ],
        stage,
      );
      setStage(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Custom changes saved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function updateLockedStatus(stage: IJourneyStage, status: boolean) {
    setJourney((prevJourney) => {
      if (!prevJourney) {
        return prevJourney;
      }

      const updatedStages = prevJourney.stages.map((s) =>
        s.id === stage.id ? { ...s, locked: status } : s,
      );

      updateLockStatus(status, stage.id!);

      return { ...prevJourney, stages: updatedStages };
    });
  }

    async function updateLockStatus(lockStatus: boolean, stageId : number) {
      if (!companyName) return;
      try {
        await postData(JOURNEY_SET_LOCK_STATUS, [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'journeyStageId', value: stageId },
          { name: 'companyName', value: encodeURIComponent(companyName) },
          { name: 'lockStatus', value: lockStatus },
        ]);

        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Lock Status Updated',
        });
      } catch (e: any) {
        console.error('error', e);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: e.message,
        });
      }
    }

  return {
    isLoading,
    isSmallLoading,
    journey,
    stages,
    stage,
    activeStageId,
    companyMembers,
    isEditJourneyContext,
    isEditWarningWindowOpen,
    updatingDeadlines,
    setStage,
    updateStageGoalProgress,
    isStageCompleted,
    putDeadline,
    putPeople,
    setIsEditJourneyContext,
    setIsEditWarningWindowOpen,
    addCustomMilestone,
    editCustomMilestone,
    deleteCustomMilestone,
    addCustomGoal,
    editCustomGoal,
    deleteCustomGoal,
    putUpdatedStage,
    updateCustomGoalResources,
    putCustomGoalResources,
    revertChanges,
    updateLockedStatus,
  };
};

const JourneyTemplateContext = createContext(
  {} as ReturnType<typeof useJourneyTemplate>,
);

export const JourneyProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { loading, journey, companyName } = useJourney();
  const journeyContext = useJourneyTemplate(journey, companyName);

  return (
    <JourneyTemplateContext.Provider value={journeyContext}>
      {loading || !journey ? <Loading /> : children}
    </JourneyTemplateContext.Provider>
  );
};

export const useJourneyContext = () => useContext(JourneyTemplateContext);
