import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import EcosystemPreview from './EcosystemPreview';
import {
  DASHBOARD_ROUTE,
  getData,
  updateRefreshTokens,
} from 'utils/requests';
import { IEcosystem } from 'utils/models';
import { getEcosystems, setEcosystems } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { Box } from '@mui/system';

interface IEcosystemsProps {
  setOneEcosystemName: (name: string) => void;
}

const Ecosystems: FunctionComponent<IEcosystemsProps> = (props) => {
  const { setOneEcosystemName } = props;
  const { addAlert } = useContext(AlertContext);

  const [ecosystemsList, setEcosystemsList] = useState<IEcosystem[] | null>(
    getEcosystems(),
  );
  const [initialList, setInitialList] = useState<IEcosystem[] | null>(
    getEcosystems(),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [isFinishedLoading, setIsFinishedLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchEcosystems();
  }, []);

  useEffect(() => {
    if (!areEcosystemListsEqual(initialList, ecosystemsList)) {
      updateRefreshTokens();
    }
  }, [isFinishedLoading]);

  useEffect(() => {
    if (ecosystemsList?.length === 1) {
      setOneEcosystemName(ecosystemsList[0].name);
    }
  }, [ecosystemsList]);

  function areEcosystemListsEqual(
    ecosystems1: IEcosystem[] | null,
    ecosystems2: IEcosystem[] | null,
  ) {
    function getEcosystemNames(ecosystems: IEcosystem[] | null) {
      if (!ecosystems) return null;
      return ecosystems.map((ecosystem) => ecosystem.name);
    }

    const arr1 = getEcosystemNames(ecosystems1);
    const arr2 = getEcosystemNames(ecosystems2);

    if (!arr1 || !arr2) return true;

    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  async function fetchEcosystems() {
    setLoading(true);
    try {
      const data = await getData(DASHBOARD_ROUTE);
      setEcosystemsList(data.ecosystems);
      setEcosystems(data.ecosystems);
      setIsFinishedLoading(true);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderEcosystems() {
    if (!ecosystemsList) return null;

    return ecosystemsList.map((ecosystem, i) => {
      return (
        <EcosystemPreview
          key={`ecosystem-preview-${i}`}
          ecosystem={ecosystem}
        />
      );
    });
  }

  return (
    <Box sx={{ marginTop: '10px', paddingX: { xs: '1rem', md: '0' } }}>
      {renderEcosystems()}
    </Box>
  );
};

export default Ecosystems;
