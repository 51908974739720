import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import InputField from 'components/common/InputField';
import { IEvent } from 'utils/models';

interface IAddUrl {
  event: IEvent;
  setEvent: (event: IEvent) => void;
  isLoading: boolean;
}

const AddUrl: FunctionComponent<IAddUrl> = (props) => {
  const { event, setEvent, isLoading } = props;

  const [url, setUrl] = useState<string>(event.url);

  useEffect(() => {
    const updatedEvent = { ...event, url: url };
    setEvent(updatedEvent);
  }, [url]);

  return (
    <div>
      <InputField
        value={url}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setUrl(event.target.value)
        }
        placeholder='Enter URL'
        readonly={isLoading}
        label={'Link To Virtual Meeting'}
      />
    </div>
  );
};

export default AddUrl;
