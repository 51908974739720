import React, { FunctionComponent } from 'react';
import { IEvent, ITag } from 'utils/models';
import TagAutocomplete from 'components/common/Tags/TagAutocomplete';
import { Typography } from '@mui/material';

interface IAddTag {
  event: IEvent;
  setEvent: (event: IEvent) => void;
    selectedTagGroup: any;
    tags: any;
    usedTags: any;
    setUsedTags: any;
}

const AddTag: FunctionComponent<IAddTag> = (props) => {
    const { selectedTagGroup, tags, usedTags, setUsedTags, event, setEvent, } = props;

   if (selectedTagGroup === null) {
      return null;
    }

    return (
      <div>
        <Typography
          variant='body1'
          sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
          Tags
        </Typography>
        <TagAutocomplete
          tags={tags}
          usedTags={usedTags}
          onTagChange={(updatedTags: ITag[]) => setUsedTags(updatedTags)}
        />
      </div>
    );
};

export default AddTag;