import React, { FunctionComponent, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileUploadZone from 'components/common/FileUploadZone';
import Modal from 'components/common/Modal';
import { ICompanyDoc } from 'utils/models';
import {
  COMPANY_FIND_DOCUMENT,
  COMPANY_UPLOAD_DOC_ROUTE,
  getData,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useTheme } from '@mui/material';

interface ICompanyUploadDoc {
  companyName: string;
  docType: string;
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  canEditCompanyProfile?: boolean;
  canUploadAdminDocs?: boolean;
}

const CompanyUploadDoc: FunctionComponent<ICompanyUploadDoc> = (props) => {
  const {
    companyName,
    docType,
    updateDocs,
    canUploadAdminDocs,
    canEditCompanyProfile,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [uploadNewDocument, setUploadNewDocument] = useState<boolean>(false);
  const [file, setFile] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function uploadDoc() {
    setLoading(true);
    const { name, size } = file;
    const encodedFileName = encodeURIComponent(name);

    try {
      const signedUrl = await postData(COMPANY_UPLOAD_DOC_ROUTE, [
        { name: 'originalFilePath', value: `${encodedFileName}` },
        { name: 'fileCategory', value: 'shared-docs' },
        { name: 'fileSize', value: size },
        { name: 'docName', value: encodedFileName },
        { name: 'docType', value: docType },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      try {
        //couldn't get this to work using axios
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('x-goog-acl', 'public-read');
        xhr.setRequestHeader('x-goog-content-length-range', '0,157286400');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(file);
        try {
          const uploadedUrl = await getData(COMPANY_FIND_DOCUMENT, [
            { name: 'name', value: encodedFileName },
            { name: 'docType', value: docType },
            { name: 'companyName', value: encodeURIComponent(companyName) },
            { name: 'ecosystemName', value: ecosystemName },
          ]);
          updateDocs(uploadedUrl, false);
        } catch (e: any) {
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: e.message,
          });
        }
      } catch (e: any) {
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: e.message,
        });
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setFile(null);
    setUploadNewDocument(false);
    setLoading(false);
  }

  return (
    <>
      {canUploadAdminDocs !== undefined && canUploadAdminDocs && (
        <Button onClick={() => setUploadNewDocument(true)}>
          <FileUploadIcon /> Upload document
        </Button>
      )}
      {canEditCompanyProfile !== undefined && canEditCompanyProfile && (
        <Button onClick={() => setUploadNewDocument(true)}>
          <FileUploadIcon /> Upload document
        </Button>
      )}
      <Modal
        open={uploadNewDocument}
        close={() => setUploadNewDocument(false)}
        title={'Upload new document'}
        color={theme.palette.primary.main}
        showFooter={true}
        hideFooterBorder={true}
        largeFooter={true}
        loading={loading}
        saveAction={() => uploadDoc()}
        disabledSave={!file}
        saveButtonText={'Upload'}>
        <FileUploadZone disabled={loading} setFile={setFile} />
      </Modal>
    </>
  );
};

export default CompanyUploadDoc;
