import { Box } from '@mui/system';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import CloseModalButton from '../common/Modal/CloseModalButton';
import { primary } from 'theme';
import { useMobileContext } from 'services/contexts/MobileContext';
import SideMenuSections from '../SideMenu/SideMenuSections';
import { useTheme } from '@mui/material';

interface IMobileNav {
  isOpen: boolean;
  onClose: () => void;
}

const MobileNavbarPopup: FunctionComponent<IMobileNav> = (props) => {
  const { isOpen, onClose } = props;

  const theme = useTheme();

  const popupRef = useRef<HTMLDivElement>(null);

  const { menuSections, selectedMenuOption } = useMobileContext();

  const isRenderMenuContent = !!menuSections && selectedMenuOption !== null;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return <></>;

  return (
    <Box
      ref={popupRef}
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        left: 0,
        height: '100vh',
        minWidth: '20rem',
        width: '42vw',
        background: '#FFF',
        border: `1px solid ${primary.natural6}`,
        borderRadius: '6px',
        borderTopLeftRadius: 0,
        boxShadow: 1,
        borderBottomLeftRadius: 0,
      }}>
      <CloseModalButton
        handleClose={onClose}
        color={theme.palette.primary.dark}
        sx={{
          borderColor: 'transparent',
          marginTop: '1rem',
          marginLeft: '1rem',
          marginBottom: '2rem',
          '&:hover': {
            borderColor: 'transparent',
          },
        }}
      />
      {isRenderMenuContent && (
        <Box sx={{ pl: '0.5rem', mt: '2rem' }}>
          <SideMenuSections
            menuItems={menuSections}
            selectedOption={selectedMenuOption}
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileNavbarPopup;
