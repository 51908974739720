import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import CollapseList from 'components/common/CollapseList';
import ArticleIcon from '@mui/icons-material/Article';
import CompanyDocItem from './CompanyDocItem';
import { ICompanyDoc } from 'utils/models';
import CompanyUploadDoc from './CompanyUploadDoc';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface ICompanySharedDocuments {
  sharedDocs: ICompanyDoc[];
  companyName: string;
  seeMore: boolean;
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  canEditCompanyProfile: boolean;
}

const CompanySharedDocuments: FunctionComponent<ICompanySharedDocuments> = (
  props,
) => {
  const {
    sharedDocs,
    companyName,
    updateDocs,
    seeMore,
    canEditCompanyProfile,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  function renderDocuments() {
    return sharedDocs.map((doc, index) => (
      <CompanyDocItem
        key={doc.id}
        doc={doc}
        companyName={companyName}
        index={index}
        updateDocs={updateDocs}
        canEditCompanyProfile={canEditCompanyProfile}
      />
    ));
  }

  return (
    <CollapseList
      title={'Shared Documents'}
      color={theme.palette.primary.main}
      seeMore={seeMore}
      icon={<ArticleIcon />}
      defaultExpanded={true}
      action={
        <CompanyUploadDoc
          docType={'shared'}
          companyName={companyName}
          updateDocs={updateDocs}
          canEditCompanyProfile={canEditCompanyProfile}
        />
      }>
      <div className={classes.root}>{renderDocuments()}</div>
    </CollapseList>
  );
};

export default CompanySharedDocuments;
