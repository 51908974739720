import React, { FunctionComponent } from 'react';
import { Fade, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { acterioTheme } from 'theme';
import { useNavigate } from 'react-router-dom';
import { IEcosystem } from 'utils/models';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '10rem',
    minHeight: '10rem',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '12px',
    marginBottom: '22.5px',
    position: 'relative',
    cursor: 'pointer',
  },
  placeholder: {
    opacity: 0.1,
    cursor: 'default',
    transition: 'all 0.3s linear',
    animation: '$load 2000ms linear infinite',
  },
  logo: {
    height: '100%',
    width: '11.875rem',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '1rem',

    '& img': {
      maxHeight: '100%',
      height: 'auto',
      width: '80%',
      borderRadius: '5px',
    },
  },
  cont: {
    padding: '1rem',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    '& h5': {
      fontSize: '1.4rem !important',
      color: `${acterioTheme.dark} !important`,
    },

    '& h6': {
      fontWeight: 600,
      fontSize: '1.05rem !important',
      marginTop: '10px!important',
      marginBottom: '5px !important',
      marginLeft: '-6px !important',
      display: 'flex',
      color: `${acterioTheme.dark} !important`,

      '& svg': {
        height: 30,
        width: 30,
        color: acterioTheme.main,
        marginTop: '-3px',
      },
    },
  },
  members: {
    position: 'relative',
    minWidth: '10rem',
  },
  notifications: {
    position: 'absolute',
    top: 10,
    right: 25,
  },
  notificationsBtn: {
    height: '40px !important',
    width: '40px !important',
    minWidth: '40px !important',
    borderRadius: '50%',
    backgroundColor: 'transparent !important',

    '& svg': {
      height: '37px !important',
      width: '37px !important',
    },
  },
  notificationCount: {
    position: 'absolute',
    bottom: '-6px',
    right: '-10px',
    borderRadius: '50%',
    minHeight: '28px',
    minWidth: '28px',
    height: '28px',
    width: '28px',
    backgroundColor: acterioTheme.dark,
    color: acterioTheme.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    fontSize: '0.9rem',
    paddingTop: '2px',
  },
  member: {
    position: 'absolute',
    height: '27px',
    width: '27px',
    borderRadius: '50%',
    border: '1px solid white',
  },
  membersCont: {
    display: 'flex',

    '& span': {
      display: 'block',
      marginTop: '3px',
      fontSize: '0.9rem !important',
    },
  },

  '@keyframes load': {
    '0%': {
      opacity: 0.1,
    },
    '10%': {
      opacity: 0.1,
    },
    '20%': {
      opacity: 0.1,
    },
    '35%': {
      opacity: 0.125,
    },
    '40%': {
      opacity: 0.15,
    },
    '50%': {
      opacity: 0.15,
    },
    '60%': {
      opacity: 0.15,
    },
    '70%': {
      opacity: 0.15,
    },
    '80%': {
      opacity: 0.125,
    },
    '90%': {
      opacity: 0.1,
    },
    '100%': {
      opacity: 0.1,
    },
  },
}));

interface IEcosystemPreview {
  ecosystem: IEcosystem | null;
}

const EcosystemPreview: FunctionComponent<IEcosystemPreview> = (props) => {
  const { ecosystem } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  // function renderMembersPreview() {
  //   const MAX_TO_SHOW = 8;
  //   return members.slice(0, MAX_TO_SHOW).map((member, i) => {
  //     return (
  //       <img
  //         key={`member-${Math.random()}`}
  //         src={member}
  //         className={classes.member}
  //         style={{
  //           left: i * 18,
  //           zIndex: MAX_TO_SHOW - i,
  //         }}
  //       />
  //     );
  //   });
  // }

  function renderEcosystem() {
    if (!ecosystem) {
      return <div className={clsx(classes.root, classes.placeholder)} />;
    }

    const { name, location, logoUrl } = ecosystem;
    return (
      <Fade in={true} timeout={1000}>
        <div
          className={classes.root}
          onClick={() => navigate(`/ecosystem/${name}`)}>
          {/* <div className={classes.notifications}>
        <div className={classes.notificationCount}>{notifications}</div>
        <Button className={classes.notificationsBtn}>
          <NotificationsIcon />
        </Button>
      </div> */}
          <div className={classes.logo}>
            <img src={logoUrl} />
          </div>
          <div className={classes.cont}>
            <Typography variant={'h5'}>{name}</Typography>
            <Typography variant={'h6'}>
              <LocationOnIcon />
              {location}
            </Typography>
            {/* <div className={classes.membersCont}>
          <div className={classes.members}>{renderMembersPreview()}</div>
          <span>{members.length} members</span>
        </div> */}
          </div>
        </div>
      </Fade>
    );
  }

  return renderEcosystem();
};

export default EcosystemPreview;
