import {
  FileCopy,
  Add,
  ListAlt,
  // LocalActivity,
  Summarize,
} from '@mui/icons-material';
import { Fade } from '@mui/material';
import { Box } from '@mui/system';
import AdminSideMenu, { AdminMenuKeys } from 'components/Admin/AdminSideMenu';
import React, { FunctionComponent, useEffect } from 'react';
import { ISideMenuSection } from 'utils/models';
import ApplicationBuilder from 'components/Admin/Applications/ApplicationBuilder';
import ApplicationRouter from 'components/Admin/Applications/ApplicationRouter';
import { useNavigate, useParams } from 'react-router';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import { TagGroupProvider } from '../../components/Admin/ManageTheme/TagGroups/TagGroupContext';
import { getEcosystemInfo } from 'utils/storage';
import AssessmentBuilder from 'components/Admin/Applications/Assessment/AssessmentBuilder';
import AssessmentRouter from './AssessmentRouter';
import TemplatesGrid from 'components/Admin/Applications/Assessment/TemplatesGrid';
import AssessmentReports from 'components/Admin/Applications/Assessment/AssessmentReports';
import { useApplicationMenuContext } from 'services/contexts/Applications/ApplicationMenuContext';

interface IApplicationsPage {}

const ApplicationsPage: FunctionComponent<IApplicationsPage> = () => {
  const { ecosystemName, applicationProcessId, applicantId } = useParams();
  const navigate = useNavigate();

  const { setSelectedApplicantId, setSelectedApplicationProcessId } =
    useApplicationContext();

  const { selectedOption, setSelectedOption } = useApplicationMenuContext();

  useEffect(() => {
    if (!setSelectedApplicationProcessId || !setSelectedApplicantId) return;
    setSelectedApplicationProcessId(null);
    setSelectedApplicantId(null);

    if (!applicationProcessId) return;

    setSelectedApplicationProcessId(applicationProcessId); //For now I typed it as any, because I don't know how id will look like, prolly it will be a string, but we have it typed as number

    if (!applicantId) return;

    setSelectedApplicantId(applicantId); //Same here
  }, [applicationProcessId, applicantId]);

  function setMenuOption(menuKey: AdminMenuKeys) {
    if (menuKey === AdminMenuKeys.Applications) {
      navigate(`/ecosystem/${ecosystemName}/admin/applications`); // Go back to the start of the "applications home page", can be changed, ask Ieva what she thinks is best
    }
    setSelectedOption(menuKey);
  }

  function renderSection() {
    if (selectedOption === AdminMenuKeys.Applications) {
      return <ApplicationRouter />;
    }

    if (selectedOption === AdminMenuKeys.AddNewApplication) {
      return (
        <TagGroupProvider>
          <ApplicationBuilder />
        </TagGroupProvider>
      );
    }

    if (selectedOption === AdminMenuKeys.EditorDuplicateApplication) {
      return (
        <TagGroupProvider>
          <ApplicationBuilder isEdit={true} />
        </TagGroupProvider>
      );
    }

    if (selectedOption === AdminMenuKeys.Assessments) {
      return <AssessmentRouter />;
    }

    if (selectedOption === AdminMenuKeys.AssessmentReports) {
      return <AssessmentReports />;
    }

    if (selectedOption === AdminMenuKeys.AssessmentTemplates) {
      return <TemplatesGrid />;
    }

    if (selectedOption === AdminMenuKeys.AddNewAssessment) {
      return <AssessmentBuilder />;
    }

    if (selectedOption === AdminMenuKeys.EditAssessment) {
      return <AssessmentBuilder isEdit={true} />;
    }

    return <ApplicationRouter />;
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Applications,
      text: 'Applications',
      icon: <FileCopy />,
      onClick: () => setMenuOption(AdminMenuKeys.Applications),
      hasAccess: true,
    },
    {
      key: AdminMenuKeys.AddNewApplication,
      text: 'New Application',
      icon: <Add />,
      onClick: () => setMenuOption(AdminMenuKeys.AddNewApplication),
      hasAccess: getEcosystemInfo().accessLevel.canManageApplications,
      isSpecial: true,
    },
    // {
    //   key: AdminMenuKeys.Assessments,
    //   text: 'Assessments',
    //   icon: <LocalActivity />,
    //   onClick: () => setMenuOption(AdminMenuKeys.Assessments),
    //   hasAccess: true,
    // },

    {
      key: AdminMenuKeys.AssessmentReports,
      text: 'Assessment reports',
      icon: <Summarize />,
      onClick: () => setMenuOption(AdminMenuKeys.AssessmentReports),
      hasAccess: true,
    },
    {
      key: AdminMenuKeys.AssessmentTemplates,
      text: 'Templates',
      icon: <ListAlt />,
      onClick: () => setMenuOption(AdminMenuKeys.AssessmentTemplates),
      hasAccess: true,
    },
    {
      key: AdminMenuKeys.AddNewAssessment,
      text: 'New Assessment',
      icon: <Add />,
      onClick: () => setMenuOption(AdminMenuKeys.AddNewAssessment),
      hasAccess: true,
      isSpecial: true,
    },
  ];

  return (
    <Fade in={true} timeout={600}>
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'row',
            minHeight: 'calc(100vh - 4.4rem)',
            marginTop: '-3rem',
            backgroundColor: 'white',
          }}>
          <Box>
            <AdminSideMenu
              menuItems={sections}
              selectedOption={selectedOption}
              text='Back'
            />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}>
            {renderSection()}
          </Box>
        </Box>
      </div>
    </Fade>
  );
};

export default ApplicationsPage;
