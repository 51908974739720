import { Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SearchUsers from 'components/common/SearchUsers';
import { IEvent } from 'utils/models';

interface IAddContactPerson {
    event: IEvent;
    setEvent: (event: IEvent) => void;
    setContactUserId: any;
}

const AddContactPerson: FunctionComponent<IAddContactPerson> = (props) => {
  const { setEvent, event, setContactUserId } = props;

  const [contactUser, setContactUser] = useState<any>(event.contactPerson);

  useEffect(() => {
    if (contactUser !== null && contactUser !== undefined) {
      setContactUserId(contactUser.id);
    }
  }, [contactUser]);

  return (
    <div>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Contact Person
      </Typography>
      <SearchUsers
        onSearch={(person) => setContactUser(person)}
        fullWidth={true}
        size={true}
        defaultUser={event?.contactPerson}
      />
    </div>
  );
};

export default AddContactPerson;
