import React, { FunctionComponent, useState } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Controller, Control, FieldError, Path } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { get } from 'lodash';
import InputField from './InputField';
import { ApplicationQuestionTypes } from '../../utils/models';
import { FormForSend } from '../Admin/Applications/FormWithHook';
import { colorPallete, naturalPallete } from '../../theme';

interface AplicationInputProps {
  type: ApplicationQuestionTypes;
  control: Control<FormForSend>;
  fixedAnswer?: keyof FormForSend;
  answerPath?: string;
  isCounter?: boolean;
  typoOfInput?: string;
  errors?: FieldErrors<FormForSend>;
  clearErrors: (name?: keyof FormForSend) => void;
  setError?: (name: keyof FormForSend, error: FieldError) => void;
}

const MAX_LENGTH_SINGLE_LINE = 250;
const MAX_LENGTH_TEXT_AREA = 5000;

const ApplicationInput: FunctionComponent<AplicationInputProps> = (props) => {
  const {
    type,
    control,
    answerPath,
    isCounter = true,
    fixedAnswer,
    typoOfInput,
    errors,
    clearErrors,
    setError,
  } = props;
  const [charCount, setCharCount] = useState(0);

  const singleLine = type === ApplicationQuestionTypes.TEXT;
  const textArea = type === ApplicationQuestionTypes.TEXT_AREA;
  const linkField = type === ApplicationQuestionTypes.LINK;

  const questionTextAnswer = `${answerPath as keyof FormForSend}.${
    textArea ? 'textArea' : linkField ? 'url' : 'text'
  }` as keyof FormForSend;

  const getErrorMessage = (): string | undefined => {
    if (fixedAnswer) {
      const error = errors ? (errors[fixedAnswer] as FieldError) : undefined;
      return error?.message;
    }
    if (answerPath) {
      const error = get(errors, questionTextAnswer) as FieldError | undefined;
      return error?.message;
    }
    return undefined;
  };

  const maxLength =
    singleLine || linkField ? MAX_LENGTH_SINGLE_LINE : MAX_LENGTH_TEXT_AREA;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value;
    setCharCount(value.length);
    const fieldName = fixedAnswer ? fixedAnswer : questionTextAnswer;

    if (errors && get(errors, fieldName)?.type === 'required') {
      clearErrors(fieldName);
    }

    //setting error for email if it's not valid
    if (fixedAnswer === 'email') {
      const emailDomainReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!value.match(emailDomainReg)) {
        setError!(fieldName, {
          type: 'pattern',
          message: 'Invalid email address',
        });
      } else {
        clearErrors(fieldName);
      }
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
      }}>
      <Controller
        name={
          (fixedAnswer ? fixedAnswer : questionTextAnswer) as Path<FormForSend>
        }
        control={control}
        rules={{ required: 'Please fill the field' }}
        render={({ field }) => (
          <InputField
            {...field}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              field.onChange(e);
              handleInputChange(e);
            }}
            helperText={getErrorMessage()}
            helperTextStyles={{
              color: colorPallete.warningRed,
            }}
            multiline={textArea}
            placeholder={'Enter text here'}
            noEffects
            maxLength={maxLength}
            type={typoOfInput}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderBottom: `1px solid ${
                  !!(
                    get(errors, fixedAnswer!) || get(errors, questionTextAnswer)
                  )
                    ? colorPallete.warningRed
                    : naturalPallete.natural3
                }`,
                borderLeft: textArea
                  ? `1px solid ${
                      !!(
                        get(errors, fixedAnswer!) ||
                        get(errors, questionTextAnswer)
                      )
                        ? colorPallete.warningRed
                        : naturalPallete.natural3
                    }`
                  : '',
                borderRadius: '0px',
                padding: `0rem ${textArea ? '0.875rem' : '2.5rem'} ${
                  textArea ? '1.5rem' : '0rem'
                } 0rem`,
                '& .MuiInputBase-input': {
                  width: '88%',
                },
              },
            }}
          />
        )}
      />
      {isCounter && (
        <Typography
          sx={{
            display: !(textArea && charCount < maxLength * 0.75)
              ? 'block'
              : 'none',
            position: 'absolute',
            bottom: textArea
              ? get(errors, questionTextAnswer)
                ? '1.6rem'
                : '0.125rem'
              : get(errors, questionTextAnswer)
              ? '2rem'
              : '0.6rem',
            right: '7px',
            color: 'rgba(0, 0, 0, 0.5)',
          }}
          variant='body2'>
          {charCount} / {maxLength}
        </Typography>
      )}
    </Box>
  );
};

export default ApplicationInput;
