import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import RichTextEditor from '../common/RichTextEditor';
import UploadImage from '../common/UploadImage';
import SelectAccessLevels from '../common/SelectAccessLevels';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.55rem',
    maxWidth: '510px',
    gap: '10px',
    '& h6': {
      marginBottom: '5px',
    },
  },
}));

interface IEditAnnouncement {
  open: boolean;
  close: () => void;
  id: number;
  title: string;
  body: string;
  imageUrl: string | null;
  accessLevelIds: number[] | undefined;
  editAnnouncement: (
    id: number,
    editedTitle: string,
    editedBody: string,
    accessLevels: number[],
    editedImage: File | null,
    closeModal: () => void,
  ) => Promise<void>;
  loading: boolean;
}

const EditAnnouncement: FunctionComponent<IEditAnnouncement> = (props) => {
  const {
    open,
    close,
    id,
    title,
    body,
    imageUrl,
    loading,
    accessLevelIds,
    editAnnouncement,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { isMobile } = useMobileContext();

  const [editedTitle, setEditedTitle] = useState<string>(title);
  const [editedBody, setEditedBody] = useState<string>(body);
  const [editedImage, setEditedImage] = useState<any>(null);
  const [accessLevels, setAccessLevels] = useState<number[]>(
    accessLevelIds ?? [],
  );

  const isAllRequiredFilled =
    editedBody.trim().length > 0 &&
    editedBody !== '<p><br></p>' &&
    accessLevels.length > 0;

  function renderContent() {
    return (
      <div className={classes.main}>
        {/* <Typography variant={'h6'}>Announcement title</Typography>
        <InputField
          value={editedTitle}
          placeholder={'Title'}
          onChange={setEditedTitle}
          small={true}
          borderSize={1}
          readonly={loading}
        /> */}
        <div
          style={{
            minHeight: '200px',
            marginBottom: '35px',
            marginTop: '25px',
          }}>
          <RichTextEditor
            value={editedBody}
            onChange={setEditedBody}
            readonly={loading}
            height={170}
          />
        </div>
        <SelectAccessLevels
          accessLevelIds={accessLevels}
          onAccessGroupChange={setAccessLevels}
        />
        <UploadImage
          initialSrc={imageUrl || undefined}
          width='100%'
          onUpload={(file) => setEditedImage(file)}
          height={200}
          borderColor={theme.palette.primary.dark}
          hideBorder
          full
          showButtons={true}
          onRemove={() => setEditedImage(null)}
          crop={true}
          aspectRatioWidth={3}
          aspectRatioHeight={1}
        />
      </div>
    );
  }

  return (
    <Modal
      width={isMobile ? 320 : 550}
      title={'Edit announcement'}
      open={open}
      close={close}
      showFooter={true}
      largeFooter={true}
      headerColor={'white'}
      headerBorder={true}
      footerButtonsFlexStart={true}
      loading={loading}
      disabledSave={!isAllRequiredFilled}
      isAllRequiredFilled={isAllRequiredFilled}
      saveButtonText={'Update'}
      saveAction={() =>
        editAnnouncement(
          id,
          editedTitle,
          editedBody,
          accessLevels,
          editedImage,
          close,
        )
      }
      backgroundColor={'white'}>
      {renderContent()}
    </Modal>
  );
};

export default EditAnnouncement;
