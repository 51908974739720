import { Button, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState, useEffect } from 'react';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import {
  MEETING_UPCOMING_ATTENDING_MEETINGS_ROUTE,
  MEETING_UPCOMING_MEETINGS_ROUTE,
  getData,
} from 'utils/requests';
import { CalendarMonth, PermContactCalendar } from '@mui/icons-material';
import StaticCalendar from '../BookingCalendar/StaticCalendar';
import MeetingList from './MeetingList';
import { IAvailableTimeslots, IMeeting, ITimeslot } from 'utils/models';
import Loading from 'components/common/Loading';
import { formatDateYMD } from 'utils/utils';
import { getEcosystemInfo } from 'utils/storage';

const UpcomingMeetings = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();
  const accessLevels = getEcosystemInfo().accessLevel;

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSecLoading, setIsSecLoading] = useState<boolean>(false);
  const [isAttendingCalendar, setIsAttendingCalendar] = useState<boolean>(
    !accessLevels.canSetAvailability,
  );

  const [meetings, setMeetings] = useState<IMeeting[]>([]);
  const [mentorMeetings, setMentorMeetings] = useState<IMeeting[]>([]);
  const [attendingMeetings, setAttendingMeetings] = useState<IMeeting[]>([]);

  const sortedMeetings = sortMeetingsByDate(meetings);

  const [selectedMeetings, setSelectedMeetings] =
    useState<IMeeting[]>(sortedMeetings);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  useEffect(() => {
    setSelectedMeetings(sortMeetingsByDate(meetings));
  }, [meetings]);

  useEffect(() => {
    if (selectedDay === null) {
      setSelectedMeetings(sortedMeetings);
    } else {
      setSelectedMeetings(
        filterMeetingsBySelectedDate(sortedMeetings, selectedDay),
      );
    }
  }, [selectedDay]);

  useEffect(() => {
    if (accessLevels.canScheduleMeetings) {
      fetchAttendingMeetings();
    }
    if (accessLevels.canSetAvailability) {
      fetchMentorMeetings();
    }
  }, []);

  useEffect(() => {
    if (isAttendingCalendar) {
      setMeetings([...attendingMeetings]);
    } else {
      setMeetings([...mentorMeetings]);
    }
  }, [isAttendingCalendar, mentorMeetings, attendingMeetings]);

  async function fetchMentorMeetings() {
    setIsLoading(true);

    try {
      const data = await getData(MEETING_UPCOMING_ATTENDING_MEETINGS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setMentorMeetings(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function fetchAttendingMeetings() {
    setIsSecLoading(true);

    try {
      const data = await getData(MEETING_UPCOMING_MEETINGS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setAttendingMeetings(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsSecLoading(false);
  }

  function updateMeetings(meetings: IMeeting[]) {
    setMeetings(meetings);
    if (isAttendingCalendar) {
      setAttendingMeetings(meetings);
    } else {
      setMentorMeetings(meetings);
    }
  }

  function convertMeetingsToTimeslots(
    meetings: IMeeting[],
  ): IAvailableTimeslots[] {
    const timeslotsMap = new Map<string, IAvailableTimeslots>();

    meetings.forEach((meeting) => {
      const date = formatDateYMD(new Date(meeting.date));
      const dayOfWeek = new Date(date).toLocaleDateString('en-US', {
        weekday: 'long',
      });

      if (!timeslotsMap.has(date)) {
        timeslotsMap.set(date, {
          date: date,
          dayOfWeek: dayOfWeek,
          timeSlots: [],
        });
      }

      const timeslot: ITimeslot = {
        booked: true,
        startTime: meeting.startTime,
        endTime: meeting.endTime,
      };

      timeslotsMap.get(date)?.timeSlots.push(timeslot);
    });

    const availableTimeslots: IAvailableTimeslots[] = Array.from(
      timeslotsMap.values(),
    );

    return availableTimeslots;
  }

  function filterMeetingsBySelectedDate(
    meetings: IMeeting[],
    selectedDate: Date,
  ) {
    return meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.date);
      return meetingDate.toDateString() === selectedDate.toDateString();
    });
  }

  function sortMeetingsByDate(meetings: IMeeting[]) {
    return meetings.slice().sort((meetingA, meetingB) => {
      const dateA = new Date(meetingA.date);
      const dateB = new Date(meetingB.date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return (
    <Box
      sx={{
        maxWidth: 1140,
        width: '100%',
      }}>
      <Box justifyContent='left' marginTop='2rem' marginBottom='2rem'>
        <Typography variant='h5' gutterBottom fontSize={22}>
          Upcoming Meetings
        </Typography>
      </Box>
      <Box
        sx={{
          minHeight: 700,
          bgcolor: '#F0F0F0',
          border: 1,
          borderColor: '#D4D4D4',
          borderRadius: '6px',
        }}>
        <Box
          sx={{
            bgcolor: '#FFFFFF',
            borderBottom: 1,
            borderColor: '#D4D4D4',
            borderRadius: '6px',
            padding: '2rem',
          }}>
          <Box display={'flex'} maxWidth={600}>
            {accessLevels.canSetAvailability && (
              <Button
                onClick={() => setIsAttendingCalendar(false)}
                sx={{
                  backgroundColor: !isAttendingCalendar
                    ? theme.palette.primary.light
                    : 'default',
                  borderRadius: '6px',
                  marginTop: 0,
                  '&:hover': { bgcolor: theme.palette.primary.contrastText },
                  flex: 1,
                }}>
                <PermContactCalendar
                  sx={{ color: theme.palette.primary.main }}
                />
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    color: theme.palette.primary.main,
                  }}>
                  By others:
                </Typography>
              </Button>
            )}
            {accessLevels.canScheduleMeetings && (
              <Button
                onClick={() => setIsAttendingCalendar(true)}
                sx={{
                  backgroundColor: isAttendingCalendar
                    ? theme.palette.primary.light
                    : 'default',
                  borderRadius: '6px',
                  marginTop: 0,
                  marginLeft: 2,
                  '&:hover': { bgcolor: theme.palette.primary.contrastText },
                  flex: 1,
                }}>
                <CalendarMonth sx={{ color: theme.palette.primary.main }} />
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    color: theme.palette.primary.main,
                  }}>
                  By me:
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={7.5}>
            <StaticCalendar
              availability={convertMeetingsToTimeslots(meetings)}
              setSelectedDay={setSelectedDay}
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            {isLoading || isSecLoading ? (
              <Loading />
            ) : (
              <MeetingList
                meetings={selectedMeetings}
                setMeetings={updateMeetings}
                isAttendingCalendar={isAttendingCalendar}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpcomingMeetings;
