import React, { FunctionComponent } from 'react';
import { Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { primary } from 'theme';

interface ICardTag {
  text: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  top?: number | string;
  left?: number | string;
  sx?: SxProps;
}

const CardTag: FunctionComponent<ICardTag> = (props) => {
  const theme = useTheme();
  const {
    text,
    color = primary.pureWhite,
    backgroundColor = theme.palette.primary.main,
    borderColor = '#E0E0E0',
    top = 0,
    left = 0,
    sx,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        // flexWrap: 'nowrap',
        // alignContent: 'center',
        // justifyContent: 'center',
        borderRadius: '6px',
        backgroundColor: backgroundColor,
        border: borderColor ? `1px solid ${borderColor}` : 'none',
        // position: 'absolute',
        left: left,
        top: top,
        // padding: '0.6rem 2rem',
        // minWidth: '12.5rem',
        zIndex: 5,
        ...sx,
      }}>
      <Typography
        variant={'h5'}
        sx={{
          fontSize: '1rem !important',
          fontWeight: 500,
          color: `${color} !important`,
          letterSpacing: '0.2px !important',
          lineHeight: '0.8rem !important',
          textWrap: 'nowrap',
          padding: '0.6rem 2rem',
        }}>
        {text}
      </Typography>
    </Box>
  );
};

export default CardTag;
