import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import InputField from 'components/common/InputField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '11rem',
    width: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'end',
    padding: '1.25rem 1rem',
  },
  name: {
    minWidth: '11.25rem',
    overflow: 'none',
    height: '3.125rem',
    borderRadius: '5px',
    position: 'absolute',
    left: '-15px',
    top: '-25px',
    backgroundColor: '#E0F2F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1rem',

    '& h6': {
      fontSize: '18px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  input: {
    minWidth: '31.25rem',
    maxWidth: '31.25rem',
  },
}));

interface IJourneyTemplateHeader {}

const JourneyTemplateHeader: FunctionComponent<IJourneyTemplateHeader> = (
  props,
) => {
  const { stage, updateStageDesc } = useJourneyTemplateContext();
  const classes = useStyles();

  return !stage ? null : (
    <div className={classes.root}>
      <div className={classes.name}>
        <Typography variant={'h6'}>{stage.name}</Typography>
      </div>
      <div className={classes.input}>
        <InputField
          value={stage.description}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            updateStageDesc(event.target.value)
          }
          multiline={true}
          rows={2}
          placeholder={
            'Write a general description of the stage and its activities...'
          }
        />
      </div>
    </div>
  );
};

export default JourneyTemplateHeader;
