import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormControl, Radio, RadioGroup, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FlexBox } from 'utils/styledComponents';
import { naturalPallete, primary } from 'theme';
import CommonButton, {
  ButtonSize,
  ButtonVariant,
} from 'components/common/CommonButton';
import CommonModal from '../../common/Modal/CommonModal';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import PaletteIcon from '@mui/icons-material/Palette';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import ColorizeIcon from '@mui/icons-material/Colorize';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { ControlButtonStyled } from '../Applications/ApplicationBuilder';
import {
  defaultSchemes,
  useCustomTheme,
} from '../../../services/contexts/Themes/ThemeContext';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import InputField from '../../common/InputField';
import ColorPicker from '../../common/ColorPicker';
import {
  COLOR_PALETTES,
  CURRENT_COLOR_PALETTE,
  deleteData,
  getData,
  postData,
  putData,
} from '../../../utils/requests';
import { IColorScheme, ICustomScheme } from '../../../utils/models';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useForm } from 'react-hook-form';

interface ColorBlockProps {
  bgcolor: string;
  width?: string;
  height?: string;
}

interface FormData {
  name: string;
}

const ColorBlock = styled(Box)<ColorBlockProps>(
  ({ bgcolor, width, height, ...otherProps }) => ({
    backgroundColor: bgcolor,
    width: width || '11.875rem',
    height: height || '8.125rem',
    position: 'absolute',
    borderRadius: '8px',
    otherProps,
  }),
);

const TagStyled = styled(FlexBox)(({ back }: { back: string }) => ({
  backgroundColor: back,
  padding: '0.125rem 0.25rem',
  borderRadius: '50px',
}));

export const ModalBlockStyled = styled(FlexBox)({
  maxWidth: '76.25rem',
  width: '70%',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: primary.pureWhite,
  padding: '3.125rem',
  position: 'relative',
});

interface IEcoSystemColorsProps {}

const defaultCustomPalette = {
  dark: '#13253E',
  main: '#138CC9',
  light: '#D0EDFB',
  contrastText: '#EBF1F9',
};

const EcosystemColors: FunctionComponent<IEcoSystemColorsProps> = (props) => {
  const {} = props;
  const { currentTheme, theme, setCurrentTheme } = useCustomTheme();

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isCustomizingModalOpen, setIsCustomizingModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<
    IColorScheme | ICustomScheme
  >(currentTheme ?? defaultSchemes[0]);
  const [customThemes, setCustomThemes] = useState<ICustomScheme[]>([]);
  const [customPalette, setCustomPalette] = useState(defaultCustomPalette);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (isEditing) {
      setValue('name', selectedTheme.name);
    }
  }, [isEditing]);

  const getCustomThemes = async () => {
    setIsLoading(true);
    try {
      const data = await getData(COLOR_PALETTES, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      setCustomThemes(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch themes:', error);
    }
  };

  useEffect(() => {
    getCustomThemes();
  }, []);

  const combinedThemes = useMemo(() => {
    return [...customThemes.reverse(), ...defaultSchemes];
  }, [customThemes]);

  const updatePaletteColor = (colorKey: string, newColor: string) => {
    setCustomPalette((prevPalette) => ({
      ...prevPalette,
      [colorKey]: newColor,
    }));
  };

  async function putSelectedTheme() {
    if (!setCurrentTheme) return; // make sure this is not undefined!
    setIsLoading(true);
    try {
      const request = await putData(
        CURRENT_COLOR_PALETTE,
        [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
        selectedTheme,
      );
      setCurrentTheme(request);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Theme is updated!',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setIsPreviewModalOpen(false);
    }
    setIsLoading(false);
  }

  async function deleteTheme() {
    if (!selectedTheme.isCustom) return;
    setIsLoading(true);
    try {
      const customTheme = selectedTheme as ICustomScheme;
      const request = await deleteData(COLOR_PALETTES, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'id',
          value: customTheme.id,
        },
      ]);
      const defaultTheme = defaultSchemes[0];
      await putData(
        CURRENT_COLOR_PALETTE,
        [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
        defaultTheme,
      );
      if (setCurrentTheme) {
        setCurrentTheme(defaultTheme);
      }

      setSelectedTheme(defaultTheme);
      const updatedThemes = customThemes.filter(
        (item: ICustomScheme) => item.id !== customTheme.id,
      );
      setCustomThemes(updatedThemes);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  async function createUpdateTheme(name?: string) {
    const newCustomTheme = {
      ...customPalette,
      name: name,
      isCustom: true,
    };
    setIsLoading(true);
    try {
      if (isEditing) {
        const request = await putData(
          COLOR_PALETTES,
          [{ name: 'ecosystemName', value: ecosystemName }],
          newCustomTheme,
        );
        setSelectedTheme(request);
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Custom palette is changed!',
        });
      } else {
        const request = await postData(
          COLOR_PALETTES,
          [{ name: 'ecosystemName', value: ecosystemName }],
          newCustomTheme,
        );
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Custom palette is created!',
        });
      }
      getCustomThemes();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      reset();
      setIsCustomizingModalOpen(false);
      setCustomPalette(defaultCustomPalette);
      setIsEditing(false);
      setIsLoading(false);
    }
  }

  function onSubmit(data: FormData) {
    createUpdateTheme(data.name);
  }

  function onCancelModal() {
    reset();
    setCustomPalette(defaultCustomPalette);
    setIsCustomizingModalOpen(false);
    setIsEditing(false);
  }

  function handleEdit() {
    setIsCustomizingModalOpen(true);
    setIsEditing(true);
    setCustomPalette(selectedTheme);
  }

  function handleResetToDefault() {
    setCustomPalette(isEditing ? selectedTheme : defaultCustomPalette);
  }

  function renderPreviewModal() {
    return (
      <CommonModal
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}>
        <FlexBox className={'center'} sx={{ height: '100%' }}>
          <ModalBlockStyled>
            <ControlButtonStyled
              sx={{
                position: 'absolute',
                top: '2.188rem',
                right: '3.438rem',
              }}
              onClick={() => setIsPreviewModalOpen(false)}>
              <CloseIcon />
            </ControlButtonStyled>
            <Typography variant={'h4'}>
              Preview - {selectedTheme.name} theme palette
            </Typography>
            {renderPreview(selectedTheme)}
            <FlexBox
              className={'end'}
              sx={{
                marginTop: '1rem',
                width: '100%',
                gap: '1rem',
              }}>
              <CommonButton
                size={ButtonSize.LARGE}
                onClick={() => setIsPreviewModalOpen(false)}
                variant={ButtonVariant.BLANK}
                text={'Cancel'}
              />
              <CommonButton
                size={ButtonSize.LARGE}
                onClick={() => putSelectedTheme()}
                text={'Apply'}
              />
            </FlexBox>
          </ModalBlockStyled>
        </FlexBox>
      </CommonModal>
    );
  }

  const renderPreview = (selectedTheme?: IColorScheme | ICustomScheme) => {
    const previewTheme = selectedTheme ? selectedTheme : customPalette;

    return (
      <FlexBox
        sx={{
          height: '29.75rem',
          padding: '2.5rem 1.563rem',
          marginTop: '1rem',
          gap: '1.25rem',
          backgroundColor: naturalPallete.natural9,
          borderRadius: '8px',
        }}>
        <FlexBox
          sx={{
            width: '20rem',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '2.813rem',
            padding: '3rem 2.5rem',
            backgroundColor: naturalPallete.pureWhite,
            borderRadius: '8px',
          }}>
          <FlexBox
            className={'between'}
            sx={{
              width: '100%',
            }}>
            <FlexBox
              className={'center'}
              sx={{
                width: '5.875rem',
                height: '4.375rem',
                borderBottom: `2px solid ${previewTheme.dark}`,
              }}>
              <Typography variant={'h6'}>ADMIN</Typography>
            </FlexBox>
            <FlexBox
              className={'center'}
              sx={{
                width: '5.875rem',
                height: '4.375rem',
                backgroundColor: previewTheme.contrastText,
              }}>
              <Typography variant={'h6'}>ADMIN</Typography>
            </FlexBox>
          </FlexBox>

          <FlexBox
            sx={{
              flexDirection: 'column',
              gap: '0.625rem',
              alignItems: 'start',
              width: '16.25rem',
            }}>
            <FlexBox
              sx={{
                padding: '0.688rem 1.375rem',
                gap: '0.5rem',
                borderLeft: `2px solid ${previewTheme.dark}`,
              }}>
              <CircleIcon />
              <Typography variant={'h5'}>Option</Typography>
            </FlexBox>

            <FlexBox
              sx={{
                gap: '0.5rem',
                paddingLeft: '1.375rem',
              }}>
              <SubdirectoryArrowRightIcon
                sx={{
                  color: previewTheme.main,
                }}
              />
              <Typography
                variant={'h6'}
                sx={{
                  color: previewTheme.main,
                }}>
                Color example
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <Box
          sx={{
            width: '29.75rem',
            height: '100%',
            backgroundColor: naturalPallete.pureWhite,
            borderRadius: '12px',
          }}>
          <Box
            sx={{
              position: 'relative',
              height: '10.375rem',
              borderRadius: '12px',
            }}>
            <img
              src='/images/covers/palette.jpg'
              alt=''
              style={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
                borderRadius: '12px',
              }}
            />

            <FlexBox
              sx={{
                position: 'absolute',
                top: '1.25rem',
                left: '1.25rem',
                padding: '0.8rem 1rem',
                borderRadius: '5px',
                flexDirection: 'column',
                backgroundColor: naturalPallete.pureWhite,
              }}>
              <Typography
                variant={'h6'}
                sx={{
                  color: previewTheme.main,
                }}>
                Color
              </Typography>
              <Typography variant={'h5'}>123</Typography>
            </FlexBox>

            <FlexBox
              sx={{
                padding: '1.25rem',
                flexDirection: 'column',
                alingItems: 'start',
                gap: '1.875rem',
              }}>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: '0.625rem',
                }}>
                <Typography variant={'h3'}>Custom color palette</Typography>
                <FlexBox
                  sx={{
                    gap: '0.625rem',
                  }}>
                  <TagStyled back={previewTheme.light}># colors</TagStyled>
                  <TagStyled back={previewTheme.light}># custom</TagStyled>
                  <TagStyled back={previewTheme.light}># contrast</TagStyled>
                </FlexBox>
                <Typography>
                  Good contrast makes it easier to read. Make sure your colors
                  stand out from the background
                </Typography>
              </FlexBox>

              <FlexBox
                className={'between'}
                sx={{
                  width: '100%',
                }}>
                <FlexBox
                  sx={{
                    gap: '0.625rem',
                  }}>
                  <PaletteIcon
                    sx={{
                      color: previewTheme.main,
                    }}
                  />
                  <Typography>Color examples</Typography>
                </FlexBox>

                <FlexBox
                  sx={{
                    gap: '0.625rem',
                  }}>
                  <ColorizeIcon
                    sx={{
                      color: previewTheme.main,
                    }}
                  />
                  <Typography>123</Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Box>
        </Box>

        <FlexBox
          sx={{
            width: '14.125rem',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '3.234rem',
          }}>
          <FlexBox
            sx={{
              backgroundColor: previewTheme.light,
              color: previewTheme.main,
              boxShadow: theme.shadows[2],
              padding: '0.25rem 2.225rem',
              borderRadius: '30px',
              gap: '0.375rem',
            }}>
            <InvertColorsIcon />
            Color example
          </FlexBox>
          <FlexBox
            sx={{
              backgroundColor: naturalPallete.whiteHighlight,
              color: naturalPallete.natural3,
              boxShadow: theme.shadows[2],
              padding: '0.25rem 2.225rem',
              borderRadius: '30px',
              gap: '0.375rem',
            }}>
            <TextFormatIcon
              sx={{
                color: previewTheme.main,
              }}
            />
            Color example
          </FlexBox>
          <FlexBox
            sx={{
              backgroundColor: previewTheme.dark,
              color: naturalPallete.pureWhite,
              boxShadow: theme.shadows[2],
              padding: '0.5rem 2.5rem',
              borderRadius: '8px',
              gap: '0.375rem',
            }}>
            <AddIcon />
            <Typography
              sx={{
                color: naturalPallete.pureWhite,
              }}
              variant={'body1'}>
              Color example
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              padding: '0.5rem 2.5rem',
            }}>
            <Typography
              sx={{
                color: previewTheme.dark,
              }}
              variant={'h6'}>
              Color example
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              backgroundColor: previewTheme.main,
              color: naturalPallete.pureWhite,
              padding: '0.188rem 3.6rem',
              borderRadius: '6px',
            }}>
            Color example
          </FlexBox>
        </FlexBox>
      </FlexBox>
    );
  };

  const renderCustomisationModal = () => {
    if (!isCustomizingModalOpen) return;
    return (
      <CommonModal
        open={isCustomizingModalOpen}
        onClose={() => setIsCustomizingModalOpen(false)}>
        <FlexBox className={'center'} sx={{ height: '100%' }}>
          <ModalBlockStyled>
            <ControlButtonStyled
              sx={{
                position: 'absolute',
                top: '2.188rem',
                right: '3.438rem',
              }}
              onClick={onCancelModal}>
              <CloseIcon />
            </ControlButtonStyled>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexBox
                className={'between'}
                sx={{
                  width: '100%',
                }}>
                <FlexBox
                  sx={{
                    gap: '1rem',
                  }}>
                  <Typography variant={'h5'}>Palette creator</Typography>
                  <InputField
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    error={!!errors.name}
                    width={'23rem'}
                    placeholder={'Create a name for your palette*'}
                  />
                </FlexBox>
              </FlexBox>

              <Typography
                sx={{
                  marginTop: '1rem',
                }}
                variant={'body2'}>
                Tailor your experience to match your brand identity. Create your
                company's colors below to personalize the interface and make it
                uniquely yours. Or choose from one of the palettes we already
                have available.
              </Typography>

              <FlexBox
                className={'between'}
                sx={{
                  width: '100%',
                  paddingTop: '1rem',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant='h5' gutterBottom>
                    Dark
                  </Typography>
                  <ColorPicker
                    color={customPalette.dark}
                    onSelectedColor={(color) =>
                      updatePaletteColor('dark', color)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant='h5' gutterBottom>
                    Primary
                  </Typography>
                  <ColorPicker
                    color={customPalette.main}
                    onSelectedColor={(color) =>
                      updatePaletteColor('main', color)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant='h5' gutterBottom>
                    Light
                  </Typography>
                  <ColorPicker
                    color={customPalette.light}
                    onSelectedColor={(color) =>
                      updatePaletteColor('light', color)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography variant='h5' gutterBottom>
                    Soft
                  </Typography>
                  <ColorPicker
                    color={customPalette.contrastText}
                    onSelectedColor={(color) =>
                      updatePaletteColor('contrastText', color)
                    }
                  />
                </Box>
              </FlexBox>

              {renderPreview()}

              <FlexBox
                className={'end'}
                sx={{
                  marginTop: '1rem',
                  width: '100%',
                  gap: '1rem',
                }}>
                <CommonButton
                  size={ButtonSize.LARGE}
                  onClick={handleResetToDefault}
                  variant={ButtonVariant.BLANK}
                  text={'Reset to default'}
                />
                <CommonButton
                  size={ButtonSize.LARGE}
                  onClick={onCancelModal}
                  variant={ButtonVariant.BLANK}
                  text={'Cancel'}
                />
                <CommonButton
                  size={ButtonSize.LARGE}
                  submit
                  text={isEditing ? 'Update' : 'Create'}
                />
              </FlexBox>
            </form>
          </ModalBlockStyled>
        </FlexBox>
      </CommonModal>
    );
  };

  const renderButtons = () => {
    return (
      <FlexBox
        sx={{
          gap: '1rem',
        }}>
        {selectedTheme.isCustom && (
          <>
            <CommonButton
              onClick={deleteTheme}
              text={'Delete'}
              size={ButtonSize.LARGE}
              variant={ButtonVariant.BLANK}
              sx={{
                width: '12.5rem',
              }}
            />
            <CommonButton
              onClick={handleEdit}
              text={'Edit'}
              size={ButtonSize.LARGE}
              variant={ButtonVariant.BLANK}
              sx={{
                width: '12.5rem',
              }}
            />
          </>
        )}
        <CommonButton
          onClick={() => setIsPreviewModalOpen(true)}
          text={'Preview'}
          variant={ButtonVariant.BLANK}
          size={ButtonSize.LARGE}
          sx={{
            width: '12.5rem',
          }}
        />
        {renderPreviewModal()}
        <CommonButton
          onClick={putSelectedTheme}
          size={ButtonSize.LARGE}
          sx={{
            width: '12.5rem',
          }}
          text={'Apply'}
        />
        {renderCustomisationModal()}
      </FlexBox>
    );
  };

  function renderPalette() {
    return (
      <FlexBox
        sx={{
          alignItems: 'end',
          flexDirection: 'column',
          gap: '1rem',
          padding: '2.5rem',
          borderRadius: '8px',
          boxShadow: theme.shadows[2],
          backgroundColor: primary.pureWhite,
        }}>
        <FlexBox
          sx={{
            alignItems: 'start',
            flexWrap: 'wrap',
            gap: '1rem',
          }}>
          <Button
            sx={{
              width: '14rem',
              height: '9.6rem',
              marginTop: '0.688rem',
              marginLeft: '1rem',
              marginRight: { lg: '', xl: '1.9rem' },
              boxShadow: theme.shadows[2],
              border: '1px solid #CDD7E1',
              borderRadius: '8px',
            }}
            onClick={() => {
              setIsCustomizingModalOpen(true);
            }}>
            <FlexBox
              className={'center'}
              sx={{
                flexDirection: 'column',
              }}>
              <Typography variant={'h6'}>Create custom</Typography>
              <AddIcon fontSize={'large'} />
            </FlexBox>
          </Button>
          {combinedThemes.map(
            (
              item,
              i, //delete i later on when names are unique
            ) => (
              <FlexBox
                onClick={() => setSelectedTheme(item)}
                key={item.name + i}
                sx={{
                  flexDirection: 'column',
                  cursor: 'pointer',
                  marginRight: '1.5rem',
                }}>
                <Box
                  sx={{
                    position: 'relative',
                    width: { lg: '13.625rem', xl: '15.625rem' },
                    height: '10.5rem',
                  }}>
                  <ColorBlock
                    bgcolor={item.dark}
                    top={'2.063rem'}
                    left={'3.75rem'}
                  />
                  <ColorBlock
                    bgcolor={item.main}
                    top={'1.375rem'}
                    left={'2.5rem'}
                  />
                  <ColorBlock
                    bgcolor={item.light}
                    top={'0.688rem'}
                    left={'1.25rem'}
                  />
                  <ColorBlock
                    bgcolor={item.contrastText}
                    width={'6.25rem'}
                    height={'5rem'}
                  />
                </Box>
                <FlexBox>
                  <Radio
                    checked={selectedTheme.name === item.name}
                    value={item.name}
                  />
                  <Typography variant={'body1'}>{item.name}</Typography>
                </FlexBox>
              </FlexBox>
            ),
          )}
        </FlexBox>
        <Box>{renderButtons()}</Box>
      </FlexBox>
    );
  }

  if (isLoading) return <Loading />;

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        gap: '2rem',
        height: '100%',
        width: '100%',
      }}>
      <Typography variant={'h2'}>Colors</Typography>

      <Typography variant={'body2'}>
        Tailor your experience to match your brand identity. Create your
        company's colors below to personalize the interface and make it uniquely
        yours. Or choose from one of the palettes we already have available.
      </Typography>

      <form action=''>
        <FormControl>
          <RadioGroup
            defaultValue={'Acterio'}
            value={selectedTheme}
            name='paletteSelector'>
            {renderPalette()}
          </RadioGroup>
        </FormControl>
      </form>
    </FlexBox>
  );
};

export default EcosystemColors;
