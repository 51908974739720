import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationTemplate } from 'utils/models';
import { APPLICATIONS_PROCESS, postData } from 'utils/requests';
import { removeStoredNewApplication } from 'utils/storage';

interface IPostNewApp {
  newApplicationSchema: Partial<IApplicationTemplate> | null;
  setIsLoading: (b: boolean) => void;
  setSharableLink: (s: string) => void;
  setIsOpenModal: (b: boolean) => void;
}

const usePostNewApplication = (props: IPostNewApp) => {
  const {
    newApplicationSchema,
    setIsLoading,
    setSharableLink,
    setIsOpenModal,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function postNewApplication() {
    setIsLoading(true);

    try {
      const data = await postData(
        APPLICATIONS_PROCESS,
        [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
        newApplicationSchema,
      );

      setSharableLink(data);
      setIsOpenModal(true);
      removeStoredNewApplication();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return postNewApplication;
};

export default usePostNewApplication;
