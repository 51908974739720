import { Box, Card, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { acterioTheme, cardShadow } from 'theme';
import { IApplicant, IApplicationSection } from 'utils/models';
import Answers from './Answers';

interface IApplicantInfo {
  sections: IApplicationSection[];
  applicant: IApplicant;
}

const ApplicantInfo: FunctionComponent<IApplicantInfo> = (props) => {
  const { sections, applicant } = props;

  const theme = useTheme();

  if (!sections) return <></>;

  function renderBasicInfo() {
    return (
      <Card
        sx={{
          marginY: '1.5rem',
          borderRadius: '10px',
          bgcolor: acterioTheme.pureWhite,
          boxShadow: cardShadow,
        }}>
        <Box>
          <Typography
            variant='h4'
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'normal',
              marginTop: '2rem',
              marginX: '3rem',
            }}>
            Basic Information
          </Typography>

          <Box>
            <Typography
              variant='h6'
              sx={{
                marginTop: '2rem',
                marginX: '3rem',
                fontWeight: 'normal',
                color: acterioTheme.natural2,
              }}>
              1. Applicant name
            </Typography>

            <Typography
              variant='h6'
              sx={{
                marginTop: '1.5rem',
                marginX: '4rem',
              }}>
              {applicant.applicantName || 'N/A'}
            </Typography>

            <Box
              sx={{
                backgroundColor: acterioTheme.natural6,
                height: '1px',
                margin: '1.5rem 1rem 2rem 1rem',
              }}></Box>
          </Box>

          <Box>
            <Typography
              variant='h6'
              sx={{
                marginTop: '2rem',
                marginX: '3rem',
                fontWeight: 'normal',
                color: acterioTheme.natural2,
              }}>
              2. Company name
            </Typography>

            <Typography
              variant='h6'
              sx={{
                marginTop: '1.5rem',
                marginX: '4rem',
              }}>
              {applicant.companyName || 'N/A'}
            </Typography>

            <Box
              sx={{
                backgroundColor: acterioTheme.natural6,
                height: '1px',
                margin: '1.5rem 1rem 2rem 1rem',
              }}></Box>
          </Box>

          <Box>
            <Typography
              variant='h6'
              sx={{
                marginTop: '2rem',
                marginX: '3rem',
                fontWeight: 'normal',
                color: acterioTheme.natural2,
              }}>
              3. Email address
            </Typography>

            <Typography
              variant='h6'
              sx={{
                marginTop: '1.5rem',
                marginX: '4rem',
              }}>
              {applicant.email || 'N/A'}
            </Typography>

            <Box
              sx={{
                backgroundColor: acterioTheme.natural6,
                height: '1px',
                margin: '1.5rem 1rem 2rem 1rem',
              }}></Box>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Box sx={{ overflow: 'auto', maxHeight: '75vh', px: '0.25rem' }}>
      {renderBasicInfo()}
      {sections.map((section, index) => (
        <Card
          key={index}
          sx={{
            marginY: '1.5rem',
            borderRadius: '10px',
            bgcolor: acterioTheme.pureWhite,
            boxShadow: cardShadow,
          }}>
          <Box>
            <Typography
              variant='h4'
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'normal',
                marginTop: '2rem',
                marginX: '3rem',
              }}>
              {section.name}
            </Typography>
            {section.questions.map((question, index) => (
              <Box key={index}>
                <Typography
                  variant='h6'
                  sx={{
                    marginTop: '2rem',
                    marginX: '3rem',
                    fontWeight: 'normal',
                    color: acterioTheme.natural2,
                  }}>
                  {`${index + 1}. ${question.question}${
                    question.required ? '*' : ''
                  }`}
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    marginX: '3rem',
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {question.description}
                </Typography>
                <Answers answer={question.answer} type={question.type} />
                <Typography
                  variant='h6'
                  sx={{
                    marginTop: '1.5rem',
                    marginX: '4rem',
                    wordWrap: 'break-word',
                  }}>
                  {}
                </Typography>

                <Box
                  sx={{
                    backgroundColor: acterioTheme.natural6,
                    height: '1px',
                    margin: '1.5rem 1rem 2rem 1rem',
                  }}></Box>
              </Box>
            ))}
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default ApplicantInfo;
