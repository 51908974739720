import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/system';
import { PDFViewer } from '@react-pdf/renderer';
import CompanyPdf from 'components/Companies/ViewCompany/CompanyPdf';
import { ICompanyProfile } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import { COMPANY_ROUTE, getData } from 'utils/requests';

interface IDowloadPdf {}

const CompanyPdfDownload: FunctionComponent<IDowloadPdf> = () => {
  const { ecosystemName, companyId } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [companyProfile, setCompanyProfile] = useState<ICompanyProfile | null>(
    null,
  );

  useEffect(() => {
    fetchCompanyProfile();
  }, [ecosystemName, companyId]);

  async function fetchCompanyProfile() {
    if (!ecosystemName || !companyId) return;
    setLoading(true);

    getData(COMPANY_ROUTE, [
      { name: 'ecosystemName', value: ecosystemName },
      { name: 'companyId', value: encodeURIComponent(companyId) },
    ])
      .then((data) => {
        setCompanyProfile(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error('error', error);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: error.message,
        });
        setLoading(false);
      });
  }

  if (isLoading) return <Loading marginTop={90} />;

  if (!companyProfile) return <div>Sorry! Couldn't find the company...</div>;

  return (
    <Box sx={{ marginTop: '-3rem' }}>
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <CompanyPdf companyProfile={companyProfile} />
      </PDFViewer>
    </Box>
  );
};

export default CompanyPdfDownload;
