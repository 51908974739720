import { useContext } from 'react';
import { APPLICATION_STATUS_ROUTE, deleteData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { IApplicationOverview } from 'utils/models';

interface IDeleteColumnProps {
  applicationOverviewData: IApplicationOverview | null;
  ecosystemName: string | undefined;
  selectedApplicationProcessId: string | null;
  statusId: number;
  setApplicationOverviewData: React.Dispatch<
    React.SetStateAction<IApplicationOverview | null>
  >;
  setIsLoading: (isLoading: boolean) => void;
}
const useDeleteColumn = (props: IDeleteColumnProps) => {
  const {
    selectedApplicationProcessId,
    applicationOverviewData,
    setApplicationOverviewData,
    ecosystemName,
    statusId,
    setIsLoading
  } = props;

  const { addAlert } = useContext(AlertContext);
  if (!applicationOverviewData)
    throw new Error('applicationOverviewData is not defined');
  async function deleteColumn() {
    setIsLoading(true);
    try {
      await deleteData(APPLICATION_STATUS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'applicationProcessId', value: selectedApplicationProcessId },
        { name: 'statusId', value: statusId },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Column deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
    const updatedApplicationOverview = {
      ...applicationOverviewData,
      statuses: applicationOverviewData!.statuses.filter(
        (item: any) => item.id !== statusId,
      ),
    };
    setIsLoading(false);
    setApplicationOverviewData(updatedApplicationOverview);
  } }
  return deleteColumn;
};

export default useDeleteColumn;
