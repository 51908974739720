import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationProccess } from 'utils/models';
import { APPLICATIONS_PROCESS_PAUSE, putData } from 'utils/requests';

interface IPauseOrResume {
  selectedApplicationProcessId: number | undefined;
  setApplicationProcesses: React.Dispatch<
    React.SetStateAction<IApplicationProccess[] | null>
  >;
  setIsLoading: (b: boolean) => void;
}

const usePauseOrResumeProcess = (props: IPauseOrResume) => {
  const {
    selectedApplicationProcessId,
    setApplicationProcesses,
    setIsLoading,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function putPauseOrResumeProcess() {
    if (!selectedApplicationProcessId) return;
    setIsLoading(true);

    try {
      const data = await putData(APPLICATIONS_PROCESS_PAUSE, [
        {
          name: 'applicationProcessId',
          value: selectedApplicationProcessId,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setApplicationProcesses((prevProcesses) =>
        prevProcesses
          ? prevProcesses.map((process) =>
              process.id === selectedApplicationProcessId
                ? { ...process, ...data }
                : process,
            )
          : [],
      );
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return putPauseOrResumeProcess;
};

export default usePauseOrResumeProcess;
