import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';

interface IBackground {
  children: React.ReactNode;
  sx?: SxProps;
}

const BackgroundContainer: FunctionComponent<IBackground> = (props) => {
  const { children, sx } = props;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        background: '#F5F5F5',
        boxShadow: '0px 2px 4px rgba(21, 21, 21, 0.08)',
        border: '1px #CDD7E1 solid',
        borderRadius: 1.2,
        padding: '2rem',
        ...{ ...sx },
      }}>
      {children}
    </Box>
  );
};

export default BackgroundContainer;
