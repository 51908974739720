import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface IAddButton {
    isMultiDay: any;
    handleIsWholeDayButton: any;
}

const AddIsWholeDayButton: FunctionComponent<IAddButton> = (props) => {
    const { isMultiDay, handleIsWholeDayButton } = props;

  return (
      <Box>
        <FormControlLabel
          control={
            <Checkbox checked={isMultiDay} onChange={handleIsWholeDayButton} />
          }
          label='Whole day event'
        />
      </Box>
    );
};

export default AddIsWholeDayButton;