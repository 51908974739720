import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles, styled } from '@mui/styles';
import {
  Switch,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { IAccessLevelsProps } from 'pages/Admin/AdminManageAccess';
import TuneIcon from '@mui/icons-material/Tune';
import { useParams } from 'react-router';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  ACCESS_CONTROL_CAN_ADD_RESOURCE_ROUTE,
  ACCESS_CONTROL_CAN_CREATE_COMPANY_PROFILE_ROUTE,
  ACCESS_CONTROL_CAN_CUSTOMIZE_ECO_ROUTE,
  ACCESS_CONTROL_CAN_DELETE_COMPANY_PROFILE_ROUTE,
  ACCESS_CONTROL_CAN_EDIT_COMPANY_OWN_PROFILE_ROUTE,
  ACCESS_CONTROL_CAN_INVITE_COMPANY_USERS,
  ACCESS_CONTROL_CAN_MANAGE_ACCESS_LEVELS_ROUTE,
  ACCESS_CONTROL_CAN_MANAGE_COMPANY_TEMPLATES_ROUTE,
  ACCESS_CONTROL_CAN_MANAGE_JOURNEYS_ROUTE,
  ACCESS_CONTROL_CAN_MANAGE_USERS_ROUTE,
  ACCESS_CONTROL_CAN_SEE_PRIVATE_COMPANY_PROFILE_ROUTE,
  ACCESS_CONTROL_CAN_BOOK_ROOMS,
  ACCESS_CONTROL_CAN_SET_ROOM_AVAILABILITY_ROUTE,
  ACCESS_CONTROL_CAN_UPLOAD_ADMIN_DOCS_ROUTE,
  IRequestParam,
  postData,
  ACCESS_CONTROL_CAN_SET_AVAILABILITY_ROUTE,
  ACCESS_CONTROL_CAN_SCHEDULE_MEETINGS_ROUTE,
  ACCESS_CONTROL_CAN_EDIT_MEMBER_PROFILES_ROUTE,
  ACCESS_CONTROL_MANAGE_EVENTS,
  ACCESS_CONTROL_MANAGE_ANNOUNCEMENTS,
  ACCESS_CONTROL_MANAGE_APPLICATIONS,
  ACCESS_CONTROL_REVIEW_APPLICANTS,
} from 'utils/requests';
import SelectDropdown from 'components/common/SelectDropdown';
import { AccessLevelOption, IAccessLevel, IAccessLevelDto } from 'utils/models';
import { getEcosystemInfo, setEcosystemInfo } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import ContWithStyledScroll from 'components/common/ContWithStyledScroll';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '80rem',
    overflowX: 'scroll',
  },
  cont: {
    display: 'inline-block',
    marginRight: '5rem',
  },
  section: {
    margin: '1rem 0',
    marginBottom: '2rem',

    '& h6': {
      fontSize: '1.2rem !important',
      marginBottom: '0.35rem !important',

      '& span': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  header: {
    marginBottom: '3rem',

    '& h5': {
      fontSize: '1.35rem !important',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '5px',
        height: 32,
        width: 32,
        marginTop: '-3px',
        color: theme.palette.primary.main,
      },
    },

    '& span': {
      fontSize: '0.75rem !important',
      marginLeft: '37.5px',
      display: 'block',
    },
  },
  accessLevel: {
    height: '5rem',
    width: '8.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchboard: {
    width: '100%',
    overflowX: 'auto',
  },
  switchboardHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '14.5rem',
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    marginBottom: '1rem',
  },
  switchboardRows: {
    '& h4': {
      fontSize: '1.2rem !important',
      fontWeight: 600,
    },

    '& h5': {
      fontSize: '0.75rem !important',
      fontWeight: 400,
      marginTop: '1rem !important',
      width: '9rem',
    },
  },
  switchboardRow: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottom: '1px solid #e0e0e0',
  },
  switchboardColumns: {},
  permissionInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '10rem',
    justifyContent: 'space-between',
    marginRight: '4.375rem',

    '& span': {
      cursor: 'pointer',
      marginTop: '1.25rem',
      marginLeft: '0.2rem',

      '& svg': {
        height: 20,
        width: 20,
      },
    },
  },
  permission: {
    display: 'flex',
    alignItems: 'center',
  },
  accessLevelSwitch: {
    width: '8.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  switch: {
    '& .MuiSwitch-thumb': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
    '& .MuiSwitch-track': {
      backgroundColor: `${theme.palette.primary.light}`,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
}));

enum PermissionType {
  Switch = 'switch',
  Dropdown = 'dropdown',
}

interface IPermission {
  name: string;
  field: string;
  tooltip: string;
  type: PermissionType;
  route: string;
  values?: string[];
}

const initialHomePermissions: IPermission[] = [
  {
    name: 'Manage announcements',
    field: 'manageAnnouncements',
    tooltip:
      '"All" allows posting and editing of all announcements. "Only Theirs" allows: viewing permitted events, posting, editing of announcements they originally created. "No" does not allow for management of announcements',
    type: PermissionType.Dropdown,
    route: ACCESS_CONTROL_MANAGE_ANNOUNCEMENTS,
    values: [
      AccessLevelOption.ALL,
      AccessLevelOption.ONLY_THEIRS,
      AccessLevelOption.NO,
    ],
  },
  {
    name: 'Manage events',
    field: 'manageEvents',
    tooltip:
      '"All" allows: viewing, posting and editing of all events. "Only Theirs" allows: viewing permitted events, posting, editing events if they are the contact person or there is no specified contact person. "No" allows for no management  of events',
    type: PermissionType.Dropdown,
    route: ACCESS_CONTROL_MANAGE_EVENTS,
    values: [
      AccessLevelOption.ALL,
      AccessLevelOption.ONLY_THEIRS,
      AccessLevelOption.NO,
    ],
  },
];

const initialBookingPermissions: IPermission[] = [
  {
    name: 'Can request meetings with people',
    field: 'canScheduleMeetings',
    tooltip:
      'Enables user to book meetings with people who have set an availability',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_SCHEDULE_MEETINGS_ROUTE,
  },
  {
    name: 'Can set availability and be booked',
    field: 'canSetAvailability',
    tooltip:
      'Enables user to set their availability and allows people to book meetings with them',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_SET_AVAILABILITY_ROUTE,
  },
  {
    name: 'Can book rooms',
    field: 'canBookRooms',
    tooltip: 'Enables user to book rooms',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_BOOK_ROOMS,
  },
  {
    name: 'Can manage rooms',
    field: 'canSetRoomAvailability',
    tooltip: 'Enables user to create and edit rooms',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_SET_ROOM_AVAILABILITY_ROUTE,
  },
];

const initialCompanyPermissions: IPermission[] = [
  {
    name: 'See private profiles',
    field: 'seePrivateCompanyProfile',
    tooltip:
      '"All" allows viewing of all private profiles."Only Theirs" means the user can only see companies they are a member of. "No" allows only company owners to see their own private profiles',
    type: PermissionType.Dropdown,
    route: ACCESS_CONTROL_CAN_SEE_PRIVATE_COMPANY_PROFILE_ROUTE,
    values: ['All', 'Only Theirs', 'No'],
  },
  {
    name: 'Manage company profile',
    field: 'editOwnCompanyProfile',
    tooltip:
      'Controls who can manage company profiles (team members, goals, shared docs, about us, ect). "All" allows managing of all profiles, "Only Theirs" means the user can only manage companies they are a member of, "No" means only the company owner can manage the profile',
    type: PermissionType.Dropdown,
    route: ACCESS_CONTROL_CAN_EDIT_COMPANY_OWN_PROFILE_ROUTE,
    values: ['All', 'Only Theirs', 'No'],
  },
  {
    name: 'Create company profile',
    field: 'createCompanyProfile',
    tooltip: 'Enable users to create their own companies in the platform',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_CREATE_COMPANY_PROFILE_ROUTE,
  },
  {
    name: 'Can companies invite new users',
    field: 'canCompaniesInviteUsers',
    tooltip:
      'Company owners with this permission can invite outside users to join their company and by extension the ecosystem',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_INVITE_COMPANY_USERS,
  },
  {
    name: 'Can delete company profile',
    field: 'deleteCompanyProfile',
    tooltip:
      'Users with this permission can delete company profiles (Company owners always have this permission)',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_DELETE_COMPANY_PROFILE_ROUTE,
  },
];

const initialResourcePermissions: IPermission[] = [
  {
    name: 'Manage resources',
    field: 'canAddResources',
    tooltip: 'Enables users to add and delete resources',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_ADD_RESOURCE_ROUTE,
  },
];

const initialManagementPermissions: IPermission[] = [
  {
    name: 'Manage admin documents',
    field: 'canUploadAdminDocs',
    tooltip:
      'Enables the user to upload and delete admin documents on all private company profiles',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_UPLOAD_ADMIN_DOCS_ROUTE,
  },
  {
    name: 'Manage access levels',
    field: 'canEditAccessLevels',
    tooltip:
      'Enables the user to view, edit, update and delete access level as well as having access to the member list',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_MANAGE_ACCESS_LEVELS_ROUTE,
  },
  {
    name: 'Manage Users in ecosystem',
    field: 'canManageUsers',
    tooltip:
      'Enables the user to add and remove members. Accept or decline join requests. Generate a list of email address of all users in ecosystem and transfer company ownership.',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_MANAGE_USERS_ROUTE,
  },
  {
    name: 'Manage User profiles',
    field: 'canEditMemberProfiles',
    tooltip: 'Enables the user to edit member profiles',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_EDIT_MEMBER_PROFILES_ROUTE,
  },
  {
    name: 'Customize ecosystems Display Settings',
    field: 'canCustomizeEcosystemDisplay',
    tooltip:
      'Enables the user to change ecosystem logo, color, custom tags and company knowledge diagram',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_CUSTOMIZE_ECO_ROUTE,
  },
];

const initialTemplatePermissions: IPermission[] = [
  {
    name: 'Manage journeys',
    field: 'canManageJourneys',
    tooltip: 'Enables the user to add, modify and delete journeys',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_MANAGE_JOURNEYS_ROUTE,
  },
  {
    name: 'Manage company templates',
    field: 'canManageCompanyTemplates',
    tooltip: 'Enables the user to create templates for companies',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_CAN_MANAGE_COMPANY_TEMPLATES_ROUTE,
  },
];

const initialApplicationPermissions: IPermission[] = [
  {
    name: 'Manage applications',
    field: 'manageApplications',
    tooltip:
      'Enables the user to view, create, edit, move applicants and see all reviews',
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_MANAGE_APPLICATIONS,
  },
  {
    name: 'Review applicants',
    field: 'reviewApplicants',
    tooltip:
      "Enables the user to see applications and their applicants and leave a review without seeing other people's reviews",
    type: PermissionType.Switch,
    route: ACCESS_CONTROL_REVIEW_APPLICANTS,
  },
];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    fontSize: 11,
    textAlign: 'center',
    padding: '0.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    maxWidth: '12.5rem',
    borderRadius: '4px',
  },
}));

const AccessSwitchboard: FunctionComponent<IAccessLevelsProps> = (props) => {
  const { accessLevelDtos, setAccessLevelDtos } = props;
  const { ecosystemName } = useParams();
  const ecosystemInfo = getEcosystemInfo();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const [updatedAccessLevels, setUpdatedAccessLevels] = useState<
    IAccessLevel[]
  >(getAccessLevels());

  useEffect(() => {
    setUpdatedAccessLevels(getAccessLevels());
  }, [accessLevelDtos]);

  function getAccessLevels() {
    return accessLevelDtos.map(({ accessLevel }) => accessLevel);
  }

  async function updateAccessLevel(
    id: number,
    field: string,
    changedValue: boolean | string,
    type: PermissionType,
    route: string,
  ) {
    try {
      const params: IRequestParam[] = [
        { name: 'id', value: id },
        { name: 'ecosystemName', value: ecosystemName },
      ];

      if (type === PermissionType.Switch) {
        params.push({ name: 'changedValue', value: changedValue });
      } else {
        params.push({ name: 'selectedText', value: changedValue });
      }

      await postData(route, params);

      setAccessLevelDtos(((prev: IAccessLevelDto[]) => {
        const updatedDtos = prev.map((dto: IAccessLevelDto) => {
          if (dto.accessLevel.id !== id) return dto;

          const accessLevel = dto.accessLevel;
          const myAccessLevel = ecosystemInfo.accessLevel;
          const isMyAccessLevel = myAccessLevel.id === id;

          const updatedLevel = {
            ...accessLevel,
            [field]: changedValue,
          };

          if (isMyAccessLevel) {
            setEcosystemInfo({
              ...ecosystemInfo,
              accessLevel: {
                ...myAccessLevel,
                [field]: changedValue,
              },
            });
          }

          return {
            ...dto,
            accessLevel: updatedLevel,
          };
        });

        return updatedDtos;
      }) as any);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function renderPermissionToggles(permission: IPermission) {
    const { field, type, values, route } = permission;

    return updatedAccessLevels.map((accessLevel, i) => {
      const { id } = accessLevel;
      const value = accessLevel[field as keyof IAccessLevel] as any;

      return (
        <div
          key={`toggle-${id}-${field}`}
          className={classes.accessLevelSwitch}>
          {type === PermissionType.Switch ? (
            <Switch
              color='secondary'
              checked={value}
              onChange={(event, checked) =>
                updateAccessLevel(id, field, checked, type, route)
              }
              className={classes.switch}
            />
          ) : (
            <div style={{ width: '6.25rem' }}>
              <SelectDropdown
                value={value}
                onChange={(selected) =>
                  updateAccessLevel(id, field, selected, type, route)
                }
                options={values as string[]}
                getOptionLabel={(option) => option}
                sx={{ minWidth: 'unset', width: '100%' }}
              />
            </div>
          )}
        </div>
      );
    });
  }

  function renderPermissions(permissions: IPermission[]) {
    return permissions.map((permission, i) => {
      const { name, tooltip } = permission;

      return (
        <div key={`permission-${name}`} className={classes.permission}>
          <div className={classes.permissionInfo}>
            <Typography variant={'h5'}>{name}</Typography>
            <LightTooltip title={tooltip}>
              <span>
                <HelpOutlineIcon />
              </span>
            </LightTooltip>
          </div>
          {renderPermissionToggles(permission)}
        </div>
      );
    });
  }

  function renderAccessLevelPermissions() {
    const header = updatedAccessLevels.map((accessLevel) => {
      const { id, accessLevelName } = accessLevel;

      return (
        <div key={`access-level-${id}`} className={classes.accessLevel}>
          <div>
            <Typography variant={'h6'}>{accessLevelName}</Typography>
          </div>
        </div>
      );
    });

    const rows = (
      <>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Home</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialHomePermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Booking</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialBookingPermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Companies</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialCompanyPermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Resources</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialResourcePermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Management</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialManagementPermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Templates</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialTemplatePermissions)}
          </div>
        </div>
        <div className={classes.switchboardRow}>
          <Typography variant={'h4'}>Applications</Typography>
          <div className={classes.switchboardColumns}>
            {renderPermissions(initialApplicationPermissions)}
          </div>
        </div>
      </>
    );

    return (
      <div className={classes.switchboard}>
        <div className={classes.switchboardHeader}>{header}</div>
        <div className={classes.switchboardRows}>{rows}</div>
      </div>
    );
  }

  return (
    <ContWithStyledScroll className={classes.root}>
      <div className={classes.header}>
        <Typography variant={'h5'}>
          <TuneIcon /> Manage Permissions
        </Typography>
        <span>
          Set specific permissions for each access level to control what users
          are allowed to do and see
        </span>
      </div>
      <div className={classes.cont}>{renderAccessLevelPermissions()}</div>
    </ContWithStyledScroll>
  );
};

export default AccessSwitchboard;
