import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import Modal, { IBaseModal } from '../common/Modal';
import { ACCOUNT_VERIFY_EMAIL_ROUTE, postData } from 'utils/requests';
import { acterioTheme } from 'theme';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1.5rem 1.25rem',
    paddingTop: '0.6rem',

    '& h6': {
      fontSize: '1.35rem !important',
      color: `${acterioTheme.dark} !important`,
    },

    '& p': {
      marginTop: '1.35rem !important',
      fontWeight: 400,
      color: `${acterioTheme.dark} !important`,

      '& span': {
        fontWeight: 600,
      },
    },
  },
}));

interface ISendVerifyEmail extends IBaseModal {
  email: string;
}

const SendVerifyEmail: FunctionComponent<ISendVerifyEmail> = (props) => {
  const { email, open, close } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);

  async function sendEmail() {
    setLoading(true);

    try {
      await postData(ACCOUNT_VERIFY_EMAIL_ROUTE);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Verification email sent',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
    close();
  }

  function renderContent() {
    return (
      <div className={classes.main}>
        <Typography variant={'h6'}>Send verification email?</Typography>
        <Typography variant={'body1'}>
          Do you want us to send you a verification email to{' '}
          <span>{email}</span>?
        </Typography>
      </div>
    );
  }

  return (
    <Modal
      open={open}
      width={isMobile ? 360 : 600}
      close={close}
      backgroundColor={'white'}
      backdropStrength={0.9}
      noHeader={true}
      showFooter={true}
      largeFooter={true}
      loading={loading}
      isActerioTheme={true}
      footerButtonsFlexStart={true}
      saveAction={() => sendEmail()}
      cancelButtonText={'Dont send'}
      saveButtonText={'Send Verification'}>
      {renderContent()}
    </Modal>
  );
};

export default SendVerifyEmail;
