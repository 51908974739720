import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { ITagGroup } from 'utils/models';
import { getData, putData, TAG_GROUPS_ROUTE } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';

const useTagGroups = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [tagGroups, setTagGroups] = useState<ITagGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const displayWarningAlert = () =>
    addAlert({
      type: ALERT_TYPES.WARNING,
      message:
        'There are two or more tag groups with the same name! Please make sure all Tag groups have unique name!',
    });

  useEffect(() => {
    if (tagGroups.length === 0) {
      fetchTagGroups();
    }
  }, []);

  useEffect(() => {
    const hasDuplicateNames = tagGroups.some((tagGroup, index) => {
      return tagGroups.some((otherTagGroup, otherIndex) => {
        return index !== otherIndex && tagGroup.name === otherTagGroup.name;
      });
    });

    if (!hasDuplicateNames) return;
    displayWarningAlert();
  }, [tagGroups]);

  async function fetchTagGroups() {
    if (!ecosystemName) {
      return null;
    }

    setLoading(true);

    try {
      const data = await getData(TAG_GROUPS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setTagGroups(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function saveTagGroups() {
    setLoading(true);
    try {
      const data = await putData(
        TAG_GROUPS_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        tagGroups,
      );
      setTagGroups(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Tag Groups Saved',
      });
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
      revertTagGroups();
    }

    setLoading(false);
  }

  const handleCheckboxChange = (tagGroupName: string, newValue: boolean) => {
    const updatedTagGroups = tagGroups.map((tg) =>
      tg.name === tagGroupName ? { ...tg, canAddCustomTags: newValue } : tg,
    );
    setTagGroups(updatedTagGroups);
  };

  const updateTagsInGroup = (tagGroup: ITagGroup, newTag: string): void => {
    setTagGroups((prevTagGroups) => {
      const tagGroupsCopy = [...prevTagGroups];
      const groupIndex = tagGroupsCopy.findIndex((group) => group === tagGroup);

      if (groupIndex !== -1) {
        const updatedGroup = { ...tagGroupsCopy[groupIndex] };
        updatedGroup.tags.push({ name: newTag });
        tagGroupsCopy[groupIndex] = updatedGroup;
      }
      return tagGroupsCopy;
    });
  };

  function removeTag(index: number, tagGroup: ITagGroup): void {
    setTagGroups((prevTagGroups) => {
      const tagGroupsCopy = [...prevTagGroups];
      const groupIndex = tagGroupsCopy.findIndex((group) => {
        return tagGroup.id === undefined
          ? group.name === tagGroup.name
          : group.id === tagGroup.id;
      });

      if (groupIndex !== -1) {
        const updatedGroup = { ...tagGroupsCopy[groupIndex] };
        updatedGroup.tags = [...updatedGroup.tags];
        tagGroupsCopy[groupIndex].tags.splice(index, 1);
      }
      return tagGroupsCopy;
    });
  }

  const updateTagGroupName = (tagGroup: ITagGroup, name: string): void => {
    setTagGroups((prevTagGroups) => {
      const tagGroupsCopy = [...prevTagGroups];
      const groupIndex = tagGroupsCopy.findIndex((group) => group === tagGroup);

      const isNameAlreadyTaken = tagGroupsCopy.some(
        (group, index) => index !== groupIndex && group.name === name,
      );

      if (isNameAlreadyTaken) {
        displayWarningAlert();
      }

      if (groupIndex !== -1 && !isNameAlreadyTaken) {
        const updatedGroup = { ...tagGroupsCopy[groupIndex] };
        updatedGroup.name = name;
        tagGroupsCopy[groupIndex] = updatedGroup;
      }
      return tagGroupsCopy;
    });
  };

  function createEmptyTagGroup() {
    setTagGroups((prevTagGroups) => {
      const updatedTagGroups: ITagGroup[] = [
        ...prevTagGroups,
        {
          name: `Tag group ${prevTagGroups.length}`,
          tags: [],
          canAddCustomTags: false,
          memberProfileTag: false,
        },
      ];
      return updatedTagGroups;
    });
  }

  function deleteTagGroup(tagGroup: ITagGroup) {
    setTagGroups((prevTagGroups) => {
      const updatedTagGroups = prevTagGroups.filter((group) =>
        tagGroup.id === undefined
          ? group.name !== tagGroup.name
          : group.id !== tagGroup.id,
      );
      return updatedTagGroups;
    });
  }

  function revertTagGroups() {
    fetchTagGroups();
  }

  return {
    loading,
    tagGroups,
    setTagGroups,
    updateTagsInGroup,
    removeTag,
    fetchTagGroups,
    updateTagGroupName,
    createEmptyTagGroup,
    saveTagGroups,
    deleteTagGroup,
    revertTagGroups,
    handleCheckboxChange,
  };
};

const TagGroupContext = createContext({} as ReturnType<typeof useTagGroups>);

export const TagGroupProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const tagGroupContext = useTagGroups();

  return (
    <TagGroupContext.Provider value={tagGroupContext}>
      {tagGroupContext.loading && tagGroupContext.tagGroups.length === 0 ? (
        <Loading />
      ) : (
        children
      )}
    </TagGroupContext.Provider>
  );
};

export const useTagGroupContext = () => useContext(TagGroupContext);
