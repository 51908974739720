import React, { FunctionComponent, useContext, useState } from 'react';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import UploadImage from 'components/common/UploadImage';
import { formatBytes } from 'utils/utils';
import { ECOADMIN_UPLOAD_LOGO_ROUTE, putData } from 'utils/requests';
import {
  getEcosystem,
  getEcosystemInfo,
  setEcosystem,
  setEcosystemInfo,
} from 'utils/storage';
import { IEcosystem, IEcosystemInfo } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { naturalPallete } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import CommonButton, {
  ButtonSize,
  ButtonVariant,
} from 'components/common/CommonButton';

interface IEcosystemLogos {}

const EcosystemLogos: FunctionComponent<IEcosystemLogos> = (props) => {
  const theme = useTheme();
  const { addAlert } = useContext(AlertContext);

  const [ecosystemInfoData, setEcosystemInfoData] = useState<IEcosystemInfo>(
    getEcosystemInfo(),
  );
  const [ecosystemData, setEcosystemData] = useState<IEcosystem>(
    getEcosystem(),
  );
  const { name, logoUrl } = ecosystemData;
  const { ecosystemNavBarLogo } = ecosystemInfoData;

  const [uploadedLogo, setUploadedLogo] = useState<any>(logoUrl);
  const [uploadedNavLogo, setUploadedNavLogo] =
    useState<any>(ecosystemNavBarLogo);
  const [loadingNav, setLoadingNav] = useState<boolean>(false);
  const [loadingLogo, setLoadingLogo] = useState<boolean>(false);

  async function uploadLogo(isNavLogo: boolean) {
    isNavLogo ? setLoadingNav(true) : setLoadingLogo(true);

    try {
      const data = await putData(
        ECOADMIN_UPLOAD_LOGO_ROUTE,
        [
          {
            name: 'isNavbarLogo',
            value: isNavLogo,
          },
          {
            name: 'ecosystemName',
            value: name,
          },
        ],
        getFileToUpload(isNavLogo),
      );

      if (isNavLogo) {
        const updatedEcosystemInfo = {
          ...ecosystemInfoData,
          ecosystemNavBarLogo: data,
        };

        setUploadedNavLogo('');
        setEcosystemInfoData(updatedEcosystemInfo);
        setEcosystemInfo(updatedEcosystemInfo);
      } else {
        const updatedEcosystem = {
          ...ecosystemData,
          logoUrl: data,
        };

        setUploadedLogo('');
        setEcosystemData(updatedEcosystem);
        setEcosystem(ecosystemData);
      }

      window.location.reload();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    isNavLogo ? setLoadingNav(false) : setLoadingLogo(false);
  }

  function getFileToUpload(isNavLogo: boolean) {
    const logo = isNavLogo ? uploadedNavLogo : uploadedLogo;
    const formData = new FormData();
    formData.append('newLogo', logo, logo.name);
    return formData;
  }

  function renderFileInfo(file: any) {
    if (!file) return null;

    return (
      <FlexBox
        sx={{
          marginLeft: '0.5rem',
          alignItems: 'start',
          flexDirection: 'column',
        }}>
        <Typography
          variant={'h6'}
          sx={{
            marginTop: '0.2rem',
            color: naturalPallete.mainBlackFont,
          }}>
          {file.name}
        </Typography>
        <Typography
          variant={'body1'}
          sx={{
            marginTop: '0.1rem',
            color: naturalPallete.natural1,
          }}>
          {formatBytes(file.size)}
        </Typography>
      </FlexBox>
    );
  }

  return (
    <Box>
      <Typography variant={'h2'} sx={{ mb: '2rem' }}>
        Logo
      </Typography>
      <Card
        sx={{
          borderRadius: '12px',
          backgroundColor: 'white',
          border: `1px solid ${naturalPallete.natural8}`,
          padding: '2.5rem',
        }}>
        <Box
          sx={{
            marginBottom: '4rem',
          }}>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <Box>
                <FlexBox sx={{ gap: '0.5rem' }}>
                  <Typography
                    variant={'h4'}
                    sx={{
                      color: theme.palette.primary.main,
                      mb: '0.5rem',
                    }}>
                    {name}
                  </Typography>
                  <Typography
                    variant={'h4'}
                    sx={{
                      mb: '0.5rem',
                    }}>
                    Logo
                  </Typography>
                </FlexBox>

                <Typography
                  variant={'body2'}
                  sx={{ maxWidth: '25rem', mb: '1rem' }}>
                  This image will represent the {name} ecosystem on the
                  dashboard
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <UploadImage
                  sx={{
                    height: '13rem',
                    '& img': {
                      height: '100%',
                      padding: '0.5rem',
                    },
                  }}
                  borderColor={naturalPallete.natural6}
                  initialSrc={uploadedLogo}
                  onUpload={(file) => setUploadedLogo(file)}
                  isDisabled={loadingNav || loadingLogo}
                />

                <FlexBox
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}>
                  <Box>
                    <CommonButton
                      text={'Upload logo'}
                      variant={ButtonVariant.FILLED}
                      size={ButtonSize.SMALL}
                      isDisabled={
                        !uploadedLogo || loadingNav || uploadedLogo === logoUrl
                      }
                      isLoading={loadingLogo}
                      onClick={() => uploadLogo(false)}
                    />
                    {uploadedLogo !== logoUrl && renderFileInfo(uploadedLogo)}
                  </Box>
                </FlexBox>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <Box>
                <FlexBox sx={{ gap: '0.5rem' }}>
                  <Typography
                    variant={'h4'}
                    sx={{
                      color: theme.palette.primary.main,
                      mb: '0.5rem',
                    }}>
                    {name}
                  </Typography>
                  <Typography
                    variant={'h4'}
                    sx={{
                      mb: '0.5rem',
                    }}>
                    navigation Logo
                  </Typography>
                </FlexBox>

                <Typography
                  variant={'body2'}
                  sx={{ maxWidth: '25rem', mb: '1rem' }}>
                  This image will be displayed in the header navigation
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <UploadImage
                  sx={{
                    height: '13rem',
                    '& img': {
                      height: '100%',
                      padding: '0.5rem',
                    },
                  }}
                  borderColor={naturalPallete.natural6}
                  initialSrc={uploadedNavLogo}
                  onUpload={(file) => setUploadedNavLogo(file)}
                  isDisabled={loadingNav || loadingLogo}
                />
                <FlexBox
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}>
                  <Box>
                    <CommonButton
                      text={'Upload logo'}
                      variant={ButtonVariant.FILLED}
                      size={ButtonSize.SMALL}
                      isDisabled={
                        !uploadedNavLogo ||
                        loadingLogo ||
                        uploadedNavLogo === ecosystemNavBarLogo
                      }
                      isLoading={loadingNav}
                      onClick={() => uploadLogo(true)}
                    />
                    {uploadedNavLogo !== ecosystemNavBarLogo &&
                      renderFileInfo(uploadedNavLogo)}
                  </Box>
                </FlexBox>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default EcosystemLogos;
