import React, { FunctionComponent } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import SideMenuBackButton from './AdminBackButton';
import { Box } from '@mui/system';
import SideMenuSections from '../SideMenu/SideMenuSections';
import { ISideMenuSection } from 'utils/models';

export enum AdminMenuKeys {
  Manage_Join_Requests,
  Add_Remove_From_Ecosystem,
  Generate_Email_List,
  Transfer_Company_Ownership,
  Logo,
  Colors,
  Knowledge_Diagram,
  Add_Calendar,
  Tags,
  Access_Structure,
  Home,
  Companies,
  Resources,
  Management,
  Workspace,
  Assign_Access_Levels,
  Journey_Templates,
  Assign_Journey,
  Company_Profile_Templates,
  Company_Label_Settings,
  Invited_List,
  Applications,
  AddNewApplication,
  EditorDuplicateApplication,
  Assessments,
  AssessmentTemplates,
  AddNewAssessment,
  AssessmentReports,
  EditAssessment,
}

interface IAdminSideMenu {
  menuItems: ISideMenuSection[];
  selectedOption: AdminMenuKeys;
  text?: string;
}

const AdminSideMenu: FunctionComponent<IAdminSideMenu> = (props) => {
  const { menuItems, selectedOption, text } = props;

  return (
    <SideMenu width={22}>
      <SideMenuBackButton text={text ?? 'Admin'} />
      <Box sx={{ mt: '2rem' }}>
        <SideMenuSections
          menuItems={menuItems}
          selectedOption={selectedOption}
        />
      </Box>
    </SideMenu>
  );
};

export default AdminSideMenu;
