import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IApplicationOverview } from 'utils/models';
import { APPLICATIONS_PROCESS_OVERVIEW, getData } from 'utils/requests';

interface IFetchKanbanBoard {
  selectedApplicationProcessId: string | null;
  setIsLoading: (b: boolean) => void;
}

const useFetchKanbanBoardData = (props: IFetchKanbanBoard) => {
  const { selectedApplicationProcessId, setIsLoading } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [data, setData] = useState<IApplicationOverview | null>(null);

  const fetchKanbanBoardData = useCallback(async () => {
    if (!selectedApplicationProcessId) return () => setData(null);
    setIsLoading(true);

    try {
      const data = await getData(APPLICATIONS_PROCESS_OVERVIEW, [
        {
          name: 'applicationProcessId',
          value: selectedApplicationProcessId,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      setData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }, [selectedApplicationProcessId, ecosystemName]);

  useEffect(() => {
    fetchKanbanBoardData();
  }, [fetchKanbanBoardData]);

  return { data };
};

export default useFetchKanbanBoardData;
