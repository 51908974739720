import { Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';

interface IEmailUsers {
  name: string;
  sendEmails: boolean;
  setSendEmails: (b: boolean) => void;
}

const EmailUsersCheckbox: FunctionComponent<IEmailUsers> = (props) => {
  const { name, sendEmails, setSendEmails } = props;
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={sendEmails}
            onChange={(event) => setSendEmails(event.target.checked)}
            color='primary'
          />
        }
        label={`Email users with access to this ${name}`}
      />
    </Box>
  );
};

export default EmailUsersCheckbox;
