import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography, useTheme } from '@mui/material';
import Modal from 'components/common/Modal';
import MainButton, { ButtonType } from 'components/common/MainButton';
import { AddLink, PersonAddAlt1 } from '@mui/icons-material';
import InputField from 'components/common/InputField';
import SearchUsers from 'components/common/SearchUsers';
import { IJourneyGoal } from 'utils/models';
import { useJourneyContext } from './JourneyContext';
import JourneyTemplatePerson, {
  IJourneyPerson,
} from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';
import JourneyTemplateLink from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplateLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '275px',
    padding: '0 1rem',
    paddingBottom: '1.5rem',
  },
  cont: {
    '& button': {
      height: '30px !important',
      width: '115px !important',
      fontWeight: 600,
      margin: '0 !important',
      fontSize: '13px !important',
      marginTop: '0.35rem !important',
      justifyContent: 'start',
      paddingLeft: '10px !important',
      marginLeft: '-7.5px !important',

      '& svg': {
        height: '22px',
        width: '22px',
      },
    },
  },
  linksCont: {
    marginTop: '0.8rem',
    width: '100%',
  },
  peoplesCont: {
    marginTop: '2rem',
  },
  newResource: {
    marginTop: '2rem',
  },
  actionCont: {
    display: 'flex',
    gap: '0.5rem',

    '& button': {
      margin: '0 !important',
      height: '35px !important',
      width: '110px !important',
    },
  },
  resources: {
    marginBottom: '3px',
    display: 'flex',
    gap: '0.3rem',
    flexDirection: 'column',
    width: '100%',
  },
  persons: {
    marginBottom: '1.5rem',
  },
}));

interface IJourneyLink {
  id?: number;
  name: string;
  url: string;
}

interface IJourneyTemplateResources {
  goal: IJourneyGoal;
  open: boolean;
  setOpen: (open: boolean) => void;
  themeIndex: number;
  milestoneIndex: number;
  goalIndex: number;
  isCustomMilestone: boolean | undefined;
}

const JourneyTemplateResources: FunctionComponent<IJourneyTemplateResources> = (
  props,
) => {
  const {
    goal,
    open,
    setOpen,
    themeIndex,
    milestoneIndex,
    goalIndex,
    isCustomMilestone,
  } = props;
  const { id, name, resources } = goal;
  const { updateCustomGoalResources, putCustomGoalResources } =
    useJourneyContext();
  const classes = useStyles();

  const [files, setFiles] = useState<string[]>([]);

  const [addLink, setAddLink] = useState<boolean>(false);
  const [links, setLinks] = useState<IJourneyLink[]>(resources.links);
  const [linkName, setLinkName] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [updatedLinkIndex, setUpdatedLinkIndex] = useState<number | null>(null);
  const theme = useTheme();

  const [addPerson, setAddPerson] = useState<boolean>(false);
  const [newPerson, setNewPerson] = useState<IJourneyPerson | null>(null);
  const [persons, setPersons] = useState<IJourneyPerson[]>(resources.persons);

  useEffect(() => {
    updateCustomGoalResources(
      isCustomMilestone,
      themeIndex,
      milestoneIndex,
      goalIndex,
      {
        files,
        links,
        persons,
      },
    );
  }, [links, persons]);

  function handleSaveCustomGoalResources() {
    putCustomGoalResources(
      isCustomMilestone,
      themeIndex,
      milestoneIndex,
      goalIndex,
      id,
      {
        files,
        links,
        persons,
      },
    );
    setOpen(false);
  }

  function addNewLink() {
    setLinks([
      ...links,
      {
        name: linkName,
        url: linkUrl,
      },
    ]);

    resetLinkState();
  }

  function modifyLink() {
    if (updatedLinkIndex === null) return resetLinkState();

    const updatedLinks = links.map((link, i) => {
      if (i !== updatedLinkIndex) return link;
      return { ...link, name: linkName, url: linkUrl };
    });

    setLinks(updatedLinks);
    resetLinkState();
  }

  function resetLinkState() {
    setAddLink(false);
    setUpdatedLinkIndex(null);
    setLinkName('');
    setLinkUrl('');
  }

  function updateLink(linkIndex: number) {
    const { name, url } = goal.resources.links[linkIndex];
    setLinkName(name);
    setLinkUrl(url);
    setUpdatedLinkIndex(linkIndex);
  }

  function deleteLink(linkIndex: number) {
    setLinks(links.filter((link, i) => i !== linkIndex));
  }

  function deletePerson(personId: number) {
    setPersons(persons.filter(({ id }) => id !== personId));
  }

  function addNewPerson() {
    if (!newPerson) return null;

    setPersons([...persons, newPerson]);
    resetPersonState();
  }

  function resetPersonState() {
    setNewPerson(null);
    setAddPerson(false);
  }

  function renderActionBtn(
    text: string,
    icon: React.ReactNode,
    onClick: () => void,
  ) {
    return (
      <MainButton
        text={text}
        type={ButtonType.BLANK}
        startIcon={icon}
        onClick={onClick}
      />
    );
  }

  function renderLinks() {
    return links.map((link, i) => (
      <JourneyTemplateLink
        index={i}
        key={`goallink-${i}`}
        link={link}
        updateLink={updateLink}
        deleteLink={deleteLink}
      />
    ));
  }

  function renderPersons() {
    return persons.map((person, i) => (
      <JourneyTemplatePerson
        key={person.id}
        person={person}
        deletePerson={deletePerson}
        isLast={i === persons.length - 1}
      />
    ));
  }

  function renderSaveButton() {
    return (
      <Button
        disabled={!open}
        variant='contained'
        sx={{
          borderRadius: 0.2,
          bgcolor: theme.palette.primary.dark,
          marginTop: 4,
        }}
        onClick={handleSaveCustomGoalResources}>
        <Typography fontWeight='bold' color='white'>
          Save changes
        </Typography>
      </Button>
    );
  }

  function renderContent() {
    if (addLink || updatedLinkIndex !== null)
      return (
        <div className={classes.newResource}>
          <InputField
            autoFocus={true}
            value={linkName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setLinkName(event.target.value)
            }
            placeholder={'Name'}
          />
          <InputField
            value={linkUrl}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setLinkUrl(event.target.value)
            }
            placeholder={'URL'}
          />
          <div className={classes.actionCont}>
            <MainButton
              text={updatedLinkIndex !== null ? 'Update' : 'Add'}
              type={ButtonType.FILLED}
              disabled={linkName === '' || linkUrl === ''}
              onClick={() =>
                updatedLinkIndex !== null ? modifyLink() : addNewLink()
              }
            />
            <MainButton
              text={'Cancel'}
              type={ButtonType.BLANK}
              onClick={resetLinkState}
            />
          </div>
        </div>
      );

    if (addPerson)
      return (
        <div className={classes.newResource}>
          <div className={classes.persons}>
            <SearchUsers
              onSearch={(person) => setNewPerson(person)}
              excludedIds={persons.map(({ id }) => id)}
              fullWidth={true}
            />
          </div>
          <div className={classes.actionCont}>
            <MainButton
              text={'Add'}
              type={ButtonType.FILLED}
              onClick={addNewPerson}
            />
            <MainButton
              text={'Cancel'}
              type={ButtonType.BLANK}
              onClick={() => resetPersonState()}
            />
          </div>
        </div>
      );

    return (
      <div className={classes.cont}>
        <div className={classes.linksCont}>
          <Typography
            variant={'h6'}
            fontSize={10}
            textTransform={'uppercase'}
            color={theme.palette.primary.main}>
            {links.length} link{links.length === 1 ? '' : 's'}
          </Typography>
          <div
            className={classes.resources}
            style={{ marginTop: links.length > 0 ? '0.5rem' : '0' }}>
            {renderLinks()}
          </div>
          {renderActionBtn('Add link', <AddLink />, () => setAddLink(true))}
        </div>

        <div className={classes.peoplesCont}>
          <Typography variant={'h6'} fontSize={16}>
            People
          </Typography>
          <div
            className={classes.resources}
            style={{ marginTop: persons.length > 0 ? '0.2rem' : '0' }}>
            {renderPersons()}
          </div>
          {renderActionBtn('Add person', <PersonAddAlt1 />, () =>
            setAddPerson(true),
          )}
        </div>
      </div>
    );
  }

  return (
    <Modal
      width={500}
      open={open}
      close={() => setOpen(false)}
      isActerioTheme={true}
      title={'Resources'}>
      <div className={classes.root}>
        <Typography variant={'h6'} fontSize={20}>
          {addLink
            ? 'Add new link'
            : updatedLinkIndex !== null
            ? 'Update link'
            : addPerson
            ? 'Add new person'
            : name}
        </Typography>
        {renderContent()}
        {!addLink && !addPerson && renderSaveButton()}
      </div>
    </Modal>
  );
};

export default JourneyTemplateResources;
