import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import { IJourneyGoal } from 'utils/models';
import { Link } from '@mui/icons-material';
import JourneyTemplatePerson from '../Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  resources: {
    marginBottom: '3px',
    display: 'flex',
    gap: '0.3rem',
    flexDirection: 'column',
    width: '100%',
  },
  persons: {
    marginBottom: '1.5rem',
  },
  cont: {
    '& button': {
      height: '30px !important',
      width: '115px !important',
      fontWeight: 600,
      margin: '0 !important',
      fontSize: '13px !important',
      marginTop: '0.35rem !important',
      justifyContent: 'start',
      paddingLeft: '10px !important',
      marginLeft: '-7.5px !important',

      '& svg': {
        height: '22px',
        width: '22px',
      },
    },
  },
  linksCont: {
    marginTop: '0.8rem',
    width: '100%',
  },
  peoplesCont: {
    marginTop: '2rem',
  },
  linkCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '0.3rem',
  },
  iconCont: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '15px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: '1px solid rgba(11, 57, 67, 0.5)',
    marginRight: '7px',

    '& svg': {
      height: '17px',
      width: '17px',
      color: theme.palette.primary.dark,
    },
  },
}));

interface IJourneyGoalResources {
  goal: IJourneyGoal;
  open: boolean;
  close: () => void;
}

const JourneyGoalResources: FunctionComponent<IJourneyGoalResources> = (
  props,
) => {
  const { goal, open, close } = props;
  const { name, resources } = goal;
  const { links, persons, files } = resources;
  const classes = useStyles();
  const theme = useTheme();

  function renderLinks() {
    return links.map((link, i) => {
      const { id, name, url } = link;

      return (
        <div
          key={`goal-${goal.id}-link-${id}`}
          className={classes.linkCont}
          style={{
            borderBottom: i === links.length - 1 ? 'none' : '1px solid #eee',
          }}>
          <div className={classes.iconCont}>
            <a className={classes.icon} target={'_blank'} href={url}>
              <Link />
            </a>
            <a style={{ textDecoration: 'none' }} target={'_blank'} href={url}>
              <Typography
                variant={'body2'}
                fontSize={12}
                style={{ marginRight: '10px', marginTop: '2px' }}>
                {name}
              </Typography>
            </a>
          </div>
        </div>
      );
    });
  }

  function renderPersons() {
    return persons.map((person, i) => (
      <JourneyTemplatePerson
        key={person.id}
        person={person}
        isLast={i === persons.length - 1}
      />
    ));
  }

  function renderContent() {
    return (
      <div className={classes.cont}>
        <div className={classes.linksCont}>
          <Typography variant={'h6'} fontSize={16} marginBottom={'8px'}>
            Resources
          </Typography>
          <Typography
            variant={'h6'}
            fontSize={10}
            textTransform={'uppercase'}
            color={theme.palette.primary.main}>
            {links.length} link{links.length === 1 ? '' : 's'}
          </Typography>
          <div
            className={classes.resources}
            style={{ marginTop: links.length > 0 ? '0.5rem' : '0' }}>
            {renderLinks()}
          </div>
        </div>

        <div className={classes.peoplesCont}>
          <Typography variant={'h6'} fontSize={16}>
            People
          </Typography>
          <div
            className={classes.resources}
            style={{ marginTop: persons.length > 0 ? '0.2rem' : '0' }}>
            {renderPersons()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      width={500}
      open={open}
      close={close}
      isActerioTheme={true}
      title={name}>
      <div className={classes.root}>{renderContent()}</div>
    </Modal>
  );
};

export default JourneyGoalResources;
