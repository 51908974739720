import React, { FunctionComponent } from 'react';
import { Box, keyframes, useTheme } from '@mui/material';
import Loading from '../common/Loading';
import Button from '@mui/material/Button';
import SendIcon from '../../assests/icons/SendIcon';
import InputField from '../common/InputField';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

interface CommentInputProps {
  inputText: string | undefined;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  commentLoading: boolean;
  renderInput?: boolean;
  onClick: () => void;
}

const CommentInput: FunctionComponent<CommentInputProps> = (props) => {
  const { inputText, commentLoading, onClick, setInputText, renderInput } =
    props;
  const theme = useTheme();

  return (
    <InputField
      maxLength={1000}
      value={inputText}
      multiline
      height={'2.5rem'}
      icon={
        inputText ? (
          commentLoading ? (
            <Box
              sx={{
                marginRight: '0.5rem',
              }}>
              <Loading size={'1.5rem'} />
            </Box>
          ) : (
            <Button
              onClick={onClick}
              sx={{
                borderRadius: '100%',
                minWidth: '1.5rem',
                padding: '0.125rem',
                marginTop: '0.125rem',
                position: 'absolute',
                right: '0.8rem',
                bottom: '0.4rem',
              }}>
              <SendIcon color={theme.palette.primary.main} />
            </Button>
          )
        ) : undefined
      }
      iconPosition={'end'}
      onChange={(event) => setInputText(event.target.value)}
      placeholder={'Comment'}
      sx={{
        marginLeft: '1rem',
        marginTop: '1rem',
        animation: `${renderInput ? slideDown : slideUp} 0.3s forwards`,
        '& .MuiOutlinedInput-root': {
          padding: '5px 0',
          alignItems: 'center',
        },
        '& .MuiInputBase-input': {
          padding: '0 1.875rem 0 1rem',
        },
      }}
      width={'96%'}
    />
  );
};

export default CommentInput;
