import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import InputField from 'components/common/InputField';
import MainButton, { ButtonType } from 'components/common/MainButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams } from 'react-router';
import { GENERATE_EMAIL_LIST_ROUTE, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    padding: '1.5rem 2rem',

    '& p': {
      fontSize: '0.95rem !important',
      maxWidth: '50rem',
      marginBottom: '1.2rem !important',
    },
  },
  actionSelects: {
    display: 'flex',
  },
  actionButtons: {
    display: 'flex',

    '& button': {
      fontWeight: 600,
      fontSize: '1.05rem !important',
      maxWidth: '190px !important',
      marginLeft: '0 !important',
    },
  },
}));

interface IGenerateEmailList {}

const GenerateEmailList: FunctionComponent<IGenerateEmailList> = (props) => {
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const filter = 'allUsers';
  const [loading, setLoading] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<string>('');

  async function generateEmailList() {
    setLoading(true);

    try {
      const data = await getData(GENERATE_EMAIL_LIST_ROUTE, [
        {
          name: 'filter',
          value: filter,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Email list generated',
      });

      setEmailList(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h5'}>Generate E-mail list</Typography>
      <div className={classes.cont}>
        <Typography variant={'body2'}>
          Click the buttons below to generate a list of email addresses for
          users in {ecosystemName}.
        </Typography>
        <InputField
          value={emailList}
          placeholder={'Generated emails will appear here!'}
          onChange={() => console.log(123)}
          multiline={true}
          rows={10}
          readonly={true}
        />
        <div className={classes.actionSelects}></div>
        <div className={classes.actionButtons}>
          <MainButton
            text={'Generate'}
            type={ButtonType.FILLED}
            onClick={() => generateEmailList()}
            loading={loading}
            disabled={!!emailList}
          />
          <MainButton
            onClick={() => navigator.clipboard.writeText(emailList)}
            text={'Copy to clipboard'}
            type={!emailList ? ButtonType.BLANK : ButtonType.FILLED}
            endIcon={<ContentCopyIcon />}
            disabled={!emailList}
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateEmailList;
