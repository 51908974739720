import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import MainButton, { ButtonType } from 'components/common/MainButton';
import { Add } from '@mui/icons-material';
import JourneyTemplateTheme from './JourneyTemplateTheme';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) => ({
  themes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginTop: (props: any) => (props.haveThemes ? '2rem' : '0'),
  },
  addMilestone: {
    '& button': {
      width: '165px !important',
      height: '37.5px !important',
      marginTop: '1.25rem !important',
      marginLeft: '1.5rem !important',
    },
  },
}));

interface IJourneyTemplateThemes {}

const JourneyTemplateThemes: FunctionComponent<IJourneyTemplateThemes> = (
  props,
) => {
  const { stage, stageIndex, addStageTheme, setStage } =
    useJourneyTemplateContext();
  const classes = useStyles({
    haveThemes: !stage ? false : stage.themes.length > 0,
  });

  function onDragEnd(result: DropResult) {
    if (!result.destination || stage === null) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const updatedThemes = [...stage.themes];
    const [draggedTheme] = updatedThemes.splice(sourceIndex, 1);
    updatedThemes.splice(destinationIndex, 0, draggedTheme);

    setStage({ ...stage, themes: updatedThemes });
  }

  function renderThemes() {
    if (!stage) return null;

    return stage.themes.map((theme, i) => {
      return (
        <JourneyTemplateTheme
          key={`theme-${stageIndex}-${i}`}
          index={i}
          theme={theme}
        />
      );
    });
  }

  return !stage ? null : (
    <div>
      <div className={classes.themes}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='themes' direction='vertical'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.themes}>
                {renderThemes()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={classes.addMilestone}>
        <MainButton
          text={'Add new theme'}
          type={ButtonType.FILLED}
          startIcon={<Add />}
          onClick={addStageTheme}
        />
      </div>
    </div>
  );
};

export default JourneyTemplateThemes;
