import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Fade, Theme, Tooltip, Typography } from '@mui/material';
import { useAssignJourneysContext } from './AssignJourneysContext';
import Avatar from 'components/common/Avatar';
import { Clear } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    height: '2.5rem',
    border: '1px solid #e0e0e0',
    backgroundColor: '#f5f5f5',
    borderRadius: '2.5rem',
    width: '375px',
    display: 'flex',
    alignItems: 'center',
  },
  companies: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '0.7rem',

    '& p': {
      fontSize: '0.8rem !important',
    },
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingRight: '0.85rem',
    height: '100%',
    backgroundColor: '#eee',
    borderBottomLeftRadius: '2.5rem',
    borderTopLeftRadius: '2.5rem',
    borderRight: '1px solid #e0e0e0',
  },
  company: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
  clear: {
    marginLeft: 'auto',

    '& button': {
      borderRadius: '50% !important',
      backgroundColor: `${theme.palette.primary.dark} !important`,
      padding: '3px',

      '& svg': {
        color: 'white',
        height: '18px',
        width: '18px',
      },
    },
  },
}));

const MAX_TO_SHOW = 9;

interface IAssignJourneysCompanies {}

const AssignJourneysCompanies: FunctionComponent<IAssignJourneysCompanies> = (
  props,
) => {
  const { selectedCompanies, removeCompany, clearAllCompanies } =
    useAssignJourneysContext();
  const classes = useStyles();
  const haveSelectedCompanies = selectedCompanies.length > 0;

  function renderSelectedCompanies() {
    if (!haveSelectedCompanies) {
      return (
        <Typography variant={'body1'} color={'#bdbdbd'}>
          No companies selected
        </Typography>
      );
    }

    return selectedCompanies.slice(0, MAX_TO_SHOW).map((company, i) => {
      const { companyId, companyName, companyLogo } = company;

      return (
        <Tooltip title={companyName} key={`selected-company-${companyId}`}>
          <Fade in={true} timeout={400} className={classes.company}>
            <div
              onClick={() => removeCompany(company)}
              style={{
                left: `${i * 20}px`,
              }}>
              <Avatar
                source={
                  companyLogo ?? '/images/placeholders/company.png'
                }
                height={27.5}
                width={27.5}
                radius={50}
                border={true}
              />
            </div>
          </Fade>
        </Tooltip>
      );
    });
  }

  function renderClearBtn() {
    return (
      <div className={classes.clear}>
        <Fade in={haveSelectedCompanies} timeout={400}>
          <ButtonBase onClick={() => clearAllCompanies()}>
            <Clear />
          </ButtonBase>
        </Fade>
      </div>
    );
  }

  function renderAdditionalCompanies() {
    if (selectedCompanies.length <= MAX_TO_SHOW) return null;

    return (
      <div
        style={{
          position: 'absolute',
          left: `${MAX_TO_SHOW * 22}px`,
        }}>
        <Typography variant={'body1'} color={'#9e9e9e'}>
          +{selectedCompanies.length - MAX_TO_SHOW}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.cont}>
        <div className={classes.selected}>
          <Typography variant={'h6'}>Selected</Typography>
        </div>
        <div className={classes.companies}>
          {renderSelectedCompanies()}
          {renderAdditionalCompanies()}
        </div>
        {renderClearBtn()}
      </div>
    </div>
  );
};

export default AssignJourneysCompanies;
