import React, { FunctionComponent } from 'react';
import ChangeNotifications from './ChangeNotifications';
import { Box } from '@mui/system';
import CommonModal from '../common/Modal/CommonModal';
import ModalHeader from '../common/Modal/ModalHeader';
import { acterioTheme } from 'theme';
import { INotificationSettings } from 'utils/models';

interface INotificationsModal {
  notificationSettings: INotificationSettings;
  open: boolean;
  close: () => void;
  showAdminNotification: boolean;
  userId: number;
}

const NotificationsModal: FunctionComponent<INotificationsModal> = (props) => {
  const { notificationSettings, open, close, showAdminNotification, userId } =
    props;

  return (
    <CommonModal open={open} onClose={close}>
      <Box
        sx={{
          width: '37rem',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          padding: '1rem',
          background:
            'linear-gradient(153deg, #1B3650 -27.92%, #2D4D6A 124.95%)',
        }}>
        <ModalHeader
          handleClose={close}
          text='Notification settings'
          textColor={acterioTheme.pureWhite}
        />
        <ChangeNotifications
          notificationSettings={notificationSettings}
          showAdminNotification={showAdminNotification}
          userId={userId}
        />
      </Box>
    </CommonModal>
  );
};

export default NotificationsModal;
