import { Close } from '@mui/icons-material';
import { Button, Grid, useTheme } from '@mui/material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { primary } from 'theme';
import { ICompanyField, IUploadFile } from 'utils/models';
import { COMPANY_PROFILE_UPLOAD_ROUTE, deleteData } from 'utils/requests';

interface IUploadImageDisplayProps {
  file: IUploadFile;
  field: ICompanyField;
}

const IUploadImageDisplay: FunctionComponent<IUploadImageDisplayProps> = (
  props,
) => {
  const { file, field } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const { companyProfile, updateCompanyField } = useCompanyContext();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  async function deleteFile(file: IUploadFile) {
    setIsDeleteLoading(true);

    try {
      const data = await deleteData(COMPANY_PROFILE_UPLOAD_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyProfileFieldId', value: field.id },
        {
          name: 'companyName',
          value: companyProfile!.name,
        },
        {
          name: 'fileId',
          value: file.id,
        },
      ]);

      const updatedFiles = field.files?.filter(
        (f: IUploadFile) => f.id !== file.id,
      );

      updateCompanyField(field.id, updatedFiles);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Deleted successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsDeleteLoading(false);
  }

  if (isDeleteLoading)
    return (
      <Grid item xs={4}>
        <Loading height={24} size={40} marginTop={80} />
      </Grid>
    );
  return (
    <Grid item xs={4}>
      <img
        src={file.url}
        style={{
          borderRadius: '8px',
          width: '100%',
          aspectRatio: 3 / 2,
        }}
      />
      <Button
        sx={{
          position: 'absolute',
          minWidth: 'unset',
          width: '25px',
          height: '25px',
          borderRadius: '100%',
          ml: '-2.125rem',
          mt: '0.375rem',

          borderColor: theme.palette.primary.dark,
          bgcolor: primary.natural6,
          p: '4px',

          '&:hover': {
            bgcolor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
        }}
        onClick={() => deleteFile(file)}>
        <Close
          sx={{
            color: theme.palette.primary.dark,
            width: '13,18px',
            height: '13,18px',
          }}
        />
      </Button>
    </Grid>
  );
};

export default IUploadImageDisplay;
