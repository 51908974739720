import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import ChangeNotifications from '../Account/ChangeNotifications';
import { useParams } from 'react-router';
import {
  ACCOUNT_NOTIFICATION_SETTINGS_ROUTE,
  getData,
} from 'utils/requests';
import { INotificationSettings } from 'utils/models';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import Loading from '../common/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  boldFont: {
    fontWeight: 'bold!important',
    color: 'white !important',
  },
  label: {
    textAlign: 'center',
    fontWeight: '700!important',
    marginBottom: '20px!important',
  },
  input: {
    border: '#e0e0e0 1.5px solid',
    borderRadius: '6px',
    paddingLeft: '7px',
  },
  inputContainer: {
    width: '100%',
    marginBottom: '-10px!important',
  },
  form: {
    display: 'contents',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
  checkboxContainer: {
    marginTop: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

interface OwnProps {}

const Unsubscribe: FunctionComponent<OwnProps> = () => {
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const [notificationSettings, setNotificationSettings] =
    useState<INotificationSettings>();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { userId } = useParams();

  useEffect(() => {
    setLoading(true);
    getData(
      ACCOUNT_NOTIFICATION_SETTINGS_ROUTE,
      [{ name: 'userId', value: userId }],
      true,
    )
      .then((data) => {
        setNotificationSettings(data.notificationSettings);
        setIsUserAdmin(data.isUserAdmin);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error('error', error);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: error.message,
        });
        setLoading(false);
      });
  }, [userId]);

  function renderLoadingOrChangeNotifications() {
    if (loading) {
      return <Loading />;
    }
    if (notificationSettings && userId) {
      return (
        <ChangeNotifications
          notificationSettings={notificationSettings}
          showAdminNotification={isUserAdmin}
          userId={userId}
        />
      );
    }
    return (
      <Typography variant={'body2'} className={classes.boldFont}>
        Notification settings could not be found, please update from your
        account page
      </Typography>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant={'h4'} className={classes.boldFont}>
        Unsubscribe
      </Typography>
      {renderLoadingOrChangeNotifications()}
    </div>
  );
};

export default Unsubscribe;
