import React, {
  useState,
  useContext /* useEffect */,
  FunctionComponent,
} from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { naturalPallete } from 'theme';
import { IApplicationOverview, IApplicationStatus } from 'utils/models';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import { Add } from '@mui/icons-material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import ApplicationKanbanColumn from './ApplicationKanbanColumn';
import { postData, APPLICATION_STATUS_ROUTE } from 'utils/requests';
import { useParams } from 'react-router';
import NewColumnModal from './NewColumnModal';

interface IApplicationKanbaProps {
  isNewColumnModalOpen: boolean;
  setIsNewColumnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ApplicationKanbanView: FunctionComponent<IApplicationKanbaProps> = (
  props,
) => {
  const { isNewColumnModalOpen, setIsNewColumnModalOpen } = props;

  const {
    applicationOverviewData,
    setApplicationOverviewData,
    selectedApplicationProcessId,
  } = useApplicationContext();

  const [newColumnName, setNewColumnName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  async function postNewColumn(newColumnName: string) {
    if (newColumnName.trim() === '') return;
    setIsLoading(true);
    try {
      const data = await postData(APPLICATION_STATUS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'applicationProcessId', value: selectedApplicationProcessId },
        { name: 'newStatusName', value: newColumnName },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Column added',
      });
      setApplicationOverviewData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
    setIsNewColumnModalOpen(false);
  }

  function renderKanbanColumns(statuses: IApplicationStatus[]) {
    return (
      <>
        {statuses.map((status: IApplicationStatus, indexOfColumn: number) => (
          <Box key={status.id}>
            <ApplicationKanbanColumn
              status={status}
              handleOpenNewColumnModal={() => setIsNewColumnModalOpen(true)}
              indexOfColumn={indexOfColumn}
            />
          </Box>
        ))}
        <Box>
          <Button
            onClick={() => setIsNewColumnModalOpen(true)}
            sx={{
              width: '100%',
              minWidth: '17rem',
            }}>
            <Add sx={{ color: naturalPallete.natural2 }} />
          </Button>
        </Box>
        <NewColumnModal
          isOpen={isNewColumnModalOpen}
          handleClose={() => setIsNewColumnModalOpen(false)}
          handleAddNewColumn={() => postNewColumn(newColumnName)}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          isAddLoading={isLoading}
        />
      </>
    );
  }

  function renderKanbanBoard(data: IApplicationOverview) {
    return (
      <Box
        sx={{
          width: 'calc(100vw - 25rem)',
          height: 'calc(100vh - 14rem)',
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          overflow: 'auto',
          paddingRight: '2rem',
        }}>
        {renderKanbanColumns(data.statuses)}
      </Box>
    );
  }

  return (
    <Box>
      {!!applicationOverviewData && renderKanbanBoard(applicationOverviewData)}
    </Box>
  );
};

export default ApplicationKanbanView;
