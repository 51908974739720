import { Box, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import UploadImage from 'components/common/UploadImage';
import { IEvent } from 'utils/models';

interface IAddPhoto {
  setCoverImg: (a: any) => void;
  removePhoto: () => void;
  event?: IEvent;
}

const AddPhoto: FunctionComponent<IAddPhoto> = (props) => {
  const { setCoverImg, removePhoto, event } = props;
  const theme = useTheme();

  return (
    <Box>
      <UploadImage
        width='100%'
        initialSrc={event?.photo}
        onUpload={(file) => {
          setCoverImg(file);
        }}
        height={200}
        borderColor={theme.palette.primary.dark}
        hideBorder
        full
        showButtons={true}
        onRemove={() => removePhoto()}
        crop={true}
        aspectRatioWidth={5.6}
        aspectRatioHeight={2.01}
      />
    </Box>
  );
};

export default AddPhoto;
