import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade, Typography } from '@mui/material';
import { ICompanySection } from 'utils/models';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CompanySection from '../CompanySection';
import RichTextEditor from '../../common/RichTextEditor';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ControlButtonStyled } from '../../Admin/Applications/ApplicationBuilder';
import CommonButton from '../../common/CommonButton';
import EditIcon from '@mui/icons-material/Edit';
import { FlexBox } from '../../../utils/styledComponents';
import InputField from '../../common/InputField';
import RichTextDisplay from '../../common/RichTextDisplay';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

interface ICompanyProblemProps {
  sections: ICompanySection[] | null;
  updateSections: (data: ICompanyForm) => void;
  tabName: string;
  isLoading: boolean;
}

export interface ICompanyForm {
  sectionName: string;
  sections: ICompanySection[] | null;
}

const CompanyProblemAndMarket: FunctionComponent<ICompanyProblemProps> = (
  props,
) => {
  const { sections, updateSections, tabName, isLoading } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const {
    control,
    trigger,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ICompanyForm>({
    defaultValues: {
      sectionName: tabName,
      sections,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'sections',
    control,
  });

  //This function cleans the richTextEditor from tags for correct validation
  function clearContent(content: string) {
    const clearedContent = content.replace(/<p><br><\/p>/g, '').trim();
    return clearedContent.length === 0 ? '' : clearedContent;
  }

  function onSubmit(data: ICompanyForm) {
    updateSections(data);
    setIsEditMode(false);
  }

  useEffect(() => {
    reset({ sections, sectionName: tabName });
  }, [sections, reset, tabName]);

  return (
    <>
      {isEditMode && (
        <Controller
          control={control}
          name={'sectionName'}
          rules={{ required: true }}
          render={({ field }) => (
            <InputField
              {...field}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                field.onChange(e);
              }}
              error={!!errors?.sectionName}
              readonly={isLoading}
              width={'60%'}
              clearBackground
            />
          )}
        />
      )}
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <Box key={`${field.id} + ${index}`}>
            <Box
              sx={{
                my: '1rem',
              }}>
              {isEditMode ? (
                <FlexBox
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: '0.5rem',
                  }}>
                  <FlexBox
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                    }}>
                    <Controller
                      control={control}
                      name={`sections.${index}.title`}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          onChange={(
                            e: React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                          ) => {
                            field.onChange(e);
                          }}
                          error={!!errors?.sections?.[index]?.title}
                          readonly={isLoading}
                          width={'80%'}
                          clearBackground
                        />
                      )}
                    />
                    <ControlButtonStyled
                      onClick={() => {
                        remove(index);
                      }}>
                      <CloseIcon />
                    </ControlButtonStyled>
                  </FlexBox>
                  <Controller
                    control={control}
                    name={`sections.${index}.description`}
                    rules={{
                      required: true,
                      validate: (value) => {
                        const clearedValue = clearContent(value);
                        return (
                          clearedValue !== '' || 'Description cannot be empty'
                        );
                      },
                    }}
                    render={({ field }) => (
                      <RichTextEditor
                        error={!!errors?.sections?.[index]?.description}
                        sx={{
                          backgroundColor: 'white',
                        }}
                        {...field}
                        readonly={isLoading}
                        onChange={(value: string) => {
                          field.onChange(value);
                          trigger(`sections.${index}.description`);
                        }}
                      />
                    )}
                  />
                </FlexBox>
              ) : (
                <CompanySection title={field.title}>
                  <Fade in={true}>
                    <FlexBox
                      sx={{
                        margin: '0.5rem',
                      }}>
                      <RichTextDisplay
                        text={field.description}
                        style={{ fontSize: 12, textOverflow: 'ellipsis' }}
                      />
                    </FlexBox>
                  </Fade>
                </CompanySection>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Typography
          variant={'h6'}
          sx={{
            marginTop: '1rem',
            marginLeft: '1.95rem',
          }}>
          Click edit to add you own sections...
        </Typography>
      )}
      <FlexBox
        className={isEditMode ? 'between' : 'end'}
        sx={{
          marginTop: '1rem',
        }}>
        {isEditMode && (
          <ControlButtonStyled
            sx={{
              marginTop: '0.25rem',
            }}
            onClick={() =>
              append({
                title: '',
                description: '',
              })
            }>
            <AddCircleIcon />{' '}
            <Typography
              sx={{
                marginLeft: '0.625rem',
              }}>
              Add section
            </Typography>
          </ControlButtonStyled>
        )}
        <CommonButton
          onClick={() => {
            if (isEditMode) {
              handleSubmit(onSubmit)();
            } else {
              setIsEditMode(true);
              if (fields.length === 0) {
                append({
                  title: '',
                  description: '',
                });
              }
            }
          }}
          isDisabled={Object.keys(errors).length > 0}
          text={!isEditMode ? 'Edit mode' : 'Save'}
          icon={<EditIcon />}
          sx={{ mt: !isEditMode ? '1rem' : 0 }}
        />
      </FlexBox>
    </>
  );
};

export default CompanyProblemAndMarket;
