import React, { FunctionComponent, useState } from 'react';
import AdminSideMenu, { AdminMenuKeys } from 'components/Admin/AdminSideMenu';
import ManageCustomCompanyProfiles from 'components/Admin/ManageCustomCompanyProfiles/ManageCustomCompanyProfiles';
import CompanyProfileOverview from 'components/Admin/ManageCustomCompanyProfiles/CompanyProfileOverview';
import { AssignCompanyProfilesProvider } from 'components/Admin/ManageCustomCompanyProfiles/AssignCompanyProfileContext';
import { ISideMenuSection } from 'utils/models';
import { DeveloperBoard, Settings } from '@mui/icons-material';
import { Box } from '@mui/system';
import { getEcosystemInfo } from 'utils/storage';
import CompanyLabelSettings from 'components/Admin/ManageCustomCompanyProfiles/components/CompanyLabelSettings';

interface OwnProps {}

const AdminCustomCompanyProfiles: FunctionComponent<OwnProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Company_Profile_Templates,
  );

  const { accessLevel } = getEcosystemInfo();

  function setMenuOption(menuKey: AdminMenuKeys) {
    setSelectedOption(menuKey);
  }

  const sections: ISideMenuSection[] = [
    {
      key: AdminMenuKeys.Company_Profile_Templates,
      text: 'Company Profile Templates',
      icon: <DeveloperBoard />,
      onClick: () => setMenuOption(AdminMenuKeys.Company_Profile_Templates),
      hasAccess: accessLevel.canManageCompanyTemplates,
    },
    {
      key: AdminMenuKeys.Company_Label_Settings,
      text: 'Label settings',
      icon: <Settings />,
      onClick: () => setMenuOption(AdminMenuKeys.Company_Label_Settings),
      hasAccess: accessLevel.canManageCompanyTemplates,
    },
  ];

  function renderSection() {
    if (selectedOption === AdminMenuKeys.Company_Profile_Templates) {
      return (
        <AssignCompanyProfilesProvider>
          <CompanyProfileOverview />
        </AssignCompanyProfilesProvider>
      );
    }
    if (selectedOption === AdminMenuKeys.Company_Label_Settings) {
      return (
        <CompanyLabelSettings
        />
      );
    }
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'start',
        minHeight: 'calc(100vh - 4.4rem)',
        marginTop: '-3rem',
      }}>
      <Box>
        <AdminSideMenu menuItems={sections} selectedOption={selectedOption} />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginLeft: '6rem' }}>
        <ManageCustomCompanyProfiles section={renderSection()} />
      </Box>
    </Box>
  );
};

export default AdminCustomCompanyProfiles;
