import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationAssessmentTemplate } from 'utils/models';
import { APPLICATION_ASSESSMENT_TEMPLATE, getData } from 'utils/requests';

interface IFetchTemplate {
  assessmentTemplateId: number | null;
  setIsLoading: (b: boolean) => void;
  updateFormWithData: (data: IApplicationAssessmentTemplate) => void;
}

const useFetchAssessmentTemplate = (props: IFetchTemplate) => {
  const { assessmentTemplateId, setIsLoading, updateFormWithData } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function getAssessmentTemplate() {
    if (!assessmentTemplateId) return;
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_TEMPLATE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'assessmentTemplateId',
          value: assessmentTemplateId,
        },
      ]);

      updateFormWithData(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return getAssessmentTemplate;
};

export default useFetchAssessmentTemplate;
