import { Box, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import CloseModalButton from 'components/common/Modal/CloseModalButton';

interface IAddHeader {
  isLoading: boolean;
  handleClose: () => void;
  isNew?: boolean;
}

const AddHeader: FunctionComponent<IAddHeader> = (props) => {
  const { isLoading, handleClose, isNew } = props;

  const theme = useTheme();

  return (
    <Box display='flex' justifyContent='space-between'>
      <Typography variant='h5' sx={{ marginBottom: '1rem', paddingTop: 1 }}>
        {isNew
          ? !isLoading
            ? 'Create new event'
            : 'Creating new event...'
          : !isLoading
          ? 'Update the event'
          : 'Updating the event...'}
      </Typography>
      <CloseModalButton
        handleClose={handleClose}
        color={theme.palette.primary.dark}
      />
    </Box>
  );
};

export default AddHeader;
