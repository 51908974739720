import { AdminMenuKeys } from '../components/Admin/AdminSideMenu';
import { IJourneyLink } from '../components/Admin/ManageJourneys/JourneyTemplate/JourneyTemplateLink';
import { IJourneyPerson } from '../components/Admin/ManageJourneys/JourneyTemplate/JourneyTemplatePerson';
import { BookingMenuKeys } from '../components/Booking/BookingSideMenu';
import { EventsMenuKeys } from '../components/Events/EventsSideMenu';
import { StageProgress } from '../components/Journey/JourneyProgressBar';

export interface IMaybeId {
  id?: number;
}

export interface IDashboard {
  ecosystems: IEcosystem[];
  changeLogs: IChangelogEntry[];
}

export interface IEcosystem {
  name: string;
  logoUrl: string;
  location: string;
}

export interface IEcosystemInfo {
  accessLevel: IAccessLevel;
  ecosystemColor: string;
  secondaryColor: string;
  highlightColor: string;
  companyNameList: string[];
  ecosystemNavBarLogo: string;
  displayAdminButton: boolean;
  calendarId: string;
  canSeeBooking: boolean;
  colorPalette: IColorScheme;
  companyAndJourneyList: ISavedCompanyInfo[];
}

interface IChangelogEntry {
  name: string;
  logoUrl: string;
  location: string;
}

export interface IProfile {
  ownProfile: boolean;
  profilePic: any;
  fullName: string;
  location: string;
  country: string;
  city: string;
  phoneNumber: string;
  linkedInLink: string;
  email: string;
  mentorLink: string;
  askMeAbout: string;
  iAm: string;
  iValue: string;
  achievements: string;
  accessLevelColor: string;
  accessLevelName: string;
  usedTags: ITag[];
  unusedTags: ITag[];
  isBookable: boolean;
}

export interface IAccount {
  city: string | null;
  country: string | null;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  fullName: string;
  middleName: string;
  isUserAdmin: boolean;
  phone: string;
  profilePicture: string;
  notificationSettings: INotificationSettings;
  userId: number;
  role: string;
  timezone: string;
  isCalendarConnected: boolean;
  connectedCalendar: string | null;
}

export interface INotificationSettings {
  id: number;
  user?: number;
  chat: string;
  adminUserAddedThroughCompanyInvite: boolean;
  ecosystemJoinRequestApproved: boolean;
  ecosystemJoinRequest: boolean;
  ecosystemNewAnnouncement: boolean;
  eventCreated: boolean;
  userNotAttendingEvent: boolean;
  meetingRequestReceived: boolean;
  meetingRequestAccepted: boolean;
  meetingRequestCancelled: boolean;
  unsubscribeFromAll: boolean;
}

export interface IHome {
  announcements: IAnnouncement[];
  events: IEvent[];
}

export interface IAnnouncement {
  id: number;
  creatorId: number;
  creatorName: string;
  title: string;
  body: string;
  timestamp: string;
  picUrl: string;
  pinned: boolean;
  role: string;
  accessLevelColor: string;
  imageUrl: string | null;
  accessLevelIds: number[];
  canUserEdit: boolean;
  type: 'announcement' | 'comment';
  announcementComments: IComment[];
}

export type IComment = Omit<
  IAnnouncement,
  'pinned' | 'title' | 'announcementComments' | 'imageUrl' | 'accessLevelIds'
>;

// export interface IComment {
//   id: number;
//   creatorId: number;
//   creatorName: string;
//   body: string;
//   timestamp: string;
//   picUrl: string;
//   pinned: boolean;
//   role: string;
//   accessLevelIds: number[];
// }

export interface IEvent extends IMaybeId {
  title: string;
  description: string;
  tagGroup?: ITagGroup;
  tags?: ITag[];
  location: string;
  latitude: number;
  longitude: number;
  startDate: string;
  endDate: string;
  startDateString: string;
  endDateString: string;
  contactPerson?: IPeople;
  accessLevelIds: number[];
  multiDay?: boolean;
  isEnd: boolean;
  startTime: string;
  endTime: string;
  time?: string;
  photo?: string;
  timestamp?: string;
  url: string;
  attendees?: IPeople[];
  recurring?: string;
  recurringEndDate: string;
  isDuplicate?: boolean;
  canUserEdit: boolean;
}

export interface IPeople extends ISharedProfilePeople {
  id: number;
  locationString: string;
  emailEcoPreferred: string;
  phone?: string;
  primaryCompanyLogo?: string;
  timezone?: string;
  companiesNameList?: string[];
}

export interface IMentor extends IPeople {
  tags: ITag[];
  location: string;
  tagString?: string;
}

export interface IProfile extends ISharedProfilePeople {
  location: string;
  phoneNumber: string;
  email: string;
  askMeAbout: string;
  iAm: string;
  iValue: string;
  achievements: string;
  companyNameAndLogoList: ICompanyAndLogo[];
  usedTags: ITag[];
  unusedTags: ITag[];
  accessLevelName: string;
  accessLevelColor: string;
  profilePic: any;
  fullName: string;
  subtitle: string;
  primaryCompany: string | null;
  canEditProfile: boolean;
}

export interface ISharedProfilePeople {
  accessLevelName: string;
  accessLevelColor: string;
  profilePic: string;
  fullName: string;
  subtitle: string;
  primaryCompany: string | null;
  usedTags?: ITag[];
}

export interface IEcoUserDto extends ISharedProfilePeople {
  id: number;
  emailEcoPrefered: string;
  locationString: string;
}

export interface ICompanyAndLogo {
  name: string;
  logo: string;
  id?: number;
}

export interface ISearchable {
  filterOut?: boolean;
}

export interface ICompany {
  id: number;
  bannerUrl: string;
  name: string;
  description: string;
  logoUrl: string;
  companyHeader: ICompanyField[];
  label: string;
  usedTags: ITag[];
  color: string | null;
}

export interface ICompanyJourney extends ISearchable {
  companyId: number;
  companyLogo: string;
  companyName: string;
  companyAssignedJourney: string | null;
}

export interface ICompanyProfile {
  name: string;
  description: string;

  email: string;
  telephone: string;

  website: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  x: string;

  tagGroup: ITagGroup;
  usedTags: ITag[];

  canEditCompanyProfile: boolean;
  canSeeCompanyWorkspace: boolean;
  canDeleteCompanyProfile: boolean;

  logoUrl: string;
  bannerUrl: string;

  header: ICompanyField[];
  main: ICompanyField[];
  sidebar: ICompanyField[];

  label: string;
  color: string;
  team: ICompanyPeople[];
}

export interface ICompanyTeamMember {
  id: number;
  fullName: string;
  role: string;
  profilePic: string;
  accessLevelColor: string;
  accessLevelName: string;
}
export interface ICompanySection {
  title: string;
  description: string;
}

export interface ICompanyPrivateProfileInfo {
  canUploadAdminDocs: boolean;
  companyGoals: ICompanyGoal[];
  companySharedNotes: ICompanySharedNote[];
  keyPerformanceIndicators: ICompanyKPI[];
  companySharedDocs: ICompanyDoc[];
  companyAdminDocs: ICompanyDoc[];
  resourceLinks: ICompanyDoc[];
  journeyId: number | null;
  companyWorkspaceSections: ICompanySection[] | null;
  workspaceSectionsName: string | null;
}

export interface ICompanyKPI extends IMaybeId {
  name: string;
  newKpi: boolean;
  showGoals: boolean;
  unit: string;
  keyPerformanceIndicatorEntry: ICompanyKPIEntry[];
}

export interface ICompanyKPIEntry extends IMaybeId {
  goal: number;
  localDate?: string;
  value: number;
  zonedDateTime: string | null;
}

export interface ICompanyDoc {
  id: number;
  docUrl: string;
  docName: string;
  docType: string;
}

export interface ICompanyGoal {
  id: number;
  goalTitle: string;
  deadline: string;
  achieved: boolean;
  achievedDate: string;
}

export interface ICompanySharedNote {
  id: number;
  noteTitle: string;
  noteContent: string;
  noteTag: string;
  dateCreated: string;
  simplifiedDate: string;
}

export interface ICompanyPeople {
  id: number;
  accessLevelName: string;
  accessLevelColor: string;
  companiesNameList: string;
  emailEcoPreferred: string;
  fullName: string;
  locationString: string;
  primaryCompany: string;
  primaryCompanyLogo: string;
  profilePic: string;
  subtitle: string;
  timezone: string;
  usedTags: ITag[];
  role: string;
}

export interface ICalendar {
  calendarID: string;
  announcementDtos: IAnnouncement;
}

export interface IAccessLevelDto {
  accessLevel: IAccessLevel;
  numberOfUsers: number;
  usersAndId: IUserAndId[];
  ecosystemName: string;
}

export interface IAccessLevel {
  id: number;
  accessLevelName: string;
  description: string;
  color: string;
  manageAnnouncement: boolean;
  manageEvents: boolean;
  createCompanyProfile: boolean;
  seePrivateCompanyProfile: string;
  deleteCompanyProfile: boolean;
  editOwnCompanyProfile: boolean;
  canCompaniesInviteUsers: boolean;
  canAddResources: boolean;
  canEditAccessLevels: boolean;
  canManageUsers: boolean;
  canEditMemberProfiles: boolean;
  canCustomizeEcosystemDisplay: boolean;
  canUploadAdminDocs: boolean;
  canManageJourneys: boolean;
  canManageCompanyTemplates: boolean;
  canScheduleMeetings: boolean;
  canSetAvailability: boolean;
  canBookRooms: boolean;
  canSetRoomAvailability: boolean;
  canReviewApplicants: boolean;
  canManageApplications: boolean;
  canCreateAnnouncements: boolean;
  canCreateEvents: boolean;
}

export enum AccessLevelOption {
  ONLY_THEIRS = 'Only Theirs',
  ALL = 'All',
  NO = 'No',
}

export interface IUserAndId {
  name: string;
  id: number;
}

export interface IResource {
  url: string;
  name: string;
  icon: string;
}
export interface IUserAndEmail {
  name: string;
  email: string;
}

export interface IEcoAdminData {
  companyNames: string[];
  ecoUserSearchData: IUserAndEmail[];
  joinRequests: IJoinRequest[];
  numberOfCompaniesInEcosystem: number;
  numberOfUsersInEcosystem: number;
}

export interface IJoinRequest {
  userId: number;
  userFullName: string;
  userEmail: string;
}

export interface ISearch {
  numberofEuds: number;
  numberofCompanies: number;
  searchString: string;
  defaultCompanyLogo: string;
  ecoUserData: ISearchUserData[];
  companies: ICompanyAndLogo[];
}

interface ISearchUserData {
  id: number;
  logo: string;
  accessLevelColor: string;
  accessLevelName: string;
}

export interface IJourney extends IMaybeId {
  id?: number;
  name: string;
  stages: IJourneyStage[];
}

export interface IJourneyStage extends IMaybeId {
  name: string;
  description?: string;
  locked?: boolean;
  themes: IJourneyTheme[];
}

export interface IJourneyTheme extends IMaybeId {
  name: string;
  milestones: IJourneyMilestone[];
  customMilestones?: IJourneyMilestone[];
}

export interface IExtendedJourneyMilestone extends IJourneyMilestone {
  [key: string]: any;
}

export interface IExtendedJourneyTheme extends IJourneyTheme {
  milestones: IExtendedJourneyMilestone[];
  customMilestones: IExtendedJourneyMilestone[];
}

export interface IExtendedJourneyStage extends IJourneyStage {
  themes: IExtendedJourneyTheme[];
}
export interface IJourneyMilestone extends IMaybeId {
  name: string;
  goals: IJourneyGoal[];
  customGoals?: IJourneyGoal[];
}

export interface IJourneyGoal extends IMaybeId {
  name: string;
  resources: IJourneyResources;
  progress?: StageProgress;
  deadline?: string;
  people?: IJourneyPerson[];
}

export interface IJourneyResources {
  files: string[];
  links: IJourneyLink[];
  persons: IJourneyPerson[];
}

export interface ICompanyProfileTemplate {
  id?: number;
  name: string | null;
  header: ICompanyProfileField[];
  main: ICompanyProfileField[];
  sidebar: ICompanyProfileField[];
  updateDate: string | null;
  color: string;
  tagGroup: ITagGroup | null;
}

export interface ICompanyProfileField {
  id?: number;
  name: string;
  type: CompanyProfileFieldType;
  required: boolean;
  tagOptions?: ITagGroup;
  icon?: string;
}

export interface IDropdownOptions {
  id?: number;
  name: string;
}

export enum CompanyProfileFieldType {
  TEXT = 'TEXT',
  RICH_TEXT = 'RICH_TEXT',
  TAGS = 'TAGS',
  DATE = 'DATE',
  FILES = 'FILES',
  PEOPLE = 'PEOPLE',
  IMAGES = 'IMAGES',
}

export enum CompanyTemplateSectionType {
  HEADER = 'header',
  MAIN = 'main',
  SIDEBAR = 'sidebar',
}

export interface ITagGroup {
  id?: number;
  name: string;
  tags: ITag[];
  canAddCustomTags: boolean;
  memberProfileTag: boolean;
}

export interface ITag {
  id?: number;
  name: string;
}

export interface ICompanyField {
  id: number;
  name: string;
  required: boolean;
  icon?: string;
  type: CompanyProfileFieldType;
  dropdownOptions?: IDropdownOptions[];
  selectedDropdownOptions?: IDropdownOptions[];
  tagGroup?: ITagGroup;
  usedTags?: ITag[];
  richText?: string;
  files?: IUploadFile[];
  people?: ICompanyPeople[];
  text?: string;
  localDate?: string;
  images?: IUploadFile[];
}

export interface IFile {
  id: number;
  name: string;
  url: string;
}
export interface IEcosystemStatus {
  ecosystemName: string;
  disabled: boolean;
}

export interface IBugReport {
  id: number;
  submittedBy: string;
  report: string;
  timestamp: string;
  imageUrl?: string;
}

export interface IRoom extends IMaybeId {
  title: string;
  description: string;
  picture: string;
  capacity?: number;
  location: string;
  availability: IAvailability;
  isActive: boolean;
  microsoftRoomAddress: string;
}

export interface IBookedRoom extends IMaybeId {
  title: string;
  description: string;
  picture: string;
  capacity: number;
  location: string;
  bookingUser: IMentor;
  bookingUserCompanyDto: ICompany;
  date: string;
  startTime: string;
  endTime: string;
}

export interface IAvailability {
  booking: IBooking;
  weeklyHours: IWeeklyHours;
}

export interface IBooking {
  choice: IAvailabilityRadio;
  amount: number;
  format: TimeSpanKeys;
  startDate: string;
  endDate: string;
  timeslotOption: number;
}

export interface IWeeklyHours {
  [key: string]: IDay;
  mon: IDay;
  tue: IDay;
  wed: IDay;
  thu: IDay;
  fri: IDay;
  sat: IDay;
  sun: IDay;
}

export interface IDay {
  active: boolean;
  times: ITimes[];
}

export interface ITimes {
  startTime: string | null;
  endTime: string | null;
}

export interface IAvailableTimeslots {
  date: string;
  dayOfWeek: string;
  timeSlots: ITimeslot[];
  daysAvailability?: ITimeslot;
}

export interface ITimeslot extends ITimes {
  booked: boolean;
}

export enum IAvailabilityRadio {
  DAYS = 'DAYS',
  DATE_RANGE = 'DATE_RANGE',
  INDEFINITELY = 'INDEFINITELY',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum TimeSpanKeys {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}

export interface IMeeting {
  bookingUser: IMentor;
  bookingUserCompanyDto: ICompany;
  bookedUser: IMentor;
  bookedUserCompanyDto: ICompany;
  date: string;
  endTime: string;
  id: number;
  info: string;
  pending: boolean;
  requestTime: string;
  startTime: string;
  topic: string;
  virtualMeetingLink: string;
  platform: string;
}

export interface IRequest extends IMaybeId {
  bookedUser: IMentor;
  bookedUserCompanyDto: ICompany;
  bookingUser: IMentor;
  bookingUserCompanyDto: ICompany;
  date: string;
  endTime: string;
  info: string;
  pending: boolean;
  platform: string;
  requestTime: string;
  startTime: string;
  topic: string;
}

export enum RecurringOption {
  THIS = 'THIS_EVENT',
  FUTURE = 'THIS_AND_FOLLOWING_EVENTS',
  ALL = 'ALL_EVENTS',
}

export interface IListButton {
  name: string;
  onClick: () => void;
  icon?: any;
  color?: string;
  textColor?: string;
  hoverColor?: string;
  isDisabled?: boolean;
}

export interface ISideMenuSection {
  key: IMenuKeys;
  text: string;
  icon?: JSX.Element;
  onClick: () => void;
  hasAccess: boolean;
  isSpecial?: boolean;
}

export type IMenuKeys = BookingMenuKeys | AdminMenuKeys | EventsMenuKeys;

export interface IModalSteps {
  number: number;
  name: string;
  component: React.ReactElement;
}

export interface ISavedCompanyInfo {
  companyId: number;
  companyName: string;
  journeyId: boolean;
  journeyName: string;
}

export enum ApplicationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
  PAUSED = 'PAUSED',
}
export interface IApplicationProccess extends IMaybeId {
  name: string;
  description: string;
  applicantsInProgress: number;
  startDate: Date;
  endDate: Date;
  url: string;
  processStatus: ApplicationStatus;
  active: boolean;
}

export interface IApplicationTemplate {
  id: number | null;
  name: string;
  description?: string;
  startDate: Date | null;
  endDate: Date | null;
  sections: IApplicationSection[];
  submissionMessage?: string;
}

export interface IApplicationSection {
  name: string;
  id?: number;
  questions: IApplicationQuestion[];
}

export interface IApplicationQuestion {
  question: string;
  type: ApplicationQuestionTypes;
  id?: number;
  description?: string;
  answer?: IApplicationAnswer;
  required?: boolean;
  tagGroup?: ITagGroup;
  dropdownOptions?: IDropdownOptions[];
  isHidden?: boolean;
}

export interface IApplicationAnswer {
  selectedOptions?: IDropdownOptions[];
  tags?: ITag[];
  textArea?: string;
  file?: IUploadFile;
  text?: string;
  date?: Date;
  radio?: IDropdownOptions;
  url?: string;
}

export interface IUploadFile {
  name: string;
  type: string;
  url: string;
  id?: number;
}

export enum ApplicationQuestionTypes {
  TEXT = 'TEXT',
  DATE = 'DATE',
  TAGS = 'TAGS',
  TEXT_AREA = 'TEXT_AREA',
  FILE = 'FILE',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  LINK = 'LINK',
}
export interface IApplicationForm {
  hashedId: string;
  applicantName: string;
  companyName: string;
  email: string;
  name: string | null;
  description: string | null;
  applicationTemplate: IApplicationTemplate;
  submissionMessage?: string;
}

export interface IApplicantProcessEditMode
  extends Omit<IApplicationForm, 'applicantName' | 'companyName' | 'email'> {
  applicantsInProgress: number;
  startDate: Date;
  endDate: Date;
  processStatus: ApplicationStatus;
}

export interface IApplicationOverview {
  name?: string;
  statuses: IApplicationStatus[];
}

export interface IApplicationStatus extends IMaybeId {
  name: string;
  color?: string;
  applicants: IApplicant[];
  custom?: boolean;
  id: number;
  assessmentTemplate?: IApplicationAssessmentTemplateBase;
  url?: string;
}

export interface IApplicantDetails {
  applicant: IApplicant;
  sections: IApplicationSection[];
  status: IApplicationStatus;
  statuses: IApplicationStatus[];
}

export interface IApplicant {
  id: number;
  applicantName: string;
  companyName: string;
  email: string;
  submitDate: Date;
  applicationStatus: any;
  averageRating: number;
  numberOfReviews: number;
}

export interface IApplicantReview {
  applicantId: number;
  rating: number;
  reviewer: IPeople;
  text: string;
  id: number;
}

export interface IColorScheme {
  name: string;
  dark: string;
  main: string;
  light: string;
  contrastText: string;
  isCustom: boolean; //Remove it from here or ICustomScheme after consulting with BE
}

export interface ICustomScheme extends IColorScheme {
  id: number;
  isCustom: boolean;
}

export interface IAccountInformation {
  message: string;
  token: string;
  refreshToken: string;
}

export interface ICompanyWithLabel {
  name: string;
  label: string;
  color: string | null;
  logoUrl: string;
  created: string | null;
}

export interface IApplicationAssessmentTemplateBase {
  id?: number;
  name: string;
}

export interface IApplicationAssessmentTemplate
  extends IApplicationAssessmentTemplateBase {
  configuration: IAssessmentConfiguration;
  sections: IApplicationAssessmentSection[];
}

export interface IAssessmentConfiguration {
  id?: number;
  numberOfOptions: number;
  isSameOptions: boolean;
  options: IApplicationAssessmentOption[];
  isWeighted: boolean;
}

export interface IApplicationAssessmentOption {
  id?: number;
  name?: string;
}

export interface IApplicationAssessmentSection {
  id?: number;
  name: string;
  subSections: IApplicationAssessmentSubSection[];
  weight?: number;
  rating?: number;
}

export interface IApplicationAssessmentSubSection {
  id?: number;
  name: string;
  questions: IAssessmentQuestion[];
  weight?: number;
  comment?: string;
  rating?: number;
}

export interface IAssessmentQuestion {
  id?: number;
  name: string;
  options: IApplicationAssessmentOption[];
  answer?: IApplicationAssessmentOption;
  weight?: number;
}

export interface IApplicationAssessmentTemplateCard {
  active: boolean;
  applicationProcessName: string;
  assessmentName: string;
  assessmentUrl: string;
  createdDate: Date;
  id: number;
  numberOfApplicantsWithAssessments: number;
  numberOfCompletedAssessments: number;
  statusName: string;
  updatedDate: Date;
}

export interface IApplicantAssessmentForm {
  applicants: IApplicantForAssessment[];
}

export interface IApplicantForAssessment {
  id: number;
  isSaved: boolean;
}

export interface IApplicantAssessment {
  applicantDetails: IApplicantDetails;
  assessmentTemplate: IApplicationAssessmentTemplate;
}

export interface IApplicationReports {
  id: number;
  name: string;
  reports: IApplicationReportOverview[];
}

export interface IApplicationReportOverview {
  id?: number;
  statusName: string;
  assessmentName: string;
  assessmentUrl: string;
  active: boolean;
  createdDate: Date;
  updatedDate: Date;
  numberOfApplicantsWithAssessments: number;
  numberOfCompletedAssessments: number;
}

export interface IApplicaitonReportApplicants {
  applicationName: string;
  statusName: string;
  assessmentName: string;
  applicants: IApplicantAssessmentReport;
}

export interface IAssessmentReportOverview {
  applicants: IApplicantReportOverview[];
  applicationName: string;
  applicationStatusName: string;
  assessmentName: string;
  statuses: IApplicationStatus[];
  applicationId: number;
}

export interface IApplicantReportOverview {
  applicantId: number;
  applicantName: string;
  overallRating: number;
  currentStatus: string;
  currentStatusId: number;
  applicantCompanyName: string;
}

export interface IApplicantAssessmentReport {
  applicantName: string;
  applicantId: number;
  assessors: IApplicantAssessor[];
}

export interface IApplicantAssessor {
  assessment: IApplicationAssessmentTemplate;
  assessorRating: number;
  name: string;
  email: string;
}

export interface ICompletedAssessment {
  applicantName: string;
  assessmentTemplate: IApplicationAssessmentTemplate;
  overallRating: number;
  assessors: IApplicantAssessor[];
  applicantCompanyName: string;
}

export interface IAssessmentApplicantListItem {
  id: number;
  name: string;
  status: string;
  number: number;
  isActive: boolean;
  isSubmitted: boolean;
  companyName: string;
}

export interface UnreadMessages {
  groups: { [key: string]: number };
  users: { [key: string]: number };
}
