import { Box } from '@mui/system';
import React, { FunctionComponent, useState } from 'react';
import { primary } from 'theme';
import {
  Button,
  ButtonBase,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { IEvent } from 'utils/models';
import { Flag } from '@mui/icons-material';
import { defaultEventPhoto, trackUmamiEvent } from 'utils/utils';
import { useEventsContext } from 'services/contexts/Events/EventsContext';

interface DayProps {
  events: IEvent[];
  day: any;
  monthIndex: number;
}

const Day: FunctionComponent<DayProps> = (props) => {
  const { events, day, monthIndex } = props;

  const theme = useTheme();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const { setChosenEvent, setIsShowEventDetails } = useEventsContext();

  const prevMonthName = dayjs()
    .month(monthIndex - 1)
    .format('MMMM');
  const nextMonthName = dayjs()
    .month(monthIndex + 1)
    .format('MMMM');

  if (day.$d.getMonth() !== ((monthIndex % 12) + 12) % 12) {
    return (
      <Box
        sx={{
          height: 120,
          border: 0.5,
          borderColor: primary.natural6,
          bgcolor: '#F5F5F5',
        }}>
        <Box display='flex' justifyContent='center'>
          <Typography
            color={primary.natural2}
            sx={{
              position: 'relative',
              top: '11px',
            }}>{`${day.$D > 15 ? prevMonthName : nextMonthName} ${
            day.$D
          }`}</Typography>
        </Box>
      </Box>
    );
  }

  function renderEvents() {
    const eventsGroupedByDate: { [key: string]: IEvent[] } = events.reduce(
      (accumulator: any, event) => {
        const startDateString = dayjs(event.startDateString).format('MM-DD');
        accumulator[startDateString] = accumulator[startDateString]
          ? [...accumulator[startDateString], event]
          : [event];
        return accumulator;
      },
      {},
    );

    const callShowEventDetails = (event: IEvent) => {
      setChosenEvent(event);
      setIsShowEventDetails(true);
      trackUmamiEvent('View Event Details');
    };

    return (
      <Box sx={{ height: '80%', width: '100%', overflow: 'hidden' }}>
        {Object.keys(eventsGroupedByDate).map((startDateString, dateIndex) => {
          const eventsOnDate = eventsGroupedByDate[startDateString];
          const remainingEventsCount = eventsOnDate.length - 1;

          function renderSingleEvent(index: number, full: boolean) {
            return (
              <ButtonBase
                onClick={() => callShowEventDetails(eventsOnDate[index])}
                sx={{
                  overflow: 'hidden',
                  maxHeight: full ? 100 : 46,
                  marginTop: 0.5,
                  borderRadius: 0.2,
                }}>
                {eventsOnDate[index].isEnd && (
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 9,
                      display: 'flex',
                      bgcolor: theme.palette.primary.main,
                      borderRadius: 0.3,
                      right: 1,
                      top: 1,
                    }}>
                    <Typography color='white' marginLeft={1} fontSize={12}>
                      End
                    </Typography>
                    <Flag
                      sx={{
                        color: 'white',
                        marginRight: 1,
                        fontSize: 15,
                        marginTop: 0.1,
                      }}
                    />
                  </Box>
                )}
                <img
                  src={eventsOnDate[index].photo}
                  alt='Event photo'
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: eventsOnDate[index].isEnd ? 15 : 10,
                    left: '5%',
                    width: '90%',
                    background: primary.pureWhite,
                    borderRadius: 0.2,
                  }}>
                  <Typography noWrap paddingLeft={0.5}>
                    {eventsOnDate[index].title}
                  </Typography>
                </Box>
              </ButtonBase>
            );
          }

          function renderMoreEvents() {
            if (eventsOnDate.length > 2) {
              return (
                <Box>
                  <Button
                    onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
                    sx={{
                      bgcolor: primary.natural6,
                      left: '3%',
                      borderRadius: 0.3,
                      width: '94%',
                      height: 40,
                      marginTop: 0.5,
                      '&:hover': { backgroundColor: primary.natural2 },
                    }}>
                    <Typography noWrap paddingLeft={0.5}>
                      +{remainingEventsCount}
                    </Typography>
                  </Button>
                  {renderPopover()}
                </Box>
              );
            } else {
              return renderSingleEvent(1, false);
            }
          }

          function renderPopover() {
            return (
              <Popover
                open={Boolean(popoverAnchorEl)}
                anchorEl={popoverAnchorEl}
                onClose={() => setPopoverAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Box sx={{ width: 160, padding: 1 }}>
                  {eventsOnDate.slice(1).map((event, index) => (
                    <ButtonBase
                      key={`Id-${event.id}-index-${index}`}
                      onClick={() => callShowEventDetails(event)}
                      sx={{
                        overflow: 'hidden',
                        maxHeight: 46,
                        marginTop: 0.5,
                        borderRadius: 0.2,
                      }}>
                      {event.isEnd && (
                        <Box
                          sx={{
                            position: 'absolute',
                            zIndex: 9999,
                            display: 'flex',
                            bgcolor: theme.palette.primary.main,
                            borderRadius: 0.3,
                            right: 1,
                            top: 1,
                          }}>
                          <Typography
                            color='white'
                            marginLeft={1}
                            fontSize={12}>
                            End
                          </Typography>
                          <Flag
                            sx={{
                              color: 'white',
                              marginRight: 1,
                              fontSize: 15,
                              marginTop: 0.1,
                            }}
                          />
                        </Box>
                      )}
                      <img
                        src={event.photo ?? defaultEventPhoto}
                        alt='Event photo'
                        style={{
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: event.isEnd ? 15 : 10,
                          left: '5%',
                          width: '90%',
                          background: primary.pureWhite,
                          borderRadius: 0.2,
                        }}>
                        <Typography noWrap paddingLeft={0.5}>
                          {event.title}
                        </Typography>
                      </Box>
                    </ButtonBase>
                  ))}
                </Box>
              </Popover>
            );
          }

          return (
            <Box key={dateIndex}>
              {eventsOnDate.length > 1 ? (
                <Box>
                  <Box
                    sx={{
                      left: '3%',
                      maxWidth: '94%',
                      position: 'relative',
                    }}>
                    {renderSingleEvent(0, false)}
                    {renderMoreEvents()}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    left: '3%',
                    maxWidth: '94%',
                    position: 'relative',
                  }}>
                  {renderSingleEvent(0, true)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: 120,
        border: 0.5,
        borderColor: primary.natural6,
        bgcolor: 'white',
      }}>
      <Box display='flex' justifyContent='center' height={10}>
        <Typography
          color={theme.palette.primary.main}
          fontWeight='bold'
          sx={{
            position: 'relative',
            top: '-11px',
            bgcolor: theme.palette.primary.light,
            paddingBottom: 2.7,
            paddingX: 0.4,
            borderRadius: 0.3,
          }}>
          {day.$D}
        </Typography>
      </Box>
      {renderEvents()}
    </Box>
  );
};

export default Day;
