import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputField from '../common/InputField';
import MainButton, { ButtonType } from '../common/MainButton';
import CheckIcon from '@mui/icons-material/Check';
import { acterioTheme } from 'theme';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { IAccount, IAccountInformation } from 'utils/models';
import {
  ACCOUNT_SAVE_INFO,
  ACCOUNT_UPLOAD_PIC_ROUTE,
  patchData,
  postData,
} from 'utils/requests';
import { setUser, updateAccount } from 'utils/storage';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import ImageCropper from '../common/ImageCropper';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '1.5rem',

    '& h6': {
      color: `${acterioTheme.dark} !important`,
    },
  },
  editBtn: {
    position: 'absolute',
    top: 15,
    right: 7.5,

    '& button': {
      height: '35px !important',
      width: '5rem !important',
      minWidth: '5rem !important',
      fontSize: '1rem !important',
      fontWeight: 600,
      margin: '0 !important',
      borderRadius: '4px !important',
      color: `${acterioTheme.dark} !important`,
    },
  },
  uploadAvatar: {
    marginBottom: '0.3rem',
    position: 'relative',
    height: '6.25rem',
    width: '6.25rem',
  },
  uploadIcon: {
    height: '2.625rem',
    width: '2.625rem',
    minWidth: '2.188rem',
    position: 'absolute',
    bottom: '-0.75rem',
    right: '-0.938rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: `${acterioTheme.light} !important`,

    '& svg': {
      height: 23,
      width: 23,
      color: `${acterioTheme.dark} !important`,
    },
  },
}));

interface IAboutSettings {
  account: IAccount;
}

type Timezone = {
  id: string;
  label: string;
};

const AboutSettings: FunctionComponent<IAboutSettings> = (props) => {
  const { account } = props;
  const { profilePicture, fullName, email, country, city, phone, timezone } =
    account;
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>(fullName);
  const [editedCountry, setEditedCountry] = useState<string>(country || '');
  const [editedCity, setEditedCity] = useState<string>(city || '');
  const [editedEmail, setEditedEmail] = useState<string>(email);
  const [editedPhone, setEditedPhone] = useState<string>(phone || '');
  const [selectedTimezone, setSelectedTimezone] = useState<string>(timezone);
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [profilePictureInUse, setProfilePictureInUse] =
    useState<string>(profilePicture);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const tzList = moment.tz.names().map((tz: string) => {
      const offset = moment.tz(tz).format('Z');
      return {
        id: tz,
        label: `${tz} (UTC${offset})`,
      };
    });
    setTimezones(tzList);
  }, []);

  async function updateInfo() {
    setLoading(true);
    try {
      //todo: update this when BE is ready to accept a DTO instead
      const response: IAccountInformation = await patchData(ACCOUNT_SAVE_INFO, [
        { name: 'fullName', value: editedName || '' },
        { name: 'city', value: editedCity || '' },
        { name: 'country', value: editedCountry || '' },
        { name: 'timezone', value: selectedTimezone || '' },
        { name: 'email', value: editedEmail || '' },
        { name: 'phone', value: editedPhone || '' },
      ]);

      updateAccount({
        fullName: editedName,
        city: editedCity,
        country: editedCountry,
        timezone: selectedTimezone,
        phone,
        editedPhone,
        email: editedEmail,
      });

      if (response.token) {
        setUser(response.token, response.refreshToken);
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Account details updated',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  const updateProfilePic = async function (croppedAvatar: Blob) {
    const formData = new FormData();
    formData.append('profile_image', croppedAvatar, 'profilePic.png');

    try {
      const accProfilePicData: string = await postData(
        ACCOUNT_UPLOAD_PIC_ROUTE,
        undefined,
        formData,
      );
      updateAccount({
        profilePicture: accProfilePicData,
      });
      setProfilePictureInUse(accProfilePicData);
      setShowImageCropper(false);
      //forces navbar to rerender
      navigate('/account');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.editBtn}>
        <MainButton
          loading={loading}
          onClick={() => updateInfo()}
          text={'Save'}
          type={ButtonType.BLANK}
          endIcon={loading ? undefined : <CheckIcon />}
        />
      </div>
      <div className={classes.uploadAvatar} style={{ cursor: 'pointer' }}>
        <img
          src={profilePictureInUse}
          style={{
            width: '6.25rem',
            height: '6.25rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => setShowImageCropper(true)}
        />
        <ImageCropper
          showImageCropper={showImageCropper}
          setShowImageCropper={setShowImageCropper}
          uploadFunction={updateProfilePic}
          aspectRatioHeight={1}
          aspectRatioWidth={1}
          roundCropper
        />
        <div
          className={classes.uploadIcon}
          onClick={() => setShowImageCropper(true)}>
          <PhotoCameraIcon />
        </div>
      </div>
      <InputField
        value={editedName}
        placeholder={'John Smith'}
        readonly={loading}
        acterioDefault
        label={'Full name'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEditedName(event.target.value)
        }
      />
      <InputField
        value={editedCountry}
        placeholder={'Norway'}
        readonly={loading}
        acterioDefault
        label={'Country'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEditedCountry(event.target.value)
        }
      />
      <InputField
        value={editedCity}
        placeholder={'Oslo'}
        readonly={loading}
        acterioDefault
        label={'City'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEditedCity(event.target.value)
        }
      />
      <InputField
        value={editedEmail}
        placeholder={'john@billionleads.com'}
        readonly={loading}
        acterioDefault
        label={'Email address'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEditedEmail(event.target.value)
        }
      />
      <InputField
        value={editedPhone}
        placeholder={'+4713456789'}
        readonly={loading}
        acterioDefault
        label={'Phone'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEditedPhone(event.target.value)
        }
      />
      <Typography variant={'h6'}>Timezone</Typography>
      <Autocomplete
        value={timezones.find((tz) => tz.id === selectedTimezone) || null} // Ensure value is never undefined
        onChange={(event, newValue) => {
          setSelectedTimezone(newValue ? newValue.id : '');
        }}
        options={timezones}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select Timezone'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        disabled={loading}
        sx={{
          width: '100%',
          marginBottom: '1rem',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: `${acterioTheme.dark} !important`,
          },
        }}
      />
    </div>
  );
};

export default AboutSettings;
