import React, { FunctionComponent } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from '@react-pdf/renderer';
import { IApplicantDetails } from 'utils/models';
import { primary } from 'theme';

Font.register({
  family: 'Inter',
  src: '/fonts/Inter-Regular.ttf',
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: primary.pureWhite,
    paddingHorizontal: 15,
    paddingVertical: 30,
    fontFamily: 'Inter',
  },

  coverContainer: {
    marginHorizontal: 25,
  },

  cover: {
    fontSize: 32,
    color: primary.natural1,
    marginTop: 130,
    marginBottom: 20,
  },

  description: {
    fontSize: 12,
    marginBottom: 12,
    color: primary.natural2,
  },

  square: {
    border: `1pt solid ${primary.natural5}`,
    borderRadius: 5,
    width: 60,
    height: 60,
    alignItems: 'center',
    marginTop: 15,
  },

  number: {
    fontSize: 18,
    color: primary.natural1,
    fontWeight: 'bold',
    marginTop: 10,
  },

  coverFooter: {
    flexDirection: 'column',
    borderBottom: `1pt solid ${primary.natural5}`,
    paddingBottom: 10,
    paddingHorizontal: 10,
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
  },

  coverFooterText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  contentsHeader: {
    fontSize: 26,
    color: primary.natural1,
    marginLeft: 15,
    // marginBottom: 15,
    paddingVertical: 25,
  },

  contentsTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  contentsText: {
    fontSize: 12,
    marginHorizontal: 15,
    color: primary.natural2,
    marginBottom: 3,
  },

  contentsFooter: {
    position: 'absolute',
    bottom: 30,
    right: 0,
    textAlign: 'right',
    alignItems: 'flex-end',
    fontSize: 12,
    marginTop: 16,
    marginRight: 25,
  },

  header: {
    fontSize: 26,
    color: primary.natural1,
    marginLeft: 15,
  },

  section: {
    fontSize: 14,
    textTransform: 'uppercase',
    marginBottom: 15,
  },

  text: {
    fontSize: 12,
    marginLeft: 2,
    color: primary.natural2,
  },

  answer: {
    fontSize: 12,
    color: primary.natural1,
    marginVertical: 5,
    marginLeft: 18,
    marginTop: 14,
  },

  divider: {
    border: `1px solid ${primary.natural5}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    margin: 12,
  },

  pageFooter: {
    textAlign: 'right',
    alignItems: 'flex-end',
    fontSize: 12,
    marginTop: 16,
  },

  p: {
    lineHeight: 1.5,
    marginBottom: 10,
    marginTop: 0,
    textAlign: 'justify',
    fontFamily: 'Inter',
  },
  br: {
    marginVertical: 10,
  },
  strong: {
    fontWeight: 'bold',
  },
  em: {
    fontStyle: 'italic',
  },
  u: {
    textDecoration: 'underline',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
  },
  h1: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 6,
    marginTop: 8,
  },
  h2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 6,
  },
  h3: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 4,
  },
  h4: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
  h5: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
  h6: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 2,
    marginTop: 2,
  },
});

interface IAppListPdf {
  applicationsList: IApplicantDetails[];
  isEmailPDFMode?: boolean;
}

const ApplicationsListPdf: FunctionComponent<IAppListPdf> = (props) => {
  const { applicationsList, isEmailPDFMode } = props;

  // We need to figure out how to start from a new page if it is a new company

  const handleRichText = (html: string) => {
    const output: any = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    doc.body.childNodes.forEach((node) => {
      switch (node.nodeName.toLowerCase()) {
        case 'p':
          output.push(<Text style={styles.p}>{node.textContent}</Text>);
          break;
        case 'br':
          output.push(<Text style={styles.br} />);
          break;
        case 'strong':
          output.push(<Text style={styles.strong}>{node.textContent}</Text>);
          break;
        case 'em':
          output.push(<Text style={styles.em}>{node.textContent}</Text>);
          break;
        case 'u':
          output.push(<Text style={styles.u}>{node.textContent}</Text>);
          break;
        case 'h1':
          output.push(<Text style={styles.h1}>{node.textContent}</Text>);
          break;
        case 'h2':
          output.push(<Text style={styles.h2}>{node.textContent}</Text>);
          break;
        case 'h3':
          output.push(<Text style={styles.h3}>{node.textContent}</Text>);
          break;
        case 'h4':
          output.push(<Text style={styles.h4}>{node.textContent}</Text>);
          break;
        case 'h5':
          output.push(<Text style={styles.h5}>{node.textContent}</Text>);
          break;
        case 'h6':
          output.push(<Text style={styles.h6}>{node.textContent}</Text>);
          break;
        case 'a':
          output.push(<Text style={styles.a}>{node.textContent}</Text>);
          break;

        default:
          output.push(<Text>{node.textContent}</Text>);
      }
    });

    return output;
  };

  function renderCover() {
    //Not sure if we have appropriate info coming from BE
    return (
      <View style={styles.coverContainer}>
        <Text style={styles.cover}>COVER NAME, Cohort summer 2024</Text>

        <Text style={styles.text}>
          DESCRIPTION Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Accusamus assumenda voluptatem doloribus est necessitatibus suscipit
          aperiam, officiis praesentium qui reprehenderit magnam eius, dolorum
          corrupti veniam tempore maiores totam soluta ab.
        </Text>
        <View style={styles.square}>
          <Text style={styles.number}>1</Text>
          <Text style={styles.h4}>applicant</Text>
        </View>
      </View>
    );
  }

  function renderContents() {
    // I don't know yet how to handle generation of table of contents; please revise, and implement
    return (
      <View>
        <Text style={styles.contentsHeader}>Table of contents</Text>
        <View style={styles.contentsTextContainer}>
          <Text style={styles.contentsText}>1. Bluetiger</Text>
          <Text style={styles.contentsText}>03 - 07 p.</Text>
        </View>
        <hr style={styles.divider} />
      </View>
    );
  }

  function renderListOfApplicationDetails() {
    return applicationsList.map((application: IApplicantDetails) => {
      return (
        <>
          {renderHeader(application)}
          {renderSingleApplicantToCreateStructure(application)}
        </>
      );
    });
  }

  function renderHeader(applicantDetails: IApplicantDetails) {
    const application = applicantDetails;
    return (
      <View>
        <Text style={styles.header}>{application.applicant.applicantName}</Text>
      </View>
    );
  }

  function renderSingleApplicantToCreateStructure(
    applicantDetails: IApplicantDetails,
  ) {
    const application = applicantDetails;
    return (
      <View style={styles.page} key={application.applicant.id}>
        <Text style={styles.section}>Contact Information</Text>
        <Text style={styles.text}>1. What is your name?</Text>
        <Text style={styles.answer}>{application.applicant.applicantName}</Text>
        <hr style={styles.divider} />
        <Text style={styles.text}>2. What is your company name?*</Text>
        <Text style={styles.answer}>{application.applicant.companyName}</Text>
        <hr style={styles.divider} />
        <Text style={styles.text}>3. What is your email address?*</Text>
        <Text style={styles.answer}>{application.applicant.email}</Text>
        <hr style={styles.divider} />

        {application.sections.map((section, sectionIndex) => (
          <View key={sectionIndex}>
            <Text style={styles.section}>{section.name}</Text>
            {section.questions.map((question, questionIndex) => (
              <View key={questionIndex}>
                <Text style={styles.text}>{`${questionIndex + 1}. ${
                  question.question
                }`}</Text>
                <Text style={styles.answer}>
                  {question.answer?.text ||
                    question.answer?.textArea ||
                    question.answer?.date?.toString() ||
                    question.answer?.url ||
                    (question.answer?.selectedOptions &&
                      question.answer?.selectedOptions
                        .map((option) => option.name)
                        .join(', ')) ||
                    (question.answer?.radio && question.answer?.radio.name) ||
                    (question.answer?.tags &&
                      question.answer?.tags
                        .map((tag) => `• ${tag.name}`)
                        .join(' ')) ||
                    (question.answer?.file && (
                      <Link
                        style={styles.answer}
                        href={question.answer.file.url}>
                        {question.answer.file.name}
                      </Link>
                    ))}
                </Text>
                <hr style={styles.divider} />
              </View>
            ))}
          </View>
        ))}
      </View>
    );
  }

  function renderFooter() {
    return (
      <>
        <hr style={styles.divider} />
        <Text
          style={styles.pageFooter}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} page of ${totalPages}`
          }
          fixed
        />
      </>
    );
  }

  function renderEmails() {
    return (
      <View>
        <Text style={styles.section}>Emails List</Text>
        {applicationsList.map((application: IApplicantDetails) => (
          <View key={application.applicant.id}>
            <Text style={styles.text}>{application.applicant.email},</Text>
          </View>
        ))}
      </View>
    );
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          {isEmailPDFMode ? renderEmails() : renderListOfApplicationDetails()}
          {renderFooter()}
        </View>
      </Page>
    </Document>
  );
};
export default ApplicationsListPdf;
