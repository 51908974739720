import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '56.25rem',
    maxWidth: '56.25rem',
    margin: '0 auto',
    marginTop: '6rem',

    '& h5': {
      fontSize: '1.6rem !important',
      marginBottom: '2rem !important',
    },
  },
  cont: {},
}));

interface IManageJourneys {
  section: JSX.Element | null;
}

const ManageJourneys: FunctionComponent<IManageJourneys> = (props) => {
  const { section } = props;
  const classes = useStyles();

  return <div className={classes.root}>{section}</div>;
};

export default ManageJourneys;
