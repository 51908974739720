import { AddCircleOutlineOutlined, WarningAmber } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';

interface IAddButton {
  isAllRequiredFilled: any;
  isLoading: any;
  saveNewEvent: any;
}

const AddNewEventButton: FunctionComponent<IAddButton> = (props) => {
  const { isAllRequiredFilled, isLoading, saveNewEvent } = props;

  const theme = useTheme();

  return (
    <Box>
      {!isAllRequiredFilled && (
        <Box display='flex' sx={{ marginTop: '2rem' }}>
          <WarningAmber sx={{ marginRight: 1 }} />
          <Typography>Not all required filleds are filled!</Typography>
        </Box>
      )}
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{ marginTop: isAllRequiredFilled ? 5 : 1 }}>
        <Button
          onClick={saveNewEvent}
          disabled={isLoading || !isAllRequiredFilled}
          sx={{
            bgcolor: !isAllRequiredFilled
              ? primary.natural2
              : theme.palette.primary.dark,
            marginTop: 2,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            '&:hover': {
              backgroundColor: !isAllRequiredFilled
                ? primary.natural2
                : theme.palette.primary.main,
            },
          }}>
          <AddCircleOutlineOutlined sx={{ color: primary.pureWhite }} />
          <Typography sx={{ color: primary.pureWhite, paddingLeft: 1 }}>
            Create event
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default AddNewEventButton;
