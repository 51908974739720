import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid #e0e0e0',
    height: '3.75rem',
    width: '100%',
    backgroundColor: '#F5F5F5',
    marginLeft: '1.3rem',
    position: 'relative',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.35rem',
    cursor: 'pointer',
  },
  index: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '18px',
    position: 'absolute',
    left: '-16px',
    top: '12px',
    color: '#bdbdbd',
   border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    borderRadius: '50%',
  },
}));

interface IJourneyTemplateAddStage {
  nextIndex: number;
  addNewStage: () => void;
}

const JourneyTemplateAddStage: FunctionComponent<IJourneyTemplateAddStage> = (
  props,
) => {
  const { nextIndex, addNewStage } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => addNewStage()}>
      <div className={classes.index}>{nextIndex}</div>
      <Add fontSize={'small'} />
      <Typography variant={'h6'} fontSize={16}>
        Add Stage
      </Typography>
    </div>
  );
};

export default JourneyTemplateAddStage;
