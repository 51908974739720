import { createContext } from 'react';

export enum ALERT_TYPES {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface IAlert {
  type: ALERT_TYPES;
  message: string;
  title?: string;
  duration?: number;
  action?: () => void;
}

interface IAlertContext {
  alerts: IAlert[];
  addAlert: (alert: IAlert) => void;
}

export const AlertContext = createContext<IAlertContext>({
  alerts: [],
  addAlert: (_: IAlert) => null,
});
