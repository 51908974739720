import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Accordion, ButtonBase, Theme, Typography } from '@mui/material';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Add,
  DensityMedium,
  ExpandCircleDownOutlined,
} from '@mui/icons-material';
import JourneyTemplateGoal from './JourneyTemplateGoal';
import InputField from 'components/common/InputField';
import { IJourneyMilestone } from 'utils/models';
import { Draggable } from 'react-beautiful-dnd';
import { primary } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  milestoneBody: {
    borderRadius: '6px',
    backgroundColor: 'white',
    minHeight: '3.125rem',
    minWidth: '100%',
    border: '1px solid #E0E0E0',
  },
  milestoneHeader: {
    padding: '0.65rem 0.5rem',
    paddingRight: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& button': {
      padding: '3px !important',
      borderRadius: '50% !important',
    },

    '& svg': {
      height: '1.875rem',
      width: '1.875rem',
    },
  },
  milestone: {
    display: 'flex',
    alignItems: 'start',
    gap: '0.5rem',
  },
  deleteMilestone: {
    borderRadius: '3px!important',
    marginTop: '0.6rem !important',
  },
  milestoneGoalHeader: {
    padding: '0.5rem 2.2rem',
    paddingBottom: '0.35rem',
    backgroundColor: '#E0F2F1',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',

    '& h6': {
      fontSize: '14px !important',
    },
  },
  addGoal: {
    padding: '0.5rem 2rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    cursor: 'pointer',
    gap: '0.25rem',

    '& svg': {
      height: '14px',
      width: '14px',
      color: theme.palette.primary.dark,
    },

    '& h6': {
      fontSize: '14px !important',
      marginTop: '3px !important',
    },
  },
  accordion: {
    borderRadius: '6px !important',
    backgroundColor: 'white !important',
  },
  cont: {
    display: 'flex',
    gap: '1rem',
  },
  icon: {
    paddingRight: '0.35rem',
    borderRight: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      color: primary.natural2,
      height: '28px',
      width: '28px',
      opacity: '0.75',
    },
  },
}));

interface IJourneyTemplateMilestone {
  index: number;
  themeIndex: number;
  milestone: IJourneyMilestone;
}

const JourneyTemplateMilestone: FunctionComponent<IJourneyTemplateMilestone> = (
  props,
) => {
  const { index, themeIndex, milestone } = props;
  const { name, goals } = milestone;
  const { deleteStageMilestone, addStageGoal, updateStageMilestoneName } =
    useJourneyTemplateContext();
  const classes = useStyles();

  const [hide, setHide] = useState<boolean>(false);

  function renderGoals() {
    return goals.map((goal, i) => (
      <JourneyTemplateGoal
        key={`goal-${themeIndex}-milestone-${index}-${i}`}
        index={i}
        themeIndex={themeIndex}
        milestoneIndex={index}
        goal={goal}
      />
    ));
  }

  return (
    <Draggable draggableId={`milestone-${themeIndex}-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div className={classes.milestone}>
            <div className={classes.milestoneBody}>
              <Accordion
                expanded={!hide}
                defaultExpanded={false}
                className={classes.accordion}>
                <div className={classes.milestoneHeader}>
                  <div className={classes.cont}>
                    <div className={classes.icon}>
                      <DensityMedium />
                    </div>
                    <div style={{ minWidth: '320px' }}>
                      <InputField
                        value={name}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          updateStageMilestoneName(
                            event.target.value,
                            themeIndex,
                            index,
                          )
                        }
                        placeholder={'Goal name...'}
                      />
                    </div>
                  </div>
                  <ButtonBase onClick={() => setHide(!hide)}>
                    <ExpandCircleDownOutlined
                      style={{
                        transition: '0.2s ease-in-out all',
                        transform: hide ? 'rotate(180deg)' : 'none',
                      }}
                    />
                  </ButtonBase>
                </div>
                <div className={classes.milestoneGoalHeader}>
                  <Typography variant={'h6'}>Activities</Typography>
                </div>
                <div>{renderGoals()}</div>
                <div
                  className={classes.addGoal}
                  onClick={() => addStageGoal(themeIndex, index)}>
                  <Add />
                  <Typography variant={'h6'}>Add new activity</Typography>
                </div>
              </Accordion>
            </div>

            <ButtonBase
              className={classes.deleteMilestone}
              onClick={() => deleteStageMilestone(themeIndex, index)}>
              <DeleteIcon fontSize={'large'} />
            </ButtonBase>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default JourneyTemplateMilestone;
