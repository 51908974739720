import InputField from 'components/common/InputField';
import React, { FunctionComponent, useEffect } from 'react';
import { Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { IApplicationAssessmentTemplate } from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { Typography } from '@mui/material';
import { primary } from 'theme';

interface IAssessmentOption {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  subSectionIndex: number;
  questionIndex: number;
  resetField: any;
}

const AssessmentOption: FunctionComponent<IAssessmentOption> = (props) => {
  const {
    sectionIndex,
    subSectionIndex,
    questionIndex,
    control,
    errors,
    resetField,
  } = props;

  const { fields: optionsFields } = useFieldArray({
    name: `sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}.options`,
    control,
  });

  const staticErrorPath =
    errors?.sections?.[sectionIndex]?.subSections?.[subSectionIndex]
      ?.questions?.[questionIndex]?.options?.[0]?.name;

  useEffect(() => {
    return () => {
      resetField(
        `sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}.options`,
      );
    };
  }, []);

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.5rem',
        marginTop: '0.5rem',
        ml: '2.25rem',
        width: '100%',
      }}>
      {optionsFields.map((option, optionIndex: number) => {
        const errorPath =
          errors?.sections?.[sectionIndex]?.subSections?.[subSectionIndex]
            ?.questions?.[questionIndex]?.options?.[optionIndex]?.name;

        return (
          <FlexBox key={option.id} sx={{ width: '90%' }}>
            <Typography
              sx={{
                color: primary.natural4,
                mr: '0.725rem',
                minWidth: '1.25rem',
                maxWidth: '1.25rem',
              }}>
              {optionIndex + 1}
            </Typography>
            <Controller
              name={`sections.${sectionIndex}.subSections.${subSectionIndex}.questions.${questionIndex}.options.${optionIndex}.name`}
              control={control}
              rules={{
                required: 'Please, enter an option',
              }}
              render={({ field }) => (
                <InputField
                  {...field}
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    field.onChange(e);
                  }}
                  helperText={errorPath && errorPath.message}
                  helperIcon={errors.sections && 'warning'}
                  error={!!errorPath}
                  placeholder={'Enter an option'}
                  multiline
                  height={'2.5rem'}
                  width={'100%'}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '5px 0',
                      alignItems: 'center',
                    },
                  }}
                />
              )}
            />
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default AssessmentOption;
