import { ALERT_TYPES, AlertContext } from '../../components/Alert/AlertContext';
import { useContext } from 'react';
import { IAnnouncement } from '../../utils/models';
import {
  deleteData,
  HOME_DELETE_ANNOUNCEMENT_ROUTE,
  HOME_NEW_ANNOUNCEMENT_ROUTE,
  HOME_PIN_ANNOUNCEMENT_ROUTE,
  patchData,
  patchFormData,
} from '../../utils/requests';
import { updateHome } from '../../utils/storage';
import { useParams } from 'react-router';

const useAnnouncementActions = (
  announcements: IAnnouncement[],
  setAnnouncements: (announcements: IAnnouncement[]) => void,
) => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const pinAnnouncement = async (id: number, pinned: boolean) => {
    try {
      await patchData(HOME_PIN_ANNOUNCEMENT_ROUTE, [
        { name: 'id', value: id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      const sortedAnnouncements = announcements
        .map((announcement) =>
          announcement.id === id
            ? { ...announcement, pinned: !pinned }
            : announcement,
        )
        .sort((a, b) => {
          const ts =
            new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime();
          return a.pinned === b.pinned ? 1 : !a.pinned ? 1 : ts ? 1 : -1;
        });

      setAnnouncements(sortedAnnouncements);
      updateHome({ announcements: sortedAnnouncements });

      addAlert({ type: ALERT_TYPES.SUCCESS, message: 'Pinned announcement' });
    } catch (e: any) {
      console.error('error', e);
      addAlert({ type: ALERT_TYPES.ERROR, message: e.message });
    }
  };

  const deleteAnnouncement = async (id: number) => {
    try {
      await deleteData(HOME_DELETE_ANNOUNCEMENT_ROUTE, [
        { name: 'id', value: id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      const updatedAnnouncements = announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setAnnouncements(updatedAnnouncements);
      updateHome({ announcements: updatedAnnouncements });

      addAlert({ type: ALERT_TYPES.SUCCESS, message: 'Announcement deleted' });
    } catch (e: any) {
      console.error('error', e);
      addAlert({ type: ALERT_TYPES.ERROR, message: e.message });
    }
  };

  const editAnnouncement = async (
    id: number,
    editedTitle: string,
    editedBody: string,
    accessLevels: number[],
    editedImage: File | null,
    closeModal: () => void,
  ) => {
    try {
      const formData = new FormData();

      const announcementRequest = JSON.stringify({
        id,
        title: editedTitle,
        body: editedBody,
        ecosystemName: ecosystemName,
        accessLevelIds: accessLevels,
      });

      formData.append(
        'announcementRequest',
        new Blob([announcementRequest], { type: 'application/json' }),
      );

      if (editedImage) {
        formData.append('image', editedImage);
      }

      const data: IAnnouncement = await patchFormData(
        HOME_NEW_ANNOUNCEMENT_ROUTE,
        formData,
      );

      const updatedAnnouncements = announcements.map((announcement) => {
        if (announcement.id === id) {
          return {
            ...announcement,
            title: editedTitle,
            body: editedBody,
            imageUrl: data.imageUrl,
            accessLevelIds: accessLevels,
          };
        }

        return announcement;
      });
      setAnnouncements(updatedAnnouncements);
      updateHome({ announcements: updatedAnnouncements });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Announcement was edited',
      });

      closeModal();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  };

  return {
    pinAnnouncement,
    deleteAnnouncement,
    editAnnouncement,
  };
};

export default useAnnouncementActions;
