import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import JourneyPreview from './JourneyPreview';
import useJourneyTemplates from 'hooks/Journey/useJourneyTemplates';
import Loading from 'components/common/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    minHeight: '21.875rem',
    padding: (props: any) => (props.selectable ? '0' : '2rem'),
    paddingTop: (props: any) => (props.selectable ? '2rem' : '2rem'),
    gap: (props: any) => (props.selectable ? '0' : '1.5rem'),
  },
}));

interface IJourneysPreviews {
  selectable?: boolean;
}

const JourneysPreviews: FunctionComponent<IJourneysPreviews> = (props) => {
  const { selectable } = props;
  const classes = useStyles({ selectable });
  const { journeys, loading } = useJourneyTemplates();
  const noJourneys = journeys.length === 0;

  function renderJourneyPreviews() {
    if (loading) return <Loading height={0} size={40} />;

    if (noJourneys) {
      return (
        <Typography variant={'h6'} fontSize={28} color={'#e0e0e0'}>
          No templates yet
        </Typography>
      );
    }

    return journeys.map((journey) => (
      <Fade in={true} timeout={600} key={journey.id}>
        <div>
          <JourneyPreview journey={journey} selectable={selectable} />
        </div>
      </Fade>
    ));
  }

  return (
    <div
      className={classes.root}
      style={{
        justifyContent: noJourneys ? 'center' : 'start',
      }}>
      {renderJourneyPreviews()}
    </div>
  );
};

export default JourneysPreviews;
