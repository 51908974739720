import React, { FunctionComponent, useState } from 'react';
import { Autocomplete, Box, SxProps, TextField, useTheme } from '@mui/material';
import { ITag } from 'utils/models';
import { naturalPallete } from 'theme';
import { FlexBox } from 'utils/styledComponents';
import { Clear } from '@mui/icons-material';

interface ITagsField {
  tags: ITag[];
  tagOptions: ITag[];
  selectedTags: ITag[];
  updateTags: (tag: string) => void;
  updateCompanyField?: (id: string, tags: ITag[]) => void;
  updateCompanyTagGroup?: (tags: ITag[]) => void;
  id: string;
  sx?: SxProps;
}

const TagsField: FunctionComponent<ITagsField> = ({
  tags,
  tagOptions,
  selectedTags,
  updateTags,
  updateCompanyField,
  updateCompanyTagGroup,
  id,
  sx,
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (
    event: React.ChangeEvent<any>,
    value: string,
    reason: string,
  ) => {
    if (reason === 'input') {
      setInputValue(value);
    }
  };

  const handleValueChange = (
    event: React.ChangeEvent<any>,
    value: string | null,
  ) => {
    setInputValue(''); // Clear the input field
    if (value) {
      const selectedTag = tagOptions.find((tag) => tag.name === value);
      if (
        selectedTag &&
        !selectedTags.some((tag) => tag.id === selectedTag.id)
      ) {
        updateCompanyTagGroup &&
          updateCompanyTagGroup([...selectedTags, selectedTag]);

        updateCompanyField &&
          updateCompanyField(id, [...selectedTags, selectedTag]);
      }
    }
  };

  function renderTags() {
    return (
      <FlexBox
        sx={{
          flexWrap: 'wrap',
        }}>
        {tags.map((tag, i) => (
          <Box
            key={`${tag}-${i}`}
            onClick={() => updateTags(tag.name)}
            sx={{
              fontSize: '0.7rem',
              margin: '0.25rem',
              fontWeight: 600,
              textAlign: 'center',
              cursor: 'pointer',
              lineHeight: '0.7rem',
              padding: '0.4rem',
              backgroundColor: theme.palette.primary.light,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '12px',
              color: naturalPallete.natural1,
            }}>
            {tag.name} <Clear sx={{ fontSize: '0.7rem', ml: '0.3rem' }} />
          </Box>
        ))}
      </FlexBox>
    );
  }

  function renderSearchTags() {
    return (
      <Autocomplete
        options={tagOptions
          .filter(
            (tag) =>
              !selectedTags.some((selectedTag) => selectedTag.id === tag.id),
          )
          .map((tag) => tag.name)}
        value={null} // Always set to null to ensure the input field can be cleared
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleValueChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Search tags...'
            variant='standard'
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                height: '2rem',
                paddingLeft: '0.5rem',
              },
            }}
            sx={{
              width: '11rem',
            }}
          />
        )}
      />
    );
  }

  return (
    <FlexBox
      sx={{
        minHeight: '2rem',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '7px',
        border: `1px solid ${naturalPallete.natural6}`,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        ...sx,
      }}>
      <Box>{renderTags()}</Box>
      <Box>{renderSearchTags()}</Box>
    </FlexBox>
  );
};

export default TagsField;
