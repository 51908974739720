import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { AdminMenuKeys } from 'components/Admin/AdminSideMenu';

const useApplicationMenu = () => {
  const [selectedOption, setSelectedOption] = useState<AdminMenuKeys>(
    AdminMenuKeys.Applications,
  );

  const [isComingFromAssessmentPage, setIsComingFromAssessmentPage] =
    useState<boolean>(false);

  return {
    selectedOption,
    isComingFromAssessmentPage,
    setSelectedOption,
    setIsComingFromAssessmentPage,
  };
};

const ApplicationMenuContext = createContext(
  {} as ReturnType<typeof useApplicationMenu>,
);

export const ApplicationMenuProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const applicationMenuContext = useApplicationMenu();

  return (
    <ApplicationMenuContext.Provider value={applicationMenuContext}>
      {children}
    </ApplicationMenuContext.Provider>
  );
};

export const useApplicationMenuContext = () =>
  useContext(ApplicationMenuContext);
