import { useContext, useEffect, useState } from 'react';
import { COMPANY_TEMPLATE_SPECIFIC, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { ICompanyProfileTemplate } from 'utils/models';

export const NEW_COMPANY_TEMPLATE_KEY = 'new';

const useFetchCompanyTemplate = () => {
  const { ecosystemName, companyTemplateId } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [companyTemplate, setCompanyTemplate] =
    useState<ICompanyProfileTemplate | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (companyTemplateId === NEW_COMPANY_TEMPLATE_KEY) {
      return addNewCompanyTemplate();
    }
    fetchCompanyTemplate();
  }, [companyTemplateId]);

  function addNewCompanyTemplate() {
    setCompanyTemplate({
      name: null,
      header: [],
      main: [],
      sidebar: [],
      updateDate: null,
      color: '#EBF1F9',
      tagGroup: null,
    });

    setLoading(false);
  }

  async function fetchCompanyTemplate() {
    setLoading(true);

    try {
      const data = await getData(COMPANY_TEMPLATE_SPECIFIC, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyTemplateId', value: companyTemplateId },
      ]);

      setCompanyTemplate(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  return {
    loading,
    companyTemplate,
  };
};

export default useFetchCompanyTemplate;
