import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { primary } from 'theme';
import { Add, CalendarMonth, ContentCopy } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarPreview from '../BookingCalendar/CalendarPreview';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { MEETING_AVAILABILITY_ROUTE, getData, putData } from 'utils/requests';
import { IAvailability, IAvailabilityRadio, TimeSpanKeys } from 'utils/models';
import Loading from 'components/common/Loading';
import AvailabilityTimePicker from './AvailabilityTimePicker';
import {
  createNewObject,
  doesAvailabilityNeedsFixing,
  fixAvailabilityTimeslots,
  formatDateYMD,
} from 'utils/utils';
import TimezoneOffsetDisplay from 'components/common/TimezoneOffsetDisplay';

export const defaultAvailability: IAvailability = {
  booking: {
    choice: IAvailabilityRadio.NOT_AVAILABLE,
    amount: 15,
    format: TimeSpanKeys.Days,
    startDate: '2024-03-01',
    endDate: '2025-12-30',
    timeslotOption: 60,
  },
  weeklyHours: {
    mon: {
      active: true,
      times: [
        {
          startTime: '09:00',
          endTime: '17:00',
        },
      ],
    },
    tue: {
      active: true,
      times: [
        {
          startTime: '09:00',
          endTime: '17:00',
        },
      ],
    },
    wed: {
      active: true,
      times: [
        {
          startTime: '09:00',
          endTime: '17:00',
        },
      ],
    },
    thu: {
      active: true,
      times: [
        {
          startTime: '09:00',
          endTime: '17:00',
        },
      ],
    },
    fri: {
      active: true,
      times: [
        {
          startTime: '09:00',
          endTime: '17:00',
        },
      ],
    },
    sat: {
      active: false,
      times: [
        {
          startTime: '10:00',
          endTime: '12:00',
        },
      ],
    },
    sun: {
      active: false,
      times: [
        {
          startTime: '10:00',
          endTime: '12:00',
        },
      ],
    },
  },
};

const Availability = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const timeslotOptions = [15, 30, 45, 60, 75, 90];

  const bookingOptions = {
    Days: [5, 10, 15, 20, 25, 30],
    Weeks: [1, 2, 3, 4, 5, 6, 7, 8],
    Months: [1, 2, 3, 6, 9, 12],
  };

  const [availability, setAvailability] =
    useState<IAvailability>(defaultAvailability);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [showStartDate, setShowStartDate] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showEndDate, setShowEndDate] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [copyOfAvailability, setCopyOfAvailability] = useState<IAvailability>(
    createNewObject(defaultAvailability),
  );

  const startDatePickerAnchorElRef = useRef<HTMLDivElement>(null);
  const endDatePickerAnchorElRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const updatedAvailability = { ...availability };
  //   updatedAvailability.booking.amount =
  //     bookingOptions[availability.booking.format][2];
  //   setAvailability(updatedAvailability);
  // }, [availability.booking.format]);

  useEffect(() => {
    fetchAvailability();
  }, []);

  useEffect(() => {
    setStartDate(new Date(availability.booking.startDate));
    setEndDate(new Date(availability.booking.endDate));

    if (!doesAvailabilityNeedsFixing(availability)) return;
    fixAvailabilityTimeslots(availability);
  }, [availability]);

  async function fetchAvailability() {
    if (!ecosystemName) {
      return null;
    }
    setIsLoading(true);

    try {
      const data = await getData(MEETING_AVAILABILITY_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      if (data) {
        setAvailability(data);
        setCopyOfAvailability(createNewObject(data));
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function putAvailability() {
    setIsLoading(true);

    try {
      const data = await putData(
        MEETING_AVAILABILITY_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        availability,
      );

      setAvailability(data);
      setCopyOfAvailability(createNewObject(data));

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Changes saved',
      });
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function handleCheckboxChange(day: string) {
    const updatedAvailability = {
      ...availability,
      weeklyHours: {
        ...availability.weeklyHours,
        [day.toLowerCase()]: {
          ...availability.weeklyHours[day.toLowerCase()],
          active: !availability.weeklyHours[day.toLowerCase()].active,
        },
      },
    };
    setAvailability(updatedAvailability);
  }

  function handleRadioButtonChange(event: any) {
    if (!availability) return;
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.choice = event.target
      .value as IAvailabilityRadio;
    setAvailability(updatedAvailability);
  }

  function handleNumberOfDays(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.amount = event.target.value;

    setAvailability(updatedAvailability);
  }

  function handleTimeslotOptionChange(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.timeslotOption = event.target.value;

    setAvailability(updatedAvailability);
  }

  function handleTimeSpan(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.format = event.target.value;
    setAvailability(updatedAvailability);
  }

  function updateStartDateChange(newDate: Date | null) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.startDate = formatDateYMD(newDate);
    setAvailability(updatedAvailability);
    setStartDate(newDate);
    setShowStartDate(false);
  }

  function updateEndDateChange(newDate: Date | null) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.endDate = formatDateYMD(newDate);
    setAvailability(updatedAvailability);
    setEndDate(newDate);
    setShowEndDate(false);
  }

  function formatDate(dateString: string | Date): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  function revertChanges() {
    setAvailability(JSON.parse(JSON.stringify(copyOfAvailability)));
  }

  // function renderBookingChoices() {
  //   return (
  //     <>
  //       <Box marginTop='1rem'>
  //         <FormControl component='fieldset'>
  //           <RadioGroup
  //             aria-label={'label'}
  //             name={'label'}
  //             value={availability.booking.choice}
  //             onChange={handleRadioButtonChange}>
  //             <FormControlLabel
  //               value={'DAYS'}
  //               control={<Radio />}
  //               label={
  //                 <>
  //                   <Typography fontWeight='bold' gutterBottom>
  //                     Days
  //                   </Typography>
  //                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //                     <Select
  //                       sx={{ bgcolor: '#F5F5F5', borderRadius: '6px' }}
  //                       value={availability.booking.amount}
  //                       onChange={(e) => handleNumberOfDays(e)}>
  //                       {bookingOptions[availability.booking.format].map(
  //                         (option: any) => (
  //                           <MenuItem value={option} key={option}>
  //                             {option}
  //                           </MenuItem>
  //                         ),
  //                       )}
  //                     </Select>
  //                     <Select
  //                       sx={{
  //                         bgcolor: '#F5F5F5',
  //                         borderRadius: '6px',
  //                         marginX: 1.5,
  //                       }}
  //                       value={availability.booking.format}
  //                       onChange={(e) => handleTimeSpan(e)}>
  //                       {Object.keys(bookingOptions).map((option) => (
  //                         <MenuItem value={option} key={option}>
  //                           {option}
  //                         </MenuItem>
  //                       ))}
  //                     </Select>
  //                     <Typography>into the future</Typography>
  //                   </Box>
  //                 </>
  //               }
  //               sx={{ marginBottom: 4 }}
  //             />
  //             <FormControlLabel
  //               value={'DATE_RANGE'}
  //               control={<Radio />}
  //               label={
  //                 <Box>
  //                   <Typography fontWeight='bold' gutterBottom>
  //                     Within date range
  //                   </Typography>
  //                   <Box
  //                     sx={{
  //                       display: 'flex',
  //                       marginBottom: 2,
  //                       alignItems: 'center',
  //                       marginTo: 1,
  //                     }}>
  //                     <Box
  //                       ref={startDatePickerAnchorElRef}
  //                       onClick={() => setShowStartDate(!showStartDate)}
  //                       sx={{
  //                         display: 'flex',
  //                         marginRight: 2,
  //                         bgcolor: '#F5F5F5',
  //                         padding: 1.5,
  //                       }}>
  //                       <Typography marginRight={1}>
  //                         {startDate ? formatDate(startDate) : 'Start date'}
  //                       </Typography>
  //                       <CalendarMonth />
  //                     </Box>
  //                     <Typography>until</Typography>
  //                     <Box
  //                       ref={endDatePickerAnchorElRef}
  //                       onClick={() => setShowEndDate(!showEndDate)}
  //                       sx={{
  //                         display: 'flex',
  //                         marginX: 2,
  //                         bgcolor: '#F5F5F5',
  //                         padding: 1.5,
  //                       }}>
  //                       <Typography marginRight={1}>
  //                         {endDate ? formatDate(endDate) : 'End date'}
  //                       </Typography>
  //                       <CalendarMonth />
  //                     </Box>
  //                   </Box>
  //                 </Box>
  //               }
  //               sx={{ marginBottom: 2 }}
  //             />
  //             <FormControlLabel
  //               value={'INDEFINITELY'}
  //               control={<Radio />}
  //               label={
  //                 <Typography fontWeight='bold'>
  //                   Indefinitely into the future
  //                 </Typography>
  //               }
  //               sx={{ marginBottom: 2 }}
  //             />
  //             <FormControlLabel
  //               value={'NOT_AVAILABLE'}
  //               control={<Radio />}
  //               label={<Typography fontWeight='bold'>Not available</Typography>}
  //               sx={{ marginBottom: 2 }}
  //             />
  //           </RadioGroup>
  //         </FormControl>
  //       </Box>
  //       {showStartDate && (
  //         <InputDatePicker
  //           onChange={updateStartDateChange}
  //           anchorEl={startDatePickerAnchorElRef.current}
  //         />
  //       )}
  //       {showEndDate && (
  //         <InputDatePicker
  //           onChange={updateEndDateChange}
  //           anchorEl={endDatePickerAnchorElRef.current}
  //         />
  //       )}
  //     </>
  //   );
  // }

  function renderInactiveOrIndefintitely() {
    if (!availability) return null;
    return (
      <Box marginTop='1rem'>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label={'label'}
            name={'label'}
            value={availability.booking.choice}
            onChange={handleRadioButtonChange}>
            <FormControlLabel
              value={'INDEFINITELY'}
              control={<Radio />}
              label={<Typography fontWeight='bold'>Available</Typography>}
              sx={{ marginBottom: '0.5rem' }}
            />
            <FormControlLabel
              value={'NOT_AVAILABLE'}
              control={<Radio />}
              label={<Typography fontWeight='bold'>Not available</Typography>}
              sx={{ marginBottom: '0.5rem' }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }

  function renderBooking() {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Box>
            <Typography variant='h5' gutterBottom fontSize={'1rem'}>
              When can people book?
            </Typography>
            {/* <Typography fontSize={14}>
              How far into the future are you available for booking?
            </Typography> */}
            {renderInactiveOrIndefintitely()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}>
            <Button
              variant='contained'
              sx={{
                borderRadius: '6px',
                marginRight: '0.5rem',
                minHeight: '32px',
                height: '2rem',
                marginBottom: { xs: '1rem', md: 0 },
                bgcolor: 'inherit',
                '&:hover': { bgcolor: theme.palette.primary.light },
              }}
              onClick={() => revertChanges()}>
              <Typography color={theme.palette.primary.dark}>
                Revert Changes
              </Typography>
            </Button>
            <Button
              variant='contained'
              sx={{
                borderRadius: '6px',
                minHeight: '32px',
                height: '2rem',
                bgcolor: theme.palette.primary.dark,
                '&:hover': { bgcolor: theme.palette.primary.main },
              }}
              onClick={() => putAvailability()}>
              <Typography color={primary.pureWhite}>Save Changes</Typography>
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  function renderWeeklyHours() {
    const daysOfTheWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant='h5' gutterBottom fontSize={'1rem'}>
              Set your weekly hours
            </Typography>
            <Typography fontSize={'0.875rem'}>
              Create timeslots for when you are available
            </Typography>
            <Typography variant='body2' fontSize={'0.75rem'}>
              Time slots displayed as:
              <TimezoneOffsetDisplay
                sx={{ display: 'inline' }}
                displayLocation
              />
            </Typography>
          </Box>
        </Box>
        <Box marginTop='1rem'>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
            onClick={() => setShowPreview(true)}>
            <Button
              sx={{
                '&:hover': { bgcolor: theme.palette.primary.contrastText },
                paddingY: '0.5rem',
                borderRadius: '6px',
                paddingLeft: 0,
              }}>
              <CalendarMonth
                sx={{
                  marginRight: '1rem',
                  fontSize: '2rem',
                  color: theme.palette.primary.dark,
                }}
              />
              <Typography variant='h5' fontSize={'1rem'}>
                Preview in calendar view
              </Typography>
            </Button>
          </Box>
          <Box>
            {daysOfTheWeek.map((option, index) => {
              const day = option.toLowerCase();
              const [popoverAnchorEl, setPopoverAnchorEl] =
                useState<HTMLElement | null>(null);

              function renderPopover(day: string, index: number) {
                const fullDaysOfTheWeek = [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ];

                const [selectedDays, setSelectedDays] = useState<string[]>([]);

                function handleCopyToOtherDays() {
                  const timeSlotsToCopy = availability.weeklyHours[day].times;
                  const updatedAvailability = { ...availability };
                  selectedDays.forEach((selectedDay) => {
                    updatedAvailability.weeklyHours[selectedDay].times =
                      timeSlotsToCopy;
                  });
                  setAvailability(updatedAvailability);
                }

                return (
                  <Popover
                    open={Boolean(popoverAnchorEl)}
                    anchorEl={popoverAnchorEl}
                    onClose={() => setPopoverAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '1rem',
                        alignItems: 'center',
                      }}>
                      <Typography
                        fontWeight='bold'
                        gutterBottom
                        sx={{
                          color: theme.palette.primary.dark,
                          textTransform: 'uppercase',
                          width: '9.375rem',
                        }}>
                        Copy times To
                      </Typography>
                      {fullDaysOfTheWeek.map((fullDay, fullDayIndex) => {
                        const [isChecked, setIsChecked] =
                          useState<boolean>(false);

                        function handleCheckboxChange() {
                          if (isChecked) {
                            setIsChecked(false);
                            setSelectedDays((prev) =>
                              prev.filter(
                                (day) =>
                                  day !== fullDay.substring(0, 3).toLowerCase(),
                              ),
                            );
                          } else {
                            setIsChecked(true);
                            setSelectedDays((prev) => [
                              ...prev,
                              fullDay.substring(0, 3).toLowerCase(),
                            ]);
                          }
                        }

                        return (
                          <Box
                            key={fullDay}
                            sx={{
                              display: 'flex',
                              width: '8.75rem',
                              justifyContent: 'space-between',
                            }}>
                            <Typography
                              sx={{
                                width: '1.25rem',
                                color: !(index === fullDayIndex)
                                  ? 'black'
                                  : 'grey',
                              }}>
                              {fullDay}
                            </Typography>
                            <Checkbox
                              sx={{ height: '1.875rem', marginRight: '1rem' }}
                              disabled={index === fullDayIndex}
                              checked={isChecked}
                              onClick={handleCheckboxChange}
                            />
                          </Box>
                        );
                      })}
                      <Button onClick={() => handleCopyToOtherDays()}>
                        <Typography fontWeight='bold'>Apply</Typography>
                      </Button>
                    </Box>
                  </Popover>
                );
              }

              function addTimeSlot() {
                const updatedAvailability = {
                  ...availability,
                  weeklyHours: {
                    ...availability.weeklyHours,
                    [day]: {
                      ...availability.weeklyHours[day],
                      times: [
                        ...availability.weeklyHours[day].times,
                        {
                          startTime: null,
                          endTime: null,
                        },
                      ],
                    },
                  },
                };
                setAvailability(updatedAvailability as IAvailability);
              }

              return (
                <Box
                  key={`${day} + ${index}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'top',
                    marginY: 1.5,
                    marginLeft: { xs: '-2rem', sm: 0 },
                  }}>
                  <Checkbox
                    sx={{ height: 30, marginTop: 2 }}
                    checked={availability?.weeklyHours?.[day]?.active ?? false}
                    onChange={(e) => handleCheckboxChange(day)}
                  />
                  <Typography
                    marginX={'1.5rem'}
                    width={'3.125rem'}
                    minWidth={'3.125rem'}
                    marginTop={2.5}>
                    {day.toUpperCase()}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {availability.weeklyHours[day].times.map((times, i) => (
                        <AvailabilityTimePicker
                          day={day}
                          key={i}
                          index={i}
                          availability={availability}
                          setAvailability={setAvailability}
                          startTime={times.startTime}
                          endTime={times.endTime}
                        />
                      ))}
                    </Box>
                  </LocalizationProvider>
                  <Button
                    sx={{
                      height: '2.375rem',
                      marginTop: 1.5,
                      width: '4rem',
                      minWidth: 'unset',
                    }}
                    onClick={() => addTimeSlot()}>
                    <Add sx={{ color: theme.palette.primary.dark }} />
                  </Button>
                  <Button
                    sx={{
                      height: '2.375rem',
                      marginTop: 1.5,
                      width: '4rem',
                      minWidth: 'unset',
                    }}
                    onClick={(event) =>
                      setPopoverAnchorEl(event.currentTarget)
                    }>
                    <ContentCopy sx={{ color: theme.palette.primary.dark }} />
                  </Button>
                  {renderPopover(day, index)}
                </Box>
              );
            })}
          </Box>
        </Box>
      </>
    );
  }

  function renderAvailability() {
    return (
      <div>
        {!isLoading ? renderBooking() : <Loading />}
        <Divider
          sx={{
            width: 'calc(100% + 4rem)',
            marginLeft: '-2rem',
            marginY: '2rem',
            marginTop: isLoading ? '100vh' : '2rem',
          }}
        />
        {!isLoading && renderTimeslotOption()}
        <Divider
          sx={{
            width: 'calc(100% + 4rem)',
            marginLeft: '-2rem',
            marginY: '2rem',
            marginTop: isLoading ? '100vh' : '2rem',
          }}
        />
        {renderWeeklyHours()}
      </div>
    );
  }

  function renderTimeslotOption() {
    if (!availability) return null;
    return (
      <Box sx={{ marginTop: '1rem' }}>
        <Typography fontWeight='bold' gutterBottom>
          How long should your sessions to be?
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Select
            sx={{ bgcolor: '#F5F5F5', borderRadius: '6px' }}
            value={availability.booking.timeslotOption}
            onChange={(e) => handleTimeslotOptionChange(e)}>
            {timeslotOptions.map((option: any) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ marginLeft: 1 }}>Minutes</Typography>
        </Box>
      </Box>
    );
  }

  function mainRender() {
    return (
      <Box sx={{ width: '100%', maxWidth: '56.25rem' }}>
        <Box justifyContent='left' marginTop='2rem' marginBottom='1rem'>
          <Typography variant='h5' gutterBottom fontSize={22}>
            Availability
          </Typography>
          <Typography>
            Manage the time in which you are available for booking
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            bgcolor: '#FFFFFF',
            border: 1,
            borderColor: '#D4D4D4',
            borderRadius: '6px',
            padding: '2rem',
            marginBottom: 20,
          }}>
          {renderAvailability()}
        </Box>
        {showPreview && (
          <CalendarPreview
            isOpen={showPreview}
            handleClose={() => {
              setShowPreview(false);
            }}
            availability={availability}
          />
        )}
      </Box>
    );
  }

  return mainRender();
};

export default Availability;
