import {
  CometChatCreateGroup,
  CreateGroupStyle,
} from '@cometchat/chat-uikit-react';
import React, { FunctionComponent } from 'react';
import { createComponent } from '@lit/react';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { naturalPallete } from '../../theme';
import { useTheme } from '@mui/material';

interface CreateGroupDemoProps {
  setActiveTab: React.Dispatch<
    React.SetStateAction<
      'groups' | 'createGroup' | 'conversations' | 'users' | 'addUsers'
    >
  >;
}

const CreateGroup: FunctionComponent<CreateGroupDemoProps> = (props) => {
  const { setActiveTab } = props;
  const theme = useTheme();

  const CreateGroup = createComponent({
    tagName: 'cometchat-create-group',
    elementClass: CometChatCreateGroup,
    react: React,
  });

  function handleClick(): void {
    setActiveTab('groups');
  }

  function customCreateClick(group: CometChat.Group) {
    CometChat.createGroup(group)
      .then((group) => {
        handleClick();
      })
      .catch((error) => {
        console.error('Error in process of creating a group:', error);
      });
  }

  const createGroupStyle = new CreateGroupStyle({
    background: naturalPallete.pureWhite,
    activeGroupTypeBackground: theme.palette.primary.light,
    activeGroupTypeTextColor: '#000000',
    createGroupButtonBackground: theme.palette.primary.main,
    closeIconTint: theme.palette.primary.main,
    height: '30rem',
    width: '20rem',
  });

  return (
    <CreateGroup
      createGroupStyle={createGroupStyle}
      createClick={customCreateClick}
      closeCallback={handleClick}
    />
  );
};

export default CreateGroup;
