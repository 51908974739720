import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useJourneyContext } from './JourneyContext';
import JourneyTheme from './JourneyTheme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginTop: '2.5rem',
  },
}));

interface IJourneyThemes {}

const JourneyThemes: FunctionComponent<IJourneyThemes> = (props) => {
  const { stage } = useJourneyContext();
  const classes = useStyles();

  function renderThemes() {
    if (!stage) return null;

    if (stage.locked) return(
      <div>This stage has been locked by your admin</div>
    );

    const { themes } = stage;
    return themes.map((theme, i) => (
      <JourneyTheme key={`theme-${theme.id}`} theme={theme} themeIndex={i} />
    ));
  }

  return <div className={classes.root}>{renderThemes()}</div>;
};

export default JourneyThemes;
