import { SxProps } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ToggleViewButton from 'components/Events/ToggleViewButton';

interface IHeaderButton {
  text: string;
  onClick: () => void;
  isSelected: boolean;
  icon: JSX.Element;
  sx?: SxProps;
}

const HeaderButton: FunctionComponent<IHeaderButton> = (props) => {
  const { onClick, text, isSelected, icon, sx } = props;

  return (
    <ToggleViewButton
      onClick={onClick}
      isActive={isSelected}
      icon={icon}
      text={text}
      sx={sx}
    />
  );
};

export default HeaderButton;
