import React, {
  FunctionComponent,
  useContext,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import { IBaseModal } from '../common/Modal';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import Fade from '@mui/material/Fade';
import { boxShadow } from 'theme';
import InputField from '../common/InputField';
import MainButton, { ButtonType } from '../common/MainButton';
import { DIGITAL_ASSISTANT_ROUTE, getData } from 'utils/requests';
import { useParams } from 'react-router';
import { Send, SmartToy } from '@mui/icons-material';
import Avatar from '../common/Avatar';
import { getAccount } from 'utils/storage';
import { useMobileContext } from 'services/contexts/MobileContext';

const useStyles = makeStyles((theme: Theme) => ({
  boxPosition: {
    position: 'fixed',
    top: '5rem',
    right: (props: any) => (props.isMobile ? '12vw' : '20px'),
    width: (props: any) => (props.isMobile ? '80vw' : '813px'),
    height: 'auto',
    boxShadow,
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    padding: '0.35rem',
    borderRadius: '4px',
    maxHeight: '34.375rem',
    resize: 'vertical',
  },
  questionBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
  responseUser: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    flexDirection: 'row-reverse',
    backgroundColor: '#9e9e9e69',
    borderRadius: '10px',
    marginLeft: 'auto',
    padding: '8px',
  },
  responseAdvisor: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    backgroundColor: 'lightblue',
    borderRadius: '10px',
    padding: '8px',
    marginRight: 'auto',
  },
  allResponsesCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginLeft: '15px',
    padding: '3px',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px',
    overflow: 'auto',
    maxHeight: '18.125rem',
    paddingRight: '10px',
  },
}));

interface IResponse {
  question: string;
  answer: string;
}

const DigitalAdvisor: FunctionComponent<IBaseModal> = (props) => {
  const { open, close } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const [question, setQuestion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<IResponse[]>([]);
  const { profilePicture } = getAccount();
  const theme = useTheme();

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo({ top: 10000, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [loading]);

  async function requestDigitalAdvisor() {
    setLoading(true);
    try {
      const data: string = await getData(DIGITAL_ASSISTANT_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'question',
          value: question,
        },
      ]);
      const newResponse: IResponse = { question: question, answer: data };
      responses.push(newResponse);
      setResponses(responses);
      setQuestion('');
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function renderResponses() {
    return responses?.map((response, i) => (
      <div className={classes.allResponsesCont} key={'response-' + i}>
        <div className={classes.responseUser}>
          <Avatar source={profilePicture} height={45} width={45} radius={10} />
          <Typography>{response.question}</Typography>
        </div>
        <div className={classes.responseAdvisor}>
          <SmartToy
            style={{
              color: theme.palette.primary.main,
              height: '45px',
              width: '45px',
              borderRadius: '10px',
            }}
          />
          <Typography>{response.answer}</Typography>
        </div>
      </div>
    ));
  }

  return (
    <Fade in={open} timeout={1000}>
      <div className={classes.boxPosition}>
        <div className={classes.flexBox} ref={messagesEndRef}>
          {renderResponses()}
        </div>
        <div className={classes.questionBox}>
          <InputField
            value={question}
            placeholder={'Write question here'}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setQuestion(event.target.value)
            }
            multiline={true}
            rows={6}
            readonly={loading}
          />
          <MainButton
            onClick={() => requestDigitalAdvisor()}
            text={'Ask Question'}
            type={ButtonType.FILLED}
            endIcon={<Send />}
            loading={loading}
            small={false}
          />
        </div>
      </div>
    </Fade>
  );
};

export default DigitalAdvisor;
