import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme } from '@mui/material';
import JourneyTemplateMenu from './JourneyTemplateMenu';
import { JourneyTemplateProvider } from './JourneyTemplateContext';
import JourneyTemplateHeader from './JourneyTemplateHeader';
import JourneyTemplateThemes from './JourneyTemplateThemes';
import JourneyTemplateSave from './JourneyTemplateSave';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '56.25rem',
    maxWidth: '56.25rem',
    margin: '0 auto',
    marginTop: '4rem',
    position: 'relative',
  },
  save: {
    position: 'fixed',
    bottom: '20px',
    right: '4.375rem',
    width: '8.75rem',

    '& button': {
      fontWeight: 600,
      fontSize: '16px !important',
      height: '50px !important',

      '& svg': {
        height: '28px',
        width: '28px',
      },
    },
  },
}));

interface IJourneyTemplate {}

const JourneyTemplate: FunctionComponent<IJourneyTemplate> = (props) => {
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!hasChanged) return;

    function handleOnBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      return (event.returnValue = '');
    }

    function handleClick(event: MouseEvent) {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        //This is not stable enough - make sure that resources still work!
        event.preventDefault();
        return window.confirm(
          'You have unsaved changes! Do you want to leave?',
        );
      }
    }

    window.addEventListener('beforeunload', handleOnBeforeUnload, {
      capture: true,
    });
    // document.addEventListener('click', handleClick);
    // document.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, {
        capture: true,
      });
      // document.removeEventListener('click', handleClick);
      // document.removeEventListener('mousedown', handleClick);
    };
  }, [hasChanged]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [hasChanged]);

  const formData = new FormData();

  function handleOnChange(event: FormEvent) {
    const target = event.target as HTMLInputElement;
    formData.append(target.name, target.value);

    const values = Array.from(formData.values());
    const changedFields = values.filter(
      (value) => (value as string).length || (value as File).size,
    );
    setHasChanged(Boolean(changedFields.length));
  }

  return (
    <JourneyTemplateProvider>
      <Fade in={true} timeout={600}>
        <form ref={formRef} onChange={handleOnChange}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              flexDirection: 'row',
              minHeight: 'calc(100vh - 4.4rem)',
              marginTop: '-3rem',
            }}>
            <Box>
              <JourneyTemplateMenu />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '6rem',
              }}>
              <Box className={classes.root}>
                <JourneyTemplateHeader />
                <JourneyTemplateThemes />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                padding: '2rem',
                position: 'fixed',
                top: '5rem',
                right: '2rem',
                zIndex: 1,
              }}>
              <JourneyTemplateSave setHasChanged={setHasChanged} />
            </Box>
          </Box>
        </form>
      </Fade>
    </JourneyTemplateProvider>
  );
};

export default JourneyTemplate;
