import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { primary } from 'theme';
import { ContentCopy } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputDatePicker from 'components/common/InputDatePicker';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { BOOKING_ROOM_AVAILABILITY, getData, putData } from 'utils/requests';
import { IAvailability, IAvailabilityRadio, TimeSpanKeys } from 'utils/models';
import Loading from 'components/common/Loading';
import RoomAvailabilityTimePicker from './RoomAvailabilityTimePicker';
import {
  doesAvailabilityNeedsFixing,
  fixAvailabilityTimeslots,
  formatDateYMD,
} from 'utils/utils';

export const defaultRoomAvailability: IAvailability = {
  booking: {
    choice: IAvailabilityRadio.INDEFINITELY,
    amount: 15,
    format: TimeSpanKeys.Days,
    startDate: '2024-03-01',
    endDate: '2025-12-30',
    timeslotOption: 0,
  },
  weeklyHours: {
    mon: {
      active: true,
      times: [
        {
          startTime: '08:00',
          endTime: '17:00',
        },
      ],
    },
    tue: {
      active: true,
      times: [
        {
          startTime: '08:00',
          endTime: '17:00',
        },
      ],
    },
    wed: {
      active: true,
      times: [
        {
          startTime: '08:00',
          endTime: '17:00',
        },
      ],
    },
    thu: {
      active: true,
      times: [
        {
          startTime: '08:00',
          endTime: '17:00',
        },
      ],
    },
    fri: {
      active: true,
      times: [
        {
          startTime: '08:00',
          endTime: '17:00',
        },
      ],
    },
    sat: {
      active: false,
      times: [
        {
          startTime: '10:00',
          endTime: '12:00',
        },
      ],
    },
    sun: {
      active: false,
      times: [
        {
          startTime: '10:00',
          endTime: '12:00',
        },
      ],
    },
  },
};

const RoomAvailability = () => {
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName } = useParams();

  const theme = useTheme();

  const bookingOptions = {
    Days: [5, 10, 15, 20, 25, 30],
    Weeks: [1, 2, 3, 4, 5, 6, 7, 8],
    Months: [1, 2, 3, 6, 9, 12],
  };

  const [availability, setAvailability] = useState<IAvailability>(
    defaultRoomAvailability,
  );

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [showStartDate, setShowStartDate] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showEndDate, setShowEndDate] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [copyOfAvailability, setCopyOfAvailability] = useState<IAvailability>(
    JSON.parse(JSON.stringify(defaultRoomAvailability)),
  );

  const startDatePickerAnchorElRef = useRef<HTMLDivElement>(null);
  const endDatePickerAnchorElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.amount =
      bookingOptions[availability.booking.format][2];
    setAvailability(updatedAvailability);
  }, [availability.booking.format]);

  useEffect(() => {
    setStartDate(new Date(availability.booking.startDate));
    setEndDate(new Date(availability.booking.endDate));

    if (!doesAvailabilityNeedsFixing(availability)) return;
    fixAvailabilityTimeslots(availability);
  }, [availability]);

  useEffect(() => {
    fetchAvailability();
  }, []);

  async function fetchAvailability() {
    if (!ecosystemName) {
      return null;
    }
    setIsLoading(true);

    try {
      const data = await getData(BOOKING_ROOM_AVAILABILITY, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      if (!!data) {
        setAvailability(data);
        setCopyOfAvailability(data);
      }
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function putAvailability() {
    setIsLoading(true);

    try {
      const data = await putData(
        BOOKING_ROOM_AVAILABILITY,
        [{ name: 'ecosystemName', value: ecosystemName }],
        availability,
      );
      setAvailability(data);
      setCopyOfAvailability(data);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Changes saved',
      });
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function handleCheckboxChange(day: string) {
    const updatedAvailability = {
      ...availability,
      weeklyHours: {
        ...availability.weeklyHours,
        [day.toLowerCase()]: {
          ...availability.weeklyHours[day.toLowerCase()],
          active: !availability.weeklyHours[day.toLowerCase()].active,
        },
      },
    };
    setAvailability(updatedAvailability);
  }

  function handleRadioButtonChange(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.choice = event.target
      .value as IAvailabilityRadio;
    setAvailability(updatedAvailability);
  }

  // function handleExcludeWeekendsChange(event: any) {
  //     const updatedAvailability = { ...availability };
  //     updatedAvailability.booking.excludeWeekends = event.target.checked;
  //     setAvailability(updatedAvailability);
  // }

  function handleNumberOfDays(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.amount = event.target.value;
    setAvailability(updatedAvailability);
  }

  function handleTimeSpan(event: any) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.format = event.target.value;
    setAvailability(updatedAvailability);
  }

  function updateStartDateChange(newDate: Date | null) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.startDate = formatDateYMD(newDate);
    setAvailability(updatedAvailability);
    setStartDate(newDate);
    setShowStartDate(false);
  }

  function updateEndDateChange(newDate: Date | null) {
    const updatedAvailability = { ...availability };
    updatedAvailability.booking.endDate = formatDateYMD(newDate);
    setAvailability(updatedAvailability);
    setEndDate(newDate);
    setShowEndDate(false);
  }

  function formatDate(dateString: string | Date): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  function revertChanges() {
    setAvailability(JSON.parse(JSON.stringify(copyOfAvailability)));
  }

  function renderBooking() {
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant='h5' gutterBottom fontSize={'1rem'}>
              Can people book?
            </Typography>
            <Typography fontSize={'0.875rem'}>
              Are all rooms currently available for booking?
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
            }}>
            <Button
              variant='contained'
              sx={{
                borderRadius: '6px',
                marginRight: '0.5rem',
                minHeight: '32px',
                height: '2rem',
                marginBottom: { xs: '1rem', md: 0 },
                bgcolor: 'inherit',
                '&:hover': { bgcolor: theme.palette.primary.light },
              }}
              onClick={() => revertChanges()}>
              <Typography color={theme.palette.primary.dark}>
                Revert Changes
              </Typography>
            </Button>
            <Button
              variant='contained'
              sx={{
                borderRadius: '6px',
                minHeight: '32px',
                height: '2rem',
                bgcolor: theme.palette.primary.dark,
                '&:hover': { bgcolor: theme.palette.primary.main },
              }}
              onClick={() => putAvailability()}>
              <Typography color={primary.pureWhite}>Save Changes</Typography>
            </Button>
          </Box>
        </Box>
        <Box marginTop='1rem'>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label={'label'}
              name={'label'}
              value={availability.booking.choice}
              onChange={handleRadioButtonChange}>
              {/* <FormControlLabel
                value={'DAYS'}
                control={<Radio />}
                label={
                  <>
                    <Typography fontWeight='bold' gutterBottom>
                      Days
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        sx={{ bgcolor: '#F5F5F5', borderRadius: '6px' }}
                        value={availability.booking.amount}
                        onChange={(e) => handleNumberOfDays(e)}>
                        {bookingOptions[availability.booking.format].map(
                          (option: any) => (
                            <MenuItem value={option} key={option}>
                              {option}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                      <Select
                        sx={{
                          bgcolor: '#F5F5F5',
                          borderRadius: '6px',
                          marginX: 1.5,
                        }}
                        value={availability.booking.format}
                        onChange={(e) => handleTimeSpan(e)}>
                        {Object.keys(bookingOptions).map((option) => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>into the future</Typography>
                    </Box>
                  </>
                }
                sx={{ marginBottom: 4 }}
              />
              <FormControlLabel
                value={'DATE_RANGE'}
                control={<Radio />}
                label={
                  <Box>
                    <Typography fontWeight='bold' gutterBottom>
                      Within date range
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        marginBottom: 2,
                        alignItems: 'center',
                        marginTo: 1,
                      }}>
                      <Box
                        ref={startDatePickerAnchorElRef}
                        onClick={() => setShowStartDate(!showStartDate)}
                        sx={{
                          display: 'flex',
                          marginRight: 2,
                          bgcolor: '#F5F5F5',
                          padding: 1.5,
                        }}>
                        <Typography marginRight={1}>
                          {startDate ? formatDate(startDate) : 'Start date'}
                        </Typography>
                        <CalendarMonth />
                      </Box>
                      <Typography>until</Typography>
                      <Box
                        ref={endDatePickerAnchorElRef}
                        onClick={() => setShowEndDate(!showEndDate)}
                        sx={{
                          display: 'flex',
                          marginX: 2,
                          bgcolor: '#F5F5F5',
                          padding: 1.5,
                        }}>
                        <Typography marginRight={1}>
                          {endDate ? formatDate(endDate) : 'End date'}
                        </Typography>
                        <CalendarMonth />
                      </Box>
                    </Box>
                  </Box>
                }
                sx={{ marginBottom: 2 }}
              /> */}
              <FormControlLabel
                value={'INDEFINITELY'}
                control={<Radio />}
                label={<Typography fontWeight='bold'>Available</Typography>}
                sx={{ marginBottom: 2 }}
              />
              <FormControlLabel
                value={'NOT_AVAILABLE'}
                control={<Radio />}
                label={<Typography fontWeight='bold'>Not available</Typography>}
                sx={{ marginBottom: 2 }}
              />
            </RadioGroup>
            {/* <FormControlLabel
                control={<Checkbox checked={availability.booking.excludeWeekends} onChange={handleExcludeWeekendsChange} />}
                label="Exclude Weekends from dates"
            />          */}
          </FormControl>
        </Box>
        {showStartDate && (
          <InputDatePicker
            onChange={updateStartDateChange}
            anchorEl={startDatePickerAnchorElRef.current}
          />
        )}
        {showEndDate && (
          <InputDatePicker
            onChange={updateEndDateChange}
            anchorEl={endDatePickerAnchorElRef.current}
          />
        )}
      </>
    );
  }

  function renderWeeklyHours() {
    const daysOfTheWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant='h5' gutterBottom fontSize={'1rem'}>
              Set opening hours
            </Typography>
            <Typography fontSize={'0.875rem'}>
              Set the default timeframe in which rooms are available for booking
            </Typography>
          </Box>
        </Box>
        <Box marginTop='1rem'>
          {/* <Box
            sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
            onClick={() => setShowPreview(true)}>
            <Button
              sx={{
                '&:hover': { bgcolor: acterioTheme.light },
                paddingY: 2,
                borderRadius: '6px',
                paddingLeft: 0,
              }}>
              <CalendarMonth
                sx={{ marginRight: 1, fontSize: 32, color: acterioTheme.dark }}
              />
              <Typography variant='h5' fontSize={'1rem'}>
                Preview in calendar view
              </Typography>
            </Button>
          </Box> */}
          <Box>
            {daysOfTheWeek.map((option, index) => {
              const day = option.toLowerCase();
              const [popoverAnchorEl, setPopoverAnchorEl] =
                useState<HTMLElement | null>(null);

              function renderPopover(day: string, index: number) {
                const fullDaysOfTheWeek = [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ];

                const [selectedDays, setSelectedDays] = useState<string[]>([]);

                function handleCopyToOtherDays() {
                  const timeSlotsToCopy = availability.weeklyHours[day].times;
                  const updatedAvailability = { ...availability };
                  selectedDays.forEach((selectedDay) => {
                    updatedAvailability.weeklyHours[selectedDay].times =
                      timeSlotsToCopy;
                  });
                  setAvailability(updatedAvailability);
                }

                return (
                  <Popover
                    open={Boolean(popoverAnchorEl)}
                    anchorEl={popoverAnchorEl}
                    onClose={() => setPopoverAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 2,
                        alignItems: 'center',
                      }}>
                      <Typography
                        fontWeight='bold'
                        gutterBottom
                        sx={{
                          color: theme.palette.primary.dark,
                          textTransform: 'uppercase',
                          width: 150,
                        }}>
                        Copy times To
                      </Typography>
                      {fullDaysOfTheWeek.map((fullDay, fullDayIndex) => {
                        const [isChecked, setIsChecked] =
                          useState<boolean>(false);

                        function handleCheckboxChange() {
                          if (isChecked) {
                            setIsChecked(false);
                            setSelectedDays((prev) =>
                              prev.filter(
                                (day) =>
                                  day !== fullDay.substring(0, 3).toLowerCase(),
                              ),
                            );
                          } else {
                            setIsChecked(true);
                            setSelectedDays((prev) => [
                              ...prev,
                              fullDay.substring(0, 3).toLowerCase(),
                            ]);
                          }
                        }

                        return (
                          <Box
                            key={fullDay}
                            sx={{
                              display: 'flex',
                              width: 140,
                              justifyContent: 'space-between',
                            }}>
                            <Typography
                              sx={{
                                width: 20,
                                color: !(index === fullDayIndex)
                                  ? 'black'
                                  : 'grey',
                              }}>
                              {fullDay}
                            </Typography>
                            <Checkbox
                              sx={{ height: 30, marginRight: 1 }}
                              disabled={index === fullDayIndex}
                              checked={isChecked}
                              onClick={handleCheckboxChange}
                            />
                          </Box>
                        );
                      })}
                      <Button onClick={() => handleCopyToOtherDays()}>
                        <Typography fontWeight='bold'>Apply</Typography>
                      </Button>
                    </Box>
                  </Popover>
                );
              }

              // function addTimeSlot() {
              //   const updatedAvailability = {
              //     ...availability,
              //     weeklyHours: {
              //       ...availability.weeklyHours,
              //       [day]: {
              //         ...availability.weeklyHours[day],
              //         times: [
              //           ...availability.weeklyHours[day].times,
              //           {
              //             startTime: null,
              //             endTime: null,
              //           },
              //         ],
              //       },
              //     },
              //   };
              //   setAvailability(updatedAvailability as IAvailability);
              // }

              return (
                <Box
                  key={`${day} + ${index}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'top',
                    marginY: 1.5,
                    marginLeft: { xs: '-2rem', sm: 0 },
                  }}>
                  <Checkbox
                    sx={{ height: 30, marginTop: 2 }}
                    checked={availability.weeklyHours[day].active}
                    onChange={(e) => handleCheckboxChange(day)}
                  />
                  <Typography
                    marginX={'1.5rem'}
                    width={'3.125rem'}
                    minWidth={'3.125rem'}
                    marginTop={2.5}>
                    {day.toUpperCase()}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {availability.weeklyHours[day].times.map((times, i) => (
                        <RoomAvailabilityTimePicker
                          day={day}
                          key={i}
                          index={i}
                          availability={availability}
                          setAvailability={setAvailability}
                          startTime={times.startTime}
                          endTime={times.endTime}
                        />
                      ))}
                    </Box>
                  </LocalizationProvider>
                  {/* <Button
                    sx={{ marginRight: -1, height: 38, marginTop: 1.5 }}
                    onClick={() => addTimeSlot()}>
                    <Add sx={{ color: acterioTheme.dark }} />
                  </Button> */}
                  <Button
                    sx={{ marginRight: -2, height: 38, marginTop: 1.5 }}
                    onClick={(event) =>
                      setPopoverAnchorEl(event.currentTarget)
                    }>
                    <ContentCopy sx={{ color: theme.palette.primary.dark }} />
                  </Button>
                  {renderPopover(day, index)}
                </Box>
              );
            })}
          </Box>
        </Box>
      </>
    );
  }

  function renderAvailability() {
    return (
      <div>
        {!isLoading ? renderBooking() : <Loading />}
        <Divider
          sx={{
            width: 'calc(100% + 4rem)',
            marginLeft: '-2rem',
            marginY: '2rem',
            marginTop: isLoading ? '100vh' : '2rem',
          }}
        />
        {renderWeeklyHours()}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 900 }}>
      <Box justifyContent='left' marginTop='2rem' marginBottom='1rem'>
        <Typography variant='h5' gutterBottom fontSize={22}>
          Default Room Availability
        </Typography>
        <Typography>
          Manage the default time in which rooms will be available for booking
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#FFFFFF',
          border: 1,
          borderColor: '#D4D4D4',
          borderRadius: '6px',
          padding: '2rem',
        }}>
        {renderAvailability()}
      </Box>
      {/* {showPreview && (
        <CalendarPreview
          isOpen={showPreview}
          handleClose={() => {
            setShowPreview(false);
          }}
          availability={availability}
        />
      )} */}
    </Box>
  );
};

export default RoomAvailability;
