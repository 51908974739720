import { Delete } from '@mui/icons-material';
import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import React, { useState, FunctionComponent, useEffect } from 'react';
import { formatDateTime } from 'utils/utils';
import { IAvailability } from 'utils/models';

interface AvailabilityTimePickerProps {
  availability: any;
  setAvailability: (e: any) => void;
  day: string;
  index: number;
  startTime?: any;
  endTime?: any;
}

const AvailabilityTimePicker: FunctionComponent<AvailabilityTimePickerProps> = (
  props,
) => {
  const { availability, setAvailability, day, index, startTime, endTime } =
    props;

  const theme = useTheme();

  const [fromTime, setFromTime] = useState<Date | null>(
    startTime ? createDateFromTime(startTime) : null,
  );
  const [toTime, setToTime] = useState<Date | null>(
    endTime ? createDateFromTime(endTime) : null,
  );

  useEffect(() => {
    setFromTime(startTime ? createDateFromTime(startTime) : null);
    setToTime(endTime ? createDateFromTime(endTime) : null);
  }, [startTime, endTime]);

  useEffect(() => {
    handleTimeChange(day);
  }, [fromTime, toTime]);

  function handleTimeChange(day: string) {
    const updatedAvailability = { ...availability };
    const startTime = formatDateTime(fromTime);
    const endTime = formatDateTime(toTime);
    updatedAvailability.weeklyHours[day].times[index] = { startTime, endTime };
    setAvailability(updatedAvailability);
  }

  function createDateFromTime(timeString: string): Date {
    const currentDate = new Date();
    const [hours, minutes] = timeString.split(':').map(Number);
    currentDate.setHours(hours, minutes);
    return currentDate;
  }

  function removeTimeSlot() {
    if (availability.weeklyHours[day].times.length <= 1) {
      return;
    }

    const updatedAvailability = { ...availability };
    updatedAvailability.weeklyHours[day].times.splice(index, 1);
    setAvailability(updatedAvailability as IAvailability);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginY: 0.5 }}>
      <Box display='flex'>
        <TimePicker
          value={fromTime}
          onChange={setFromTime}
          sx={{ maxWidth: 120 }}
          ampm={false}
        />
      </Box>
      <Typography
        fontWeight='bold'
        fontSize={'1.5rem'}
        marginBottom={'1rem'}
        marginX={'0.5rem'}>
        {' '}
        _{' '}
      </Typography>
      <Box display='flex' marginRight={'1rem'}>
        <TimePicker
          value={toTime}
          onChange={setToTime}
          sx={{ maxWidth: 120 }}
          ampm={false}
        />
      </Box>
      <Button
        sx={{ height: '2.375rem', width: '4rem', minWidth: 'unset', top: -0.5 }}
        onClick={() => removeTimeSlot()}>
        <Delete sx={{ color: theme.palette.primary.dark }} />
      </Button>
    </Box>
  );
};

export default AvailabilityTimePicker;
