import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import InputField from '../common/InputField';
import { postData, SUPER_ADMIN_CREATE_ECOSYSTEM } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import UploadImage from '../common/UploadImage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '60vh',
    position: 'relative',
    '& h1': {
      fontSize: '7rem !important',
    },

    '& button': {
      marginTop: '5rem !important',
    },
  },
}));

interface ICreateNewEcosystem {
  showNewEcosystem: boolean;
  close: () => void;
}

const CreateNewEcosystem: FunctionComponent<ICreateNewEcosystem> = (props) => {
  const { showNewEcosystem, close } = props;
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [ecosystemName, setEcosystemName] = useState<string>('');
  const [adminEmail, setAdminEmail] = useState<string>('');
  const [ecosystemLocation, setEcosystemLocation] = useState<string>('');
  const [uploadedLogo, setUploadedLogo] = useState<any>(null);
  const [uploadedNavLogo, setUploadedNavLogo] = useState<any>(null);

  async function getImagesToUpload() {
    const formData = new FormData();
    formData.append('ecosystemLogo', uploadedLogo, uploadedLogo.name);
    formData.append(
      'ecosystemNavbarLogo',
      uploadedNavLogo,
      uploadedNavLogo.name,
    );
    return formData;
  }

  async function createNewEcosystem() {
    setLoading(true);
    try {
      const data = await postData(
        SUPER_ADMIN_CREATE_ECOSYSTEM,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'ecosystemAdminEmail', value: adminEmail },
          { name: 'ecosystemLocation', value: ecosystemLocation },
          { name: 'ecosystemColor', value: encodeURIComponent('#0B3943') },
        ],
        await getImagesToUpload(),
      );
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: data,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function renderMetricContent() {
    return (
      <>
        <InputField
          value={ecosystemName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setEcosystemName(event.target.value)
          }
          label={'Ecosystem Name:'}
        />
        <InputField
          value={adminEmail}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setAdminEmail(event.target.value)
          }
          label={'Admin Email Address:'}
        />
        <InputField
          value={ecosystemLocation}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setEcosystemLocation(event.target.value)
          }
          label={'Ecosystem Location:'}
        />
        <Typography variant={'h6'}>Ecosystem Main Logo:</Typography>
        <UploadImage
          height={250}
          width={250}
          borderColor={'#e0e0e0'}
          initialSrc={uploadedLogo}
          onUpload={(file) => setUploadedLogo(file)}
          isDisabled={loading}
        />
        <Typography variant={'h6'}>Ecosystem Navbar Logo:</Typography>
        <UploadImage
          height={250}
          width={250}
          borderColor={'#e0e0e0'}
          initialSrc={uploadedNavLogo}
          onUpload={(file) => setUploadedNavLogo(file)}
          isDisabled={loading}
        />
      </>
    );
  }

  return (
    <Modal
      open={showNewEcosystem}
      close={close}
      isActerioTheme={true}
      title={'Create New Ecosystem'}
      largeHeader={true}
      showFooter={true}
      largeFooter={true}
      footerButtonsFlexStart={true}
      saveButtonText={'Create'}
      saveAction={() => createNewEcosystem()}
      backgroundColor={'white'}
      headerColor={theme.palette.primary.light}
      loading={loading}>
      {renderMetricContent()}
    </Modal>
  );
};

export default CreateNewEcosystem;
