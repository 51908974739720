import React, {
  createContext,
  FunctionComponent,
  useContext,
  PropsWithChildren,
  useState,
} from 'react';
import { ICompanyJourney } from 'utils/models';

const useAssignJourneys = () => {
  const [selectedCompanies, setSelectedCompanies] = useState<ICompanyJourney[]>(
    [],
  );
  const [selectedJourneyId, setSelectedJourneyId] = useState<number | null>(
    null,
  );

  function addCompany(company: ICompanyJourney) {
    setSelectedCompanies([...selectedCompanies, company]);
  }

  function removeCompany(company: ICompanyJourney) {
    const updatedCompanies = selectedCompanies.filter(
      (selectedCompany) => selectedCompany.companyId !== company.companyId,
    );

    setSelectedCompanies(updatedCompanies);
  }

  function isSelectedCompany(company: ICompanyJourney) {
    return !!selectedCompanies.find(
      ({ companyId }) => companyId === company.companyId,
    );
  }

  function clearAllCompanies() {
    setSelectedCompanies([]);
  }

  function addJourneyId(id: number) {
    setSelectedJourneyId(id);
  }

  function removeJourneyId() {
    setSelectedJourneyId(null);
  }

  function isSelectedJourney(id: number) {
    return !!selectedJourneyId && selectedJourneyId === id;
  }

  return {
    selectedCompanies,
    addCompany,
    removeCompany,
    isSelectedCompany,
    clearAllCompanies,
    selectedJourneyId,
    addJourneyId,
    removeJourneyId,
    isSelectedJourney,
  };
};

const AssignJourneysContext = createContext(
  {} as ReturnType<typeof useAssignJourneys>,
);

export const AssignJourneysProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const assignJourneysContext = useAssignJourneys();

  return (
    <AssignJourneysContext.Provider value={assignJourneysContext}>
      {children}
    </AssignJourneysContext.Provider>
  );
};

export const useAssignJourneysContext = () => useContext(AssignJourneysContext);
