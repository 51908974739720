import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext, useEffect, useState } from 'react';
import { IApplicantProcessEditMode } from 'utils/models';
import { APPLICATION_PROCESS_EDIT, getData } from 'utils/requests';
import { useParams } from 'react-router';

export interface IFetchProcess {
  id: number | string | undefined;
}

const useFetchProcess = (props: IFetchProcess) => {
  const [isProcessLoading, setIsProcessLoading] = useState<boolean>(false);
  const [form, setForm] = useState<IApplicantProcessEditMode | null>(null);
  const { ecosystemName } = useParams();

  const { id } = props;
  const { addAlert } = useContext(AlertContext);

  if (!id) return { isLoading: true, form: null };

  useEffect(() => {
    fetchProcess();
  }, []);
  async function fetchProcess() {
    setIsProcessLoading(true);
    try {
      const data = await getData(APPLICATION_PROCESS_EDIT, [
        {
          name: 'processId',
          value: id,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      setForm(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsProcessLoading(false);
  }

  return { isProcessLoading, form };
};

export default useFetchProcess;
