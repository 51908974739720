import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { APPLICATION_PROCESS_APPLICANT, patchData } from 'utils/requests';

interface IUpdateApplicant {
  applicantId: any;
  destinationColumnId: any;
  selectedApplicationProcessId: any;
  setIsLoading: (b: boolean) => void;
  successPopup: (id: number) => void;
  // setApplicationOverviewData: (d: IApplicationOverview) => void;
}

const useUpdateApplicant = (props: IUpdateApplicant) => {
  const {
    applicantId,
    destinationColumnId,
    selectedApplicationProcessId,
    setIsLoading,
    successPopup,
    // setApplicationOverviewData,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  if (!destinationColumnId || !applicantId)
    return () => console.log('Something went wrong');

  async function updateApplicant() {
    setIsLoading(true);
    try {
      const data = await patchData(APPLICATION_PROCESS_APPLICANT, [
        {
          name: 'applicantId',
          value: applicantId,
        },
        {
          name: 'statusId',
          value: destinationColumnId,
        },
        {
          name: 'applicationProcessId',
          value: selectedApplicationProcessId,
        },
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      successPopup(destinationColumnId);
      // setApplicationOverviewData(data); //Nothing is returned
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return updateApplicant;
};

export default useUpdateApplicant;
