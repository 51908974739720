import { Button, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IListButton } from 'utils/models';

interface IMoreButtons {
  buttonList: IListButton[];
}

const MoreOptionsButtons: FunctionComponent<IMoreButtons> = (props) => {
  const { buttonList } = props;

  const theme = useTheme();

  return (
    <>
      {buttonList
        .filter((button) => button.isDisabled !== true)
        .map((button, i) => (
          <Button
            key={`${button.name}-${i}`}
            onClick={button.onClick}
            sx={{
              display: 'flex',
              bgcolor: button.color ?? 'inherit',
              '&:hover': {
                bgcolor: button.hoverColor ?? theme.palette.primary.light,
              },
              borderRadius: 0.2,
              justifyContent: 'start',
            }}>
            <button.icon
              sx={{
                color: button.textColor ?? theme.palette.primary.dark,
                marginLeft: 1,
                marginRight: 1,
              }}
            />
            <Typography
              sx={{
                color: button.textColor ?? theme.palette.primary.dark,
                marginRight: 1,
              }}>
              {button.name}
            </Typography>
          </Button>
        ))}
    </>
  );
};

export default MoreOptionsButtons;
