import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { colorPallete, primary } from 'theme';
import { Typography } from '@mui/material';
import { IListButton } from 'utils/models';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '6px',
    marginTop: '0.3rem',
    width: '13rem',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: '0.7rem',
        color: colorPallete.additionalDarkGreen,
        marginRight: '0.1rem',
      },
      '&:hover': {
        backgroundColor: colorPallete.additionalGreen,
      },
    },
  },
}));

interface IMoveFowardToButton {
  buttonList: IListButton[];
}

const MoveFowardToButton: React.FunctionComponent<IMoveFowardToButton> = ({
  buttonList,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='move-forward-to'
        aria-controls={open ? 'move-forward-to-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        sx={{
          width: '13rem',
          margin: 'auto',
          paddingY: '0.4rem',
          bgcolor: colorPallete.additionalGreen,
          color: primary.dark,
          fontSize: '1rem',
          '&:hover': {
            bgcolor: colorPallete.additionalDarkGreen,
            color: primary.pureWhite,
          },
        }}>
        Move forward to
      </Button>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {buttonList &&
          buttonList.map((button, i) => (
            <MenuItem
              key={i}
              disableRipple={i === 0}
              disableTouchRipple={i === 0}
              onClick={() => {
                button.onClick();
                handleClose();
              }}
              sx={{
                color: button.textColor || primary.dark,
                bgcolor: 'transparent',
                cursor: i === 0 ? 'default' : 'pointer',
                fontWeight: i === 0 ? 700 : 'normal',
                '&:hover': {
                  backgroundColor:
                    i === 0
                      ? 'transparent !important'
                      : primary.additionalGreen,
                },
              }}>
              {button.icon && (
                <button.icon
                  sx={{
                    color:
                      button.name === 'Rejected'
                        ? `${primary.warningRed} !important`
                        : 'inherit',
                  }}
                />
              )}
              <Typography
                variant='body1'
                sx={{
                  fontWeight: i === 0 ? 'bold' : 'normal',
                  ml: '0.25rem',
                }}>
                {button.name}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

export default MoveFowardToButton;
