import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import InputField from 'components/common/InputField';
import { IEvent } from 'utils/models';

interface IAddEventName {
  event: IEvent;
  setEvent: (event: IEvent) => void;
  isLoading: boolean;
}

const AddEventName: FunctionComponent<IAddEventName> = (props) => {
  const { event, setEvent, isLoading } = props;

  const [title, setTitle] = useState<string>(event.title);

  useEffect(() => {
    const updatedEvent = { ...event, title: title };
    setEvent(updatedEvent);
  }, [title]);

  return (
    <div>
      <InputField
        value={title}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setTitle(event.target.value)
        }
        placeholder='Enter event name'
        readonly={isLoading}
        label={'Event name*'}
      />
    </div>
  );
};

export default AddEventName;
