import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';

import { Fade, Theme, Typography } from '@mui/material';
import MainButton, { ButtonType } from 'components/common/MainButton';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '60vh',
    position: 'relative',
    width: '80vw',
    margin: 'auto',

    '& h1': {
      fontSize: '7rem !important',
    },

    '& button': {
      marginTop: '5rem !important',
    },
  },
}));

interface INotFound {}

const NotFound: FunctionComponent<INotFound> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.root}>
        <Typography variant={'h1'}>404</Typography>
        <Typography variant={'h4'}>Page not found</Typography>
        <MainButton
          text={'Take me home'}
          type={ButtonType.FILLED}
          onClick={() => navigate('dashboard')}
        />
      </div>
    </Fade>
  );
};

export default NotFound;
