import { useState, useContext } from 'react';
import { useParams } from 'react-router';
import {
  deleteData,
  HOME_NEW_COMMENT_ROUTE,
  putData,
} from '../../utils/requests';
import { ALERT_TYPES, AlertContext } from '../../components/Alert/AlertContext';
import { IComment } from '../../utils/models';

export const useComment = (
  setComments: React.Dispatch<React.SetStateAction<IComment[]>> | undefined,
  setIsInputVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [commentLoading, setCommentLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const putComment = async (
    announcementId: number,
    comment: string,
    setInputText: React.Dispatch<React.SetStateAction<string>>,
    commentId: number,
  ) => {
    setCommentLoading(true);
    try {
      const data = await putData(
        HOME_NEW_COMMENT_ROUTE,
        [
          { name: 'announcementId', value: announcementId },
          { name: 'ecosystemName', value: ecosystemName },
        ],
        { body: comment, type: 'comment', id: isEditing ? commentId : null },
      );
      if (setComments) {
        setComments((prev) => {
          if (isEditing) {
            return prev.map((item) => (item.id === commentId ? data : item));
          } else {
            return [...prev, data];
          }
        });
      }
      setInputText('');
      if (setIsInputVisible) {
        setIsInputVisible(false);
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setCommentLoading(false);
      setIsEditing(false);
    }
  };

  const deleteComment = async (commentId: number) => {
    setCommentLoading(true);
    try {
      const data = await deleteData(HOME_NEW_COMMENT_ROUTE, [
        { name: 'commentId', value: commentId },
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      if (setComments) {
        setComments((prev) => prev.filter((item) => item.id !== commentId));
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setCommentLoading(false);
      setIsEditing(false);
    }
  };

  return {
    putComment,
    commentLoading,
    setIsEditing,
    isEditing,
    deleteComment,
  };
};
