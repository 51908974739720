import { ArrowBackIos, ArrowForwardIos, Star } from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import CommonButton from 'components/common/CommonButton';
import Loading from 'components/common/Loading';
import CommonModal from 'components/common/Modal/CommonModal';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import { grey, primary } from 'theme';
import {
  IApplicationAssessmentSection,
  IApplicationAssessmentSubSection,
  ICompletedAssessment,
} from 'utils/models';
import { APPLICATION_ASSESSMENT_REPORT_DETAILS, getData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';

interface IAssessmentApplicantReportDetailsProps {
  isOpen: boolean;
  handleClose: () => void;
  applicantId: number;
}

const AssessmentApplicantReportDetails: FunctionComponent<
  IAssessmentApplicantReportDetailsProps
> = (props) => {
  const { isOpen, handleClose, applicantId } = props;

  const theme = useTheme();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const { selectedReportId } = useAssessmentContext();

  const [selectedSubSection, setSelectedSubSection] =
    useState<IApplicationAssessmentSubSection | null>(null);

  const [selectedSectionNumber, setSelectedSectionNumber] = useState<number>(0);

  const [selectedSubSectionNumber, setSelectedSubSectionNumber] =
    useState<number>(0);

  const [selectedAssessorIndex, setSelectedAssessorIndex] = useState<number>(0);

  const [assessmentDetails, setAssessmentDetails] =
    useState<ICompletedAssessment | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isFirstAssessorSelected = selectedAssessorIndex < 1;
  const isLastAssessorSelected =
    !!assessmentDetails &&
    selectedAssessorIndex > assessmentDetails.assessors.length - 2;

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  useEffect(() => {
    if (!assessmentDetails) return;

    updateSelectedSubSectionInfo(
      assessmentDetails.assessors[selectedAssessorIndex].assessment.sections[
        selectedSectionNumber
      ],
      assessmentDetails.assessors[selectedAssessorIndex].assessment.sections[
        selectedSectionNumber
      ].subSections[selectedSubSectionNumber],
    );
  }, [
    selectedSectionNumber,
    selectedSubSectionNumber,
    assessmentDetails,
    selectedAssessorIndex,
  ]);

  function handleSubSectionChange(secIndex: number, subSecIndex: number) {
    setSelectedSectionNumber(secIndex);
    setSelectedSubSectionNumber(subSecIndex);
  }

  function updateSelectedSubSectionInfo(
    section: IApplicationAssessmentSection,
    subSection: IApplicationAssessmentSubSection,
  ) {
    const assessorAssessment =
      assessmentDetails?.assessors[selectedAssessorIndex].assessment;

    const sectionToSelect = assessorAssessment!.sections.find(
      (item) => item.id === section.id,
    );
    const subSectionToSelect = sectionToSelect?.subSections.find(
      (item) => item.id === subSection.id,
    );

    if (!sectionToSelect || !subSectionToSelect) return;
    setSelectedSubSection(subSectionToSelect);
  }

  async function fetchAssessmentDetails() {
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_REPORT_DETAILS, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'reportId', value: selectedReportId },
        { name: 'applicantId', value: applicantId },
      ]);

      setAssessmentDetails(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function handlePreviousAssessor() {
    if (isFirstAssessorSelected) return;
    setSelectedAssessorIndex(selectedAssessorIndex - 1);
  }

  function handleNextAssessor() {
    if (isLastAssessorSelected) return;
    setSelectedAssessorIndex(selectedAssessorIndex + 1);
  }

  function renderFormDetails() {
    if (!assessmentDetails) return <>No assessment details...</>;

    function renderTableOfContents() {
      if (
        !assessmentDetails ||
        !assessmentDetails.assessors ||
        !assessmentDetails.assessors[selectedAssessorIndex] ||
        !assessmentDetails.assessors[selectedAssessorIndex].assessment ||
        !assessmentDetails.assessors[selectedAssessorIndex].assessment.sections
      )
        return;

      const assessorAssessment =
        assessmentDetails.assessors[selectedAssessorIndex].assessment;

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            maxHeight: '70vh',
          }}>
          {assessorAssessment.sections.map((section, sectionIndex) => (
            <Box key={section.id} sx={{ mt: '1.25rem' }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  textTransform: 'uppercase',
                  color: grey,
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Star
                    sx={{
                      color: grey,
                      fontSize: '2rem',
                      mr: '0.5rem',
                    }}
                  />
                  {section.rating}
                </Box>
              </Typography>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  textTransform: 'uppercase',
                  mb: '1.5rem',
                }}>
                {section.name}
              </Typography>
              {section.subSections.map((subSection, subSectionIndex) => {
                const isSelected = selectedSubSection?.id === subSection.id;

                return (
                  <Box
                    key={subSection.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: isSelected ? '0rem' : '1rem',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleSubSectionChange(sectionIndex, subSectionIndex)
                    }>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          color: primary.natural1,
                          textTransform: 'uppercase',
                          fontWeight: isSelected ? 'bold' : '',
                        }}>
                        {`${subSectionIndex + 1}. ${subSection.name}`}
                      </Typography>
                      {/* Display the subSection rating */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Star
                          sx={{
                            color: grey,
                            fontSize: '1rem',
                            mr: '0.25rem',
                          }}
                        />
                        {subSection.rating}
                      </Box>
                    </Box>

                    {isSelected && (
                      <Box
                        sx={{
                          bgcolor: primary.natural4,
                          width: '100%',
                          borderRadius: '8px',
                          height: '3px',
                          mt: '0.25rem',
                          mb: '0.75rem',
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      );
    }

    function renderAssessmentForm() {
      if (!selectedSubSection) return null;

      const assessorAssessment =
        assessmentDetails?.assessors[selectedAssessorIndex].assessment;

      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: '2rem' }}>
          <Typography
            variant='h3'
            sx={{ mb: '2rem', fontWeight: 400, color: primary.natural2 }}>
            {selectedSubSection?.name}
          </Typography>
          {selectedSubSection?.questions.map((question, i) => (
            <Box
              key={question.id}
              sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 'bold', mb: '0.75rem' }}>
                {`${i + 1}. ${question.name}`}
              </Typography>
              <Box sx={{ pl: '1rem' }}>
                {question.options.map((option, questionIndex) => (
                  <Box
                    key={option.id}
                    sx={{
                      display: 'flex',
                      mb: '0.5rem',
                      alignItems: 'center',
                    }}>
                    <Typography
                      sx={{
                        mr: '0.5rem',
                        fontWeight: 'bold',
                        fontSize: '0.75rem',
                        width: '1.5rem',
                      }}>
                      {questionIndex + 1}
                    </Typography>
                    <FormControlLabel
                      value={question.answer}
                      control={
                        <Radio
                          checked={question.answer?.id === option.id}
                          sx={{
                            '&.Mui-checked': {
                              color: 'black',
                            },
                          }}
                        />
                      }
                      label={option.name}
                    />
                  </Box>
                ))}
              </Box>
              <Divider
                sx={{ color: primary.natural4, mt: '1rem', mb: '1.5rem' }}
              />
            </Box>
          ))}
          {selectedSubSection.comment && (
            <Box
              sx={{
                backgroundColor: primary.natural8,
                p: '1rem',
                borderRadius: 1,
                minHeight: '7.5rem',
              }}>
              <Typography>{selectedSubSection.comment}</Typography>
            </Box>
          )}
        </Box>
      );
    }

    function renderAssessorAndButtons() {
      if (!assessmentDetails) return <></>;

      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '1.5rem',
            mt: '0.5rem',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>Assessed by:</Typography>
            <Typography
              sx={{ fontWeight: 'bold', ml: '0.5rem', fontSize: '1.25rem' }}>
              {assessmentDetails.assessors[selectedAssessorIndex].name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '30%',
              justifyContent: 'space-between',
            }}>
            <Button
              sx={{ minWidth: '1rem', color: primary.mainBlackFont }}
              onClick={handlePreviousAssessor}
              disabled={isFirstAssessorSelected}>
              <ArrowBackIos />
            </Button>
            <Typography>{selectedAssessorIndex + 1}</Typography>
            <Typography>of</Typography>
            <Typography>{assessmentDetails.assessors.length}</Typography>
            <Button
              sx={{ minWidth: '1rem', color: primary.mainBlackFont }}
              onClick={handleNextAssessor}
              disabled={isLastAssessorSelected}>
              <ArrowForwardIos />
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Grid container columnSpacing={'1.25rem'}>
        <Grid item xs={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <Typography
              variant='h4'
              sx={{ fontWeight: 'bold', mt: '1rem', mb: '1.5rem' }}>
              {assessmentDetails.applicantCompanyName}
            </Typography>
            <FlexBox sx={{ alignItems: 'center', mb: '1rem' }}>
              <Star
                sx={{
                  color: primary.successGreen,
                  fontSize: '2rem',
                  mr: '0.75rem',
                }}
              />
              <Typography
                sx={{
                  color: primary.successGreen,
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                }}>
                {
                  assessmentDetails.assessors[selectedAssessorIndex]
                    .assessorRating
                }
              </Typography>
            </FlexBox>
            {renderTableOfContents()}
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
          {renderAssessorAndButtons()}
          <Card
            sx={{
              borderRadius: '8px',
              bgcolor: primary.pureWhite,
              width: '100%',
              boxShadow: theme.shadows[1],
            }}>
            {renderAssessmentForm()}
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: '80vw',
          maxHeight: '90vh',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: primary.natural5 + ' transparent',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          paddingY: '1.5rem',
          paddingX: '2rem',
          background: primary.pureWhite,
          borderRadius: '10px',
          bgcolor: primary.additionalLightGreen,
          boxShadow: theme.shadows[2],
          border: `1px solid ${primary.greyBorder}`,
        }}>
        {isLoading ? <Loading /> : renderFormDetails()}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            mt: '1.5rem',
          }}>
          <CommonButton
            text='Close'
            onClick={handleClose}
            sx={{ justifySelf: 'flex-end' }}
          />
        </Box>
      </Box>
    </CommonModal>
  );
};

export default AssessmentApplicantReportDetails;
