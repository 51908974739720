import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { cardShadow } from 'theme';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  user: {
    width: '12.5rem',
    minWidth: '12.5rem',
    maxWidth: '12.5rem',
    borderRadius: '4px',
    backgroundColor: (props: any) =>
      props.accessLevelIndex % 2 !== 0 ? 'white' : '#F5F5F5',
    border: '1px solid #e0e0e0',
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer',
    position: 'relative',

    '& p': {
      fontSize: '0.7rem !important',
      marginTop: '3px !important',
    },
  },
  menuButton: {
    minWidth: '25px !important',
    width: '25px !important',
    maxHeight: '25px !important',
    borderRadius: '50% !important',

    '& svg': {
      color: theme.palette.primary.dark,
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  color: {
    minHeight: '16px',
    minWidth: '16px',
    maxWidth: '16px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: '7.5px',
    marginLeft: '5px',
    marginTop: '2px',
  },
  menu: {
    minWidth: '85px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    position: 'absolute',
    right: '-5rem',
    bottom: '-14px',
    zIndex: 1000,
    boxShadow: cardShadow,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& button': {
      width: '100% !important',
      minWidth: '100 !important',
      minHeight: '1.875rem',
      height: '1.875rem !important',
      borderRadius: '4px !important',
      fontSize: '0.7rem !important',
      color: `${theme.palette.primary.dark} !important`,
    },
  },
}));

interface IDraggableAccessLevelUser {
  id: number;
  name: string;
  index: number;
  accessLevelIndex: number;
  ecosystemName: string;
}

const DraggableAccessLevelUser: FunctionComponent<IDraggableAccessLevelUser> = (
  props,
) => {
  const { id, name, index, accessLevelIndex, ecosystemName } = props;
  const classes = useStyles({ accessLevelIndex });
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    boxShadow: isDragging ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
    ...draggableStyle,
  });

  function renderMenu() {
    return (
      <div className={classes.menu}>
        <Button
          onClick={() => navigate(`/ecosystem/${ecosystemName}/members/${id}`)}>
          See profile
        </Button>
      </div>
    );
  }

  return (
    <Draggable draggableId={`draggable-${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          key={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.user}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}>
          <div className={classes.userInfo}>
            <div className={classes.color} />
            <Typography variant={'body2'}>{name}</Typography>
          </div>
          <Button
            className={classes.menuButton}
            onClick={() => setShowMenu(!showMenu)}>
            {showMenu ? <CloseIcon /> : <MoreVertIcon />}
          </Button>
          {showMenu && renderMenu()}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableAccessLevelUser;
