import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IAnnouncement, IComment, IListButton } from 'utils/models';
import { useNavigate } from 'react-router-dom';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { FlexBox } from '../../utils/styledComponents';
import RichTextDisplay from '../common/RichTextDisplay';
import Loading from '../common/Loading';
import EditAnnouncement from './EditAnnouncement';
import Button from '@mui/material/Button';
import CommentIcon from '../../assests/icons/CommentIcon';
import CommentInput from './CommentInput';
import { useComment } from '../../hooks/Announcements/useComment';

interface IPostItemProps {
  userId?: number;
  announcementId: number;
  post: IAnnouncement | IComment;
  accessLevelIds?: number[];
  onPin?: () => void;
  onDelete?: () => void;
  setComments?: React.Dispatch<React.SetStateAction<IComment[]>>;
  editAnnouncement: (
    id: number,
    editedTitle: string,
    editedBody: string,
    accessLevels: number[],
    editedImage: File | null,
    closeModal: () => void,
  ) => Promise<void>;
}

const PostItem: FunctionComponent<IPostItemProps> = ({
  post,
  onPin,
  onDelete,
  accessLevelIds,
  editAnnouncement,
  setComments,
  announcementId,
  userId,
}) => {
  const {
    id,
    creatorId,
    creatorName,
    body,
    timestamp,
    picUrl,
    role,
    canUserEdit,
    type,
    accessLevelColor,
  } = post;
  const title = 'title' in post ? post.title : undefined;
  const pinned = 'pinned' in post ? post.pinned : undefined;
  const imageUrl = 'imageUrl' in post ? post.imageUrl : null;

  const navigate = useNavigate();
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [renderInput, setRenderInput] = useState<boolean>(false);
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [inputText, setInputText] = useState<string>('');
  const { putComment, commentLoading, setIsEditing, isEditing } = useComment(
    setComments,
    setIsInputVisible,
  );

  const handleToggleInput = () => {
    if (renderInput) {
      setRenderInput(false);
    } else {
      setIsInputVisible(true);
      setRenderInput(true);
    }
  };

  useEffect(() => {
    if (!renderInput) {
      const timer = setTimeout(() => setIsInputVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [renderInput]);

  const buttonList: IListButton[] = [
    onPin && {
      name: pinned ? 'Unpin' : 'Pin',
      onClick: async () => {
        setLoading(true);
        setShowMenu(null);
        onPin && (await onPin());
        setLoading(false);
      },
      icon: PushPinIcon,
    },
    {
      name: 'Edit',
      onClick:
        type !== 'comment'
          ? () => {
              setShowMenu(null);
              setShowEdit(true);
            }
          : () => {
              setInputText(body);
              setShowMenu(null);
              setRenderInput(true);
              setIsEditing(true);
            },
      icon: EditIcon,
    },
    onDelete && {
      name: 'Delete',
      onClick: async () => {
        setLoading(true);
        setShowMenu(null);
        onDelete && (await onDelete());
        setLoading(false);
      },
      icon: DeleteIcon,
      textColor: theme.palette.error.main,
    },
  ].filter(Boolean) as IListButton[];

  return (
    <>
      <Box
        sx={{
          padding: '0.5rem 0.5rem 0.5rem 0',
          position: 'relative',
          // borderBottom: `1px solid ${theme.palette.divider}`,
        }}>
        <Box sx={{ margin: '0 1rem', paddingBottom: '0.75rem' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                width: '104.2%',
                height: '120%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                top: 0,
                left: 0,
                zIndex: 4,
                marginTop: '-1.563rem',
              }}>
              <Loading />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <Box
            sx={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => navigate(`members/${creatorId}`)}>
            <Box
              sx={{
                marginRight: '1rem',
              }}>
              <img
                style={{
                  width: '2.5rem',
                  height: '2.5rem',
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
                src={picUrl}
                alt={`${creatorName}'s profile picture`}
              />
            </Box>
            <FlexBox
              sx={{
                flexDirection: 'column',
                alignItems: 'start',
              }}>
              <FlexBox
                sx={{
                  marginRight: '1.5rem',
                  alignItems: 'start',
                  gap: '0.625rem',
                }}>
                <Typography variant={'h6'}>{creatorName}</Typography>
                <AccessLevelTagCard
                  sx={{
                    marginTop: '0.188rem',
                  }}
                  accessLevelName={role}
                  accessLevelColor={accessLevelColor}
                  height={'1.3rem'}
                />
              </FlexBox>
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 700,
                  lineHeight: '1.313rem',
                  color: theme.palette.primary.main,
                }}>
                {role}
              </Typography>
            </FlexBox>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: -10,
            top: -5,
            marginRight: '15px',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
          }}>
          {pinned && (
            <PushPinIcon
              sx={{
                color: theme.palette.primary.main,
                transform: 'rotate(30deg)',
                marginRight: '1rem',
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginY: '0.5rem',
              marginRight:
                type === 'comment' && userId !== creatorId ? '3.25rem' : '',
            }}>
            <Typography
              variant={'body2'}
              sx={{
                fontSize: '0.9rem',
                color: '#333333',
                display: 'flex',
                fontWeight: '400',
                alignItem: 'right',
                lineHeight: '1.313rem',
                margin: '0.5rem 0 0.5rem 0',
              }}>
              {timestamp}
            </Typography>
          </Box>
          {(canUserEdit || (type === 'comment' && userId === creatorId)) && (
            <MoreOptionsOverlay
              popoverAnchorEl={showMenu}
              setPopoverAnchorEl={setShowMenu}>
              <MoreOptionsButtons buttonList={buttonList} />
            </MoreOptionsOverlay>
          )}
        </Box>
        <Box>{title && <Typography variant={'h5'}>{title}</Typography>}</Box>
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft: '3rem',
          }}>
          {isEditing ? (
            <CommentInput
              inputText={inputText}
              renderInput={renderInput}
              setInputText={setInputText}
              commentLoading={commentLoading}
              onClick={() =>
                putComment(announcementId, inputText, setInputText, id)
              }
            />
          ) : (
            <RichTextDisplay
              style={{
                paddingBottom: 0,
              }}
              text={decodeURIComponent(body.replace(/%/g, '%25'))}
            />
          )}
          {imageUrl && (
            <Box
              sx={{
                padding: '0.75rem 0.938rem',
                borderRadius: '10px',
                width: '100%',
                height: { xs: '12.688rem', md: '16.375rem' },
              }}>
              <img
                src={imageUrl}
                alt='Image'
                style={{
                  borderRadius: '10px',
                  width: '95%',
                  height: '100%',
                }}
              />
            </Box>
          )}
          <FlexBox
            sx={{
              marginTop: '0.625rem',
              marginLeft: '1rem',
            }}>
            {type !== 'comment' && (
              <Button
                onClick={handleToggleInput}
                sx={{
                  minWidth: '3.5rem',
                  height: '2.438rem',
                  backgroundColor: theme.palette.primary.contrastText,
                }}>
                <FlexBox
                  sx={{
                    minWidth: '1.5rem',
                  }}>
                  <CommentIcon color={theme.palette.primary.main} />
                </FlexBox>
              </Button>
            )}
          </FlexBox>
          {isInputVisible && (
            <CommentInput
              inputText={inputText}
              setInputText={setInputText}
              renderInput={renderInput}
              commentLoading={commentLoading}
              onClick={() =>
                putComment(announcementId, inputText, setInputText, id)
              }
            />
          )}
        </FlexBox>
        {canUserEdit && (
          <EditAnnouncement
            open={showEdit}
            close={() => setShowEdit(false)}
            id={id}
            loading={loading}
            title={title || ''}
            body={body}
            imageUrl={imageUrl}
            editAnnouncement={editAnnouncement}
            accessLevelIds={accessLevelIds}
          />
        )}
      </Box>
    </>
  );
};

export default PostItem;
