import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import Room from './Room';
import { BOOKING_ROOMS, getData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { IRoom } from 'utils/models';
import Loading from 'components/common/Loading';
import InputField from 'components/common/InputField';
import { adjustResponsiveness, filterCollectionAndReturn } from 'utils/utils';

const RoomBooking = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const filterOnField = ['title', 'description', 'location'];

  useEffect(() => {
    fetchRooms();
  }, []);

  function searchFilter(roomArr: IRoom[]) {
    if (searchQuery === '') return roomArr;

    return filterCollectionAndReturn(roomArr, filterOnField, searchQuery);
  }

  async function fetchRooms() {
    setIsLoading(true);

    try {
      const data = await getData(BOOKING_ROOMS, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setRooms(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderHeaderAndSearch() {
    return (
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div />
        <Box
          sx={{
            width: adjustResponsiveness(420, 360, 280, 160),
            marginRight: '1.2rem',
          }}>
          <InputField
            placeholder='Search for rooms'
            value={searchQuery}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchQuery(event.target.value)
            }
            basicIcon={'search'}
            iconPosition={'start'}
            clearBackground
          />
        </Box>
        {/*  <SelectDropdown
          value={'Filter by availability'}
          onChange={() => console.log('filtered')}
          options={filterOptions}
          height={40}
          placeholder={'Filter by availability'}
          sx={{ maxWidth: '14.125rem', mx: '1rem' }}
        /> */}
      </Box>
    );
  }

  function renderRooms() {
    const filteredRooms = searchFilter(
      rooms.filter((room) => room.isActive === true),
    );

    if (filteredRooms.length === 0) {
      return (
        <Typography variant={'h5'} marginTop={'2rem'}>
          There are no rooms available for booking
        </Typography>
      );
    }

    return (
      <Box sx={{ width: '100%', marginTop: '2rem' }}>
        <Grid container spacing={4} sx={{ padding: '0 1.2rem' }}>
          {filteredRooms.map((room, index) => (
            <Grid key={`${room.title}-${index}`} item xs={12} md={6} lg={4}>
              <Room room={room} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      width={'100%'}
      sx={{
        maxWidth: 'calc(100vw - 20rem)',
        width: '100%',
      }}>
      {renderHeaderAndSearch()}
      {isLoading ? <Loading /> : renderRooms()}
    </Box>
  );
};

export default RoomBooking;
