import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Card, Typography, useTheme } from '@mui/material';
import { primary } from 'theme';
import { IconPicker } from '../../common/IconPicker';
import { CompanyTemplateTagGroup } from './components/CompanyTemplateTagGroup';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
  ICompanyProfileField,
} from 'utils/models';
import { AddCircle } from '@mui/icons-material';
import { useCompanyTemplateContext } from './CompanyTemplateContext';
import { CompanyTemplateAddHeaderModal } from './components/CompanyTemplateAddHeaderModal';

export const CompanyTemplateHeaderSection = () => {
  const theme = useTheme();
  const {
    companyTemplate,
    addSectionField,
    setSectionField,
    removeSectionField,
  } = useCompanyTemplateContext();

  const [isSectionFieldsAdded, setSectionFieldsAdded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialHeaderSectionFields: ICompanyProfileField[] = [
    {
      name: 'City, Country',
      type: CompanyProfileFieldType.TEXT,
      icon: 'LocationOnRounded',
      required: true,
    },
    {
      name: 'Industry',
      type: CompanyProfileFieldType.TEXT,
      icon: 'WorkRounded',
      required: true,
    },
  ];

  useEffect(() => {
    if (!companyTemplate) return;

    if (companyTemplate.header.length === 0 && !isSectionFieldsAdded) {
      initialHeaderSectionFields.forEach((field) => {
        addSectionField(CompanyTemplateSectionType.HEADER, field);
      });
    }
    setSectionFieldsAdded(true);
  }, [companyTemplate]);

  function handleOpenModal() {
    setIsModalOpen(true);
    setSectionField({
      name: '',
      type: CompanyProfileFieldType.TEXT,
      required: false,
      icon: 'InfoRounded',
    });
  }

  function renderSectionFields() {
    const initialFields = (
      <>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            paddingRight: '0.5rem',
            borderRadius: '4px',
          }}>
          <IconPicker
            name='LocationOnRounded'
            sx={{ color: theme.palette.primary.main }}
          />
          <Typography variant={'h6'} sx={{ textWrap: 'nowrap' }}>
            City, Country
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            paddingRight: '0.5rem',
            borderRadius: '4px',
          }}>
          <IconPicker
            name='WorkRounded'
            sx={{ color: theme.palette.primary.main }}
          />
          <Typography variant={'h6'} sx={{ textWrap: 'nowrap' }}>
            Industry
          </Typography>
        </Box>
      </>
    );
    const newFields = companyTemplate?.header
      .filter(
        (headerItem) =>
          headerItem.name !== 'Tag group' &&
          headerItem.name !== 'City, Country' &&
          headerItem.name !== 'Industry',
      )
      .map((headerItem, index) => {
        return (
          <Box
            key={index}
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
              paddingRight: '0.5rem',
              border: `1px solid ${primary.natural6}`,
              borderRadius: '4px',
            }}>
            <Box
              onClick={() =>
                removeSectionField(
                  CompanyTemplateSectionType.HEADER,
                  headerItem.name,
                )
              }>
              <IconPicker
                name='Cancel'
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  transform: 'translate(50%, -50%)',
                  width: '1rem',
                  height: '1rem',
                  cursor: 'pointer',
                  color: primary.natural6,
                  backgroundColor: 'white',
                }}
              />
            </Box>
            <IconPicker
              name={headerItem.icon}
              sx={{ color: theme.palette.primary.main }}
            />
            <Typography variant={'h6'} sx={{ textWrap: 'nowrap' }}>
              {headerItem.name}
            </Typography>
          </Box>
        );
      });

    return [initialFields, newFields];
  }

  return (
    <Card
      sx={{
        width: '100%',
        backgroundColor: primary.pureWhite,
        padding: '1.25rem',
        height: 'auto',
        overflow: 'visible',
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: '1.25rem ',
          paddingBottom: '0.625rem',
        }}>
        <Typography variant={'h3'}>Company name*</Typography>
        <CompanyTemplateTagGroup />
      </Box>
      <Typography sx={{ fontSize: '1rem', paddingBottom: '1.25rem' }}>
        Slogan or motto*
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '1.5rem',
          }}>
          {renderSectionFields()}
          <Box
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}>
            <CommonButton
              onClick={() => handleOpenModal()}
              icon={<AddCircle />}
              text={'Add field'}
              variant={ButtonVariant.BLANK}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}>
          <CompanyTemplateAddHeaderModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </Box>
      </Box>
    </Card>
  );
};
