import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography, useTheme } from '@mui/material';
import LineChart from 'components/Charts/LineChart';
import CompanySection from '../CompanySection';
import Modal from 'components/common/Modal';
import CompanyEditKPI from '../EditCompany/CompanyEditKPI';
import Button from '@mui/material/Button';
import InsightsIcon from '@mui/icons-material/Insights';
import EditIcon from '@mui/icons-material/Edit';
import { ICompanyKPI } from 'utils/models';
import {
  COMPANY_DELETE_KPI_ROUTE,
  COMPANY_SAVE_KPI_ROUTE,
  deleteData,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '12.5px',
  },
  editBtn: {
    height: '27.5px !important',
    width: '27.5px !important',
    minWidth: '27.5px !important',
    padding: '4px !important',
    backgroundColor: `${theme.palette.primary.light} !important`,
    marginBottom: '-3px !important',
    marginRight: '-10px !important',

    '& svg': {
      height: '18px',
      width: '18px',
      color: theme.palette.primary.dark,
    },
  },
  addBtn: {
    marginBottom: '5px !important',
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '4px !important',
    color: `${theme.palette.primary.dark} !important`,
    padding: '5px 12px !important',

    '& h6': {
      fontSize: '0.7rem !important',
      lineHeight: '1rem !important',
      fontWeight: 800,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2px !important',

      '& svg': {
        height: '20px',
        width: '20px',
        marginRight: '7.5px',
      },
    },
  },
}));

interface ICompanyKPIProps {
  keyPerformanceIndicators: ICompanyKPI[];
  companyName: string;
  updateKPIs: (kpis: ICompanyKPI[]) => void;
  canEditCompanyProfile?: boolean;
}

const CompanyKPI: FunctionComponent<ICompanyKPIProps> = (props) => {
  const {
    keyPerformanceIndicators,
    companyName,
    updateKPIs,
    canEditCompanyProfile,
  } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [KPIs, setKPIs] = useState<ICompanyKPI[]>(keyPerformanceIndicators);
  const [currentKPI, setCurrentKPI] = useState<ICompanyKPI | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setKPIs(keyPerformanceIndicators);
  }, [keyPerformanceIndicators]);

  function initNewKPI(): ICompanyKPI {
    return {
      name: '',
      unit: '',
      keyPerformanceIndicatorEntry: [],
      showGoals: false,
      newKpi: true,
    };
  }

  async function saveKPI() {
    setLoading(true);

    try {
      const data = await putData(COMPANY_SAVE_KPI_ROUTE, [
        {
          name: 'jsonKPI',
          value: encodeURIComponent(JSON.stringify(currentKPI)),
        },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'KPI Saved',
      });

      if (currentKPI?.newKpi) {
        updateKPIs([data, ...KPIs]);
      } else {
        updateKPIs(KPIs.map((kpi) => (kpi.id === data.id ? data : kpi)));
      }
      setCurrentKPI(null);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function deleteKPI() {
    setLoading(true);

    try {
      await deleteData(COMPANY_DELETE_KPI_ROUTE, [
        {
          name: 'kpiId',
          value: currentKPI?.id,
        },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'KPI deleted',
      });

      updateKPIs(KPIs.filter((kpi) => kpi.id !== currentKPI?.id));
      setCurrentKPI(null);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderCharts() {
    return KPIs.map((kpi, index) => (
      <Fade key={index} in={true} timeout={1400}>
        <div className={classes.root}>
          <CompanySection
            title={kpi.name || 'Unnamed KPI'}
            simple={true}
            headerAction={renderEditKPIBtn(kpi)}>
            <LineChart
              name={kpi.name}
              unit={kpi.unit}
              showGoals={kpi.showGoals}
              keyPerformanceIndicatorEntry={kpi.keyPerformanceIndicatorEntry}
            />
          </CompanySection>
        </div>
      </Fade>
    ));
  }

  function renderEditKPIBtn(kpi: ICompanyKPI) {
    if (!canEditCompanyProfile) return undefined;
    return (
      <Fade in={true} timeout={1000}>
        <Button className={classes.editBtn} onClick={() => setCurrentKPI(kpi)}>
          <EditIcon />
        </Button>
      </Fade>
    );
  }

  function renderAddNewKPIBtn() {
    return (
      <Fade in={true} timeout={1000}>
        <Button
          className={classes.addBtn}
          onClick={() => setCurrentKPI(initNewKPI())}>
          <Typography variant={'h6'}>
            <InsightsIcon /> Add new KPI
          </Typography>
        </Button>
      </Fade>
    );
  }

  return (
    <>
      {canEditCompanyProfile && renderAddNewKPIBtn()}
      <div>{renderCharts()}</div>
      <Modal
        open={!!currentKPI}
        close={() => setCurrentKPI(null)}
        title={!!currentKPI && currentKPI.newKpi ? 'New KPI' : 'Edit KPI'}
        color={theme.palette.primary.dark}
        showFooter={true}
        width={870}
        largeFooter={true}
        loading={loading}
        disabledSave={
          JSON.stringify(currentKPI) ===
            JSON.stringify(KPIs.find((kpi) => kpi.id === currentKPI?.id)) ||
          currentKPI?.keyPerformanceIndicatorEntry.some(
            (entry) => entry.zonedDateTime === null,
          )
        }
        saveAction={saveKPI}
        footerRemoveAction={deleteKPI}
        footerRemoveText={'Remove KPI'}>
        <div style={{ minHeight: '410px' }}>
          {!!currentKPI && (
            <CompanyEditKPI kpi={currentKPI} setEditedKPI={setCurrentKPI} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CompanyKPI;
