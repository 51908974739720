import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, useTheme } from '@mui/material';
import Modal from '../common/Modal';
import {
  deleteData,
  getData,
  SUPER_ADMIN_BUG_REPORTS,
  SUPER_ADMIN_DELETE_BUG_REPORT,
} from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { IBugReport } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& td': {
      padding: '0 15px',
      textAlign: 'center',
    },
  },
}));

interface IBugReports {
  showBugReports: boolean;
  close: () => void;
}

const allEcosystemsOption = 'All Ecosystems';

const BugReports: FunctionComponent<IBugReports> = (props) => {
  const { showBugReports, close } = props;
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [bugReports, setBugReports] = useState<IBugReport[]>([]);

  useEffect(() => {
    fetchBugReports();
  }, []);

  function removeBugReport(bugReport: IBugReport) {
    const index = bugReports.indexOf(bugReport, 0);
    setBugReports((oldArray) => {
      return oldArray.filter((value, i) => i !== index);
    });
  }

  async function fetchBugReports() {
    try {
      const data = await getData(SUPER_ADMIN_BUG_REPORTS);
      setBugReports(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function deleteBugReport(bugReport: IBugReport) {
    setLoading(true);
    try {
      const data = await deleteData(SUPER_ADMIN_DELETE_BUG_REPORT, [
        { name: 'id', value: bugReport.id },
      ]);
      removeBugReport(bugReport);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function formatData(timestamp: string) {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  }

  function renderMetricContent() {
    return (
      <table className={classes.root}>
        <thead>
          <tr>
            <th>Report</th>
            <th>Submited By</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {bugReports.length &&
            bugReports.map((bugReport: IBugReport, index: number) => {
              return (
                <tr key={index}>
                  <td>{bugReport.report}</td>
                  <td>{bugReport.submittedBy}</td>
                  <td>{formatData(bugReport.timestamp)}</td>
                  <td>
                    <a
                      href={bugReport.imageUrl}
                      target='_blank'
                      rel='noopener noreferrer'>
                      image
                    </a>
                  </td>
                  <td>
                    <Button
                      disabled={loading}
                      onClick={() => deleteBugReport(bugReport)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }

  return (
    <Modal
      open={showBugReports}
      close={close}
      width={1300}
      isActerioTheme={true}
      title={'Bug Reports'}
      largeHeader={true}
      backgroundColor={'white'}
      headerColor={theme.palette.primary.light}
      loading={loading}>
      {renderMetricContent()}
    </Modal>
  );
};

export default BugReports;
