import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FlexBox } from '../../utils/styledComponents';
import { ControlButtonStyled } from '../Admin/Applications/ApplicationBuilder';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { colorPallete, naturalPallete } from '../../theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormForSend } from '../Admin/Applications/FormWithHook';
import {
  Controller,
  FieldError,
  Path,
  UseFormClearErrors,
  UseFormSetError,
  useWatch,
} from 'react-hook-form';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';

const acceptableFileFormats = {
  'application/pdf': [],
  'text/plain': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
};

const baseStyle: CSSProperties = {
  flexDirection: 'column',
  width: '28rem',
  height: '7.5rem',
  borderWidth: '2px',
  borderStyle: 'dashed',
  borderColor: naturalPallete.natural4,
  borderRadius: '8px',
  padding: '4.2rem 1.344rem',
  cursor: 'pointer',
};

const activeStyle: CSSProperties = {
  borderColor: colorPallete.additionalDeepBlue,
};

const rejectStyle: CSSProperties = {
  borderColor: colorPallete.warningRed,
};

interface ApplicationDropZoneProps {
  sectionId: number;
  questionId: number;
  control: Control<FormForSend>;
  setValue: UseFormSetValue<FormForSend>;
  answerPath: string;
  setError: UseFormSetError<FormForSend>;
  clearErrors: UseFormClearErrors<FormForSend>;
  isTriggerClearFiles?: boolean;
}

const ApplicationDropZone: FunctionComponent<ApplicationDropZoneProps> = (
  props,
) => {
  const {
    sectionId,
    questionId,
    control,
    setValue,
    answerPath,
    setError,
    clearErrors,
    isTriggerClearFiles,
  } = props;

  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {},
  );

  useEffect(() => {
    if (!isTriggerClearFiles) return;
    removeFile(sectionId, questionId);
  }, [isTriggerClearFiles]);

  const fileAnswerPath = `${answerPath}.file.name` as keyof FormForSend;
  const currentErrorMessage = errorMessages[`${sectionId}-${questionId}`] || '';

  const files =
    useWatch({
      control,
      name: 'files',
      defaultValue: [],
    }) || [];

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const error = fileRejections[0].errors[0];
      const errorMessage =
        error.code === 'file-too-large'
          ? 'File size is too large. File was not uploaded.'
          : 'Invalid file format. File was not uploaded.';

      setErrorMessages((prev) => ({
        ...prev,
        [`${sectionId}-${questionId}`]: errorMessage,
      }));
      setError(fileAnswerPath, { type: 'manual', message: errorMessage });
      return;
    }

    setErrorMessages((prev) => ({
      ...prev,
      [`${sectionId}-${questionId}`]: '',
    }));

    const newFile = {
      sectionId,
      questionId,
      file: acceptedFiles[0],
    };

    const updatedFiles = [...files, newFile];
    setValue('files', updatedFiles);
    setValue(fileAnswerPath as Path<FormForSend>, newFile.file.name);
    clearErrors(fileAnswerPath);
  };

  const removeFile = (
    sectionId: number | undefined,
    questionId: number | undefined,
  ) => {
    const updatedFiles = files.filter(
      (file) => file.sectionId !== sectionId || file.questionId !== questionId,
    );
    setValue('files', updatedFiles);
    setError(fileAnswerPath, {
      type: 'manual',
      message: 'Please, upload a file',
    });
  };

  const uploadedFile =
    files.find(
      (file) => file.sectionId === sectionId && file.questionId === questionId,
    )?.file || null;

  const getStyle = (
    isDragActive: boolean,
    isDragAccept: boolean,
    isDragReject: boolean,
    isFileDialogActive: boolean,
    errorMessage: string,
    uploadedFile: File | null,
    fieldError: FieldError | undefined,
  ) => {
    return {
      ...baseStyle,
      ...(isDragActive || isFileDialogActive || isDragAccept || uploadedFile
        ? activeStyle
        : {}),
      ...(isDragReject || errorMessage || fieldError ? rejectStyle : {}),
    };
  };

  return (
    <Controller
      name={fileAnswerPath}
      control={control}
      rules={{ required: 'Please, upload a file' }}
      render={({ field, fieldState }) => (
        <Dropzone
          accept={acceptableFileFormats}
          onDrop={onDrop}
          maxFiles={1}
          maxSize={50 * 1024 * 1024}>
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
            isFileDialogActive,
          }) => {
            return (
              <section>
                <FlexBox
                  className={'center'}
                  {...getRootProps({
                    style: getStyle(
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                      isFileDialogActive,
                      currentErrorMessage,
                      uploadedFile,
                      fieldState.error,
                    ),
                  })}>
                  <input {...getInputProps()} />
                  {uploadedFile ? (
                    <FlexBox
                      sx={{
                        flexDirection: 'column',
                      }}>
                      <ControlButtonStyled
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFile(sectionId, questionId);
                        }}>
                        <CloseIcon />
                      </ControlButtonStyled>
                      <Typography variant={'h6'}>File uploaded</Typography>
                      <Typography>
                        {(uploadedFile.size / 1024).toFixed(2)} KB
                      </Typography>
                    </FlexBox>
                  ) : (
                    <FlexBox
                      sx={{
                        flexDirection: 'column',
                        gap: '0.625rem',
                      }}>
                      <FlexBox sx={{ gap: '0.4rem' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                          }}>
                          Choose file
                        </Typography>{' '}
                        <Typography>or drag it here</Typography>
                      </FlexBox>
                      <FlexBox
                        sx={{
                          flexDirection: 'column',
                        }}>
                        <Typography>
                          Supports PDF, TXT, PP, DOC, JPG, GIF or PNG
                        </Typography>
                        <Typography>(max size 50MB)</Typography>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
                {(currentErrorMessage || fieldState.error) && (
                  <FlexBox
                    sx={{
                      color: colorPallete.warningRed,
                      marginTop: '0.3rem',
                      gap: '0.3rem',
                    }}>
                    <ErrorOutlineIcon />
                    <Typography sx={{ color: colorPallete.warningRed }}>
                      {currentErrorMessage || fieldState.error?.message}
                    </Typography>
                  </FlexBox>
                )}
              </section>
            );
          }}
        </Dropzone>
      )}
    />
  );
};

export default ApplicationDropZone;
