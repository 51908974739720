import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { ICompanyPeople } from 'utils/models';
import { FlexBox } from 'utils/styledComponents';

interface IPeopleInTheBoxDisplay {
  people: ICompanyPeople[];
  numberToDisplay?: number;
}

const PeopleInTheBoxDisplay: FunctionComponent<IPeopleInTheBoxDisplay> = (
  props,
) => {
  const { people, numberToDisplay = 3 } = props;

  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  const { editProfile } = useCompanyContext();

  const peopleToDisplay = people.slice(0, numberToDisplay);

  const noNavigateAway = !!editProfile;

  function navigateToMemberProfile(id: number) {
    if (!id || noNavigateAway) return;
    navigate(`/ecosystem/${ecosystemName}/members/${id}`);
  }

  return (
    <>
      {peopleToDisplay.map((people) => (
        <Grid
          item
          key={people.id}
          onClick={() => navigateToMemberProfile(people.id)}>
          <FlexBox sx={{ mb: '1rem' }}>
            <Box sx={{ mr: '1rem', height: '3rem' }}>
              <img
                src={people.profilePic}
                style={{
                  height: '3rem',
                  width: '3rem',
                  borderRadius: '50%',
                  cursor: noNavigateAway ? 'default' : 'pointer',
                }}
              />
            </Box>
            <Box sx={{ justifyItems: 'flex-start', width: '100%' }}>
              <FlexBox
                sx={{
                  justifyContent: 'space-between',
                }}>
                <Typography variant={'h6'}>{people.fullName}</Typography>
                <AccessLevelTagCard
                  accessLevelName={people.accessLevelName || 'Default'}
                  accessLevelColor={people.accessLevelColor}
                  height={'1.3rem'}
                />
              </FlexBox>
              <Typography variant={'body1'}>
                {people.subtitle || people.role}
              </Typography>
            </Box>
          </FlexBox>
        </Grid>
      ))}
    </>
  );
};

export default PeopleInTheBoxDisplay;
