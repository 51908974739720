import React, { FunctionComponent } from 'react';
import { Box, ButtonBase, Card, Typography } from '@mui/material';
import { useTagGroupContext } from './TagGroupContext';
import TagGroup from './TagGroup';

import { AddCircle } from '@mui/icons-material';
import { FlexBox } from 'utils/styledComponents';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';

interface IEcosystemTags {}

const EcosystemTags: FunctionComponent<IEcosystemTags> = (props) => {
  const {
    tagGroups,
    createEmptyTagGroup,
    loading,
    saveTagGroups,
    revertTagGroups,
    handleCheckboxChange,
  } = useTagGroupContext();

  function renderTagGroups() {
    return tagGroups.map((tagGroup, i) => {
      return (
        <TagGroup
          tagGroup={tagGroup}
          key={tagGroup.name + '-' + i}
          allowCustomTags={tagGroup.canAddCustomTags}
          onCheckboxChange={(name, newValue) =>
            handleCheckboxChange(name, newValue)
          }
        />
      );
    });
  }

  return (
    <Box>
      <Typography variant={'h2'} sx={{ mb: '2rem' }}>
        Tags
      </Typography>
      <Typography variant={'body2'} sx={{ mb: '2rem' }}>
        Here you can create different tag groups, add or remove tags in existing
        tag groups.
      </Typography>
      <Card sx={{ borderRadius: '18px', p: '2.5rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            mb: tagGroups.length > 0 ? '2rem' : '0rem',
          }}>
          {renderTagGroups()}
        </Box>
        <FlexBox sx={{ justifyContent: 'space-between' }}>
          <Box>
            <ButtonBase
              onClick={() => createEmptyTagGroup()}
              disabled={loading}>
              <FlexBox sx={{ gap: '0.5rem' }}>
                <AddCircle />
                <Typography variant={'body2'} sx={{ mt: '0.1rem' }}>
                  Add new tag group
                </Typography>
              </FlexBox>
            </ButtonBase>
          </Box>
          <FlexBox sx={{ gap: '0.5rem' }}>
            <CommonButton
              text={'Revert Changes'}
              onClick={() => revertTagGroups()}
              isLoading={loading}
              variant={ButtonVariant.BLANK}
            />
            <CommonButton
              text={'Save'}
              onClick={() => saveTagGroups()}
              isLoading={loading}
            />
          </FlexBox>
        </FlexBox>
      </Card>
    </Box>
  );
};

export default EcosystemTags;
