import useCompanies from 'hooks/Companies/useCompanies';
import useMembers from 'hooks/Members/useMembers';
import { useEffect, useState } from 'react';
import { ICompany, IPeople, ITag } from 'utils/models';
import {
  filterCollectionTwiceWithIntersection,
  filterIntersectionOnListField,
  isCompany,
  isPeople,
} from 'utils/utils';

const useCommunity = (isMembersPage: boolean) => {
  const isMembers = isMembersPage;
  const { loading: membersLoading, members } = useMembers();
  const { loading: companiesLoading, companies } = useCompanies();

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [availableTypes, setAvailableTypes] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [availableCompanies, setAvailableCompanies] = useState<string[]>([]);

  const isMembersLoading = membersLoading && isMembers;
  const isCompaniesLoading = companiesLoading && !isMembers;

  const isLoading =
    (isMembersLoading && isMembers) || (isCompaniesLoading && !isMembers);

  const [community, setCommunity] = useState<IPeople[] | ICompany[] | null>(
    null,
  );

  const [filteredCommunity, setFilteredCommunity] = useState<
    IPeople[] | ICompany[] | null
  >(null);

  const didMount =
    (!!filteredCommunity && isMembers && isPeople(filteredCommunity[0])) ||
    (!!filteredCommunity && !isMembers && isCompany(filteredCommunity[0]));

  useEffect(() => {
    if (!community) return;
    const deepCopy = JSON.parse(JSON.stringify(community));

    if (!selectedTags || !selectedType) {
      setFilteredCommunity(deepCopy);
    }
    if (selectedType || selectedTags) {
      if (isMembers && selectedCompany) {
        const updatedFilteredCommunity = filterIntersectionOnListField(
          deepCopy,
          'companiesNameList',
          [selectedCompany],
        );
        setFilteredCommunity(updatedFilteredCommunity);
      } else {
        const updatedFilteredCommunity = filterCollectionTwiceWithIntersection(
          deepCopy,
          isMembers ? 'accessLevelName' : 'templateName',
          'usedTags',
          selectedType,
          selectedTags,
        );
        setFilteredCommunity(updatedFilteredCommunity);
        setAvailableTags(createSetOfTags(updatedFilteredCommunity));
      }
    }
  }, [community, selectedTags, selectedType, selectedCompany, didMount]);

  useEffect(() => {
    setSelectedType('');
    setSelectedTags([]);
    setSelectedCompany('');
    if (isMembers) {
      if (!members) return;
      setCommunity(members);
      setAvailableTypes(createSetOfTypes(members));
      setAvailableTags(createSetOfTags(members));
      setAvailableCompanies(createSetOfCompanies(members));
    }
    if (!isMembers) {
      if (!companies) return;
      setCommunity(companies);
      setAvailableTypes(createSetOfTypes(companies));
      setAvailableTags(createSetOfTags(companies));
    }
  }, [isMembers, members, companies]);

  function createSetOfTypes(communityList: IPeople[] | ICompany[]): string[] {
    const setOfTypes = new Set<string>();

    communityList.forEach((communityItem) => {
      if (isPeople(communityItem)) {
        setOfTypes.add(communityItem.accessLevelName);
      } else if (isCompany(communityItem)) {
        setOfTypes.add(communityItem.label);
      }
    });

    return Array.from(setOfTypes);
  }

  function createSetOfTags(communityList: IPeople[] | ICompany[]): string[] {
    const setOfTags = new Set<string>();
    communityList.forEach((communityItem) => {
      if (!communityItem.usedTags) return;
      communityItem.usedTags.forEach((tag: ITag) => setOfTags.add(tag.name));
    });
    return Array.from(setOfTags);
  }

  function createSetOfCompanies(peopleList: IPeople[]): string[] {
    const setOfCompanies = new Set<string>();
    peopleList.forEach((item) => {
      if (!item.companiesNameList) return;
      item.companiesNameList.forEach((name: string) =>
        setOfCompanies.add(name),
      );
    });
    return Array.from(setOfCompanies);
  }

  return {
    filteredCommunity,
    didMount,
    availableTypes,
    availableTags,
    selectedType,
    selectedTags,
    isMembersLoading,
    isCompaniesLoading,
    isLoading,
    community,
    selectedCompany,
    availableCompanies,
    setFilteredCommunity,
    setCommunity,
    setSelectedType,
    setSelectedTags,
    setSelectedCompany,
  };
};

export default useCommunity;
