import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { previewText } from 'utils/utils';
import { useAssignJourneysContext } from './AssignJourneys/AssignJourneysContext';
import { CheckCircle } from '@mui/icons-material';
import { IJourney } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: (props: any) => (props.selectable ? '18.75rem' : '285px'),
    maxWidth: (props: any) => (props.selectable ? '175px' : '11.875rem'),
    transform: (props: any) => (props.selectable ? 'scale(0.8)' : 'none'),
    marginTop: (props: any) => (props.selectable ? '-3rem' : '0'),

    '& h6, p': {
      userSelect: 'none',
    },
  },
  box: {
    minHeight: '225px',
    maxHeight: '225px',
    width: '11.875rem',
    border: (props: any) =>
      props.isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : '2px solid #E0E0E0',
    borderRadius: '6px',
    backgroundColor: '#eee',
    padding: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '0.6rem',
    marginBottom: '0.75rem',
    cursor: 'pointer',
    transition: '0.3s ease-in-out all',
    position: 'relative',

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  stage: {
    padding: '0.3rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    width: '100%',
    height: '2.5rem',
    borderRadius: '6px',
    color: '#757575',
    userSelect: 'none',
  },
  totalStages: {
    color: '#757575',
    fontSize: '18px',
    marginTop: '5px',
    userSelect: 'none',
  },
  selectable: {
    position: 'absolute',
    bottom: '2.5px',
    right: '12px',

    '& svg': {
      height: '32px',
      width: '32px',
      color: theme.palette.primary.main,
    },
  },
}));

const MAX_STAGES_TO_SHOW = 3;

interface IJourneyPreview {
  journey: IJourney;
  selectable?: boolean;
}

const JourneyPreview: FunctionComponent<IJourneyPreview> = (props) => {
  const { journey, selectable } = props;
  const { id, name, stages } = journey;
  const { isSelectedJourney, addJourneyId, removeJourneyId } =
    useAssignJourneysContext();
  const navigate = useNavigate();
  const isSelected = selectable && !!id && isSelectedJourney(id);
  const classes = useStyles({ selectable, isSelected });

  function renderStages() {
    return stages
      .slice(0, MAX_STAGES_TO_SHOW)
      .map((stage: { name: string }, i: any) => (
        <div key={`${id}-stage-${i}`} className={classes.stage}>
          {previewText(stage.name, 14)}
        </div>
      ));
  }

  function renderTotalStages() {
    if (stages.length <= MAX_STAGES_TO_SHOW) return null;
    return `+${stages.length - MAX_STAGES_TO_SHOW} more`;
  }

  function handleOnClick() {
    if (!selectable) return navigate(`${id}`);
    if (!id) return;

    return isSelected ? removeJourneyId() : addJourneyId(id);
  }

  function renderSelected() {
    if (!selectable) return null;

    return (
      <div className={classes.selectable}>
        <Fade in={isSelected} timeout={300}>
          <CheckCircle />
        </Fade>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.box} onClick={handleOnClick}>
          {renderSelected()}
          {renderStages()}
          <div className={classes.totalStages}>{renderTotalStages()}</div>
        </div>
        <Typography
          variant={'h6'}
          fontSize={selectable ? 16 : 18}
          textAlign={'center'}>
          {previewText(name, 25)}
        </Typography>
      </div>
    </div>
  );
};

export default JourneyPreview;
