import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { APPLICATION_STOP_ASSESSMENT, patchData } from 'utils/requests';

interface IStopAssessmentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  statusId: number;
  setIsAssessmentOn: Dispatch<SetStateAction<boolean>>;
}
const StopAssessmentModal: FunctionComponent<IStopAssessmentModalProps> = (
  props,
) => {
  const { isOpen, handleClose, statusId, setIsAssessmentOn } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function stopAssessment() {
    setIsLoading(true);

    try {
      const data = await patchData(APPLICATION_STOP_ASSESSMENT, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'statusId', value: statusId },
      ]);

      setIsAssessmentOn(false);
      handleClose();
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Assessment stopped',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText='Stop assessment process'
      primaryText='Stop'
      primaryOnClick={stopAssessment}
      secondaryText='Cancel'
      secondaryOnClick={handleClose}
      headerSize='1.875rem'
      width='36rem'
      buttonSx={{
        '&:hover': {
          color: '#13253E',
          backgroundColor: '#EBF1F9',
        },
      }}>
      {isLoading ? (
        <Loading height={200} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: '0.75rem' }}>
            Are you sure you want to stop the assessment process?
          </Typography>
          <Typography sx={{ fontWeight: 'bold', mb: '0.75rem' }}>
            Please note:
          </Typography>
          <Typography sx={{ fontWeight: 'bold', mb: '0.25rem' }}>
            {' '}
            - All current evaluation data will be saved.
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {' '}
            - No new evaluations can be submitted.
          </Typography>
        </Box>
      )}
    </InformationModal>
  );
};

export default StopAssessmentModal;
