import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import InputField from '../common/InputField';
import Modal, { IBaseModal } from '../common/Modal';
import { postData, SUBMIT_BUG_REPORT_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useMobileContext } from 'services/contexts/MobileContext';
import UploadImage from '../common/UploadImage';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '5px 20px',
    paddingRight: '5px',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid #eee',

    '& h6': {
      textTransform: 'capitalize',
      fontSize: '1rem !important',
      lineHeight: '1rem !important',
      marginTop: '2px',
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1.5rem',

    '& h6': {
      marginBottom: '5px',
      marginTop: '0.25rem !important',
      fontSize: '1.1rem !important',
      color: `${theme.palette.primary.main} !important`,
    },
  },
  footer: {
    padding: '20px',
    paddingTop: '0',
    display: 'flex',
    alignItems: 'center',

    '& button': {
      fontSize: '0.95rem',
      borderRadius: '4px',
      marginLeft: '7.5px',
      padding: '7.5px 15px',
      minWidth: '5rem',
      textTransform: 'capitalize',
      fontWeight: 600,
    },
  },
  cancel: {
    color: `${theme.palette.primary.dark} !important`,
  },
  save: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    color: 'white !important',
  },
  info: {
    paddingBottom: '0.7rem',

    '& p': {
      fontSize: '1rem !important',
      color: '#616161 !important',
      marginBottom: '1rem !important',
    },
  },
}));

const BugReport: FunctionComponent<IBaseModal> = (props) => {
  const { open, close } = props;
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [reportText, setReportText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>(null);

  async function sendBugReport() {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('report', reportText);

      if (imageUrl) {
        formData.append('image', imageUrl);
      }

      await postData(SUBMIT_BUG_REPORT_ROUTE, [], formData);

      setReportText('');

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Bug was reported',
      });

      close();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function renderContent() {
    return (
      <div className={classes.main}>
        <div className={classes.info}>
          <Typography variant={'h5'}>Found an issue?</Typography>
          <Typography variant={'body2'}>
            Help up improve! Share details about any issue you encounter. Which
            page, device and time?
          </Typography>
          <Typography variant={'body2'}>
            We welcome any general thoughts or suggestions. Thanks for your
            input!
          </Typography>
        </div>
        <InputField
          value={reportText}
          placeholder={'Write your bug report here...'}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setReportText(event.target.value)
          }
          multiline={true}
          rows={7}
          label={'Bug Report'}
          labelTextColor={theme.palette.primary.main}
          formLabelStyles={{
            fontSize: '1.1rem',
          }}
        />
        <Box sx={{ marginTop: '15px' }}>
          <UploadImage
            full
            width='100%'
            onUpload={(file) => setImageUrl(file)}
            borderColor={theme.palette.primary.dark}
            hideBorder
            showButtons={true}
            onRemove={() => setImageUrl(null)}
            crop={false}
          />
        </Box>
      </div>
    );
  }

  return (
    <Modal
      width={isMobile ? 360 : 550}
      title={'Report a bug'}
      open={open}
      close={close}
      showFooter={true}
      largeFooter={true}
      headerColor={theme.palette.primary.light}
      headerBorder={true}
      disabledSave={!reportText}
      footerButtonsFlexStart={true}
      saveButtonText={'Submit'}
      saveAction={() => sendBugReport()}
      hideFooterBorder={true}
      loading={loading}
      backgroundColor={'white'}>
      {renderContent()}
    </Modal>
  );
};

export default BugReport;
