import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Card, Typography, useTheme } from '@mui/material';
import { IconPicker } from '../../../common/IconPicker';
import CardTag from 'components/common/CardTag';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
  ICompanyProfileField,
  ITag,
} from 'utils/models';
import { useCompanyTemplateContext } from '../CompanyTemplateContext';
import { primary } from 'theme';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import AddTagGroup from 'components/Events/AddEditComponents/AddTagGroup';
import { AddCircle } from '@mui/icons-material';

interface ICompanyTemplateFieldContainerProps extends ICompanyProfileField {
  sectionType: CompanyTemplateSectionType;
}

export const CompanyTemplateFieldContainer = ({
  sectionType,
  ...props
}: ICompanyTemplateFieldContainerProps) => {
  const { name, type, required, tagOptions } = props;
  const theme = useTheme();
  const { removeSectionField, toggleRequiredField, updateFieldTagOptions } =
    useCompanyTemplateContext();

  const [selectedTagGroup, setSelectedTagGroup] = useState<number | null>(
    tagOptions?.id || null,
  );

  const [tags, setTags] = useState<ITag[]>([]);

  const handleTagGroupSelect = (tagGroup: any) => {
    updateFieldTagOptions(sectionType, name, tagGroup);
  };

  function renderTagsField() {
    return (
      <AddTagGroup
        selectedTagGroup={selectedTagGroup}
        setSelectedTagGroup={setSelectedTagGroup}
        setTags={setTags}
        onTagGroupSelect={handleTagGroupSelect}
      />
    );
  }

  function renderCompanyField(fieldType: CompanyProfileFieldType) {
    switch (fieldType) {
      case 'TEXT':
        return 'Single line input field';
      case 'TAGS':
        return renderTagsField();
      case 'RICH_TEXT':
        return 'Text area field';
      case 'DATE':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <IconPicker
              name='Event'
              sx={{ color: theme.palette.primary.main }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.625rem',
                padding: '0.5rem',
                border: `1px solid ${primary.mainBlackFont}`,
                borderRadius: '8px',
              }}>
              10/01/2021{' '}
              <IconPicker name='Event' sx={{ color: primary.dark }} />
            </Box>
          </Box>
        );
      case 'FILES':
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '0.625rem',
              fontWeight: '600',
            }}>
            <IconPicker name='FileUpload' sx={{ color: primary.dark }} />
            Upload file
          </Box>
        );
      case 'PEOPLE':
        return (
          <Box>
            <Typography
              sx={{
                color: primary.natural2,
                paddingBottom: '1.25rem',
              }}>
              Here the company will be able to add and show a list of their
              <i style={{ padding: '0 0.25rem' }}>{name}</i>members.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '1.25rem',
              }}>
              <IconPicker
                name='AccountCirlce'
                sx={{
                  width: '3rem',
                  height: '3rem',
                  color: theme.palette.primary.main,
                }}
              />
              <Box>
                <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
                  Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: primary.natural2,
                  }}>
                  Role
                </Typography>
              </Box>
              <AccessLevelTagCard
                accessLevelName={'Tag label'}
                accessLevelColor={theme.palette.primary.main}
                height={'1.25rem'}
              />
            </Box>
          </Box>
        );
      case 'IMAGES':
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '0.625rem',
              fontWeight: '600',
            }}>
            <AddCircle name='ImageUpload' sx={{ color: primary.dark }} />
            Add image
          </Box>
        );
      default:
        return null;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem ',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <CardTag text={name} />
        {name !== 'Team' && (
          <Box
            sx={{
              display: 'flex',
              gap: '0.5rem ',
            }}>
            <Box
              onClick={() => toggleRequiredField(sectionType, name)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              {required ? (
                <IconPicker
                  name='Error'
                  sx={{ color: theme.palette.primary.main }}
                />
              ) : (
                <IconPicker
                  name='ErrorOutline'
                  sx={{ color: theme.palette.primary.main }}
                />
              )}
            </Box>
            <Box
              onClick={() => removeSectionField(sectionType, name)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <IconPicker
                name='Close'
                sx={{ color: theme.palette.primary.dark }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Card
        sx={{
          width: '100%',
          backgroundColor: primary.pureWhite,
          padding: '1.25rem',
        }}>
        {renderCompanyField(type)}
      </Card>
    </Box>
  );
};
