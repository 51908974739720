import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import GetFileIcon from 'components/common/GetFileIcon';
import Loading from 'components/common/Loading';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { primary } from 'theme';
import { ICompanyField, IUploadFile } from 'utils/models';
import { COMPANY_PROFILE_UPLOAD_ROUTE, deleteData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';

interface IUploadFileDisplayProps {
  file: IUploadFile;
  field: ICompanyField;
}

const IUploadFileDisplay: FunctionComponent<IUploadFileDisplayProps> = (
  props,
) => {
  const { file, field } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { companyProfile, updateCompanyField } = useCompanyContext();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  async function deleteFile(file: IUploadFile) {
    setIsDeleteLoading(true);

    try {
      const data = await deleteData(COMPANY_PROFILE_UPLOAD_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'companyProfileFieldId', value: field.id },
        {
          name: 'companyName',
          value: companyProfile!.name,
        },
        {
          name: 'fileId',
          value: file.id,
        },
      ]);

      const updatedFiles = field.files?.filter(
        (f: IUploadFile) => f.name !== file.name,
      );

      updateCompanyField(field.id, updatedFiles);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Deleted successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsDeleteLoading(false);
  }

  if (isDeleteLoading)
    return (
      <Box sx={{ height: '2.5rem' }}>
        <Loading height={24} size={24} />
      </Box>
    );

  return (
    <FlexBox
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '1rem',
        height: '2.25rem',
      }}>
      <FlexBox
        sx={{
          gap: '0.5rem',
        }}>
        <Box sx={{ img: { height: '1.7rem' } }}>
          <GetFileIcon file={file.name.split('.').pop()} />
        </Box>
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
                textDecorationColor: primary.natural4,
              },
            },
          }}>
          <a href={file.url} target='_blank' rel='noopener noreferrer'>
            <Typography
              sx={{
                ':hover': {
                  color: primary.natural2,
                },
              }}>
              {file.name}
            </Typography>
          </a>
        </Box>
      </FlexBox>
      <CloseModalButton
        handleClose={() => deleteFile(file)}
        color={primary.mainBlackFont}
        sx={{ boxShadow: 'none' }}
      />
    </FlexBox>
  );
};

export default IUploadFileDisplay;
