import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';
import { Check, Backspace } from '@mui/icons-material';
import Loading from './Loading';
import CommonModal from './Modal/CommonModal';
import { useMobileContext } from 'services/contexts/MobileContext';
import CloseModalButton from './Modal/CloseModalButton';

interface ConfirmationFormProps {
  isOpen: boolean;
  handleClose: () => void;
  funcExe: () => void;
  text: string;
  isLoading?: boolean;
  isWarning?: boolean;
}

const ConfirmationForm: FunctionComponent<ConfirmationFormProps> = (props) => {
  const { isOpen, handleClose, funcExe, text, isLoading, isWarning } = props;

  const { isMobile } = useMobileContext();

  const theme = useTheme();

  function onConfrim() {
    funcExe();
    handleClose();
  }

  function renderConfirmationForm() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography
          fontWeight='bold'
          fontSize={'1.25rem'}
          my={'1rem'}
          color={theme.palette.primary.dark}>
          {`Do you wish to ${text}`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '2rem',
            marginTop: '1rem',
            width: '100%',
          }}>
          <Button
            onClick={handleClose}
            sx={{
              height: '2.5rem',
              width: '100%',
              borderRadius: '6px',
              maxWidth: '18rem',
              marginBottom: '1rem',
              marginX: '1rem',
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
            }}>
            {!isWarning && (
              <Backspace
                sx={{
                  color: theme.palette.primary.dark,
                  fontSize: 26,
                  marginRight: 1,
                }}
              />
            )}
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                paddingLeft: '0.5rem',
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}>
              {isWarning ? 'Cancel' : 'Back'}
            </Typography>
          </Button>

          <Button
            onClick={onConfrim}
            sx={{
              bgcolor: isWarning
                ? primary.warningRed
                : theme.palette.primary.dark,
              height: '2.5rem',
              width: '100%',
              maxWidth: '18rem',
              marginBottom: '1rem',
              borderRadius: '6px',
              marginX: '1rem',
              '&:hover': {
                backgroundColor: isWarning
                  ? primary.warningPlaceholder
                  : theme.palette.primary.main,
              },
            }}>
            {!isWarning && (
              <Check sx={{ color: primary.pureWhite, fontSize: 26 }} />
            )}
            <Typography
              sx={{
                color: primary.pureWhite,
                paddingLeft: '0.5rem',
                fontSize: '1.125rem',
                fontWeight: 'bold',
              }}>
              {isWarning ? 'Delete' : 'Confirm'}
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <CommonModal
      open={isOpen}
      onClose={handleClose}
      disableScrollLock={true}
      sx={{ overflow: 'scroll', maxHeight: '95vh' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(50% - min(90vh, 100px))',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40rem',
          bgcolor: '#FFFFFF',
          boxShadow: 24,
          p: '2rem',
          paddingBottom: 0,
          borderRadius: '6px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <CloseModalButton
          handleClose={handleClose}
          color={theme.palette.primary.dark}
          sx={{ alignSelf: 'end' }}
        />
        {isLoading ? (
          <Box sx={{ maxHeight: 180 }}>
            <Loading marginTop={-120} />
          </Box>
        ) : (
          renderConfirmationForm()
        )}
      </Box>
    </CommonModal>
  );
};

export default ConfirmationForm;
