import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationAssessmentTemplate } from 'utils/models';
import { APPLICATION_ASSESSMENT_TEMPLATE, putData } from 'utils/requests';

interface IPostNewAssessment {
  assessmentTemplate: IApplicationAssessmentTemplate | null;
  setIsLoading: (b: boolean) => void;
}

const usePutAssessmentTemplate = (props: IPostNewAssessment) => {
  const { assessmentTemplate, setIsLoading } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  async function putAssessmentTemplate() {
    setIsLoading(true);

    try {
      // Make the PUT request
      const data: IApplicationAssessmentTemplate = await putData(
        APPLICATION_ASSESSMENT_TEMPLATE,
        [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
        ],
        assessmentTemplate,
      );

      return data;
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  return putAssessmentTemplate;
};

export default usePutAssessmentTemplate;
