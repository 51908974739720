import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import { downloadFromUrl } from 'utils/utils';
import Modal from 'components/common/Modal';
import { useParams } from 'react-router';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ICompanyDoc } from 'utils/models';
import {
  COMPANY_DELETE_RESOURCE_ROUTE,
  COMPANY_DELETE_SHARED_DOC_ROUTE,
  deleteData,
  postData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '2.5px',
    padding: '2px 7.5px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.7rem',
    backgroundColor: (props: any) =>
      props.index % 2 === 0 ? '#F5F5F5' : 'transparent',
    border: (props: any) =>
      props.index % 2 === 0 ? '1px solid #D4D4D4' : '1px solid transparent',

    '& button': {
      minWidth: '25px !important',
      width: '25px !important',
      marginRight: '2.5px !important',
      marginBottom: '0 !important',
      borderRadius: '4px',
      fontSize: '0.75rem !important',
      color: `${theme.palette.primary.dark} !important`,
      padding: '5px',
      fontWeight: 800,

      '& svg': {
        marginRight: '0 !important',
        height: '20px',
        width: '20px',
      },
    },
  },
}));

interface ICompanyDocItem {
  doc: ICompanyDoc;
  companyName: string;
  index: number;
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  canEditCompanyProfile?: boolean;
  canUploadAdminDocs?: boolean;
}

const MAX_NAME_LEN = 25;

const CompanyDocItem: FunctionComponent<ICompanyDocItem> = (props) => {
  const {
    doc,
    companyName,
    index,
    updateDocs,
    canEditCompanyProfile,
    canUploadAdminDocs,
  } = props;
  const { id, docUrl, docName, docType } = doc;
  const { ecosystemName } = useParams();
  const classes = useStyles({ index });
  const isDoc = docType !== 'resource';
  const type = isDoc ? 'Document' : 'Resource';
  const { addAlert } = useContext(AlertContext);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function deleteSharedDoc() {
    setLoading(true);

    try {
      await postData(COMPANY_DELETE_SHARED_DOC_ROUTE, [
        { name: 'sharedDocId', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Document deleted',
      });

      updateDocs(doc, true);
      setConfirmDelete(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function deleteResource() {
    setLoading(true);

    try {
      await deleteData(COMPANY_DELETE_RESOURCE_ROUTE, [
        { name: 'id', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Resource deleted',
      });

      updateDocs(doc, true);
      setConfirmDelete(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function trimName() {
    if (docName.length >= MAX_NAME_LEN) {
      const ext = docName.slice(docName.length - 3, docName.length);
      return `${docName.slice(0, MAX_NAME_LEN)}...${ext}`;
    }

    return docName;
  }

  function renderSecondaryBtn() {
    if (isDoc) {
      return (
        <Button onClick={() => downloadFromUrl(docUrl, docName)}>
          <FileDownloadIcon />
        </Button>
      );
    }

    return (
      <Button onClick={() => window.open(docUrl, '_href')}>
        <OpenInNewIcon />
      </Button>
    );
  }

  function renderTitle() {
    if (docName.length < MAX_NAME_LEN) {
      return <Typography variant={'body2'}>{trimName()}</Typography>;
    }

    return (
      <Tooltip title={docName}>
        <Typography variant={'body2'}>{trimName()}</Typography>
      </Tooltip>
    );
  }

  return (
    <>
      <Fade in={true}>
        <div className={classes.root}>
          {renderTitle()}
          <div>
            {canUploadAdminDocs !== undefined && canUploadAdminDocs && (
              <Button onClick={() => setConfirmDelete(true)}>
                <DeleteForeverIcon />
              </Button>
            )}
            {canEditCompanyProfile !== undefined && canEditCompanyProfile && (
              <Button onClick={() => setConfirmDelete(true)}>
                <DeleteForeverIcon />
              </Button>
            )}
            {renderSecondaryBtn()}
          </div>
        </div>
      </Fade>
      <Modal
        title={`Are you sure you want to delete this ${type}?`}
        open={confirmDelete}
        close={() => setConfirmDelete(false)}
        showFooter={true}
        cancelButtonText={'No'}
        saveButtonText={'Yes'}
        headerColor={'#FFCDD2'}
        saveButtonColor={'#EF5350'}
        saveAction={() => (isDoc ? deleteSharedDoc() : deleteResource())}
        largeFooter={true}
        loading={loading}
        hideFooterBorder={true}>
        <Typography variant={'body2'}>
          This action is irreversible and you will not be able to recover this{' '}
          {type} once deleted
        </Typography>
      </Modal>
    </>
  );
};

export default CompanyDocItem;
