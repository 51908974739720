import { Card, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import { primary } from 'theme';
import {
  APPLICATION_ASSESSMENT_REPORT_OVERVIEW,
  getData,
} from 'utils/requests';
import AssessmentReportApplicantRow from './AssessmentReportApplicantRow';

interface IAssessmentReportOverviewProps {}

const AssessmentReportOverview: FunctionComponent<
  IAssessmentReportOverviewProps
> = (props) => {
  const {} = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const {
    selectedReportId,
    assessmentReportDetails,
    setAssessmentReportDetails,
  } = useAssessmentContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!selectedReportId) return <></>;

  useEffect(() => {
    fetchReportOverview();
  }, [selectedReportId]);

  async function fetchReportOverview() {
    setIsLoading(true);

    try {
      const data = await getData(APPLICATION_ASSESSMENT_REPORT_OVERVIEW, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'reportId', value: selectedReportId },
      ]);

      setAssessmentReportDetails(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderApplicantsList() {
    if (!assessmentReportDetails) return <>No applicant info</>;

    return (
      <>
        {assessmentReportDetails.applicants.map((applicant, i) => (
          <AssessmentReportApplicantRow applicant={applicant} key={i} />
        ))}
      </>
    );
  }

  if (isLoading) return <Loading />;

  return (
    <Card
      sx={{
        marginY: '1.5rem',
        borderRadius: '10px',
        bgcolor: primary.pureWhite,
        p: '2rem',
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant='h5'
          sx={{
            color: primary.natural2,
            mb: '1.5rem',
            mt: '-0.75rem',
            fontWeight: 500,
            textTransform: 'uppercase',
          }}>
          Summary
        </Typography>
        <Grid container columnSpacing={'1rem'}>
          <Grid item xs={3}>
            <Typography
              sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
              Applicant
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
              Overall score
            </Typography>
          </Grid>
          <Grid item xs={1.75}>
            <Typography
              sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
              Company name
            </Typography>
          </Grid>
          <Grid item xs={1.75}>
            <Typography
              sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
              Current status
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <div />
          </Grid>
          <Grid xs={12}>
            <Divider sx={{ width: '100%', mt: '0.75rem', mb: '1.5rem' }} />
          </Grid>
          {renderApplicantsList()}
        </Grid>
      </Box>
    </Card>
  );
};

export default AssessmentReportOverview;
