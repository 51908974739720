import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  IApplicationAssessmentTemplateCard,
  IAssessmentReportOverview,
} from 'utils/models';

const useAssessment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAssessmentProcessId, setSelectedAssessmentProcessId] =
    useState<number | null>(null);

  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);

  const [assessmentReportDetails, setAssessmentReportDetails] =
    useState<IAssessmentReportOverview | null>(null);

  const [assessmentProcesses, setAssessmentProcesses] = useState<
    IApplicationAssessmentTemplateCard[]
  >([]);

  return {
    isLoading,
    selectedAssessmentProcessId,
    assessmentProcesses,
    selectedReportId,
    assessmentReportDetails,
    setIsLoading,
    setSelectedAssessmentProcessId,
    setAssessmentProcesses,
    setSelectedReportId,
    setAssessmentReportDetails,
  };
};

const AssessmentContext = createContext({} as ReturnType<typeof useAssessment>);

export const AssessmentProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const assessmentContext = useAssessment();

  return (
    <AssessmentContext.Provider value={assessmentContext}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessmentContext = () => useContext(AssessmentContext);
