import { Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { ChangeEvent, FunctionComponent } from 'react';
import InputField from './InputField';

interface IconAndTextProps {
  icon: JSX.Element;
  text: string;
  sx?: SxProps;
  iconSx?: SxProps;
  isEdit?: boolean;
  updateText?: (text: string) => void;
}

const IconAndText: FunctionComponent<IconAndTextProps> = (props) => {
  const { icon, text, sx, iconSx, isEdit, updateText } = props;
  const theme = useTheme();

  if (!text && !isEdit) return null;

  return (
    <Box
      sx={{
        width: '100%',
        marginY: '1rem',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}>
      {React.cloneElement(icon, {
        sx: { color: theme.palette.primary.main, fontSize: '1.5rem' },
      })}
      {isEdit && !!updateText ? (
        <Box sx={{ width: '90%', ml: '0.5rem' }}>
          <InputField
            value={text}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateText(event.target.value)
            }
          />
        </Box>
      ) : (
        <Typography sx={{ ml: '0.5rem' }}>{text}</Typography>
      )}
    </Box>
  );
};

export default IconAndText;
