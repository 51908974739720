import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { PDFViewer } from '@react-pdf/renderer';
import { IApplicantDetails } from 'utils/models';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import ApplicationsListPdf from './ApplicationsListPdf';
import useFetchApplicantDetails from 'hooks/Applications/useFetchApplicantDetails';

interface IDowloadPdf {}

const ApplicationSinglePdfDownload: FunctionComponent<IDowloadPdf> = () => {
  const { applicationProcessId, applicantId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationsList, setApplicationsList] = useState<
    IApplicantDetails[] | null
  >(null);

  const selectedApplicationProcessId = applicationProcessId ?? '0';

  const selectedApplicantId = applicantId ?? '0';

  const fetchDetailsProps = {
    selectedApplicationProcessId,
    selectedApplicantId,
    setIsLoading,
  };
  const { data } = useFetchApplicantDetails(fetchDetailsProps);

  function saveSingleAsList(data: IApplicantDetails | null) {
    if (!data) return;
    setApplicationsList([data]);
  }

  useEffect(() => {
    saveSingleAsList(data);
  }, [data]);

  if (isLoading) return <Loading marginTop={90} />;

  if (!applicationsList) return <div>Sorry! Something went wrong...</div>;

  return (
    <Box sx={{ marginTop: '-3rem' }}>
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <ApplicationsListPdf applicationsList={applicationsList} />
      </PDFViewer>
    </Box>
  );
};

export default ApplicationSinglePdfDownload;
