import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Modal from '../common/Modal';
import { IEcosystemStatus } from 'utils/models';
import SelectDropdown from '../common/SelectDropdown';
import InputField from '../common/InputField';
import { getData, SUPER_ADMIN_GENERATE_METRICS } from 'utils/requests';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '60vh',
    position: 'relative',
    '& h1': {
      fontSize: '7rem !important',
    },

    '& button': {
      marginTop: '5rem !important',
    },
  },
}));

interface IMetrics {
  showMetrics: boolean;
  close: () => void;
  ecosystems: IEcosystemStatus[];
}

const allEcosystemsOption = 'All Ecosystems';

const Metrics: FunctionComponent<IMetrics> = (props) => {
  const { showMetrics, close, ecosystems } = props;
  const classes = useStyles();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);
  const [ecosystemName, setEcosystemName] =
    useState<string>(allEcosystemsOption);
  const [metrics, setMetrics] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [includeDisabledEcos, setIncludeDisabledEcos] = useState<boolean>(true);

  async function fetchMetrics() {
    setLoading(true);
    let paramList = [];
    if (ecosystemName !== allEcosystemsOption) {
      paramList = [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'startDate', value: startDate },
        { name: 'endDate', value: endDate },
        { name: 'includeDisabledEcos', value: includeDisabledEcos },
      ];
    } else {
      paramList = [
        { name: 'startDate', value: startDate },
        { name: 'endDate', value: endDate },
        { name: 'includeDisabledEcos', value: includeDisabledEcos },
      ];
    }
    try {
      const data = await getData(SUPER_ADMIN_GENERATE_METRICS, paramList);
      setMetrics(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  function getOptions() {
    const ecosystemNames = ecosystems.map(
      (ecosystemStatus) => ecosystemStatus.ecosystemName,
    );
    ecosystemNames.push(allEcosystemsOption);
    return ecosystemNames;
  }

  function renderMetricContent() {
    return (
      <>
        <Typography variant={'h6'}>
          Generate metrics for specific ecosystem:
        </Typography>
        <SelectDropdown
          value={ecosystemName}
          onChange={(value) => setEcosystemName(value)}
          options={getOptions()}
          getOptionLabel={(option) => option}
          height={50}
        />
        <Typography variant={'h6'}>Date range from and to :</Typography>
        <input
          type={'date'}
          value={startDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setStartDate(e.target.value)
          }
        />
        <input
          type={'date'}
          value={endDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setEndDate(e.target.value)
          }
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeDisabledEcos}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setIncludeDisabledEcos(!includeDisabledEcos)
                }
              />
            }
            label='Include disabled ecosystems'
          />
        </FormGroup>
        <InputField
          value={metrics}
          placeholder={'Metrics will appear here'}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setMetrics(event.target.value)
          }
          multiline={true}
          readonly={true}
        />
      </>
    );
  }

  return (
    <Modal
      open={showMetrics}
      close={close}
      isActerioTheme={true}
      title={'Metrics'}
      largeHeader={true}
      showFooter={true}
      largeFooter={true}
      footerButtonsFlexStart={true}
      saveButtonText={'Generate Metrics'}
      saveAction={() => fetchMetrics()}
      backgroundColor={'white'}
      headerColor={theme.palette.primary.light}
      loading={loading}>
      {renderMetricContent()}
    </Modal>
  );
};

export default Metrics;
