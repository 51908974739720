import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RequestJoinEcosystem from 'components/Dashboard/RequestJoinEcosystem';
import Ecosystems from 'components/Dashboard/Ecosystems';
import { useMobileContext } from 'services/contexts/MobileContext';
import { removeEcosystemInfo, removeStoredAnnouncements } from 'utils/storage';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import TimeZoneDetector from 'components/common/TimeZoneDetector';
import {
  defaultSchemes,
  useCustomTheme,
} from 'services/contexts/Themes/ThemeContext';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: (props: any) => (props.isMobile ? 'column' : 'row'),
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h3': {
      color: 'white !important',
      fontWeight: 600,
      marginBottom: 'auto !important',
      marginTop: '-5px !important',
      fontSize: '2.65rem !important',
    },
  },
}));

interface IDashboard {}

const Dashboard: FunctionComponent<IDashboard> = (props) => {
  const { isMobile } = useMobileContext();
  const classes = useStyles({ isMobile });
  const navigate = useNavigate();
  const [oneEcosystemName, setOneEcosystemName] = useState('');
  const { setCurrentTheme } = useCustomTheme();

  useEffect(() => {
    removeStoredAnnouncements();
    removeEcosystemInfo();

    if (!setCurrentTheme) return;
    setCurrentTheme(defaultSchemes[0]);
  }, []);

  useEffect(() => {
    if (oneEcosystemName && !sessionStorage.getItem('ecosystemNameEnter')) {
      sessionStorage.setItem('ecosystemNameEnter', oneEcosystemName);
      navigate(`/ecosystem/${oneEcosystemName}`);
    }
  }, [oneEcosystemName]);

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: ' auto',
          marginRight: 'auto',
          width: isMobile ? '100%' : '43.75rem',
          display: isMobile ? 'flex' : 'unset',
          flexDirection: isMobile ? 'column-reverse' : 'inherit',
          justifyContent: isMobile ? 'start' : 'inherit',
          maxHeight: isMobile ? '80vh' : '',
          marginTop: isMobile ? '2rem' : '75px',
          paddingBottom: '3.125rem',
        }}>
        <div className={classes.header}>
          {!isMobile && <Typography variant={'h3'}>Dashboard</Typography>}
          <RequestJoinEcosystem />
        </div>
        <div>
          <Ecosystems setOneEcosystemName={setOneEcosystemName} />
        </div>
        <TimeZoneDetector />
      </Box>
    </Fade>
  );
};

export default Dashboard;
