import React, { FunctionComponent, useEffect, useState } from 'react';
import { IconButton, Typography, Theme, Grid, useTheme } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { getMonth } from 'utils/utils';
import BookingMonth from './BookingMonth';
import dayjs from 'dayjs';
import { IAvailability, IAvailableTimeslots } from 'utils/models';

const useStyles = makeStyles((theme: Theme) => ({
  placeholder: {},
}));

interface CalendarViewProps {
  availability: IAvailability | IAvailableTimeslots[];
  handleHourDateClick: (selectedDay: Date) => void;
  selectedDate?: Date;
  setSelectedDate: (date: Date) => void;
  monthIndex: number;
  setMonthIndex: (n: number) => void;
  monthNumber: number;
  setMonthNumber: (n: number) => void;
  currentYear: number;
  setCurrentYear: (n: number) => void;
}

const BookingCalendar: FunctionComponent<CalendarViewProps> = (props) => {
  const {
    availability,
    handleHourDateClick,
    selectedDate,
    setSelectedDate,
    monthIndex,
    setMonthIndex,
    monthNumber,
    setMonthNumber,
    currentYear,
    setCurrentYear,
  } = props;
  const theme = useTheme();

  const [calendarDays, setCaledarDays] = useState<any>(getMonth());

  useEffect(() => {
    setCaledarDays(getMonth(monthIndex));
  }, [monthIndex]);

  if (monthNumber < 0) {
    setMonthNumber(11);
    setCurrentYear(currentYear - 1);
  } else if (monthNumber > 11) {
    setMonthNumber(0);
    setCurrentYear(currentYear + 1);
  }

  function handlePreviousMonth() {
    setMonthIndex(monthIndex - 1);
    setMonthNumber(monthNumber - 1);
  }

  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
    setMonthNumber(monthNumber + 1);
  }

  return (
    <Grid sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <Box>
          <Typography
            variant='h5'
            fontWeight='bold'
            fontSize='2rem'
            paddingTop={1.3}>
            {`${dayjs().month(monthIndex).format('MMMM')} ${currentYear}`}
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={handlePreviousMonth}
            sx={{ '&:hover': { bgcolor: theme.palette.primary.light } }}>
            <ArrowBackIos sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
          <IconButton
            onClick={handleNextMonth}
            sx={{ '&:hover': { bgcolor: theme.palette.primary.light } }}>
            <ArrowForwardIos sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </Box>
      </Box>
      <Grid>
        <BookingMonth
          calendarDays={calendarDays}
          monthIndex={monthIndex}
          availability={availability}
          handleHourDateClick={handleHourDateClick}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Grid>
    </Grid>
  );
};

export default BookingCalendar;
