import * as React from 'react';
import { SVGProps } from 'react';

interface ILoginBackground extends SVGProps<SVGSVGElement> {
  height: number;
  width: number;
}

const LoginBackground = (props: ILoginBackground) => (
  <svg
    viewBox='0 0 1920 1080'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <g clipPath='url(#clip0_1579_56523)'>
      <path fill='#fff' d='M0 0H1920V1080H0z' />
      <g clipPath='url(#clip1_1579_56523)'>
        <path fill='url(#paint0_linear_1579_56523)' d='M0 0H1920V1080H0z' />
        <path
          fill='url(#paint1_linear_1579_56523)'
          fillOpacity={0.2}
          d='M0 0H1920V1080H0z'
        />
        <path
          fill='url(#paint2_linear_1579_56523)'
          fillOpacity={0.2}
          d='M0 0H1920V1080H0z'
        />
        <g filter='url(#filter0_b_1579_56523)'>
          <path
            d='M929 1090V407.134c0-84.819-78.232-136.509-138.515-91.527l-247.319 184.56C498.27 533.671 471 592.389 471 655.56V1090h458z'
            fill='url(#paint3_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M928.5 407.134V1089.5h-457V655.56c0-63.036 27.213-121.595 71.965-154.992l247.319-184.56c29.96-22.356 64.358-20.69 91.289-2.896 26.942 17.803 46.427 51.767 46.427 94.022z'
            stroke='url(#paint4_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
        <g filter='url(#filter1_b_1579_56523)'>
          <path
            d='M430-180v682.866c0 84.819-78.232 136.509-138.515 91.527L44.166 409.833C-.73 376.329-28 317.611-28 254.44V-180h458z'
            fill='url(#paint5_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M429.5 502.866V-179.5h-457v433.94c0 63.036 27.213 121.595 71.965 154.992l247.319 184.56c29.96 22.356 64.358 20.69 91.289 2.896 26.942-17.803 46.427-51.767 46.427-94.022z'
            stroke='url(#paint6_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
        <g filter='url(#filter2_b_1579_56523)'>
          <path
            d='M1462 1090V407.134c0-84.819 78.23-136.509 138.51-91.527l247.32 184.56c44.9 33.504 72.17 92.222 72.17 155.393V1090h-458z'
            fill='url(#paint7_linear_1579_56523)'
          />
          <path
            d='M1462.5 407.134V1089.5h457V655.56c0-63.036-27.21-121.595-71.97-154.992l-247.32-184.56c-29.96-22.356-64.35-20.69-91.28-2.896-26.95 17.803-46.43 51.767-46.43 94.022z'
            stroke='url(#paint8_linear_1579_56523)'
            strokeOpacity={0.5}
          />
        </g>
        <g filter='url(#filter3_b_1579_56523)'>
          <path
            d='M962-79v682.866c0 84.819 78.23 136.509 138.52 91.527l247.31-184.56c44.9-33.504 72.17-92.222 72.17-155.393V-79H962z'
            fill='url(#paint9_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M962.5 603.866V-78.5h457v433.94c0 63.036-27.21 121.595-71.96 154.992l-247.32 184.56c-29.96 22.356-64.36 20.69-91.29 2.896-26.945-17.803-46.43-51.767-46.43-94.022z'
            stroke='url(#paint10_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
        <g filter='url(#filter4_b_1579_56523)'>
          <path
            d='M471-349v669.053c0 83.103 76.695 133.748 135.793 89.676l242.459-180.827C893.266 196.075 920 138.545 920 76.652V-349H471z'
            fill='url(#paint11_linear_1579_56523)'
          />
          <path
            d='M471.5 320.053V-348.5h448V76.652c0 61.758-26.677 119.13-70.547 151.849L606.495 409.328c-29.369 21.901-63.087 20.269-89.485 2.837-26.41-17.441-45.51-50.714-45.51-92.112z'
            stroke='url(#paint12_linear_1579_56523)'
            strokeOpacity={0.5}
          />
        </g>
        <g filter='url(#filter5_b_1579_56523)'>
          <path
            d='M1920-349v669.053c0 83.103-76.7 133.748-135.8 89.676l-242.45-180.827c-44.02-32.827-70.75-90.357-70.75-152.25V-349h449z'
            fill='url(#paint13_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M1919.5 320.053V-348.5h-448V76.652c0 61.758 26.67 119.13 70.54 151.849l242.46 180.827c29.37 21.901 63.09 20.269 89.49 2.837 26.41-17.441 45.51-50.715 45.51-92.112z'
            stroke='url(#paint14_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
        <g filter='url(#filter6_b_1579_56523)'>
          <path
            d='M1420 1360V690.947c0-83.103-76.7-133.748-135.8-89.676l-242.45 180.827c-44.018 32.827-70.752 90.357-70.752 152.25V1360H1420z'
            fill='url(#paint15_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M1419.5 690.947V1359.5H971.498V934.348c0-61.758 26.677-119.13 70.542-151.849l242.46-180.827c29.37-21.901 63.09-20.269 89.49-2.837 26.41 17.441 45.51 50.715 45.51 92.112z'
            stroke='url(#paint16_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
        <g filter='url(#filter7_b_1579_56523)'>
          <path
            d='M-28 1259V589.947c0-83.103 76.695-133.748 135.793-89.676l242.459 180.827C394.266 713.925 421 771.455 421 833.348V1259H-28z'
            fill='url(#paint17_linear_1579_56523)'
            fillOpacity={0.4}
          />
          <path
            d='M-27.5 589.947V1258.5h448V833.348c0-61.758-26.677-119.13-70.547-151.849L107.495 500.672c-29.369-21.901-63.087-20.269-89.485-2.837-26.41 17.441-45.51 50.714-45.51 92.112z'
            stroke='url(#paint18_linear_1579_56523)'
            strokeOpacity={0.2}
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_b_1579_56523'
        x={441}
        y={269}
        width={518}
        height={851}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_b_1579_56523'
        x={-58}
        y={-210}
        width={518}
        height={851}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_b_1579_56523'
        x={1432}
        y={269}
        width={518}
        height={851}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter3_b_1579_56523'
        x={932}
        y={-109}
        width={518}
        height={851}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter4_b_1579_56523'
        x={441}
        y={-379}
        width={509}
        height={835}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter5_b_1579_56523'
        x={1441}
        y={-379}
        width={509}
        height={835}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter6_b_1579_56523'
        x={940.998}
        y={555}
        width={509}
        height={835}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <filter
        id='filter7_b_1579_56523'
        x={-58}
        y={454}
        width={509}
        height={835}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation={15} />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_1579_56523'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_backgroundBlur_1579_56523'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_1579_56523'
        x1={1904.5}
        y1={1063}
        x2={-0.0000364563}
        y2={-53}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#13253E' />
        <stop offset={1} stopColor='#20415A' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1579_56523'
        x1={0}
        y1={0}
        x2={960}
        y2={418}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#23B0F7' />
        <stop offset={1} stopColor='#182F48' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1579_56523'
        x1={1822}
        y1={86.0001}
        x2={372.5}
        y2={526}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#23B0F7' />
        <stop offset={1} stopColor='#182F48' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1579_56523'
        x1={699.531}
        y1={1118.36}
        x2={806.102}
        y2={351.53}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1579_56523'
        x1={873.408}
        y1={288.793}
        x2={715.295}
        y2={870.049}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint5_linear_1579_56523'
        x1={200.531}
        y1={-208.357}
        x2={307.102}
        y2={558.47}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint6_linear_1579_56523'
        x1={374.408}
        y1={621.207}
        x2={216.295}
        y2={39.9505}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint7_linear_1579_56523'
        x1={1691.47}
        y1={1118.36}
        x2={1584.9}
        y2={351.53}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#657DA0' stopOpacity={0} />
        <stop offset={1} stopColor='#E2EFFB' stopOpacity={0.15} />
      </linearGradient>
      <linearGradient
        id='paint8_linear_1579_56523'
        x1={1517.59}
        y1={288.793}
        x2={1675.7}
        y2={870.049}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint9_linear_1579_56523'
        x1={1191.47}
        y1={-107.357}
        x2={1084.9}
        y2={659.47}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint10_linear_1579_56523'
        x1={1017.59}
        y1={722.207}
        x2={1175.7}
        y2={140.951}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint11_linear_1579_56523'
        x1={695.96}
        y1={-376.783}
        x2={591.604}
        y2={374.549}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#657DA0' stopOpacity={0} />
        <stop offset={1} stopColor='#E2EFFB' stopOpacity={0.15} />
      </linearGradient>
      <linearGradient
        id='paint12_linear_1579_56523'
        x1={525.5}
        y1={436}
        x2={680.335}
        y2={-133.545}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint13_linear_1579_56523'
        x1={1695.04}
        y1={-376.783}
        x2={1799.39}
        y2={374.549}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint14_linear_1579_56523'
        x1={1865.5}
        y1={436}
        x2={1710.66}
        y2={-133.545}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint15_linear_1579_56523'
        x1={1195.04}
        y1={1387.78}
        x2={1299.39}
        y2={636.451}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint16_linear_1579_56523'
        x1={1365.5}
        y1={575}
        x2={1210.66}
        y2={1144.54}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='paint17_linear_1579_56523'
        x1={196.96}
        y1={1286.78}
        x2={92.604}
        y2={535.451}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0D203A' stopOpacity={0} />
        <stop offset={1} stopColor='#7EC1FF' stopOpacity={0.2} />
      </linearGradient>
      <linearGradient
        id='paint18_linear_1579_56523'
        x1={26.5}
        y1={474}
        x2={181.335}
        y2={1043.54}
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <clipPath id='clip0_1579_56523'>
        <path fill='#fff' d='M0 0H1920V1080H0z' />
      </clipPath>
      <clipPath id='clip1_1579_56523'>
        <path fill='#fff' d='M0 0H1920V1080H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default LoginBackground;
