import React, { FunctionComponent } from 'react';
import { colorPallete } from '../../../theme';
import { Controller, FieldError } from 'react-hook-form';
import { useTagGroupContext } from '../ManageTheme/TagGroups/TagGroupContext';
import { useTheme } from '@mui/material';
import ForwardedSelectDropdown from './ApplicationBuilderSelect';

interface ApplicationTagGroupsSelectProps {
  control: any;
  sectionIndex: number;
  questionIndex: number;
  tagsErrorPath: FieldError | undefined;
  resetField: any;
}

const ApplicationBuilderTagGroupsSelect: FunctionComponent<
  ApplicationTagGroupsSelectProps
> = (props) => {
  const { control, sectionIndex, questionIndex, tagsErrorPath, resetField } =
    props;
  const { tagGroups } = useTagGroupContext();
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={`sections.${sectionIndex}.questions.${questionIndex}.tagGroup.id`}
      rules={{ required: 'Please, pick a tag group' }}
      render={({ field }) => (
        <ForwardedSelectDropdown
          placeholder={'Select a tag group'}
          {...field}
          options={tagGroups.reduce<number[]>((accumulator, currentValue) => {
            accumulator.push(currentValue.id as number);
            return accumulator;
          }, [])}
          getOptionLabel={(id) => {
            const group = tagGroups.find((tagGroup) => tagGroup.id === id);
            return group ? group.name : '';
          }}
          value={field.value}
          onChange={(value) => {
            resetField(
              `sections.${sectionIndex}.questions.${questionIndex}.tagGroup`,
            );
            field.onChange(value);
          }}
          sx={{
            width: '21%',
            boxShadow: theme.shadows[2],
            height: '2.5rem',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              boxShadow: tagsErrorPath
                ? `inset 0 0 0 0.063rem ${colorPallete.warning}`
                : 'inset 0 0 0 0.063rem transparent',
              transition: 'all .3s ease',
            },
            '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                boxShadow: tagsErrorPath
                  ? `inset 0 0 0 0.063rem ${colorPallete.warning}`
                  : `inset 0 0 0 0.063rem ${theme.palette.primary.main}`,
              },
          }}
        />
      )}
    />
  );
};

export default ApplicationBuilderTagGroupsSelect;
