import React, { FunctionComponent } from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { primary } from 'theme';

interface IToggleViewButtonProps {
  onClick?: () => void;
  sx?: SxProps;
  text: string;
  isActive: boolean;
  icon?: React.ReactElement;
}

const ToggleViewButton: FunctionComponent<IToggleViewButtonProps> = (props) => {
  const { onClick, sx, text, isActive, icon } = props;
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: isActive ? theme.palette.primary.light : 'default',
        borderRadius: '30px',
        boxShadow: theme.mixins.toolbar.boxShadow,
        minWidth: { xs: '14rem', sm: '12rem', lg: '9rem' },
        ...sx,
        '& .MuiSvgIcon-root': {
          fontSize: { xs: '3rem', sm: '2.4rem', md: '2.25rem', lg: '1.5rem' },
        },
      }}>
      {icon}
      <Typography
        paddingLeft={1}
        sx={{
         color: isActive ? theme.palette.primary.main : primary.natural2,
          fontSize: { xs: '2rem', sm: '1.6rem', md: '1.5rem', lg: '1rem' },
        }}>
        {text}
      </Typography>
    </Button>
  );
};

export default ToggleViewButton;
