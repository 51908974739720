import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IMenuKeys, ISideMenuSection } from 'utils/models';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLaptopView, setIsLaptopView] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const [menuSections, setMenuSections] = useState<ISideMenuSection[] | null>(
    null,
  );
  const [selectedMenuOption, setSelectedMenuOption] =
    useState<IMenuKeys | null>(null);

  const [mobilePath, setMobilePath] = useState<string>('');

  const ecosystemName = extractEcosystemName(mobilePath);

  const ecoPath = extractPathAfterEcosystemName(mobilePath);

  function extractEcosystemName(inputString: string) {
    if (!mobilePath) return '';

    const indexOfEcosystem = inputString.indexOf('/ecosystem/');

    if (indexOfEcosystem === -1) {
      return '';
    }

    const substringAfterEcosystem = inputString.substring(
      indexOfEcosystem + '/ecosystem/'.length,
    );
    const indexOfNextSlash = substringAfterEcosystem.indexOf('/');

    if (indexOfNextSlash !== -1) {
      return substringAfterEcosystem.substring(0, indexOfNextSlash);
    }

    return substringAfterEcosystem;
  }

  function extractPathAfterEcosystemName(inputString: string) {
    if (!ecosystemName) return '';

    const index = inputString.indexOf(ecosystemName);

    if (index !== -1) {
      const result = inputString.substring(index + ecosystemName.length + 1);
      return result;
    } else {
      return '';
    }
  }

  function checkWindowSize() {
    setIsLaptopView(false);
    setIsMobile(false);

    if (window.innerWidth < 1300 && window.innerWidth >= 960) {
      setIsLaptopView(true);
    } else if (window.innerWidth < 960) {
      setIsMobile(true);
    }
  }

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);

    if (ecoPath.includes('booking') || ecoPath.includes('events')) {
      setIsMenuVisible(true);
    } else {
      setIsMenuVisible(false);
      setMenuSections(null);
    }
  }, [mobilePath]);

  return {
    isMobile,
    isLaptopView,
    isMenuOpen,
    setIsMenuOpen,
    isMenuVisible,
    setIsMenuVisible,
    menuSections,
    setMenuSections,
    mobilePath,
    setMobilePath,
    selectedMenuOption,
    setSelectedMenuOption,
  };
};

const MobileContext = createContext({} as ReturnType<typeof useMobile>);

export const MobileProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const mobileContext = useMobile();

  return (
    <MobileContext.Provider value={mobileContext}>
      {children}
    </MobileContext.Provider>
  );
};

export const useMobileContext = () => useContext(MobileContext);
