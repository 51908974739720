import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';
import Loading from 'components/common/Loading';
import useJourneyTemplateSpecific from 'hooks/Journey/useJourneyTemplateSpecific';
import {
  IJourney,
  IJourneyResources,
  IJourneyStage,
} from 'utils/models';

const useJourneyTemplate = (loadedJourney: IJourney | null) => {
  const [journey, setJourney] = useState<IJourney | null>(loadedJourney);
  const [stage, setStage] = useState<IJourneyStage | null>(
    !journey ? null : journey.stages[0],
  );
  const [stageIndex, setStageIndex] = useState<number>(0);
  const numStages = !journey ? 0 : journey.stages.length;

  useEffect(() => {
    if (loadedJourney) {
      setJourney(loadedJourney);
      setStage(loadedJourney.stages[0]);
    }
  }, [loadedJourney]);

  function saveJourneyStage() {
    if (!journey || !stage) return;

    const updatedStages = [...journey.stages];
    updatedStages[stageIndex] = stage;

    setJourney({ ...journey, stages: updatedStages });
  }

  function addNewStage() {
    if (!journey || !stage) return;

    const updatedStages = [...journey.stages];
    updatedStages[stageIndex] = stage;

    const newStage = {
      name: `Stage ${journey.stages.length + 1}`,
      description: '',
      themes: [
        {
          name: 'Theme 1',
          milestones: [
            {
              name: 'Goal 1',
              goals: [],
            },
          ],
        },
      ],
    };

    const updatedStagesWithNew = [...updatedStages, newStage];

    const updatedJourney = { ...journey, stages: updatedStagesWithNew };

    setJourney(updatedJourney);
    setStage(newStage);
    setStageIndex(updatedStagesWithNew.length - 1);
  }

  function updateName(name: string) {
    if (!journey) return;

    setJourney({ ...journey, name });
  }

  function updateStageName(name: string) {
    if (!journey || !stage) return;

    const updatedStages = [...journey.stages];
    updatedStages[stageIndex] = { ...stage, name };

    setJourney({ ...journey, stages: updatedStages });
    setStage({ ...stage, name });
  }

  function updateStageDesc(description: string) {
    if (!stage) return;

    setStage({ ...stage, description });
  }

  function deleteStage(stageIndex: number) {
    if (!journey || !stage) return;

    const updatedStages = journey.stages.filter(
      (journeyStage, i) => i !== stageIndex,
    );

    setTimeout(() => {
      setJourney({ ...journey, stages: updatedStages });
      setStage(updatedStages[0]);
      setStageIndex(0);
    });
  }

  function addStageTheme() {
    if (!stage) return;

    setStage({
      ...stage,
      themes: [
        ...stage.themes,
        {
          name: `Theme ${stage.themes.length + 1}`,
          milestones: [
            {
              name: 'Goal 1',
              goals: [],
            },
          ],
        },
      ],
    });
  }

  function deleteStageTheme(themeIndex: number) {
    if (!stage) return;

    const updatedThemes = stage.themes.filter((theme, i) => i !== themeIndex);

    setStage({
      ...stage,
      themes: updatedThemes,
    });
  }

  function addStageMilestone(themeIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    updatedThemes[themeIndex].milestones.push({
      name: `Goal ${updatedThemes[themeIndex].milestones.length + 1}`,
      goals: [],
    });

    setStage({ ...stage, themes: updatedThemes });
  }

  function deleteStageMilestone(themeIndex: number, milestoneIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    updatedThemes[themeIndex].milestones = updatedThemes[
      themeIndex
    ].milestones.filter((milestone, i) => i !== milestoneIndex);

    setStage({ ...stage, themes: updatedThemes });
  }

  function updateStageMilestoneName(
    name: string,
    themeIndex: number,
    milestoneIndex: number,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];

    updatedTheme.milestones[milestoneIndex] = {
      ...updatedTheme.milestones[milestoneIndex],
      name,
    };

    setStage({ ...stage, themes: updatedThemes });
  }

  function updateStageThemeName(name: string, themeIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    updatedThemes[themeIndex] = { ...updatedThemes[themeIndex], name };

    setStage({ ...stage, themes: updatedThemes });
  }

  function addStageGoal(themeIndex: number, milestoneIndex: number) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];
    const updatedMilestone = updatedTheme.milestones[milestoneIndex];

    updatedTheme.milestones[milestoneIndex].goals.push({
      name: `Activity ${updatedMilestone.goals.length + 1}`,
      resources: {
        files: [],
        links: [],
        persons: [],
      },
    });

    setStage({ ...stage, themes: updatedThemes });
  }

  function deleteStageGoal(
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];
    updatedTheme.milestones[milestoneIndex].goals = updatedTheme.milestones[
      milestoneIndex
    ].goals.filter((goal, i) => i !== goalIndex);

    setStage({ ...stage, themes: updatedThemes });
  }

  function updateStageGoalName(
    name: string,
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];
    const updatedMilestone = updatedTheme.milestones[milestoneIndex];

    updatedMilestone.goals[goalIndex] = {
      ...updatedMilestone.goals[goalIndex],
      name,
    };

    setStage({ ...stage, themes: updatedThemes });
  }

  function updateStageResources(
    themeIndex: number,
    milestoneIndex: number,
    goalIndex: number,
    resources: IJourneyResources,
  ) {
    if (!stage) return;

    const updatedThemes = [...stage.themes];
    const updatedTheme = updatedThemes[themeIndex];
    const updatedMilestone = updatedTheme.milestones[milestoneIndex];

    updatedMilestone.goals[goalIndex] = {
      ...updatedMilestone.goals[goalIndex],
      resources,
    };

    setStage({ ...stage, themes: updatedThemes });
  }

  return {
    journey,
    stage,
    stageIndex,
    numStages,
    setJourney,
    saveJourneyStage,
    setStage,
    setStageIndex,
    addNewStage,
    deleteStage,
    updateName,
    updateStageName,
    updateStageDesc,
    addStageTheme,
    deleteStageTheme,
    updateStageThemeName,
    addStageMilestone,
    deleteStageMilestone,
    updateStageMilestoneName,
    updateStageGoalName,
    addStageGoal,
    deleteStageGoal,
    updateStageResources,
  };
};

const JourneyTemplateContext = createContext(
  {} as ReturnType<typeof useJourneyTemplate>,
);

export const JourneyTemplateProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { loading, journey } = useJourneyTemplateSpecific();
  const journeyTemplateContext = useJourneyTemplate(journey);

  return (
    <JourneyTemplateContext.Provider value={journeyTemplateContext}>
      {loading || !journey ? <Loading /> : children}
    </JourneyTemplateContext.Provider>
  );
};

export const useJourneyTemplateContext = () =>
  useContext(JourneyTemplateContext);
