import { useContext, useEffect, useState } from 'react';
import { TAG_GROUPS_ROUTE, getData } from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { ITagGroup } from 'utils/models';

const useFetchTagGroups = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [tagGroups, setTagGroups] = useState<ITagGroup[]>([]);

  useEffect(() => {
    fetchTagGroups();
  }, []);

  async function fetchTagGroups() {
    try {
      const data = await getData(TAG_GROUPS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setTagGroups(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return { tagGroups };
};

export default useFetchTagGroups;
