import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '65.625rem',
    maxWidth: '65.625rem',
    margin: '0 auto',
    marginTop: '3rem',

    '& h5': {
      fontSize: '1.6rem !important',
    },
  },
  cont: {},
}));

interface IManageAccess {
  section: JSX.Element | null;
}

const ManageAccess: FunctionComponent<IManageAccess> = (props) => {
  const { section } = props;
  const classes = useStyles();

  return <div className={classes.root}>{section}</div>;
};

export default ManageAccess;
