import React, { useEffect, useState } from 'react';
import { acterioTheme } from 'theme';
import { Box, Typography } from '@mui/material';
import { useAssessmentContext } from 'services/contexts/Applications/AssessmentContext';
import AssessmentProcessCard from './AssessmentProcessCard';
import Loading from 'components/common/Loading';
import useFetchAssessmentProcesses from 'hooks/Applications/useFetchAssessmentProcesses';

const AssessmentsGrid = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { assessmentProcesses, setAssessmentProcesses } =
    useAssessmentContext();

  const fetchProcessesList = useFetchAssessmentProcesses({
    setIsLoading,
    setAssessmentProcesses,
  });

  useEffect(() => {
    fetchProcessesList();
  }, []);

  function renderNoAssessments() {
    return (
      <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
        <Box
          sx={{
            width: { xs: '80%', md: '49.625rem' },
            height: '21.875rem',
            margin: '4rem 0 0 3rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            component='img'
            src='/images/illustrations/Application_forms_grey.png'
            alt='application forms illustration'
            sx={{
              width: '16.25rem',
              height: '16.25rem',
              marginBottom: '1rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: '400',
              lineHeight: '1.875rem',
            }}>
            Looks like you're starting with a clean slate! Go ahead and create
            your first assessment to get started. Click the '
            <span style={{ color: acterioTheme.main }}>+ New assessment</span>'
            button to begin building.
          </Typography>
        </Box>
      </Box>
    );
  }

  function renderAssessmentGrid() {
    const listOfAssessments = assessmentProcesses;
    //   ? assessmentProcesses.filter(
    //       (process) => process.processStatus === applicationStatus,
    //     )
    //   : [];

    if (!listOfAssessments.length) return renderNoAssessments();

    return listOfAssessments.map((process) => (
      <AssessmentProcessCard {...process} key={process.id} />
    ));
  }

  if (isLoading) return <Loading />;

  return (
    <Box sx={{ margin: '4rem 0 0 3rem' }}>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '2rem',
          lineHeight: '3.75rem',
        }}>
        Assessments
      </Typography>
      {assessmentProcesses && !!assessmentProcesses.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {renderAssessmentGrid()}
        </Box>
      ) : (
        renderNoAssessments()
      )}
    </Box>
  );
};

export default AssessmentsGrid;
