import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import { IJourneyStage } from 'utils/models';
import JourneyProgressBar, {
  getProgressKey,
  INITIAL_STAGE_PROGRESS,
  IStageProgress,
} from './JourneyProgressBar';
import { AlertContext } from '../Alert/AlertContext';
import { useParams } from 'react-router';
import { useJourneyContext } from './JourneyContext';
import { Box } from '@mui/system';

const JourneyStage: FunctionComponent<IJourneyStage> = (props) => {
  const { stages } = useJourneyContext();
  const stage = stages.find((s) => s.id === props.id);

  if (!stage) {
    console.error(`Stage with id ${props.id} not found in the context.`);
    return null;
  }

  const { name, themes, locked, id } = stage;
  const { addAlert } = useContext(AlertContext);
  const { ecosystemName, companyName } = useParams();
  const { updateLockedStatus } = useJourneyContext();

  const [stageProgress, setStageProgress] = useState<IStageProgress>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getStageProgress();
  }, [props]);

  function getStageProgress() {
    const stageProgressCopy = { ...INITIAL_STAGE_PROGRESS };

    themes.forEach(({ milestones }) => {
      milestones.forEach(({ goals }) => {
        if (!goals || goals.length === 0) return;

        goals.forEach(({ progress }) => {
          const key = getProgressKey(progress);
          stageProgressCopy[key] = stageProgressCopy[key] + 1;
        });
      });
    });

    setStageProgress(stageProgressCopy);
  }

  return (
    <Box>
      <Box
        sx={{
          root: {
            padding: '0,625rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            marginBottom: '3px',

            '& p': {
              fontSize: '16px !important',
            },
          },
        }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.875rem',
            paddingBottom: '0.2rem',
          }}>
          {name}
        </Typography>
        <JourneyProgressBar progress={stageProgress} width={140} />
      </Box>
    </Box>
  );
};

export default JourneyStage;
