import { Autocomplete, TextField, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { IEvent } from 'utils/models';
import { AUTOCOMPLETE_LOCATION, getData } from 'utils/requests';
import { useParams } from 'react-router';

interface IAddLocation {
  isLoading: boolean;
  event: IEvent;
  setEvent: (event: IEvent) => void;
}
const AddChooseLocation: FunctionComponent<IAddLocation> = (props) => {
  const { isLoading, event, setEvent } = props;
  const { ecosystemName } = useParams();

  const [location, setLocation] = useState<string>(event.location ?? '');
  const [latitude, setLatitude] = useState<number>(
    event.latitude ? event.latitude : 0.0,
  );

  const [longitude, setLongitude] = useState<number>(
    event.longitude ? event.longitude : 0.0,
  );

  const [autocompleteResults, setAutocompleteResults] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      // Clear timeout on component unmount
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  function debouncedAutocompleteSearch(input: string) {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (input.trim() === '') {
      setAutocompleteResults([]);
      return;
    }

    debounceTimeout.current = setTimeout(async () => {
      try {
        const response = await getData(AUTOCOMPLETE_LOCATION, [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
          {
            name: 'input',
            value: input,
          },
        ]);
        const updatedResults = [
          ...response,
          { description: input, lat: 0.0, lng: 0.0 },
        ];
        setAutocompleteResults(updatedResults);
      } catch (error: any) {
        console.error(error);
      }
    }, 300);
  }

  useEffect(() => {
    if (!location) return;
    debouncedAutocompleteSearch(location);
  }, [location]);

  function handleLocationChange(newLocation: string) {
    setLocation(newLocation);
    setIsDropdownOpen(false);

    const selectedResult = autocompleteResults.find(
      (result) => result.description === newLocation,
    );

    if (selectedResult) {
      setLatitude(selectedResult.lat);
      setLongitude(selectedResult.lng);

      const updatedEvent = {
        ...event,
        location: newLocation,
        latitude: selectedResult.lat,
        longitude: selectedResult.lng,
      };
      setEvent(updatedEvent);
    } else {
      setLatitude(0.0);
      setLongitude(0.0);
      setEvent({
        ...event,
        location: '',
        latitude: 0.0,
        longitude: 0.0,
      });
    }
  }

  return (
    <div>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
        Location
      </Typography>
      <Autocomplete
        options={autocompleteResults.map((result) => result.description)}
        open={isDropdownOpen}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        inputValue={location}
        readOnly={isLoading}
        onInputChange={(event, newInputValue) => {
          if (event !== null) {
            setLocation(newInputValue);
            setIsDropdownOpen(true);
            debouncedAutocompleteSearch(newInputValue);
          }

          if (event && newInputValue === '') {
            setLocation('');
            handleLocationChange('');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
        onChange={(event, newValue) => {
          if (newValue) {
            handleLocationChange(newValue.toString());
          }
        }}
      />
    </div>
  );
};

export default AddChooseLocation;
