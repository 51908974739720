import React, { useState } from 'react';
import { Card, Typography, useTheme } from '@mui/material';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
} from 'utils/models';
import { Box } from '@mui/system';
import { cardShadow, primary } from 'theme';
import { useCompanyTemplateContext } from '../CompanyTemplateContext';
import InputField from 'components/common/InputField';
import { IconPicker } from '../../../common/IconPicker';
import InformationModal from 'components/common/Modal/InformationModal';

interface IAddField {
  isOpen: boolean;
  onClose: () => void;
}

export const CompanyTemplateAddHeaderModal = (props: IAddField) => {
  const { isOpen, onClose } = props;
  const theme = useTheme();
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { sectionField, setSectionField, addSectionField, companyTemplate } =
    useCompanyTemplateContext();

  async function handleAddSectionField() {
    if (!companyTemplate) return;

    if (companyTemplate && companyTemplate.header) {
      const fieldNames = companyTemplate.header.map((field) => field.name);
      if (fieldNames.includes(sectionField.name)) {
        setHasError(true);
        setHelperText('Field name already exists');
        return;
      }
    }

    await addSectionField(CompanyTemplateSectionType.HEADER, sectionField);
    setSectionField({
      name: '',
      type: CompanyProfileFieldType.TEXT,
      required: false,
      icon: '',
    });
    onClose();
  }

  function handleChange(name: string) {
    setHasError(false);
    setHelperText('');

    setSectionField((prevField) => ({
      ...prevField,
      name: name,
    }));
  }

  function renderInputField() {
    return (
      <Box sx={{ width: '100%', minWidth: '16rem' }}>
        <InputField
          placeholder='Name of the field'
          value={sectionField.name}
          onChange={(e) => handleChange(e.target.value)}
          helperText={helperText}
          error={hasError}
        />
      </Box>
    );
  }

  function renderRequiredToggler() {
    return (
      <Box
        onClick={() =>
          setSectionField((prevField) => ({
            ...prevField,
            required: !prevField.required,
          }))
        }
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {sectionField.required ? (
          <IconPicker name='Error' sx={{ color: theme.palette.primary.main }} />
        ) : (
          <IconPicker
            name='ErrorOutline'
            sx={{ color: theme.palette.primary.main }}
          />
        )}
      </Box>
    );
  }

  function renderIconsContainer() {
    const iconsList = [
      'InfoRounded',
      'WorkRounded',
      'LocationOnRounded',
      'TurnedIn',
      'AssignmentIndRounded',
      'SellRounded',
      'CheckCircleRounded',
      'FlagRounded',
      'InsertDriveFileRounded',
      'ApartmentRounded',
      'EventRounded',
      'LocationSearchingRounded',
      'SourceRounded',
      'MonetizationOnRounded',
    ];

    return (
      <>
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}>
          Choose an icon
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.55rem',
            paddingLeft: '0.25rem',
            paddingTop: '1.625rem',
            paddingBottom: '4rem',
            overflow: 'hidden',
          }}>
          {iconsList.map((iconName, index) => (
            <Card
              key={index}
              onClick={() =>
                setSectionField((prevField) => ({
                  ...prevField,
                  icon: iconName,
                }))
              }
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0.625rem',
                cursor: 'pointer',
                boxShadow: cardShadow,
                '&:hover': {
                  '& > svg': {
                    color: `${theme.palette.primary.main} !important`,
                  },
                },
              }}>
              <IconPicker
                name={iconName}
                sx={{
                  width: '1.25rem',
                  height: '1.25rem',
                  color:
                    sectionField.icon === iconName
                      ? theme.palette.primary.main
                      : primary.natural4,
                }}
              />
            </Card>
          ))}
        </Box>
      </>
    );
  }

  return (
    <InformationModal
      isOpen={isOpen || false}
      handleClose={onClose}
      headerText='Add field'
      width='34rem'
      primaryText='Add'
      primaryOnClick={handleAddSectionField}
      secondaryText='Cancel'
      secondaryOnClick={onClose}>
      <Box>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            my: '1rem',
          }}>
          Mark field with
          <IconPicker name='Error' sx={{ color: theme.palette.primary.main }} />
          icon if you want it to be mandatory
        </Typography>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              paddingRight: '2rem',
              paddingBottom: '1.625rem',
            }}>
            {renderInputField()}
            {renderRequiredToggler()}
          </Box>
          {renderIconsContainer()}
        </Box>
      </Box>
    </InformationModal>
  );
};
