import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { IApplicationTemplate } from 'utils/models';
import { APPLICATION_PROCESS_EDIT, putData } from 'utils/requests';
import { removeStoredEditedApplication } from 'utils/storage';

interface IUsePutProcess {
  newApplicationSchema: Partial<IApplicationTemplate> | null;
  setIsLoading: (b: boolean) => void;
  setIsOpenModal: (b: boolean) => void;
  id: number | string | undefined;
  defaultTemplate: IApplicationTemplate;
}

const usePutProcess = (props: IUsePutProcess) => {
  const {
    newApplicationSchema,
    setIsLoading,
    setIsOpenModal,
    id,
    defaultTemplate,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const editedFormWithId = {
    ...newApplicationSchema,
    sections: newApplicationSchema
      ? newApplicationSchema.sections
      : defaultTemplate,
  };

  async function putNewApplication() {
    setIsLoading(true);
    try {
      const data = await putData(
        APPLICATION_PROCESS_EDIT,
        [
          {
            name: 'ecosystemName',
            value: ecosystemName,
          },
          { name: 'applicationProcessId', value: id },
        ],
        editedFormWithId,
      );

      setIsOpenModal(true);
      removeStoredEditedApplication(id as number);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return putNewApplication;
};

export default usePutProcess;
