import { Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { primary } from 'theme';
import {
  AccessTimeFilledRounded,
  Phone,
  EmailRounded,
  LocationOn,
  Error,
} from '@mui/icons-material';
import InputField from 'components/common/InputField';
import {
  MEETING_CANCEL_USER,
  MEETING_HANDLE_REQUEST,
  deleteData,
  putData,
} from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import { formatDistanceToNow } from 'date-fns';
import { IRequest } from 'utils/models';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import CommonButton from 'components/common/CommonButton';
import InformationModal from 'components/common/Modal/InformationModal';

interface RequestProps {
  request: IRequest;
  isMyRequests: boolean;
  setRequests: (requests: any) => void;
}

const BookingRequest: FunctionComponent<RequestProps> = (props) => {
  const { request, isMyRequests, setRequests } = props;

  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [isDeny, setIsDeny] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [optionalMessage, setOptionalMessage] = useState<string>('');
  const theme = useTheme();
  const timeDifferance = formatDistanceToNow(new Date(request.requestTime), {
    addSuffix: true,
  });

  function viewProfile() {
    navigate(
      `/ecosystem/${ecosystemName}/members/${
        isMyRequests ? request.bookedUser.id : request.bookingUser.id
      }`,
    );
  }

  async function handleAccept() {
    setIsLoading(true);

    try {
      await putData(MEETING_HANDLE_REQUEST, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'meetingId', value: request.id },
        { name: 'status', value: isAccept },
        {
          name: 'message',
          value: optionalMessage,
        },
      ]);

      setRequests((prevRequests: IRequest[]) =>
        prevRequests.filter((req: IRequest) => req.id !== request.id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Meeting ${isAccept ? 'accepted' : 'rejected'}`,
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  async function handleDeny() {
    setIsLoading(true);

    try {
      await deleteData(MEETING_CANCEL_USER, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'meetingId', value: request.id },
        {
          name: 'message',
          value: optionalMessage,
        },
      ]);

      setRequests((prevRequests: IRequest[]) =>
        prevRequests.filter((req: IRequest) => req.id !== request.id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Meeting canceled',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderRequestSender() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '2rem',
          marginLeft: '2rem',
        }}>
        <Box
          sx={{
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            overflow: 'hidden',
            cursor: 'pointer',
            marginRight: '0.5rem',
          }}
          onClick={viewProfile}>
          <img
            src={request.bookingUser.profilePic}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '0.5rem',
          }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {isMyRequests
                ? request.bookedUser.fullName
                : request.bookingUser.fullName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                color: theme.palette.primary.main,
                fontSize: '0.875rem',
              }}>
              {isMyRequests
                ? request.bookedUser.accessLevelName
                : request.bookingUser.accessLevelName}
            </Typography>
          </Box>
        </Box>
        <Box>
          <AccessLevelTagCard
            accessLevelName={request.bookingUser.accessLevelName}
            accessLevelColor={request.bookingUser.accessLevelColor}
            height={'1.5rem'}
            borderRadius={'5px 0 0 5px'}
            sx={{ borderRadius: '7px 5px 5px 7px', marginBottom: '1.2rem' }}
          />
        </Box>
      </Box>
    );
  }

  function renderMeetingDetails() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '70%',
          width: { sm: '90%', lg: '56rem' },
          marginLeft: '1.5rem',
        }}>
        <Typography
          sx={{
            color: primary.mainBlackFont,
            fontWeight: 600,
            fontSize: '1rem',
            paddingTop: { xs: '2rem', sm: '5rem', md: '6rem' },
          }}>
          {request.topic || 'Default Name'}
        </Typography>
        <Typography
          fontSize={'1rem'}
          textOverflow='ellipsis'
          sx={{ paddingTop: '0.2rem', lineHeight: '1.25rem' }}>
          {request.info || 'The user did not provide additional information'}
        </Typography>
      </Box>
    );
  }

  function renderRequestDetails() {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: '1rem',
          marginLeft: '2rem',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '0.5rem',
        }}>
        <Box sx={{ display: 'flex' }}>
          <AccessTimeFilledRounded sx={{ color: theme.palette.primary.main }} />
          <Typography
            fontSize={'1rem'}
            fontWeight={400}
            color={primary.mainBlackFont}
            sx={{ marginLeft: '1rem' }}>
            {`${request.startTime} - ${request.endTime || 'Unknown'}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Phone sx={{ color: theme.palette.primary.main }} />
          <Typography
            fontSize={'1rem'}
            fontWeight={400}
            color={primary.mainBlackFont}
            sx={{ marginLeft: '1rem' }}>
            {`${request.bookingUser.phone || 'Unknown'}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <EmailRounded sx={{ color: theme.palette.primary.main }} />
          <Typography
            fontSize={'1rem'}
            fontWeight={400}
            color={primary.mainBlackFont}
            sx={{ marginLeft: '1rem' }}>
            {`${request.bookingUser.emailEcoPreferred || 'Unknown'}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <LocationOn sx={{ color: theme.palette.primary.main }} />
          <Typography
            fontSize={'1rem'}
            fontWeight={400}
            color={primary.mainBlackFont}
            sx={{ marginLeft: '1rem' }}>
            {`${request.platform || 'Unknown'}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { sm: 'column-reverse', md: 'row' },
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginRight: '2rem',
          paddingTop: { xs: '1rem' },
          height: '100%',
          width: '100%',
        }}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            color: primary.natural2,
            marginRight: '1rem',
            paddingTop: { xs: '1rem', sm: '0rem', md: '2rem' },
          }}>
          {`Requested ${timeDifferance}`}
        </Typography>
        <Box>
          {isMyRequests && (
            <CommonButton
              onClick={() => setIsCancel(true)}
              text='Cancel request'
              color={theme.palette.primary.main}
              bgcolor={theme.palette.primary.light}
            />
          )}
          {!isMyRequests && (
            <>
              <CommonButton
                onClick={() => setIsDeny(true)}
                text='Deny'
                color={theme.palette.primary.main}
                bgcolor={theme.palette.primary.light}
              />
              <CommonButton
                onClick={() => setIsAccept(true)}
                text='Accept'
                color={theme.palette.primary.light}
                bgcolor={theme.palette.primary.main}
                sx={{
                  marginLeft: '1rem',
                }}
              />
            </>
          )}
        </Box>
      </Box>
    );
  }

  function renderAcceptForm() {
    return (
      <InformationModal
        isOpen={isAccept}
        handleClose={() => setIsAccept(false)}
        primaryText='Confirm'
        primaryOnClick={handleAccept}
        secondaryText={'Cancel'}
        secondaryOnClick={() => setIsAccept(false)}
        headerText={'Do you wish to accept this meeting request?'}>
        <div>
          You are accepting the request ´
          <Typography sx={{ fontWeight: 600 }} component='span'>
            {request.topic}
          </Typography>
          ´
        </div>
      </InformationModal>
    );
  }

  function renderDenyForm() {
    return (
      <InformationModal
        isOpen={isDeny}
        handleClose={() => setIsDeny(false)}
        primaryText='Confirm'
        primaryOnClick={handleDeny}
        secondaryText={'Cancel'}
        secondaryOnClick={() => setIsDeny(false)}
        headerText={'Are you sure you want to deny this meeting request?'}
        primaryBgColor={primary.warningRed}>
        <Box>
          <Typography
            sx={{
              borderBottom: '1px solid #E0E0E0',
              paddingBottom: '1rem',
              marginBottom: '1rem',
            }}>
            <Error
              sx={{
                color: primary.warningRed,
                marginRight: '0.5rem',
                marginTop: '0.5rem',
              }}
            />
            Denying the meeting request is permanent. Please confirm your choice
            carefully.
          </Typography>
          <InputField
            value={optionalMessage}
            label='Send an optional message'
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setOptionalMessage(event.target.value)
            }
          />
        </Box>
      </InformationModal>
    );
  }

  function renderCancelForm() {
    return (
      <InformationModal
        isOpen={isCancel}
        handleClose={() => setIsCancel(false)}
        primaryText='Confirm'
        primaryOnClick={handleDeny}
        secondaryText={'Cancel'}
        secondaryOnClick={() => setIsCancel(false)}
        headerText={'Are you sure you want to cancel this meeting request?'}
        primaryBgColor={primary.warningRed}>
        <Box>
          <Typography
            sx={{
              borderBottom: '1px solid #E0E0E0',
              paddingBottom: '1rem',
              marginBottom: '1rem',
            }}>
            <Error
              sx={{
                color: primary.warningRed,
                marginRight: '0.5rem',
                marginTop: '0.5rem',
              }}
            />
            Cancelling the meeting request is permanent. Please confirm your
            choice carefully.
          </Typography>
          <InputField
            value={optionalMessage}
            label='Send an optional message'
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setOptionalMessage(event.target.value)
            }
          />
        </Box>
      </InformationModal>
    );
  }

  return (
    <Box
      sx={{
        height: 'auto',
        width: {
          xs: '100%',
          md: '60rem',
          lg: '78rem',
        },
        bgcolor: '#FFFFFF',
        boxShadow: '0px 20px 27px 0px #0000000D',
        borderRadius: '12px',
        marginY: '1rem ',
      }}>
      {isLoading ? (
        <Loading marginTop={-150} />
      ) : isAccept ? (
        <>{renderAcceptForm()}</>
      ) : isDeny ? (
        <>{renderDenyForm()}</>
      ) : isCancel ? (
        <>{renderCancelForm()}</>
      ) : (
        <Grid container sx={{ pb: '2rem', pr: '1rem' }}>
          <Grid item xs={12} sm={5} md={4}>
            {renderRequestSender()}
            {renderRequestDetails()}
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            sx={{
              padding: { xs: '1rem', md: 0 },
              paddingLeft: { xs: '1rem', md: '0.5rem' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              width: '100%',
            }}>
            {renderMeetingDetails()}
            {renderButtons()}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BookingRequest;
