import React, { FunctionComponent, useContext, useState } from 'react';
import { Box, Fade, Typography } from '@mui/material';
import { deleteData, JOURNEY_TEMPLATES_DELETE_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/common/Loading';
import { useJourneyTemplateContext } from './JourneyTemplateContext';
import InformationModal from 'components/common/Modal/InformationModal';
import { primary } from 'theme';
import { Delete, Error } from '@mui/icons-material';
import CommonButton from 'components/common/CommonButton';

interface IJourneyTemplateDelete {}

const JourneyTemplateDelete: FunctionComponent<IJourneyTemplateDelete> = (
  props,
) => {
  const { ecosystemName, journeyId } = useParams();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContext);
  const { journey } = useJourneyTemplateContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function renderModal() {
    return (
      <InformationModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        headerText='Are you sure you want to delete this journey?'
        primaryText='Delete'
        primaryOnClick={deleteTemplate}
        primaryBgColor={primary.warningRed}
        secondaryText='Cancel'
        secondaryOnClick={() => setIsOpen(false)}
        headerSize='1.875rem'
        width='38rem'
        buttonSx={{
          '&:hover': {
            color: '#CC2D2D',
            backgroundColor: primary.warningHover,
          },
        }}>
        <Box sx={{ width: '30rem', marginY: '2rem', display: 'flex' }}>
          <Error sx={{ color: primary.warningRed, marginRight: '1rem' }} />
          <Typography>The journey will be permanently deleted.</Typography>
        </Box>
      </InformationModal>
    );
  }

  async function deleteTemplate() {
    setLoading(true);

    try {
      await deleteData(JOURNEY_TEMPLATES_DELETE_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: journey?.id },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Journey deleted',
      });

      navigate(`/ecosystem/${ecosystemName}/admin/journeys`);
    } catch (e: any) {
      setLoading(false);

      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  return !journey?.id ? null : (
    <Fade in={true} timeout={600}>
      <div>
        {loading ? (
          <Loading size={30} height={80} />
        ) : (
          <>
            <CommonButton
              text='Delete'
              bgcolor={primary.warningRed}
              hoverColor={{
                color: primary.warningRed,
                bgcolor: primary.warningHover,
              }}
              icon={<Delete />}
              onClick={() => setIsOpen(true)}
              sx={{ margin: '1rem' }}
            />
            {renderModal()}
          </>
        )}
      </div>
    </Fade>
  );
};

export default JourneyTemplateDelete;
