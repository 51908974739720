import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  ButtonBase,
  Input,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IJourneyGoal, IJourneyResources } from 'utils/models';
import LaunchIcon from '@mui/icons-material/Launch';
import JourneyGoalProgress from './JourneyGoalProgress';
import JourneyGoalResources from './JourneyGoalResources';
import JourneyGoalDeadline from './JourneyGoalDeadline';
import JourneyGoalAssignedPeople from './JourneyGoalAssignedPeople';
import JourneyGoalCustomResources from './JourneyGoalCustomResources';
import { useJourneyContext } from './JourneyContext';
import { Delete } from '@mui/icons-material';
import TypographyBox from '../common/TypographyBox';
import { primary } from 'theme';

const formatNumOfWithBrackets = (num: number) => {
  return `(${num})`;
};

const useStyles = makeStyles((theme: Theme) => ({

  aside: {
    width: '175px',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.primary.dark,
    },

    '& p': {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  pastDeadline: {
    backgroundColor: '#FF5252 !important',
    '& p': {
      color: '#FFEBEE !important',
    },
    '& svg': {
      color: '#FFEBEE !important',
      marginRight: '10px',
    },
  },
  member: {
    height: '37px',
    width: 'auto',
    borderRadius: '50%',
  },
  members: {
    display: 'flex',
    position: 'relative',
    minWidth: '10rem',
    right: '17px',
    overflow: 'hidden',
  },
}));

function getTotalResources(resources: IJourneyResources) {
  const { files, persons, links } = resources;
  return files.length + persons.length + links.length;
}

interface IJourneyGoalProps {
  themeIndex: number;
  milestoneIndex: number;
  index: number;
  goal: IJourneyGoal;
  isLast: boolean;
  isCustom?: boolean;
  isCustomMilestone?: boolean;
}

const JourneyGoal: FunctionComponent<IJourneyGoalProps> = (props) => {
  const {
    index,
    goal,
    isLast,
    themeIndex,
    milestoneIndex,
    isCustom,
    isCustomMilestone,
  } = props;
  const { resources, progress, deadline } = goal;
  const classes = useStyles({ isLast, isCustom });

  const theme = useTheme();

  const [showResources, setShowResources] = useState<boolean>(false);
  const [openCustomResources, setOpenCustomResources] =
    useState<boolean>(false);
  const { isEditJourneyContext, editCustomGoal, deleteCustomGoal } =
    useJourneyContext();
  const showEdit = isCustom && isEditJourneyContext;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Box
        sx={{
          borderBottom: (props: any) =>
            props.isLast ? 'none' : '1px solid #E0E0E0',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: (props: any) =>
            props.isCustom ? '#fffcef' : 'inherit',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '2.5rem',
            position: 'relative',
            width: { sm: '33%', md: '40%', lg: '60%' },
          }}>
          {showEdit ? (
            <Input
              value={goal.name}
              placeholder='New custom activity'
              onChange={(e) =>
                editCustomGoal(
                  themeIndex,
                  milestoneIndex,
                  index,
                  e.target.value,
                  isCustomMilestone,
                )
              }
              sx={{ fontSize: 13, minWidth: 285, marginRight: 1 }}
            />
          ) : (
            <TypographyBox
              text={goal.name}
              sx={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}
            />
          )}
        </Box>
        {showEdit && (
          <Button
            sx={{ marginRight: 1 }}
            onClick={() =>
              deleteCustomGoal(
                themeIndex,
                milestoneIndex,
                index,
                isCustomMilestone,
              )
            }>
            <Delete sx={{ color: theme.palette.primary.dark }} />
          </Button>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '2.5rem',
            position: 'relative',
            width: { sm: '33%', md: '25%', lg: '25%' },
          }}>
          {!(isEditJourneyContext && isCustom) && (
            <JourneyGoalDeadline
              progress={progress}
              deadline={deadline ? new Date(deadline) : null}
              goalId={goal.id}
              isCustom={isCustom}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { sm: 'center', md: 'flex-start' },
            height: '2.5rem',
            position: 'relative',
            width: { sm: '33%', md: '25%', lg: '25%' },
            cursor: 'pointer',
          }}
          onClick={() => setShowResources(true)}>
          {!(isEditJourneyContext && isCustom) && (
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <ButtonBase>
                <LaunchIcon />
              </ButtonBase>
              <Typography sx={{fontSize: '0.8rem', fontWeight: 500, color: primary.natural2, marginLeft: '0.5rem'}}>
                {formatNumOfWithBrackets(getTotalResources(resources))}
              </Typography>
            </Box>
          )}
        </Box>

        {!(isEditJourneyContext && isCustom) && (
          <JourneyGoalAssignedPeople goal={goal} isCustom={isCustom} />
        )}
        {!(isEditJourneyContext && isCustom) && (
          <JourneyGoalProgress
            themeIndex={themeIndex}
            milestoneIndex={milestoneIndex}
            progress={progress}
            isLast={isLast}
            goalId={goal.id}
            isCustom={isCustom}
            isCustomMilestone={isCustomMilestone}
          />
        )}
      </Box>
      {!(isEditJourneyContext && isCustom) && !isCustom ? (
        <JourneyGoalResources
          goal={goal}
          open={showResources}
          close={() => setShowResources(false)}
        />
      ) : (
        !isEditJourneyContext && (
          <JourneyGoalCustomResources
            goal={goal}
            open={showResources}
            setOpen={setShowResources}
            themeIndex={themeIndex}
            milestoneIndex={milestoneIndex}
            goalIndex={index}
            isCustomMilestone={isCustomMilestone}
          />
        )
      )}
    </Box>
  );
};

export default JourneyGoal;
