import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, Fade, Theme, Typography, useTheme } from '@mui/material';
import CompanySection from '../CompanySection';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Modal from 'components/common/Modal';
import CompanyGoalsHistory from './CompanyGoalsHistory';
import ContWithStyledScroll from 'components/common/ContWithStyledScroll';
import {
  COMPANY_ACHIEVED_GOAL_ROUTE,
  COMPANY_ADD_GOAL_ROUTE,
  COMPANY_DELETE_GOAL_ROUTE,
  COMPANY_UPDATE_GOAL_ROUTE,
  deleteData,
  patchData,
  postData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ICompanyGoal } from 'utils/models';
import AddTaskIcon from '@mui/icons-material/AddTask';
import InputField from 'components/common/InputField';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { TransitionGroup } from 'react-transition-group';
import { ONE_DAY } from 'utils/utils';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '12.5px',
  },
  cont: {
    minHeight: '4.375rem',
    maxHeight: '135px',
    overflowY: 'auto',
    marginLeft: '10px',

    '& ul': {
      padding: '0 !important',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '5px 7.5px',
    transition: '0.3s all linear',
    borderRadius: '4px',
    borderBottom: '1px solid #eee',
    marginRight: '10px',

    '&:hover': {
      backgroundColor: '#f5f5f5',
    },

    '& svg': {
      height: '20px',
      width: '20px',
      color: theme.palette.primary.main,
      marginRight: '20px',
      marginBottom: '-5px',
      marginLeft: '5px',
    },

    '& h6': {
      textTransform: 'capitalize',
      fontSize: '0.8rem !important',
    },

    '& button': {
      width: '30px !important',
      minWidth: '30px !important',
      padding: '5px !important',

      '&:disabled': {
        opacity: 0.5,
      },

      '& svg': {
        color: theme.palette.primary.dark,
        marginRight: '0px',
        marginBottom: '0px',
        marginLeft: '0px',
      },
    },
  },
  aside: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '10px',
      color: theme.palette.primary.dark,
    },

    '& h6': {
      width: '10rem',
      marginLeft: '10px',
      marginRight: '20px',
      padding: '6px 2.5px',
      paddingRight: '10px',
      borderRadius: '4px',
      backgroundColor: theme.palette.primary.light,
      textAlign: 'center',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3px',

    '& button': {
      padding: '5px 7.5px !important',
      borderRadius: '4px !important',
      marginRight: '5px',

      '&:disabled': {
        opacity: 0.5,
      },

      '& p': {
        marginTop: '3px',
        fontSize: '0.7rem !important',
        color: theme.palette.primary.dark,
        textDecoration: 'underline !important',
      },

      '& h6': {
        fontSize: '0.85rem !important',
        color: theme.palette.primary.dark,
      },

      '& svg': {
        height: '20px',
        width: '20px',
        marginRight: '5px',
        marginBottom: '-5px',
        color: theme.palette.primary.dark,
      },
    },
  },
  newGoal: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    margin: '0.45rem 1.5rem',
    marginRight: '2.35rem',
  },
  pastDeadline: {
    backgroundColor: '#FF5252 !important',
    color: '#FFEBEE !important',

    '& svg': {
      color: '#FFEBEE !important',
    },
  },
  datePicker: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: '20rem',
      maxHeight: '50px',
      borderRadius: '5px',
    },
  },
}));

export interface ICompanyGoals {
  goals: ICompanyGoal[];
  updateGoals: (goals: ICompanyGoal[]) => void;
  companyName: string;
  canEditCompanyProfile?: boolean;
}

const CompanyGoals: FunctionComponent<ICompanyGoals> = (props) => {
  const { goals, updateGoals, companyName, canEditCompanyProfile } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [showNewGoal, setShowNewGoal] = useState<boolean>(false);
  const [newGoalTitle, setNewGoalTitle] = useState<string>('');
  const [newGoalDeadline, setNewGoalDeadline] = useState<Date | null>(null);

  const [editGoal, setEditGoal] = useState<ICompanyGoal | null>(null);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [editedDeadline, setEditedDeadline] = useState<Date | null>(null);

  const [allGoals, setAllGoals] = useState<ICompanyGoal[]>(sortGoals(goals));
  const activeGoals = allGoals.filter(({ achieved }) => !achieved);

  const [seeGoalHistory, setSeeGoalHistory] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('keypress', addGoalOnKeyPress);
    return () => window.removeEventListener('keypress', addGoalOnKeyPress);
  }, [showNewGoal]);

  useEffect(() => {
    setAllGoals(goals);
  }, [goals]);

  useEffect(() => {
    if (!!editGoal) {
      setEditedTitle(editGoal.goalTitle);
      setEditedDeadline(new Date(editGoal.deadline));
    }
  }, [editGoal]);

  async function addGoalOnKeyPress(e: KeyboardEvent) {
    if (e.key === 'Enter' && showNewGoal) {
      await saveNewGoal();
    }
  }

  async function markGoalAsAchieved(goalId: number) {
    setLoading(true);

    try {
      const achievedDate = new Date().toDateString();
      await patchData(COMPANY_ACHIEVED_GOAL_ROUTE, [
        {
          name: 'goalId',
          value: goalId,
        },
        { name: 'achievedDate', value: achievedDate },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      updateGoals(
        allGoals.map((goal) => {
          if (goal.id === goalId) {
            return {
              ...goal,
              achievedDate,
              achieved: true,
            };
          }

          return goal;
        }),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Goal marked as achieved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function updateGoal() {
    setLoading(true);

    try {
      const deadline = (editedDeadline as Date).toDateString();
      await patchData(COMPANY_UPDATE_GOAL_ROUTE, [
        { name: 'goalId', value: editGoal?.id },
        { name: 'goalDeadline', value: deadline },
        { name: 'goalTitle', value: encodeURIComponent(editedTitle) },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      updateGoals(
        allGoals.map((goal) => {
          if (goal.id === editGoal?.id) {
            return {
              ...goal,
              deadline,
              goalTitle: editedTitle,
            };
          }

          return goal;
        }),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Goal updated',
      });

      clearEditGoal();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function saveNewGoal() {
    setLoading(true);
    const deadline = (newGoalDeadline as Date).toDateString();

    try {
      const data = await postData(COMPANY_ADD_GOAL_ROUTE, [
        { name: 'goalValue', value: encodeURIComponent(newGoalTitle) },
        { name: 'goalDate', value: deadline },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      updateGoals([...allGoals, data]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'New goal added',
      });

      clearNewGoal();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  async function deleteGoal(id: number) {
    setLoading(true);

    try {
      await deleteData(COMPANY_DELETE_GOAL_ROUTE, [
        { name: 'goalId', value: id },
        { name: 'companyName', value: encodeURIComponent(companyName) },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Goal deleted',
      });

      updateGoals(allGoals.filter((goal) => goal.id !== id));
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function clearNewGoal() {
    setShowNewGoal(false);
    setNewGoalTitle('');
    setNewGoalDeadline(null);
  }

  function clearEditGoal() {
    setEditGoal(null);
    setEditedTitle('');
    setEditedDeadline(null);
  }

  function pastDeadline(deadline: Date) {
    return new Date().getTime() > deadline.getTime() + ONE_DAY;
  }

  function sortGoals(goals: ICompanyGoal[]) {
    return goals.sort((a, b) => {
      if (!a.achieved && !b.achieved) {
        return new Date(a.deadline).getTime() < new Date(b.deadline).getTime()
          ? -1
          : 1;
      }

      if (a.achieved && b.achieved) {
        return new Date(a.achievedDate).getTime() <
          new Date(b.achievedDate).getTime()
          ? -1
          : 1;
      }

      if (!a.achieved && b.achieved) return -1;
      if (a.achieved && !b.achieved) return 1;
      return 0;
    });
  }

  function renderNewGoal() {
    return (
      <Fade in={true}>
        <div className={classes.newGoal}>
          <div style={{ minWidth: '28.125rem' }}>
            <InputField
              value={newGoalTitle}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNewGoalTitle(event.target.value)
              }
              placeholder={'Goal title'}
              readonly={loading}
            />
          </div>
          <DatePicker
            className={classes.datePicker}
            format='dd/MM/yyyy'
            value={newGoalDeadline}
            onChange={setNewGoalDeadline}
          />
        </div>
      </Fade>
    );
  }

  function renderEditGoal() {
    return (
      <Fade in={true}>
        <div className={classes.newGoal}>
          <div style={{ minWidth: '21.125rem', marginRight: '0.875rem' }}>
            <InputField
              value={editedTitle}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEditedTitle(event.target.value)
              }
              placeholder={'Goal title'}
              readonly={loading}
            />
          </div>
          <DatePicker
            className={classes.datePicker}
            format='dd/MM/yyyy'
            value={editedDeadline}
            onChange={setEditedDeadline}
          />
        </div>
      </Fade>
    );
  }

  function renderActiveGoals() {
    return activeGoals.map((goal) => {
      const { id, goalTitle, deadline } = goal;

      return (
        <Collapse key={`goal-active-main-${id}`}>
          <Fade in={true} timeout={1000}>
            <li className={classes.listItem}>
              <Typography variant={'h6'}>
                <Button
                  disabled={loading || !canEditCompanyProfile}
                  onClick={() => markGoalAsAchieved(id)}
                  sx={{
                    marginRight: '20px',
                  }}>
                  <CheckCircleOutlineIcon />
                </Button>
                {goalTitle}
              </Typography>
              <div className={classes.aside}>
                <Typography
                  variant={'h6'}
                  className={clsx({
                    [classes.pastDeadline]: pastDeadline(new Date(deadline)),
                  })}>
                  <DateRangeIcon />
                  {deadline}
                </Typography>
                {canEditCompanyProfile && (
                  <Button
                    disabled={loading || showNewGoal}
                    onClick={() => setEditGoal(goal)}>
                    <EditIcon />
                  </Button>
                )}
                {canEditCompanyProfile && (
                  <Button disabled={loading} onClick={() => deleteGoal(id)}>
                    <DeleteForeverIcon />
                  </Button>
                )}
              </div>
            </li>
          </Fade>
        </Collapse>
      );
    });
  }

  function renderContent() {
    if (activeGoals.length === 0 && !showNewGoal) {
      return (
        <Typography
          variant={'body2'}
          sx={{
            margin: '0 1rem !important',
            color: '#9e9e9e !important',
          }}>
          All set! No active goals remaining
        </Typography>
      );
    }

    return (
      <ul style={{ paddingLeft: 0, marginTop: 0, listStyleType: 'none' }}>
        <TransitionGroup>{renderActiveGoals()}</TransitionGroup>
      </ul>
    );
  }

  function renderActions() {
    return (
      <div className={classes.actions}>
        {canEditCompanyProfile && (
          <Button
            disabled={
              loading ||
              (showNewGoal && !newGoalTitle) ||
              (showNewGoal && !newGoalDeadline)
            }
            onClick={() =>
              showNewGoal ? saveNewGoal() : setShowNewGoal(true)
            }>
            <Typography variant={'h6'}>
              {showNewGoal ? <AddTaskIcon /> : <AddCircleOutlineIcon />}
              {showNewGoal ? 'Add Goal' : 'New Goal'}
            </Typography>
          </Button>
        )}
        <Button
          style={{
            color: showNewGoal ? theme.palette.primary.dark : 'inherit',
          }}
          onClick={() =>
            showNewGoal ? clearNewGoal() : setSeeGoalHistory(true)
          }>
          <Fade in={true}>
            <Typography variant={'body2'}>
              {showNewGoal ? 'Cancel' : 'View Goal History'}
            </Typography>
          </Fade>
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <CompanySection
          title={'Active Goals'}
          simple={true}
          noPadding={true}
          headerAction={renderActions()}>
          <>
            {showNewGoal && renderNewGoal()}
            <ContWithStyledScroll className={classes.cont}>
              {renderContent()}
            </ContWithStyledScroll>
          </>
        </CompanySection>
      </div>
      <Modal
        open={seeGoalHistory}
        noPadding={true}
        loading={loading}
        close={() => setSeeGoalHistory(false)}
        title={'Goal History'}
        color={theme.palette.primary.dark}>
        <CompanyGoalsHistory
          goals={allGoals}
          pastDeadline={pastDeadline}
          updateGoals={updateGoals}
          companyName={companyName}
        />
      </Modal>
      <Modal
        open={!!editGoal && !showNewGoal}
        noPadding={true}
        loading={loading}
        width={700}
        disabledSave={
          editedTitle === editGoal?.goalTitle &&
          editedDeadline?.toDateString() ===
            new Date(editGoal.deadline).toDateString()
        }
        close={() => clearEditGoal()}
        title={'Edit Goal'}
        showFooter={true}
        largeFooter={true}
        saveAction={() => updateGoal()}
        color={theme.palette.primary.dark}>
        <div style={{ marginTop: '1.5rem' }}>{renderEditGoal()}</div>
      </Modal>
    </>
  );
};

export default CompanyGoals;
