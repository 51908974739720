import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { formatBytes } from 'utils/utils';
import ArticleIcon from '@mui/icons-material/Article';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    paddingBottom: '10px',
  },
  zone: {
    width: '100%',
    height: '12.5rem',
    borderRadius: '12px',
    border: `2px dotted ${theme.palette.primary.dark}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',

    '& button': {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      color: 'white !important',
      textTransform: 'capitalize',
      borderRadius: '4px',
      padding: '7.5px 15px',
      fontSize: '0.8rem',
      margin: '10px 0',
      zIndex: 100,
    },

    '& svg': {
      height: '3.75rem',
      width: '3.75rem',
      color: theme.palette.primary.dark,
      marginBottom: '10px',
      zIndex: 100,
    },

    '& h6': {
      fontSize: '0.75rem !important',
      zIndex: 100,
    },

    '& p': {
      fontSize: '0.6rem !important',
      zIndex: 100,
    },
  },
  fileUploadField: {
    display: 'none',
  },
  zoneIndicator: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#eee',
    borderRadius: '12px',
    zIndex: 99,
  },
}));

interface IFileUploadZone {
  setFile: (file: any) => void;
  disabled?: boolean;
}

const FileUploadZone: FunctionComponent<IFileUploadZone> = (props) => {
  const { setFile, disabled } = props;
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<any | null>(null);

  useEffect(() => {
    if (!!fileToUpload) {
      setFile(fileToUpload);
    }
  }, [fileToUpload]);

  function handleDrag(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    }

    if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileToUpload(e.dataTransfer.files[0]);
    }
  }

  function handleChange(e: any) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      setFileToUpload(e.target.files[0]);
    }
  }

  function setTitle() {
    if (!fileToUpload) {
      return 'Drag and drop filer here or';
    }

    return `${fileToUpload.name} - ${formatBytes(fileToUpload.size)}`;
  }

  return (
    <div className={classes.root}>
      <input
        ref={inputRef}
        className={classes.fileUploadField}
        type='file'
        accept={
          'application/pdf,application/vnd.ms-excel,text/plain,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
        multiple={true}
        onChange={handleChange}
      />
      <form
        className={classes.zone}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}>
        {fileToUpload ? <ArticleIcon /> : <CloudUploadIcon />}
        <Typography variant={'h6'}>{setTitle()}</Typography>
        <Button
          onClick={() => inputRef.current && inputRef.current.click()}
          disabled={disabled}>
          Browse Files
        </Button>
        <Typography variant={'body2'}>*Limit per file: 150mb</Typography>
        {dragActive && !disabled && (
          <div
            className={classes.zoneIndicator}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}></div>
        )}
      </form>
    </div>
  );
};

export default FileUploadZone;
