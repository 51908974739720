import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material';
import { useJourneyContext } from '../Journey/JourneyContext';
import { Box } from '@mui/system';

interface IJourneyStageWrapper {
  selected: boolean;
  index: number;
  onClick: () => void;
  children: React.ReactNode;
  locked?: boolean | undefined;
}

const JourneyStageWrapper: FunctionComponent<IJourneyStageWrapper> = (
  props,
) => {
  const { selected, index, onClick, children, locked } = props;
  const { isEditJourneyContext } = useJourneyContext();
  const theme = useTheme();

  if (locked) {
    return (
      <Box
        sx={{
          height: '3.75rem',
          width: '100%',
          position: 'relative',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom: { xs: '1rem', sm: '0rem' },
          gap: '0.35rem',
          transition: '0.3s ease-in-out all',
          padding: '1.563rem',
          cursor: 'default',
          border: `2px solid ${
            selected
              ? theme.palette.primary.main
              : isEditJourneyContext
              ? theme.palette.grey
              : '#E0F2F1'
          }`,
          backgroundColor:
            isEditJourneyContext && !selected ? '#d4d4d4' : '#E0F2F1',
        }}>
        <Box
          sx={{
            height: '1.875rem',
            width: '1.875rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 600,
            fontSize: '0.875rem',
            position: 'absolute',
            left: '-1rem',
            top: '0.75rem',
            transition: '0.3s ease-in-out all',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '50%',
            backgroundColor: selected
              ? theme.palette.primary.main
              : isEditJourneyContext
              ? 'white'
              : 'white',
            color: selected
              ? 'white'
              : isEditJourneyContext
              ? 'grey'
              : theme.palette.primary.main,
          }}>
          {index + 1}
        </Box>
        {children}
      </Box>
    );
  }

  return (
    <Box
      onClick={onClick}
      tabIndex={index}
      sx={{
        height: '3.75rem',
        width: '100%',
        position: 'relative',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        marginBottom: { xs: '1rem', sm: '0rem' },
        gap: '0.35rem',
        transition: '0.3s ease-in-out all',
        padding: '1.563rem',
        cursor: 'pointer',
        border: `1px solid ${
          selected
            ? theme.palette.primary.main
            : isEditJourneyContext
            ? theme.palette.grey
            : '#E0F2F1'
        }`,
        backgroundColor:
          isEditJourneyContext && !selected ? '#d4d4d4' : '#E0F2F1',
      }}>
      <Box
        sx={{
          height: '1.875rem',
          width: '1.875rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 600,
          fontSize: '0.875rem',
          position: 'absolute',
          left: '-1rem',
          top: '0.75rem',
          transition: '0.3s ease-in-out all',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '50%',
          backgroundColor: selected
            ? theme.palette.primary.main
            : isEditJourneyContext
            ? 'white'
            : 'white',
          color: selected
            ? 'white'
            : isEditJourneyContext
            ? 'grey'
            : theme.palette.primary.main,
        }}>
        {index + 1}
      </Box>
      {children}
    </Box>
  );
};

export default JourneyStageWrapper;
