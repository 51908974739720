import { useContext } from 'react';
import { APPLICATION_STATUS_ROUTE, patchData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { IApplicationOverview /* , IApplicationStatus */ } from 'utils/models';
interface IRenameColumnProps {
  applicationOverviewData: IApplicationOverview | null;
  ecosystemName: string | undefined;
  selectedApplicationProcessId: string | null;
  statusId: number;
  newColumnName: string;
  setApplicationOverviewData: React.Dispatch<
    React.SetStateAction<IApplicationOverview | null>
  >;
  setIsLoading: (isLoading: boolean) => void;
}

const useRenameColumn = (props: IRenameColumnProps) => {
  const {
    applicationOverviewData,
    setApplicationOverviewData,
    ecosystemName,
    statusId,
    newColumnName,
    setIsLoading,
  } = props;

  const { addAlert } = useContext(AlertContext);
  async function renameColumn() {
    if (!applicationOverviewData) return;
    setIsLoading(true);
    try {
      const data = await patchData(APPLICATION_STATUS_ROUTE, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'statusId', value: statusId },
        { name: 'name', value: newColumnName },
      ]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Column renamed',
      });
      const updatedApplicationOverview = {
        ...applicationOverviewData,
        statuses: applicationOverviewData.statuses.map((item) =>
          item.id === statusId ? { ...item, name: newColumnName } : item,
        ),
      };
      console.log(
        'Updated application overview data:',
        updatedApplicationOverview,
      );
      setIsLoading(false);
      setApplicationOverviewData(updatedApplicationOverview);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }
  return renameColumn;
};

export default useRenameColumn;
