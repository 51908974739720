import React, { FunctionComponent, useState } from 'react';
import { AlertContext, IAlert } from './AlertContext';
import Alert, { BASE_DURATION } from './Alert';

interface IActiveAlert extends IAlert {
  mountedAt: number;
  id: string;
}

interface AlertProviderProps {
  children: JSX.Element;
}

const AlertProvider: FunctionComponent<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<IActiveAlert[]>([]);

  function addAlert(alert: IAlert) {
    setAlerts((prevState) => [
      ...prevState.filter((alert) => alertExpired(alert)),
      createNewAlert(alert),
    ]);
  }

  function updateAlerts(id: string) {
    setAlerts((prevState) => prevState.filter((alert) => alert.id !== id));
  }

  function createNewAlert(alert: IAlert): IActiveAlert {
    const ts = new Date().getTime();

    return {
      ...alert,
      id: `alert-id-${ts}`,
      mountedAt: ts,
    };
  }

  // required if alerts were rendered during async/await event
  function alertExpired(alert: IActiveAlert): boolean {
    const duration = alert.duration ? alert.duration : BASE_DURATION;
    const now = new Date().getTime();
    const expiredAt = alert.mountedAt + duration;
    return expiredAt > now;
  }

  function renderAlerts() {
    return alerts.map((alert, i) => {
      return (
        <Alert
          key={alert.id}
          alert={alert}
          alertIndex={i}
          removeAlert={() => updateAlerts(alert.id)}
        />
      );
    });
  }

  return (
    <AlertContext.Provider value={{ alerts, addAlert }}>
      {children}
      {renderAlerts()}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
