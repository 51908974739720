import { Modal, SxProps } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react';

interface IModal {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  disableScrollLock?: boolean;
  sx?: SxProps;
}

const CommonModal: FunctionComponent<IModal> = (props) => {
  const { children, open, onClose, sx, disableScrollLock } = props;

  const disableBackdropClick = (event: any, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose;
  };

  return (
    <Modal
      open={open}
      onClose={disableBackdropClick}
      sx={sx}
      disableScrollLock={disableScrollLock}>
      {children}
    </Modal>
  );
};

export default CommonModal;
