import React, { FunctionComponent, useState } from 'react';
import { Box } from '@mui/material';
import { IEvent, ITag } from 'utils/models';
import { updateEvents } from 'utils/storage';
import Loading from '../common/Loading';
import AddHeader from './AddEditComponents/AddHeader';
import AddPhoto from './AddEditComponents/AddPhoto';
import AddEventName from './AddEditComponents/AddEventName';
import AddContactPerson from './AddEditComponents/AddContactPerson';
import SelectAccessLevels from '../common/SelectAccessLevels';
import AddTime from './AddEditComponents/AddTime';
import AddIsWholeDayButton from './AddEditComponents/AddIsWholeDayButton';
import AddChooseLocation from './AddEditComponents/AddChooseLocation';
import AddUrl from './AddEditComponents/AddUrl';
import AddTagGroup from './AddEditComponents/AddTagGroup';
import AddTag from './AddEditComponents/AddTag';
import AddEventDescription from './AddEditComponents/AddEventDescription';
import AddNewEventButton from './AddEditComponents/AddNewEventButton';
import useDeleteEventImage from 'hooks/Events/useDeleteEventImage';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import usePostEvent from 'hooks/Events/usePostEvent';
import AddRecurringOption from './AddEditComponents/AddRecurringOption';
import CommonModal from '../common/Modal/CommonModal';
import EmailUsersCheckbox from '../common/EmailUsersCheckbox';

interface AddEventProps {
  isOpen: boolean;
  handleClose: () => void;
}

const emptyEvent = {
  title: '',
  description: '',
  tags: [],
  location: '',
  latitude: 0,
  longitude: 0,
  date: '',
  htmlDate: '',
  multiDay: false,
  startTime: '',
  endTime: '',
  sharedUrl: '',
  startDateString: '',
  endDateString: '',
  htmlStartDate: '',
  htmlEndDate: '',
  attendees: [],
  isEnd: false,
  recurring: '',
  isDuplicate: false,
  recurringEventId: '',
  recurringEndDate: '',
};

const AddEvent: FunctionComponent<AddEventProps> = (props) => {
  const { isOpen, handleClose } = props;

  const { events, setEvents } = useEventsContext();

  const [event, setEvent] = useState<any>(emptyEvent);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [contactUserId, setContactUserId] = useState<number>(NaN); // It could and should be moved in the future, but its too much hustle for now <requires creation of additional props on IEvent

  const [selectedTagGroup, setSelectedTagGroup] = useState<number | null>(
    event?.tagGroup?.id || null, //Would love to move tags, but also pretty complex for now
  );
  const [tags, setTags] = useState<ITag[]>([]);
  const [usedTags, setUsedTags] = useState<ITag[]>(event?.tags || []);

  const [coverImg, setCoverImg] = useState<any>(null);

  const [sendEmails, setSendEmails] = useState<boolean>(false);

  if (event.isDuplicate) {
    event.isDuplicate = false;

    const updatedEvents = events.map((e: IEvent) =>
      e.id === event.id ? event : e,
    );

    setEvents(updatedEvents);
    updateEvents(updatedEvents);
  }

  const isAllRequiredFilled =
    event.title !== '' &&
    event.description !== '' &&
    event.startDate !== '' &&
    (!event.accessLevelIds ? false : event.accessLevelIds.length > 0) &&
    (!event.multiDay || event.endDate) &&
    (event.recurring === "Doesn't Repeat" ||
      !event.recurring ||
      (event.recurring && event.recurringEndDate)) &&
    (event.multiDay || (!event.multiDay && event.startTime && event.endTime));

  function removePhoto() {
    setCoverImg(null);
    deleteEventImage();
  }

  function handleIsWholeDayButton() {
    const isMultiDay = event.multiDay;
    const updatedEvent = { ...event, multiDay: !isMultiDay };
    setEvent(updatedEvent);
  }

  function handleAccessGroupChange(selectedGroupIds: any[]) {
    const updatedEvent = { ...event, accessLevelIds: selectedGroupIds };
    setEvent(updatedEvent);
  }

  const deleteEventImage = useDeleteEventImage({ event });

  const postProps = {
    event,
    setIsLoading,
    handleClose,
    coverImg,
    usedTags,
    selectedTagGroup,
    contactUserId,
    sendEmails,
  };
  const postEvent = usePostEvent(postProps);

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '37rem',
          bgcolor: 'background.paper',
          border: '1px solid #000',
          boxShadow: 24,
          p: '2rem',
        }}>
        <Box>
          <AddHeader
            isLoading={isLoading}
            handleClose={handleClose}
            isNew={true}
          />
          <Box
            style={{ maxHeight: '37.5rem', overflowY: 'auto' }}
            marginRight={-2}>
            {isLoading ? (
              <Loading height={545} />
            ) : (
              <Box
                sx={{
                  marginRight: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.9rem',
                }}>
                <AddPhoto
                  event={event}
                  setCoverImg={setCoverImg}
                  removePhoto={removePhoto}
                />
                <AddEventName
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
                <AddContactPerson
                  setContactUserId={setContactUserId}
                  event={event}
                  setEvent={setEvent}
                />
                <SelectAccessLevels
                  accessLevelIds={event.accessLevelIds}
                  onAccessGroupChange={handleAccessGroupChange}
                />
                <AddTime event={event} setEvent={setEvent} />
                <AddIsWholeDayButton
                  isMultiDay={event.multiDay}
                  handleIsWholeDayButton={handleIsWholeDayButton}
                />
                <AddChooseLocation
                  isLoading={isLoading}
                  event={event}
                  setEvent={setEvent}
                />
                <AddUrl
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
                <AddTagGroup
                  setTags={setTags}
                  selectedTagGroup={selectedTagGroup}
                  setSelectedTagGroup={setSelectedTagGroup}
                />
                <AddTag
                  event={event}
                  setEvent={setEvent}
                  selectedTagGroup={selectedTagGroup}
                  tags={tags}
                  usedTags={usedTags}
                  setUsedTags={setUsedTags}
                />
                <AddRecurringOption event={event} setEvent={setEvent} />
                <AddEventDescription
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
                <EmailUsersCheckbox
                  name='event'
                  sendEmails={sendEmails}
                  setSendEmails={setSendEmails}
                />
              </Box>
            )}
            <AddNewEventButton
              isAllRequiredFilled={isAllRequiredFilled}
              isLoading={isLoading}
              saveNewEvent={postEvent}
            />
          </Box>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default AddEvent;
