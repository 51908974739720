import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import CalendarSync from './Availability/CalendarSync';
import {
  getAccount,
  getSkipCalendarConnection,
  setSkipCalendarConnection,
} from 'utils/storage';
import CommonModal from 'components/common/Modal/CommonModal';
import { primary } from 'theme';
import ModalHeader from 'components/common/Modal/ModalHeader';
import ModalBox from 'components/common/Modal/ModalBox';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
export interface IConnectCalendarPopup {
  isTriggerPopup?: boolean;
}

const ConnectCalendarPopup: FunctionComponent<IConnectCalendarPopup> = (
  props,
) => {
  const { isTriggerPopup } = props;
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const isConnected = getAccount().isCalendarConnected;

  const theme = useTheme();

  function handleClose() {
    setDisplayPopup(false);
  }

  useEffect(() => {
    if (getAccount().isCalendarConnected || getSkipCalendarConnection()) return;
    setDisplayPopup(true);
  }, []);

  useEffect(() => {
    if (!isTriggerPopup) return;

    setDisplayPopup(true);
  }, [isTriggerPopup]);

  const handleSkip = () => {
    setSkipCalendarConnection(true);
    handleClose();
  };

  return (
    <CommonModal open={displayPopup} onClose={handleClose}>
      <ModalBox width={'30rem'}>
        <Box
          sx={{
            maxHeight: '40rem',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <ModalHeader
            text={isConnected ? 'Calendar connected' : 'Connect your calendar'}
            handleClose={handleClose}
            textColor={primary.mainBlackFont}
            headerSize={'1.75rem'}
          />
          <CalendarSync inModal />
          <CommonButton
            text={isConnected ? 'Close' : 'Remind me later'}
            variant={ButtonVariant.FILLED}
            color={isConnected ? primary.pureWhite : theme.palette.primary.dark}
            bgcolor={
              isConnected
                ? theme.palette.primary.dark
                : theme.palette.primary.contrastText
            }
            onClick={handleSkip}
            hoverColor={{
              bgcolor: theme.palette.primary.light,
            }}
            sx={{
              width: isConnected ? '49%' : '100%',
              alignSelf: 'end',
              mt: '-0.5rem',
            }}
          />
        </Box>
      </ModalBox>
    </CommonModal>
  );
};

export default ConnectCalendarPopup;
