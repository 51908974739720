import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AlertContext, ALERT_TYPES } from 'components/Alert/AlertContext';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { IconPicker } from 'components/common/IconPicker';
import InputField from 'components/common/InputField';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import { StarRating } from 'components/common/StarRating';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { primary } from 'theme';
import { IApplicantReview } from 'utils/models';
import {
  APPLICANT_REVIEWS,
  RATE_APPLICANT as APPLICANT_REVIEW,
  deleteData,
  getData,
  putData,
} from 'utils/requests';
import { getAccount, getEcosystemInfo } from 'utils/storage';

interface IApplicantRatingModal {
  applicantId: number;
  isOpen: boolean;
  onClose: () => void;
  setApplicantAverageRating?: Dispatch<SetStateAction<number>>;
  setNumberOfReviews?: Dispatch<SetStateAction<number>>;
}

export const ApplicantRatingModal = (
  props: IApplicantRatingModal,
): React.ReactElement => {
  const {
    applicantId,
    isOpen,
    onClose,
    setApplicantAverageRating,
    setNumberOfReviews,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [applicantRatings, setApplicantRatings] = useState<IApplicantReview[]>(
    [],
  );
  const [rating, setRating] = useState({
    rating: 0,
    comment: '',
  });

  const calculateRatingAverage = (ratings: IApplicantReview[]) => {
    if (ratings.length === 0) return 0;
    return (
      ratings.map((r) => r.rating).reduce((a, b) => a + b, 0) / ratings.length
    );
  };

  const hasAnyRatings = applicantRatings.length > 0;
  const hasAnyComments = applicantRatings.some((rating) => rating.text);

  const usersProfilePicture = getAccount().profilePicture;
  const userId = getAccount().userId;

  const { canManageApplications, canReviewApplicants } =
    getEcosystemInfo().accessLevel;

  const hasUserReviewed = applicantRatings.some((item) => {
    return item.reviewer.id === userId;
  });

  const usersReview = applicantRatings.find((item) => {
    return item.reviewer.id === userId;
  });

  const usersReviewId = usersReview ? usersReview.id : null;

  function handleClose() {
    onClose();
    setRating({
      rating: 0,
      comment: '',
    });
  }

  //this function is checking if there are any ratings from user before
  const upsert = (array: IApplicantReview[], newObj: IApplicantReview) => {
    const index = array.findIndex(
      (item) => item.reviewer.id === newObj.reviewer.id,
    );
    if (index > -1) {
      return array.map((item) =>
        item.reviewer.id === newObj.reviewer.id ? { ...item, ...newObj } : item,
      );
    } else {
      return [...array, newObj];
    }
  };

  async function getApplicantRatings() {
    setLoading(true);
    try {
      const data = await getData(APPLICANT_REVIEWS, [
        {
          name: 'applicantId',
          value: applicantId,
        },
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setApplicantRatings(data);
    } catch (error) {
      console.error('Failed to fetch aplicant ratings', error);
    }
    setLoading(false);
  }

  async function submitApplicantRating() {
    setLoading(true);
    try {
      const data = await putData(
        APPLICANT_REVIEW,
        [{ name: 'ecosystemName', value: ecosystemName }],
        {
          applicantId: applicantId,
          text: rating.comment,
          rating: rating.rating,
        },
      );

      setApplicantRatings((prevRatings) => {
        const updatedRatings = upsert(prevRatings, data);
        const newAverageRating = calculateRatingAverage(updatedRatings);
        setApplicantAverageRating &&
          setApplicantAverageRating(newAverageRating);
        setNumberOfReviews &&
          setNumberOfReviews((prev) => {
            if (hasUserReviewed) return prev;
            return prev + 1;
          });
        return updatedRatings;
      });

      handleClose();
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Your rating was added',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  async function handleDeleteOwnComment(reviewId: number | null) {
    if (!reviewId) return;
    setLoading(true);
    try {
      const data = await deleteData(APPLICANT_REVIEW, [
        { name: 'reviewId', value: reviewId },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      setApplicantRatings((prevRatings) => {
        const updatedRatings = prevRatings.filter(
          (item) => item.id !== reviewId,
        );

        const newAverageRating = calculateRatingAverage(updatedRatings);
        setApplicantAverageRating &&
          setApplicantAverageRating(newAverageRating);
        return updatedRatings;
      });
      setNumberOfReviews && setNumberOfReviews((prev) => prev - 1);

      handleClose();
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Your review was deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    getApplicantRatings();
  }, []);

  function renderComments() {
    if (!hasAnyRatings) {
      return (
        <Box
          sx={{
            maxHeight: '22rem',
            overflowY: 'auto',
            borderBottom: `1px solid ${primary.natural6}`,
          }}>
          <Typography sx={{ py: '0.5rem', mb: '1rem' }}>
            {canManageApplications && canReviewApplicants
              ? 'There are no ratings yet.'
              : "You don't have permission to see other people's reviews."}
          </Typography>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          maxHeight: '22rem',
          overflowY: 'auto',
          borderBottom: `1px solid ${primary.natural6}`,
        }}>
        {applicantRatings.map((review, index) => (
          <Box key={index} sx={{ marginBottom: '1.25rem' }}>
            <Box
              sx={{
                display: 'flex',
              }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {review.reviewer.profilePic ? (
                  <img
                    src={review.reviewer.profilePic}
                    style={{
                      width: '3rem',
                      height: '3rem',
                      borderRadius: '100%',
                    }}
                  />
                ) : (
                  <IconPicker
                    name='AccountCircle'
                    sx={{
                      width: '3rem',
                      height: '3rem',
                      color: primary.main,
                    }}
                  />
                )}
                <Box sx={{ marginBottom: '0.5rem' }}>
                  <Typography variant='h6'>
                    {review.reviewer.fullName}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {review.reviewer.fullName}
                  </Typography>
                </Box>
                <AccessLevelTagCard
                  accessLevelName={review.reviewer.accessLevelName}
                  accessLevelColor={review.reviewer.accessLevelColor}
                  height={'1.25rem'}
                  sx={{ marginBottom: 'auto' }}
                />
              </Box>
              <StarRating
                stars={review.rating}
                sx={{
                  marginLeft: userId === review.reviewer.id ? '1rem' : 'auto',
                }}
              />
            </Box>
            <Typography variant='body1'>{review.text}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  function renderLeaveRating() {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            gap: '0.5rem',
            paddingBottom: '1.25rem',
            paddingTop: '1.25rem',
            marginTop: '1.25rem',
          }}>
          <Typography variant='h4'>
            {hasUserReviewed ? 'Update your review' : 'What is your review?'}
          </Typography>
          <StarRating
            stars={rating.rating}
            setRating
            onRatingClick={(value) => {
              setRating((prevRating) => ({
                ...prevRating,
                rating: value,
              }));
            }}
            sxIcon={{ height: '2rem', width: '2rem' }}
          />
          {hasUserReviewed && (
            <IconButton
              sx={{ marginLeft: 'auto', alignSelf: 'center', borderRadius: 2 }}
              onClick={() => handleDeleteOwnComment(usersReviewId)}>
              <Typography
                sx={{ color: primary.warningRed, fontWeight: 'bold' }}>
                Remove previous review
              </Typography>
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            paddingBottom: '1.875rem',
            color: primary.additionalDarkOrange,
          }}>
          {usersProfilePicture ? (
            <img
              src={usersProfilePicture}
              style={{
                width: '3rem',
                height: '3rem',
                borderRadius: '100%',
              }}
            />
          ) : (
            <IconPicker
              name='AccountCircle'
              sx={{
                width: '3rem',
                height: '3rem',
                color: primary.main,
              }}
            />
          )}
          <InputField
            placeholder={'Write your review...'}
            multiline
            width='90%'
            value={rating.comment}
            onChange={(e) => {
              setRating((prevRating) => ({
                ...prevRating,
                comment: e.target.value,
              }));
            }}
          />
        </Box>
      </>
    );
  }

  return (
    <InformationModal
      isOpen={isOpen}
      handleClose={handleClose}
      headerText=''
      primaryText='Submit'
      primaryOnClick={submitApplicantRating}
      secondaryText='Close'
      secondaryOnClick={handleClose}
      maxHeight={'44rem'}>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              paddingBottom: '1.25rem',
              marginBottom: '1.25rem',
              borderBottom: `1px solid ${primary.natural6}`,
            }}>
            <Typography variant='h3'>Application Ratings</Typography>
            <StarRating
              stars={calculateRatingAverage(applicantRatings)}
              sxIcon={{ height: '2rem', width: '2rem' }}
            />
            {!!applicantRatings.length && (
              <Typography>({applicantRatings.length})</Typography>
            )}
          </Box>
          {renderComments()}
          {renderLeaveRating()}
        </Box>
      )}
    </InformationModal>
  );
};
