import { Card, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent, useState } from 'react';
import { acterioTheme, naturalPallete, primary } from 'theme';
import {
  CalendarTodayRounded,
  Place,
  RemoveRedEyeRounded,
  Group,
} from '@mui/icons-material';
import CalendarPreview from '../BookingCalendar/CalendarPreview';
import { IRoom } from 'utils/models';
import RoomDetails from './RoomDetails';
import RichTextDisplay from 'components/common/RichTextDisplay';
import { useMobileContext } from 'services/contexts/MobileContext';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';

interface RoomProps {
  room: IRoom;
}

const Room: FunctionComponent<RoomProps> = (props) => {
  const { room } = props;

  const theme = useTheme();

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);

  const { isMobile } = useMobileContext();

  function viewRoomDetails() {
    setIsShowDetails(true);
  }

  function startBooking() {
    setIsShowDetails(false);
    setShowPreview(true);
  }

  function renderPhoto() {
    return (
      <Box
        sx={{
          borderTopRightRadius: '6px',
          borderTopLeftRadius: '6px',
          height: '14.375rem',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}>
        <img
          src={room.picture}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            left: '1rem',
            top: '1rem',
            width: '3.75rem',
            height: '3.75rem',
            bgcolor: 'white',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '2px 0px 8px 0px #0000001A',
          }}>
          <Group sx={{ color: acterioTheme.main }} />
          <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
            {room.capacity}
          </Typography>
        </Box>
      </Box>
    );
  }

  function renderName() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 2,
          marginTop: '1.5rem',
          alignItems: 'left',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            marginBottom: '0.2rem',
            overflow: 'hidden',
            width: '100%',
          }}>
          <Typography
            sx={{
              color: '#1A1A1A',
              fontSize: '1.563rem',
              fontWeight: 600,
              lineHeight: '2.3rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '2rem',
            }}>
            {room.title}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          {/* <Box
            sx={{
              bgcolor: acterioTheme.lightHighlight,
              paddingX: 1,
              paddingY: 0.2,
              borderRadius: 0.6,
              marginLeft: -0.5,
              marginRight: 1,
              width: 108,
              maxHeight: 25,
            }}>
            <Typography
              fontSize={'0.875rem'}
              fontWeight='bold'
              color={acterioTheme.main}>
              {room.daysAvailability}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              marginTop: 0.2,
              // maxWidth: 80,
              overflow: 'hidden',
              width: '100%',
            }}>
            <Place
              sx={{
                color: theme.palette.primary.main,
                fontSize: '1.5rem',
                marginRight: 0.5,
                marginTop: 0.2,
              }}
            />
            <Typography
              sx={{ color: '#1A1A1A', fontWeight: 400, fontSize: '1rem' }}>
              {room.location}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  function renderDescription() {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          padding: '1',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            bgcolor: '#FAFAFA',
            borderRadius: '6px',
            width: '100%',
          }}>
          <RichTextDisplay
            style={{
              color: primary.natural2,
              fontSize: '0.75rem',
              textOverflow: 'ellipsis',
              maxHeight: 80,
            }}
            text={room.description}
          />
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          width: '95%',
          marginTop: '1rem',
          gap: { xs: '1.25rem', md: '0rem' },
        }}>
        <CommonButton
          text='View more'
          icon={<RemoveRedEyeRounded />}
          variant={ButtonVariant.BLANK}
          onClick={viewRoomDetails}
          sx={{
            marginLeft: '1rem',
            width: '100%',
            fontWeight: 600,
          }}
        />
        <CommonButton
          onClick={() => setShowPreview(true)}
          icon={<CalendarTodayRounded />}
          variant={ButtonVariant.FILLED}
          text='Start booking'
          sx={{
            fontWeight: 600,
            marginLeft: '1rem',
            width: '100%',
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <Card
        sx={{
          border: `1px solid ${naturalPallete.natural8}`,
          width: '100%',
          height: '100%',
          pb: '1.25rem',
          borderRadius: 2,
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}>
        {renderPhoto()}
        {renderName()}
        {renderButtons()}
      </Card>

      {showPreview && (
        <CalendarPreview
          isOpen={showPreview}
          handleClose={() => {
            setShowPreview(false);
          }}
          mentor={room}
          canBook={true}
          isRoomBooking={true}
        />
      )}
      {isShowDetails && (
        <RoomDetails
          isOpen={isShowDetails}
          handleClose={() => {
            setIsShowDetails(false);
          }}
          startBooking={startBooking}
          room={room}
        />
      )}
    </>
  );
};

export default Room;
