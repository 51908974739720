import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import SelectDropdown from 'components/common/SelectDropdown';
import { IAccessLevelsProps } from 'pages/Admin/AdminManageAccess';
import DroppableAccessLevel from './DroppableAccessLevel';
import ContWithStyledScroll from 'components/common/ContWithStyledScroll';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { IAccessLevel, IAccessLevelDto } from 'utils/models';
import {
  ACCESS_CONTROL_MOVE_USER_ROUTE,
  ACCESS_CONTROL_UPDATE_DEFAULT_LEVEL_ROUTE,
  putData,
} from 'utils/requests';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { existsStoredCompanies, removeStoredCompanies } from 'utils/storage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    marginTop: '2rem',
    display: 'flex',
    paddingBottom: '1.5rem',
    maxWidth: '62.5rem',
    overflowX: 'auto',
  },
  header: {
    '& h5': {
      fontSize: '1.35rem !important',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '5px',
        height: 32,
        width: 32,
        marginTop: '-3px',
        color: theme.palette.primary.main,
      },
    },

    '& span': {
      fontSize: '0.75rem !important',
      marginLeft: '37.5px',
      display: 'block',
    },
  },
  dropdownCont: {
    marginLeft: '37.5px',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'flex-end',

    '& h6': {
      marginRight: '20px !important',
    },
  },
  dropdown: {
    maxWidth: '12.5rem',
    width: '12.5rem',
  },
}));

interface IAccessMemberList extends IAccessLevelsProps {
  setDefaultAccessLevel: (accessLevel: IAccessLevel) => void;
}

const AccessMemberList: FunctionComponent<IAccessMemberList> = (props) => {
  const { accessLevelDtos, defaultAccessLevel, setDefaultAccessLevel } = props;
  const { ecosystemName } = useParams();
  const classes = useStyles();
  const { addAlert } = useContext(AlertContext);

  const [editDefaultAccessLevel, setEditDefaultAccessLevel] = useState<string>(
    defaultAccessLevel.accessLevelName,
  );
  const [modifiedAccessLevels, setModifiedAccessLevels] =
    useState<IAccessLevelDto[]>(accessLevelDtos);

  async function updateDefaultAccessLevel(name: string) {
    const accessLevel = getAccessLevelByName(name);
    if (!accessLevel) {
      return addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Unable to detect access level',
      });
    }

    setEditDefaultAccessLevel(name);
    setDefaultAccessLevel(accessLevel);

    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Default access level updated',
    });

    try {
      await putData(ACCESS_CONTROL_UPDATE_DEFAULT_LEVEL_ROUTE, [
        { name: 'id', value: accessLevel.id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  async function saveMovedUser(userIdArray: number[], accessIdArray: number[]) {
    try {
      await putData(ACCESS_CONTROL_MOVE_USER_ROUTE, [
        { name: 'userIdArray', value: userIdArray },
        { name: 'accessIdArray', value: accessIdArray },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      if (existsStoredCompanies()) {
        removeStoredCompanies();
      }
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function onDragEnd(result: DropResult) {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    // access level ids
    const fromId = getDropId(source.droppableId);
    const toId = getDropId(destination.droppableId);

    // dragged user indexes
    const fromUserIndex = source.index;
    const toUserIndex = destination.index;

    const fromAccessLevel = getAccessLevelById(fromId);
    const toAccessLevel = getAccessLevelById(toId);

    const fromUsers = fromAccessLevel.usersAndId;
    const toUsers = toAccessLevel.usersAndId;

    // remove from source, insert at destination
    const userToMove = fromUsers.splice(fromUserIndex, 1)[0];
    toUsers.splice(toUserIndex, 0, userToMove);

    const updatedAccessLevels = modifiedAccessLevels.map((accessLevelDto) => {
      const { accessLevel } = accessLevelDto;
      const { id } = accessLevel;
      if (id === fromId) accessLevelDto.usersAndId = fromUsers;
      if (id === toId) accessLevelDto.usersAndId = toUsers;
      return accessLevelDto;
    });

    setModifiedAccessLevels(updatedAccessLevels);
    saveMovedUser([userToMove.id], [toId]);
  }

  function getAccessLevelByName(name: string) {
    const accessLevelDto = accessLevelDtos.find(
      (a) => a.accessLevel.accessLevelName === name,
    );

    return !accessLevelDto ? null : accessLevelDto.accessLevel;
  }

  function getAccessLevelById(id: number) {
    return modifiedAccessLevels.find(
      ({ accessLevel }) => accessLevel.id === id,
    ) as IAccessLevelDto;
  }

  function getDropId(droppableId: string) {
    return parseInt(droppableId.split('-')[1]);
  }

  function renderDroppableAccessLevels() {
    return modifiedAccessLevels.map((accessLevelDto, i) => (
      <DroppableAccessLevel
        key={`drag-access-level-${i}`}
        accessLevelDto={accessLevelDto}
        index={i}
        ecosystemName={ecosystemName ? ecosystemName : ''}
      />
    ));
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant={'h5'}>
          <GppGoodIcon /> Member List
        </Typography>
        <span>Drag to move members from one level to another</span>
      </div>
      <div className={classes.dropdownCont}>
        <Typography variant={'h6'}>
          Users without an assigned access level are automatically given:{' '}
        </Typography>
        <div className={classes.dropdown}>
          <SelectDropdown
            value={editDefaultAccessLevel}
            onChange={updateDefaultAccessLevel}
            getOptionLabel={(option) => option}
            options={accessLevelDtos.map(
              ({ accessLevel }) => accessLevel.accessLevelName,
            )}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <ContWithStyledScroll className={classes.cont}>
          {renderDroppableAccessLevels()}
        </ContWithStyledScroll>
      </DragDropContext>
    </div>
  );
};

export default AccessMemberList;
