import React, { FunctionComponent } from 'react';
import CompanySection from '../CompanySection';
import CompanySharedNotes from './CompanySharedNotes';
import CompanySharedDocuments from './CompanySharedDocuments';
import { MAX_NUM_LIST_ITEMS } from './CompanyPrivateProfile';
import { ICompanyDoc, ICompanySharedNote } from 'utils/models';

interface ICompanyWorkspace {
  companyName: string;
  sharedNotes: ICompanySharedNote[];
  updateDocs: (doc: ICompanyDoc, deleted: boolean) => void;
  sharedDocs: ICompanyDoc[];
  setNote: (note: ICompanySharedNote) => void;
  setShowNote: (show: boolean) => void;
  canEditCompanyProfile: boolean;
  showNote: boolean;
  patchingNote: boolean;
}

const CompanyWorkspace: FunctionComponent<ICompanyWorkspace> = (props) => {
  const {
    companyName,
    sharedNotes,
    updateDocs,
    sharedDocs,
    setNote,
    setShowNote,
    canEditCompanyProfile,
    showNote,
    patchingNote,
  } = props;

  return (
    <CompanySection title={'Work Space'} noPadding={true}>
      <div>
        <CompanySharedNotes
          patchingNote={patchingNote}
          setNote={setNote}
          setShowNote={setShowNote}
          sharedNotes={sharedNotes}
          showNote={showNote}
          canEditCompanyProfile={canEditCompanyProfile}
        />
        <CompanySharedDocuments
          sharedDocs={sharedDocs}
          companyName={companyName}
          updateDocs={updateDocs}
          seeMore={sharedDocs.length > MAX_NUM_LIST_ITEMS}
          canEditCompanyProfile={canEditCompanyProfile}
        />
      </div>
    </CompanySection>
  );
};

export default CompanyWorkspace;
