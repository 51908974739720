import { IApplicationOverview } from 'utils/models';

interface IMoveApplicant {
  applicantId: number | undefined;
  selcetedColumnId: number | null;
  applicationOverviewData: IApplicationOverview | null;
  setApplicationOverviewData: any;
}

const useMoveApplicant = (props: IMoveApplicant) => {
  const {
    applicantId,
    selcetedColumnId,
    applicationOverviewData,
    setApplicationOverviewData,
  } = props;

  const targetColumn = applicationOverviewData?.statuses.find(
    (status) => status.id === selcetedColumnId,
  );

  const sourceColumn = applicationOverviewData?.statuses.find((status) =>
    status.applicants.some((applicant) => applicant.id === applicantId),
  );

  const applicant = sourceColumn?.applicants.find(
    (applicant) => applicant.id === applicantId,
  );

  if (!applicationOverviewData) return;

  if (sourceColumn && targetColumn && applicant) {
    sourceColumn.applicants = sourceColumn.applicants.filter(
      (app) => app.id !== applicant.id,
    );

    targetColumn.applicants.push(applicant);
    setApplicationOverviewData({
      ...applicationOverviewData,
      statuses: applicationOverviewData.statuses.map((status) =>
        status.name === sourceColumn?.name
          ? sourceColumn
          : status.name === targetColumn.name
          ? targetColumn
          : status,
      ),
    });
  }

  return;
};

export default useMoveApplicant;
