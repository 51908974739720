import React from 'react';
import { acterioTheme } from 'theme';
import ApplicationProcessCard from './ApplicationProcessCard';
//import { ApplicationStatus } from 'utils/models';
import { Box, Typography } from '@mui/material';
import ToggleViewButton from 'components/Events/ToggleViewButton';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import { FlexBox } from 'utils/styledComponents';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import { ApplicationStatus } from 'utils/models';

const ApplicationsGrid = () => {
  const { applicationProcesses, applicationStatus, setApplicationStatus } =
    useApplicationContext();

  function renderViewChoice() {
    return (
      <FlexBox
        className={'between'}
        sx={{
          marginY: '1.875rem',
        }}>
        <FlexBox
          className={'center'}
          sx={{
            gap: '1.25rem',
            justifyContent: { xs: 'space-evenly', sm: 'start' },
            minWidth: { xs: '100%', sm: '30%' },
          }}>
          <ToggleViewButton
            onClick={() => setApplicationStatus(ApplicationStatus.ACTIVE)}
            text={'Active'}
            isActive={applicationStatus === ApplicationStatus.ACTIVE}
            icon={<NewspaperRoundedIcon />}
            sx={{ width: '7.125rem', height: '2.375rem' }}
          />
          <ToggleViewButton
            onClick={() => setApplicationStatus(ApplicationStatus.PAUSED)}
            text={'Paused'}
            isActive={applicationStatus === ApplicationStatus.PAUSED}
            icon={<NewspaperRoundedIcon />}
            sx={{ width: '7.125rem', height: '2.375rem' }}
          />
          <ToggleViewButton
            onClick={() => setApplicationStatus(ApplicationStatus.SCHEDULED)}
            text={'Scheduled'}
            isActive={applicationStatus === ApplicationStatus.SCHEDULED}
            icon={<NewspaperRoundedIcon />}
            sx={{ width: '7.125rem', height: '2.375rem' }}
          />
          <ToggleViewButton
            onClick={() => setApplicationStatus(ApplicationStatus.INACTIVE)}
            text={'Inactive'}
            isActive={applicationStatus === ApplicationStatus.INACTIVE}
            icon={<NewspaperRoundedIcon />}
            sx={{ width: '7.125rem', height: '2.375rem' }}
          />
        </FlexBox>
      </FlexBox>
    );
  }

  function renderNoApplications() {
    return (
      <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
        <Box
          sx={{
            width: { xs: '80%', md: '49.625rem' },
            height: '21.875rem',
            margin: '4rem 0 0 3rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            component='img'
            src='/images/illustrations/Application_forms_grey.png'
            alt='application forms illustration'
            sx={{
              width: '16.25rem',
              height: '16.25rem',
              marginBottom: '1rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: '400',
              lineHeight: '1.875rem',
            }}>
            Looks like you're starting with a clean slate! Go ahead and create
            your first application to get started. Click the '
            <span style={{ color: acterioTheme.main }}>+ New application</span>'
            button to begin building.
          </Typography>
        </Box>
      </Box>
    );
  }

  function renderApplicationGrid() {
    const listOfApplications = applicationProcesses
      ? applicationProcesses.filter(
          (process) => process.processStatus === applicationStatus,
        )
      : [];

    if (!listOfApplications.length)
      return (
        <Box
          sx={{
            width: { xs: '80%', md: '49.625rem' },
            height: '21.875rem',
            margin: '4rem 0 0 3rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            component='img'
            src='/images/illustrations/Application_forms_grey.png'
            alt='application forms illustration'
            sx={{
              width: '16.25rem',
              height: '16.25rem',
              marginBottom: '1rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: '400',
              lineHeight: '1.875rem',
            }}>
            Looks like there are no inactive applications at the moment. Only
            applications that have been stopped or completed will appear here.
          </Typography>
        </Box>
      );

    return listOfApplications.map((process) => (
      <ApplicationProcessCard {...process} key={process.id} />
    ));
  }

  return (
    <Box sx={{ margin: '4rem 0 0 3rem' }}>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '2.5rem',
          lineHeight: '3.75rem',
        }}>
        Applications
      </Typography>
      {renderViewChoice()}
      {applicationProcesses && !!applicationProcesses.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {renderApplicationGrid()}
        </Box>
      ) : (
        renderNoApplications()
      )}
    </Box>
  );
};

export default ApplicationsGrid;
