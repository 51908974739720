import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { AddCircle, ErrorOutline } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import InformationModal from 'components/common/Modal/InformationModal';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { primary } from 'theme';
import { ICompanyField, ICompanyProfile } from 'utils/models';
import { COMPANY_PROFILE_UPLOAD_ROUTE, postData } from 'utils/requests';
import { FlexBox } from 'utils/styledComponents';
import IFileDisplay from './IFileDisplay';
import IUploadImageDisplay from './IUploadImageDisplay';

interface IUploadSidesectionFiles {
  field: ICompanyField;
}

const CompanySectionUploadImages: FunctionComponent<IUploadSidesectionFiles> = (
  props,
) => {
  const { field } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const {
    companyProfile,
    updateCompanyField,
    setCompanyProfile,
    checkIfInfoIsMissing,
  } = useCompanyContext();

  const [files, setFiles] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejected, setRejected] = useState<FileRejection[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [copyCompanyProfiles, setCopyCompanyProfiles] =
    useState<ICompanyProfile>(JSON.parse(JSON.stringify(companyProfile!)));
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles?.length) {
        setFiles((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) }),
          ),
        ]);
        setErrorMessage(null);
      }

      if (rejectedFiles?.length) {
        setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
        const errorCode = rejectedFiles[0].errors[0].code;
        if (errorCode === 'file-invalid-type') {
          setErrorMessage('Invalid file format. File was not uploaded.');
        } else if (errorCode === 'file-too-large') {
          setErrorMessage('File size is too large. File was not uploaded.');
        }
      }
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive } =
    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': ['.jpg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
      },

      maxSize: 50 * 1000 * 1000,
    });

  const fieldImages = field.files ? field.files : [];

  async function uploadFiles(fieldId: number) {
    setIsLoading(true);

    try {
      const formData = new FormData();

      let i = 1;
      for (const item of files) {
        formData.append(i.toString(), item);
        i++;
      }

      const data = await postData(
        COMPANY_PROFILE_UPLOAD_ROUTE,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'companyProfileFieldId', value: fieldId },
          {
            name: 'companyName',
            value: companyProfile!.name,
          },
        ],
        formData,
      );

      updateCompanyField(fieldId, [...data]);
      setIsModalOpen(false);
      setFiles([]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Uploaded successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function saveUploadedFiles(field: ICompanyField) {
    uploadFiles(field.id);
  }

  function revertChanges() {
    if (!companyProfile) return;
    setCompanyProfile({ ...copyCompanyProfiles });
    setFiles([]);
    setIsModalOpen(false);
  }

  checkIfInfoIsMissing(field, 'files');
  return (
    <>
      <Box
        sx={{
          p: '1rem',
          border:
            field.required && fieldImages.length < 1
              ? `1px solid ${primary.warningRed}`
              : '',
          borderRadius: '6px',
        }}>
        {
          <Grid
            container
            spacing={'0.5rem'}
            rowSpacing={'0rem'}
            sx={{ mb: '1rem' }}>
            {fieldImages.map((file) => (
              <IUploadImageDisplay key={file.id} file={file} field={field} />
            ))}
          </Grid>
        }
        {field.required && fieldImages.length < 1 && (
          <Typography
            variant='body1'
            color={primary.warningRed}
            sx={{ pb: '1rem' }}>
            Upload images
          </Typography>
        )}
        <FlexBox sx={{ gap: '0.5rem', cursor: 'pointer' }} onClick={openModal}>
          <AddCircle />
          <Typography variant='h6'>Upload image</Typography>
        </FlexBox>
      </Box>
      <InformationModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        primaryText='Save'
        primaryOnClick={() => saveUploadedFiles(field)}
        secondaryText='Cancel'
        secondaryOnClick={revertChanges}
        headerText={`Upload images to ${field.name}`}
        headerSize={'2rem'}
        width={'34rem'}>
        {isLoading ? (
          <Box>
            <Loading />
          </Box>
        ) : (
          <Box>
            <FlexBox
              {...getRootProps()}
              sx={{
                my: '1rem',
                maxWidth: '30rem',
                height: '10rem',
                border: errorMessage
                  ? `1px dashed ${primary.warningRed}`
                  : isDragActive || isFileDialogActive
                  ? `1px dashed ${primary.additionalDeepBlue}`
                  : `1px dashed ${primary.natural6}`,
                borderRadius: '12px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <Typography>Drop the files here</Typography>
                </>
              ) : (
                <>
                  <Typography>
                    <b> Choose a file</b> or drag it here
                  </Typography>
                  <Typography sx={{ px: '1rem', textAlign: 'center' }}>
                    Supports JPG, PNG or GIF
                  </Typography>
                  <Typography>(max size 50MB)</Typography>
                </>
              )}
            </FlexBox>
            {errorMessage && (
              <Typography sx={{ color: primary.warningRed }}>
                <FlexBox sx={{ gap: '0.5rem' }}>
                  <ErrorOutline />
                  {errorMessage}
                </FlexBox>
              </Typography>
            )}
            {files.map((file) => (
              <IFileDisplay key={field.id} file={file} setFiles={setFiles} />
            ))}
          </Box>
        )}
      </InformationModal>
    </>
  );
};

export default CompanySectionUploadImages;
