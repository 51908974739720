import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  CompanyProfileFieldType,
  CompanyTemplateSectionType,
  ICompanyProfileField,
  ICompanyProfileTemplate,
  ITagGroup,
} from 'utils/models';
import Loading from 'components/common/Loading';
import useFetchCompanyTemplate from 'hooks/Companies/useFetchCompanyTemplate';

const useCompanyTemplate = (loadedTemplate: ICompanyProfileTemplate | null) => {
  const [companyTemplate, setCompanyTemplate] =
    useState<ICompanyProfileTemplate | null>(loadedTemplate);
  const [sectionField, setSectionField] = useState<ICompanyProfileField>({
    name: '',
    type: CompanyProfileFieldType.TAGS,
    required: false,
  });

  useEffect(() => {
    if (loadedTemplate) {
      setCompanyTemplate(loadedTemplate);
    }
  }, [loadedTemplate]);

  function addSectionField(
    sectionType: CompanyTemplateSectionType,
    field: ICompanyProfileField,
  ) {
    setCompanyTemplate((prevTemplate) => {
      if (!prevTemplate) return prevTemplate;

      let updatedSection = [...prevTemplate[sectionType]];

      // If statement to insert new PEOPLE field after the last PEOPLE field
      if (field.type === CompanyProfileFieldType.PEOPLE) {
        // Find the last index of a PEOPLE field in the section
        const lastPeopleFieldIndex = updatedSection.reduce(
          (lastIndex, currentField, currentIndex) =>
            currentField.type === CompanyProfileFieldType.PEOPLE
              ? currentIndex
              : lastIndex,
          -1,
        );

        if (lastPeopleFieldIndex !== -1) {
          updatedSection = [
            ...updatedSection.slice(0, lastPeopleFieldIndex + 1),
            field,
            ...updatedSection.slice(lastPeopleFieldIndex + 1),
          ];
        } else {
          updatedSection = [field, ...updatedSection];
        }
      } else {
        updatedSection.push(field);
      }

      return {
        ...prevTemplate,
        [sectionType]: updatedSection,
      };
    });
  }

  function removeSectionField(
    sectionType: CompanyTemplateSectionType,
    fieldName: string,
  ) {
    setCompanyTemplate((prevTemplate: ICompanyProfileTemplate | null) => {
      if (!prevTemplate || !prevTemplate[sectionType]) return prevTemplate;

      const section = prevTemplate[sectionType];

      if (!Array.isArray(section)) return prevTemplate;

      const updatedSection = section.filter(
        (field: ICompanyProfileField) => field.name !== fieldName,
      );

      return {
        ...prevTemplate,
        [sectionType]: updatedSection,
      };
    });
  }

  function toggleRequiredField(
    sectionType: CompanyTemplateSectionType,
    fieldName: string,
  ) {
    setCompanyTemplate((prevTemplate: ICompanyProfileTemplate | null) => {
      if (!prevTemplate || !prevTemplate[sectionType]) return prevTemplate;

      const section = prevTemplate[sectionType];

      if (!Array.isArray(section)) return prevTemplate;

      const updatedSection = section.map((field: ICompanyProfileField) => {
        if (field.name === fieldName) {
          return {
            ...field,
            required: !field.required,
          };
        }
        return field;
      });

      return {
        ...prevTemplate,
        [sectionType]: updatedSection,
      };
    });
  }

  function updateFieldTagOptions(
    sectionType: CompanyTemplateSectionType,
    fieldName: string,
    tagGroup: ITagGroup,
  ) {
    setCompanyTemplate((prevTemplate: ICompanyProfileTemplate | null) => {
      if (!prevTemplate || !prevTemplate[sectionType]) return prevTemplate;

      const section = prevTemplate[sectionType];

      if (!Array.isArray(section)) return prevTemplate;

      const updatedSection = section.map((field: ICompanyProfileField) => {
        if (field.name === fieldName) {
          return {
            ...field,
            tagOptions: tagGroup || field.tagOptions,
          };
        }
        return field;
      });

      return {
        ...prevTemplate,
        [sectionType]: updatedSection,
      };
    });
  }

  return {
    sectionField,
    setSectionField,
    companyTemplate,
    setCompanyTemplate,
    addSectionField,
    removeSectionField,
    toggleRequiredField,
    updateFieldTagOptions,
  };
};

const CompanyTemplateContext = createContext(
  {} as ReturnType<typeof useCompanyTemplate>,
);

export const CompanyTemplateProvider = ({ children }: PropsWithChildren) => {
  const { loading, companyTemplate } = useFetchCompanyTemplate();
  const companyTemplateContext = useCompanyTemplate(companyTemplate);

  return (
    <CompanyTemplateContext.Provider value={companyTemplateContext}>
      {loading || !companyTemplate == null ? <Loading /> : children}
    </CompanyTemplateContext.Provider>
  );
};

export const useCompanyTemplateContext = () =>
  useContext(CompanyTemplateContext);
