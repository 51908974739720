import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TextField, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router';
import MainButton, { ButtonType } from '../common/MainButton';
import SearchIcon from '@mui/icons-material/Search';
import { primary } from 'theme';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import { filterCollection } from 'utils/utils';
import Avatar from '../common/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { ISearch } from 'utils/models';
import { getData, SEARCH_ROUTE } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import useDebounce from 'hooks/Common/useDebounce';
import Loading from '../common/Loading';
import CommonModal from 'components/common/Modal/CommonModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingRight: 0,

    '& button': {
      width: '100%',
      color: `${primary.placeholderFont} !important`,
      justifyContent: 'flex-start',
      paddingLeft: '2rem',
      fontWeight: 600,
      borderRadius: '8px !important',

      '& svg': {
        height: '1.5rem',
        width: '1.5rem',
        marginTop: '2.5px',
        color: '#e0e0e0',
        marginRight: '0.75rem',
      },
    },
  },
  overlay: {
    position: 'fixed',
    top: 110,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '50rem',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    zIndex: 100000,
    borderRadius: '6px',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',

    '&:focus': {
      outline: 'none !important',
    },
  },
  cont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  field: {
    justifyContent: 'flex-start',
    padding: '2,5px !important',
    paddingLeft: '2rem',
    width: '43.75rem  !important',
    marginTop: '5px !important',

    '& .MuiInputBase-input': {
      fontWeight: 600,
      fontSize: '1rem !important',
      lineHeight: '20px',
      paddingLeft: '85px',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },

    '& svg': {
      height: 42,
      width: 42,
      marginTop: '2.5px',
      color: '#e0e0e0',
      marginRight: '0.75rem',
    },
  },
  close: {
    position: 'absolute',
    right: 30,
    top: 30,

    '& button': {
      borderRadius: '50% !important',
      minHeight: '35px !important',
      height: '35px !important',
      width: '35px !important',
      minWidth: '35px !important',

      '& svg': {
        color: theme.palette.primary.dark,
      },
    },
  },
  search: {
    position: 'relative',
    display: 'inline-block',
  },
  results: {
    display: 'flex',
    marginTop: '2.5rem',
    width: '100%',
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #F5F5F5',
    borderRadius: '6px',
    width: '10rem',
    height: '130.3px',
    marginTop: '2rem',
    marginRight: '4rem',
  },
  filterOn: {
    padding: '0.55rem !important',
    textAlign: 'center',
    color: `${theme.palette.primary.dark} !important`,
  },
  showResults: {
    width: '50rem',
  },
  resultCont: {
    padding: '1rem',
    paddingLeft: '0',
    paddingBottom: '4rem',
    borderBottom: '1px solid #e0e0e0',
    width: '50rem',
    marginTop: '1rem',
    position: 'relative',

    '& h5': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem !important',

      '& svg': {
        height: 35,
        width: 35,
        color: `${theme.palette.primary.main} !important`,
        marginRight: '7px',
        marginTop: '-7px',
      },
    },
  },
  resultTitle: {
    marginBottom: '2rem',
  },
  resultItem: {
    marginRight: '1.6rem',
    cursor: 'pointer',

    '& h6': {
      fontSize: '0.75rem !important',
    },

    '& p': {
      fontSize: '0.7rem !important',
      display: 'flex',
      alignItems: 'center',

      '& span': {
        marginLeft: '3px',
        marginRight: '5px',
        borderRadius: '50%',
        height: '10px',
        width: '10px',
        minHeight: '10px',
        minWidth: '10px',
        display: 'inline-block',
      },
    },
  },
  peoplesCont: {
    display: 'flex',
    marginTop: '0.75rem',
  },
  resultCount: {
    position: 'absolute',
    right: 15,
    top: 20,
    color: theme.palette.primary.dark,
    fontSize: '0.7rem',
  },
  noResult: {
    color: '#BDBDBD !important',
    fontSize: '1.2rem !important',
    margin: '1.25rem 0 !important',
  },
  seeMore: {
    position: 'absolute',
    right: -30,
    bottom: 5,

    '& button': {
      fontSize: '0.725rem !important',
      height: '35px !important',
      width: '275px !important',
      fontWeight: 600,
      margin: '0 !important',

      '& svg': {
        height: 15,
        width: 15,
      },
    },
  },
}));

enum FilterTypes {
  All = 'All',
  Companies = 'Companies',
  People = 'People',
}

const filters = [FilterTypes.All, FilterTypes.Companies, FilterTypes.People];

interface IHomeSearch {
  main: HTMLDivElement | null;
}

const HomeSearch: FunctionComponent<IHomeSearch> = (props) => {
  const { main } = props;
  const classes = useStyles();
  const MAX_PREVIEW = 6;
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterOn, setFilterOn] = useState<FilterTypes>(FilterTypes.All);
  const [filteredPeople, setFilteredPeople] = useState<any[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<ISearch>();
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay

  if (main) {
    main.style.overflowY = isActive ? 'hidden' : 'auto';
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      fetchSearchData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      if (main) main.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchData !== undefined) {
        filterCollections();
      } else {
        setFilteredPeople([]);
        setFilteredCompanies([]);
      }
    }, 10);

    return () => clearTimeout(delayDebounceFn);
  }, [filterOn, searchData]);

  function filterCollections() {
    if (!searchData) return null;
    if (filterOn === FilterTypes.People) {
      return setFilteredPeople(searchData.ecoUserData);
    }

    if (filterOn === FilterTypes.Companies) {
      return setFilteredCompanies(
        filterCollection(searchData.companies, 'companyName', searchTerm),
      );
    }

    setFilteredPeople(searchData.ecoUserData);
    setFilteredCompanies(
      filterCollection(searchData.companies, 'companyName', searchTerm),
    );
  }

  async function fetchSearchData() {
    try {
      const data = await getData(SEARCH_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
        {
          name: 'query',
          value: debouncedSearchTerm,
        },
      ]);

      setSearchData(data);
      setLoading(false);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
  }

  function renderFilter() {
    return filters.map((filter, i) => {
      return (
        <Button
          onClick={() => setFilterOn(filter)}
          key={filter}
          className={classes.filterOn}
          style={{
            backgroundColor: filterOn === filter ? '#F5F5F5' : 'white',
            fontWeight: filterOn === filter ? 600 : 500,
            borderTopRightRadius: i === 0 ? '6px' : '0',
            borderTopLeftRadius: i === 0 ? '6px' : '0',
            borderBottomRightRadius: i === filters.length - 1 ? '6px' : '0',
            borderBottomLeftRadius: i === filters.length - 1 ? '6px' : '0',
            borderBottom:
              i !== filters.length - 1 ? '1px solid #F5F5F5' : 'none',
          }}>
          {filter}
        </Button>
      );
    });
  }

  function renderPeopleResults() {
    if (filterOn !== FilterTypes.All && filterOn !== FilterTypes.People) {
      return null;
    }

    const renderPeople = () => {
      if (searchTerm === '') return null;

      let totalResults = 0;
      let renderedResults = 0;
      let calculated = false;
      const peopleResult = [...filteredPeople].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      const peoples = peopleResult.map((people, i) => {
        if (i === peopleResult.length - 1) calculated = true;
        const hide = people.filterOut === false;
        if (!hide) {
          totalResults++;
          renderedResults++;
        }

        return (
          <div
            key={`people-result-${i}`}
            className={classes.resultItem}
            onClick={() => navigate(`members/${people.id}`)}
            style={{
              display: hide || renderedResults > MAX_PREVIEW ? 'none' : '',
            }}>
            <Avatar source={people.logo} height={110} width={110} radius={6} />
            <Typography variant={'h6'}>{people.name}</Typography>
            <Typography variant={'body2'}>
              <span
                style={{
                  backgroundColor: people.accessLevelColor.includes('#')
                    ? people.accessLevelColor
                    : `#${people.accessLevelColor}`,
                }}
              />
              {people.accessLevelName}
            </Typography>
          </div>
        );
      });

      if (!calculated) return null;
      if (totalResults === 0) {
        return renderNoResultsFound();
      }

      return (
        <>
          <span className={classes.resultCount}>{totalResults} results</span>
          <div className={classes.peoplesCont}>{peoples}</div>
          {totalResults > MAX_PREVIEW && renderSeeMore('members')}
        </>
      );
    };

    return (
      <div className={classes.resultCont}>
        <Typography variant={'h5'}>
          <GroupIcon /> People
        </Typography>
        {renderPeople()}
      </div>
    );
  }

  function renderCompaniesResult() {
    if (filterOn !== FilterTypes.All && filterOn !== FilterTypes.Companies)
      return null;

    const renderCompany = () => {
      if (searchTerm === '') return null;
      let totalResults = 0;
      let renderedResults = 0;
      let calculated = false;
      const companyResult = [...filteredCompanies].sort((a, b) =>
        a.companyName.localeCompare(b.name),
      );

      const companies = companyResult.map((company, i) => {
        if (i === companyResult.length - 1) calculated = true;
        const hide = company.filterOut === false;
        if (!hide) {
          totalResults++;
          renderedResults++;
        }

        return (
          <div
            key={`company-result-${i}`}
            className={classes.resultItem}
            onClick={() => navigate(`companies/${company.companyName}`)}
            style={{
              display: hide || renderedResults > MAX_PREVIEW ? 'none' : '',
            }}>
            <Avatar source={company.logo} height={110} width={110} radius={6} />
            <Typography variant={'h6'}>{company.companyName}</Typography>
            <Typography variant={'body2'}>{company.sector}</Typography>
          </div>
        );
      });

      if (!calculated) return null;
      if (totalResults === 0) {
        return renderNoResultsFound();
      }

      return (
        <>
          <span className={classes.resultCount}>{totalResults} results</span>
          <div className={classes.peoplesCont}>{companies}</div>
          {totalResults > MAX_PREVIEW && renderSeeMore('companies')}
        </>
      );
    };

    return (
      <div className={classes.resultCont}>
        <Typography variant={'h5'}>
          <BusinessIcon /> Companies
        </Typography>
        {renderCompany()}
      </div>
    );
  }

  function renderSeeMore(path: string) {
    const resultType = path.split('/')[2];
    return (
      <div className={classes.seeMore}>
        <MainButton
          onClick={() => navigate(`/${path}`)}
          text={`See all ${resultType} here`}
          type={ButtonType.BLANK}
          endIcon={<ArrowForwardIosIcon />}
        />
      </div>
    );
  }

  function renderNoResultsFound() {
    return (
      <Typography className={classes.noResult} variant={'h4'}>
        No results found
      </Typography>
    );
  }

  function renderResults() {
    if (loading) {
      return <Loading />;
    }
    return (
      <div>
        <div className={classes.resultTitle}>
          <Typography variant={'h5'}>{`${filterOn} results`}</Typography>
        </div>
        {renderPeopleResults()}
        {renderCompaniesResult()}
      </div>
    );
  }

  function reset() {
    setIsActive(false);
    setSearchTerm('');
    setFilteredCompanies([]);
    setFilteredPeople([]);
    setFilterOn(FilterTypes.All);
  }

  return (
    <>
      <div className={classes.root}>
        {!isActive && (
          <MainButton
            onClick={() => setIsActive(true)}
            text={'What do you want to search for?'}
            type={ButtonType.BLANK}
            startIcon={<SearchIcon />}
            disableRipple={true}
            style={{
              width: '100% !important',
              height: '2.5rem',
              border: '2px solid #E0E0E0 !important',
              fontSize: '0.9rem !important',
              color: '#4D4D4D',
              marginRight: { xs: '0', md: '0 !important' },
            }}
          />
        )}
      </div>
      {isActive && (
        <CommonModal open={isActive} onClose={() => setIsActive(false)}>
          <div
            tabIndex={0}
            className={classes.overlay}
            style={{ maxWidth: '80rem' }}
            onKeyDown={({ key }) => key == 'Escape' && reset()}>
            <div className={classes.close}>
              <Button onClick={reset}>
                <HighlightOffIcon />
              </Button>
            </div>
            <div className={classes.cont}>
              <div className={classes.search}>
                <SearchIcon
                  style={{
                    position: 'absolute',
                    top: 12,
                    left: 30,
                    height: 42,
                    width: 42,
                    marginTop: '2.5px',
                    color: theme.palette.primary.main,
                    marginRight: '0.75rem',
                    zIndex: 100,
                  }}
                />
                <TextField
                  autoFocus={true}
                  className={classes.field}
                  variant='outlined'
                  value={searchTerm}
                  placeholder={''}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                      padding: '2px',
                      '& > fieldset': {
                        border: `2px solid ${theme.palette.primary.main}`,
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.results}>
                <div className={classes.filter}>{renderFilter()}</div>
                <div className={classes.showResults}>{renderResults()}</div>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default HomeSearch;
