import React, { FunctionComponent } from 'react';
import { CircularProgress, Fade, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    display: 'flex',
    alignItems: (props: any) => (props.noCenter ? 'initial' : 'center'),
    justifyContent: 'center',
    width: (props: any) => (props.width ? `${props.width}px` : '100%'),
    height: (props: any) => `${props.height ?? 400}px`,
    marginTop: (props: any) => `${props.marginTop ?? 0}px`,
  },
}));

interface ILoading {
  size?: number | string;
  color?: string;
  marginTop?: number;
  height?: number;
  width?: number;
  noCenter?: boolean;
}

const Loading: FunctionComponent<ILoading> = (props) => {
  const { size, color, marginTop, height, width, noCenter } = props;
  const classes = useStyles({ marginTop, height, width, noCenter });
  const location = useLocation();
  //const inEcosystem = !!getEcosystemNameFromPath(location.pathname);
  //const hide = inEcosystem && !existsEcosystemTheme();

  return (
    <Fade in={true}>
      <div className={classes.loading} style={{ color }}>
        <CircularProgress
          size={size ?? 40}
          color={color ? 'inherit' : 'primary'}
        />
      </div>
    </Fade>
  );
};

export default Loading;
