import React, { useState, FunctionComponent, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CardMedia,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import CommonModal from 'components/common/Modal/CommonModal';
import CloseModalButton from 'components/common/Modal/CloseModalButton';
import { acterioTheme, naturalPallete } from 'theme';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import ColorPicker from 'components/common/ColorPicker';
import InputField from 'components/common/InputField';
import {
  COMPANY_LABEL_AND_COLOR_ROUTE,
  COMPANY_LABEL_SETTINGS_ROUTE,
  getData,
  patchData,
} from 'utils/requests';
import { useParams } from 'react-router';
import Loading from 'components/common/Loading';
import { ICompanyWithLabel } from 'utils/models';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { formatDateString } from 'utils/utils';

interface ICompanyLabelSettings {}

const CompanyLabelSettings: FunctionComponent<ICompanyLabelSettings> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [companiesList, setCompaniesList] = useState<ICompanyWithLabel[]>([]);
  const [selectedCompany, setSelectedCompany] =
    useState<ICompanyWithLabel | null>(null);
  const [label, setLabel] = useState('');

  const { ecosystemName } = useParams();

  const handleOpen = (company: ICompanyWithLabel) => {
    setSelectedCompany(company);
    setOpenEditModal(true);
   
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setSelectedCompany(null);
  };

  const getCompanyLabelSettings = async () => {
    setIsLoading(true);
    try {
      const data = await getData(COMPANY_LABEL_SETTINGS_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      setCompaniesList(data);
    } catch (error) {
      console.error('Failed to fetch company details', error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getCompanyLabelSettings();
  }, []);

  async function changeCompanyLabelAndColor() {
    if (!selectedCompany) return;
    setIsLoading(true);
    try {
      const request = await patchData(
        COMPANY_LABEL_AND_COLOR_ROUTE,
        [{ name: 'ecosystemName', value: ecosystemName }],
        {
          name: selectedCompany.name,
          label: selectedCompany.label === '' ? null : selectedCompany.label,
          color: selectedCompany.color === '#ffffff' ? null : selectedCompany.color,
        },
      );

      setCompaniesList((prevList) => {
        const updatedList = prevList.map((row) =>
          row.name === selectedCompany.name
            ? { ...row, label: request.label, color: request.color }
            : row,
        );
        return updatedList;
      });
      handleClose();
    } catch (error) {
      console.error('Failed to fetch company details', error);
    }

    setIsLoading(false);
  }
  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
    if (selectedCompany) {
      setSelectedCompany({ ...selectedCompany, label: event.target.value });
    }
  };

  return (
    <Box>
      <Typography
        sx={{ fontWeight: '600', fontSize: '2.5rem', lineHeight: '3.75rem' }}>
        Company label settings
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          color: '#4D4D4D',
          marginY: '1.5rem',
        }}>
        Select and edit existing company label color or name without moving
        company to a different template.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
        }}>
        {isLoading ? (
          <Box sx={{ margin: '10rem' }}>
            <Loading height={0} size={40} />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>COMPANY NAME</TableCell>
                  <TableCell align='right'>CREATED</TableCell>
                  <TableCell>LABEL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companiesList.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        color: '#141414',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: '#141414',
                          }}>
                          {row.name}
                        </Typography>
                        <CardMedia
                          component='img'
                          src={
                            row.logoUrl ?? '/images/placeholders/company.png'
                          }
                          alt='CompanyCard-Logo'
                          sx={{
                            width: '2.265rem',
                            height: '2.265rem',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align='right'>
                      {row.created ? formatDateString(row.created) : 'Unknown'}
                    </TableCell>
                    <TableCell align='right'>
                      <AccessLevelTagCard
                        accessLevelName={row.label}
                        accessLevelColor={row.color || undefined}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton onClick={() => handleOpen(row)}>
                        <Edit sx={{ color: '#1A1A1A' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {selectedCompany && (
        <CommonModal open={openEditModal} onClose={handleClose}>
          <Box
            sx={{
              position: 'relative',
              top: { md: '30%' },
              left: { xs: 0, md: '50%' },
              transform: { md: 'translate(-50%, 0%)' },
              width: '36rem',
              height: '20.125rem',
              bgcolor: '#FFFFFF',
              boxShadow: 24,
              padding: '3rem',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}>
            <CloseModalButton
              handleClose={handleClose}
              color={acterioTheme.mainBlackFont}
              background={true}
              bgColor={acterioTheme.pureWhite}
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                zIndex: '9',
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.875rem',
                lineHeight: '2.8rem',
              }}>
              Edit company label
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '1rem',
                color: '#4D4D4D',
                marginTop: '0.5rem',
              }}>
              Changes you make will be seen in selected company profiles
            </Typography>
            <Box
              sx={{ display: 'flex', marginTop: '2rem', marginBottom: '3rem' }}>
              <ColorPicker
                noMargin={true}
                sxOuterBox={{
                  width: '9.875rem',
                  height: '2.25rem',
                  padding: '0.301rem',
                  border: '0.6px solid',
                  borderColor: naturalPallete.natural6,
                  borderRadius: '6px',
                }}
                sxColorBox={{
                  width: '3.75rem',
                  height: '1.688rem',
                  borderRadius: '6px',
                }}
                sxTypography={{
                  fontWeight: 400,
                  fontSize: '0.603rem',
                  lineHeight: '1rem',
                }}
                color={selectedCompany.color || acterioTheme.light}
                onSelectedColor={(color) =>
                  setSelectedCompany((prevState) =>
                    prevState ? { ...prevState, color } : prevState,
                  )
                }
              />
              <InputField
                sx={{ width: '17.2rem', marginLeft: '2rem' }}
                placeholder=''
                value={selectedCompany.label}
                onChange={handleLabelChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '1rem',
              }}>
              <CommonButton
                text='Cancel'
                variant={ButtonVariant.BLANK}
                sx={{ marginRight: '0.5rem' }}
              />
              <CommonButton
                text='Save'
                variant={ButtonVariant.FILLED}
                onClick={changeCompanyLabelAndColor}
              />
            </Box>
          </Box>
        </CommonModal>
      )}
    </Box>
  );
};

export default CompanyLabelSettings;
