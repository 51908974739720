import { Button, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { BookingMenuKeys } from '../Booking/BookingSideMenu';
import { IMenuKeys, ISideMenuSection } from 'utils/models';
import { TripOrigin } from '@mui/icons-material';
import { naturalPallete } from 'theme';
import { AdminMenuKeys } from 'components/Admin/AdminSideMenu';

interface ISideMenuSections {
  menuItems: ISideMenuSection[];
  selectedOption: IMenuKeys;
  sx?: SxProps;
}
const SideMenuSections: FunctionComponent<ISideMenuSections> = (props) => {
  const { menuItems, selectedOption, sx } = props;
  const theme = useTheme();

  function renderSections() {
    return menuItems.map((menuItem, i) => {
      const {
        key,
        text,
        icon = <TripOrigin />,
        onClick,
        isSpecial,
        hasAccess,
      } = menuItem;
      const isActive = selectedOption === key;
      const isBigGapTop =
        key === BookingMenuKeys.Rooms ||
        key === AdminMenuKeys.Assessments || // Remove the one below once assessments are back
        key === AdminMenuKeys.AssessmentReports;

      if (!hasAccess) return;

      return (
        <Box
          key={key}
          sx={{
            pl: '1rem',
            py: {
              xs: '0.5rem',
              md: '0.2rem',
            },
            mt: isSpecial ? '0.5rem' : isBigGapTop ? '3rem' : 0,
            ...sx,
          }}>
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'start',
              color: isSpecial
                ? theme.palette.primary.main
                : isActive
                ? naturalPallete.mainBlackFont
                : naturalPallete.natural2,
              fontSize: { xs: '2rem', md: '1.25rem' },
              lineHeight: { xs: '2.1rem', md: '1.3rem' },
              borderRadius: '0px',
              borderLeft: isActive
                ? isSpecial
                  ? `2px solid ${theme.palette.primary.main}`
                  : `2px solid ${naturalPallete.mainBlackFont}`
                : '2px solid transparent',
              // '&:hover': {
              //   bgcolor: primary.soft,
              // },
            }}
            fullWidth
            onClick={onClick}>
            {icon}
            <Typography
              sx={{
                ml: isActive ? '0.65rem' : '0.75rem',
                fontWeight: isActive || isSpecial ? 600 : 500,
                color: isSpecial
                  ? theme.palette.primary.main
                  : isActive
                  ? naturalPallete.mainBlackFont
                  : naturalPallete.natural2,
              }}>
              {text}
            </Typography>
          </Button>
        </Box>
      );
    });
  }

  return <>{renderSections()}</>;
};

export default SideMenuSections;
