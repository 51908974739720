import { DeleteRounded } from '@mui/icons-material';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from 'components/common/Loading';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { primary } from 'theme';
import { IApplicationReports, IListButton } from 'utils/models';
import { APPLICATION_ASSESSMENT_REPORTS, deleteData } from 'utils/requests';
import AssessmentProcessReportRow from './AssessmentProcessReportRow';

interface IAssessmentProcessReportProps {
  process: IApplicationReports;
  setProcessesReportsList: Dispatch<SetStateAction<IApplicationReports[]>>;
}

const AssessmentProcessReport: FunctionComponent<
  IAssessmentProcessReportProps
> = (props) => {
  const { process, setProcessesReportsList } = props;

  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const buttonList: IListButton[] = [
    {
      name: 'Delete',
      onClick: () => {
        setPopoverAnchorEl(null);
        deleteProcess();
      },
      icon: DeleteRounded,
      textColor: primary.warningRed,
    },
  ];

  async function deleteProcess() {
    if (!process.id) return;
    setIsLoading(true);
    try {
      const data = await deleteData(APPLICATION_ASSESSMENT_REPORTS, [
        { name: 'processId', value: process.id },
        { name: 'ecosystemName', value: ecosystemName },
      ]);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Report was deleted',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return (
    <Card
      sx={{
        my: '1.5rem',
        p: '1.5rem',
        border: `1px solid ${primary.additionalGrey}`,
        borderRadius: '8px',
        backgroundColor: `${primary.pureWhite}`,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      }}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant='h4'
              sx={{
                color: primary.natural2,
                mt: '0.75rem',
                mb: '2rem',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}>
              {process.name}
            </Typography>
            <Box sx={{ width: '2rem', height: '2rem' }}>
              {false && ( //Commented out for now to add button to specific report lines.
                <MoreOptionsOverlay
                  popoverAnchorEl={popoverAnchorEl}
                  setPopoverAnchorEl={setPopoverAnchorEl}>
                  <MoreOptionsButtons buttonList={buttonList} />
                </MoreOptionsOverlay>
              )}
            </Box>
          </Box>
          <Grid container columnSpacing={'1rem'}>
            <Grid item xs={3}>
              <Typography
                sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
                Stage
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
                Status
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography
                sx={{ color: primary.natural3, textTransform: 'uppercase' }}>
                Date started
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography
                sx={{
                  color: primary.natural3,
                  textTransform: 'uppercase',
                  width: '100%',
                }}>
                Assessed Applications
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box />
            </Grid>
            <Divider sx={{ width: '100%', mt: '0.75rem', mb: '1.5rem' }} />
            {process.reports.map((report, i) => (
              <AssessmentProcessReportRow
                report={report}
                key={report.id}
                process={process}
                setProcessesReportsList={setProcessesReportsList}
              />
            ))}
          </Grid>
        </Box>
      )}
    </Card>
  );
};

export default AssessmentProcessReport;
