import React, { FunctionComponent, useState } from 'react';
import {
  Theme,
  Grid,
  Typography,
  Box,
  Card,
  ButtonBase,
  useTheme,
} from '@mui/material';
import {
  WatchLater,
  CalendarMonthOutlined,
  PeopleAlt,
  ModeRounded,
  ControlPointDuplicate,
  DeleteRounded,
  LocationOn,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { acterioTheme, naturalPallete } from 'theme';
import { IEvent, IListButton, ITag } from 'utils/models';
import { getEcosystemInfo, getStoredEvents } from 'utils/storage';
import { useMobileContext } from 'services/contexts/MobileContext';
import TagDisplay from 'components/common/Tags/TagDisplay';
import { defaultEventPhoto, trackUmamiEvent } from 'utils/utils';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import RichTextDisplay from 'components/common/RichTextDisplay';
import TypographyBox from 'components/common/TypographyBox';
import DateTag from 'components/Events/DateTag';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import useDeleteEvent from 'hooks/Events/useDeleteEvent';
import TimezoneOffsetDisplay from 'components/common/TimezoneOffsetDisplay';
import ConfirmationForm from 'components/common/ConfirmationForm';

const useStyles = makeStyles((theme: Theme) => ({
  coverImage: {
    width: '100%',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
  },
}));

interface GridPostProps {
  event: IEvent;
  isHome?: boolean;
  handleClose: () => void;
}

const GridPost: FunctionComponent<GridPostProps> = (props) => {
  const { event, isHome, handleClose } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteProps = { event, setIsLoading, handleClose };
  const deleteEvent = useDeleteEvent({ ...deleteProps });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);

  const {
    title,
    location,
    time,
    startTime,
    endTime,
    description,
    attendees,
    photo = event.photo ?? defaultEventPhoto,
    startDateString,
    startDate,
    multiDay,
    endDateString,
  } = event;

  const { isMobile } = useMobileContext();
  const theme = useTheme();
  const classes = useStyles({ isMobile });
  const buttonList: IListButton[] = [
    {
      name: 'Edit',
      onClick: () => callShowEditEvent(false),
      icon: ModeRounded,
    },
    {
      name: 'Duplicate',
      onClick: () => callShowEditEvent(true),
      icon: ControlPointDuplicate,
    },
    {
      name: 'Delete',
      onClick: openConfrimDelte,
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  const {
    setIsShowEventDetails,
    setChosenEvent,
    setIsShowEdit,
    setIsDuplicate,
  } = useEventsContext();

  function callShowEventDetails() {
    setIsShowEventDetails(true);
    setChosenEvent(event);
    trackUmamiEvent('View Event Details');
  }

  function callShowEditEvent(isDuplicate: boolean) {
    setIsShowEdit(true);
    setChosenEvent(event);
    setPopoverAnchorEl(null);
    if (!isDuplicate) return;
    setIsDuplicate(true);
  }

  function openConfrimDelte() {
    setIsShowDeleteConfirm(true);
    setPopoverAnchorEl(null);
  }

  const storageEvents = getStoredEvents();
  const storedEvent =
    storageEvents && storageEvents.find((e) => e.id === event.id);

  const eventAttendingList = storedEvent?.attendees || event.attendees;
  const eventAttendingListLength = eventAttendingList?.length;

  const isAbleToEdit = getEcosystemInfo().accessLevel?.manageEvents;

  function RenderTextGrid() {
    const titleTags: ITag[] = [
      {
        name: title,
      },
    ];

    return (
      <Grid
        item
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          cursor: 'pointer',
          padding: '0.5rem',
        }}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            onClick={callShowEventDetails}
            variant='h4'
            sx={{
              marginLeft: 1,
              textAlign: 'left',
            }}>
            {title}
          </Typography>

          {!isHome && event.canUserEdit && (
            <MoreOptionsOverlay
              popoverAnchorEl={popoverAnchorEl}
              setPopoverAnchorEl={setPopoverAnchorEl}>
              <MoreOptionsButtons buttonList={buttonList} />
            </MoreOptionsOverlay>
          )}
        </Box>

        <Box
          onClick={callShowEventDetails}
          display='flex'
          alignItems='center'
          marginLeft={0.5}
          sx={{
            width: '100%',
          }}>
          <TagDisplay
            tags={!!event.tags?.length ? event.tags : titleTags}
            oneRow={isHome}
          />
        </Box>

        <Box
          onClick={callShowEventDetails}
          display={'flex'}
          flexDirection={'column'}
          marginBottom={0}
          flex={1}>
          {!isMobile && !!description && (
            <RichTextDisplay
              style={{
                variant: 'body1',
                paddingX: '0.5rem',
                marginBottom: '1.5rem',
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '0.25rem',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                maxHeight: '4rem',
                lineHeight: '1.5rem',
                wordWrap: 'break-word',
              }}
              text={description}
            />
          )}
        </Box>
        <Box
          onClick={callShowEventDetails}
          display={'flex'}
          flexDirection={'column'}
          marginBottom={0}>
          {!location && (
            <Box
              sx={{
                height: '1.5rem',
              }}
            />
          )}
          {location !== '' && location !== null && (
            <Box
              display='flex'
              alignItems='center'
              marginTop={1}
              marginLeft={1}>
              <LocationOn
                sx={{
                  color: theme.palette.primary.main,
                  marginLeft: 0.2,
                  marginRight: 0.5,
                  fontSize: '1.375rem',
                }}
              />
              <TypographyBox text={location} numberOfLines={2} />
            </Box>
          )}

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'>
            <Box display='flex' marginY={1} marginLeft={1} width={'100%'}>
              {multiDay ? (
                <CalendarMonthOutlined
                  sx={{
                    color: theme.palette.primary.main,
                    marginLeft: 0.2,
                    marginRight: 0.5,
                    fontSize: '1.375rem',
                  }}
                />
              ) : (
                <WatchLater
                  sx={{
                    color: theme.palette.primary.main,
                    marginLeft: 0.2,
                    marginRight: 0.5,
                    fontSize: '1.375rem',
                  }}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}>
                <Typography
                  sx={{
                    overflow: 'hidden',
                  }}>
                  {multiDay
                    ? `${startTime ? startTime : ''} ${startDateString} - ${
                        endTime ? endTime : ''
                      } ${endDateString}`
                    : time ?? `${startTime} - ${endTime}`}
                </Typography>
                {!multiDay && <TimezoneOffsetDisplay />}
              </Box>
            </Box>

            <Box
              display='flex'
              marginRight={1}
              marginLeft={1}
              alignItems={'center'}>
              <PeopleAlt
                sx={{
                  color: theme.palette.primary.main,
                  marginLeft: 0.2,
                  marginRight: 0.5,
                  fontSize: '1.375rem',
                }}
              />
              <Typography
                sx={{
                  marginLeft: 1,
                }}>
                {eventAttendingListLength}
              </Typography>
            </Box>
          </Box>
        </Box>
        {isShowDeleteConfirm && (
          <ConfirmationForm
            isOpen={isShowDeleteConfirm}
            handleClose={() => {
              setIsShowDeleteConfirm(false);
            }}
            funcExe={deleteEvent}
            text={'to delete this post?'}
            isLoading={isLoading}
            isWarning
          />
        )}
      </Grid>
    );
  }

  function RenderPhotoGrid() {
    return (
      <Grid
        item
        sx={{
          height: isMobile ? 105 : isHome ? 150 : 150,
        }}>
        <ButtonBase
          onClick={callShowEventDetails}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}>
          <img src={photo} alt='Event' className={classes.coverImage} />
          <DateTag
            date={startDate}
            sx={{ position: 'absolute', top: '1rem', left: '1rem' }}
          />
        </ButtonBase>
      </Grid>
    );
  }

  return (
    <Card
      sx={{
        border: `1px solid ${naturalPallete.natural8}`,
        width: '100%',
        height: '100%',
        borderRadius: 2,
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
      {RenderPhotoGrid()}
      {RenderTextGrid()}
    </Card>
  );
};

export default GridPost;
