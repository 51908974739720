import React, { FunctionComponent } from 'react';
import CompanySection from '../CompanySection';
import RichTextDisplay from '../../common/RichTextDisplay';
import { Box, Grid } from '@mui/material';
import { isEmptyRichText } from 'utils/utils';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import { IUploadFile } from 'utils/models';
import CompanyImageCard from '../CompanyImageCard';

export interface ICompanyMain {}

const CompanyMain: FunctionComponent<ICompanyMain> = (props) => {
  const { companyProfile } = useCompanyContext();
  const { main } = companyProfile!;

  function renderMain() {
    return main.map((field) => {
      if (!field) return;

      if (field.type === 'RICH_TEXT') {
        if (isEmptyRichText(field.richText)) {
          return null;
        }

        return (
          <Box sx={{ marginBottom: '1rem' }} key={field.id}>
            <CompanySection title={field.name}>
              <RichTextDisplay text={field.richText as string} />
            </CompanySection>
          </Box>
        );
      }

      if (field.type === 'IMAGES') {
        if (!field.files || !field.files?.length) {
          return null;
        }

        return (
          <Box sx={{ marginBottom: '1rem' }} key={field.id}>
            <CompanySection title={field.name}>
              <>
                <Grid
                  container
                  spacing={'0.5rem'}
                  rowSpacing={'0rem'}
                  sx={{ p: '0.25rem' }}>
                  {field.files.map((file: IUploadFile) => (
                    <CompanyImageCard key={file.id} file={file} />
                  ))}
                </Grid>
              </>
            </CompanySection>
          </Box>
        );
      }
    });
  }

  return <>{renderMain()}</>;
};

export default CompanyMain;
