import { useEffect, useState } from 'react';
import { filterCollection } from 'utils/utils';

interface ISearchFilter {
  collection: any[] | null;
  filterOnField: string | string[];
  onFilter: (filteredCollection: any[]) => void;
}

const useFilterSearch = (props: ISearchFilter) => {
  const { collection, filterOnField, onFilter } = props;

  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (!collection) return;

    const delayDebounceFn = setTimeout(() => {
      const filteredCollection = filterCollection(
        collection,
        filterOnField,
        searchTerm,
      );

      onFilter(filteredCollection);
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
  };
};

export default useFilterSearch;
