import { useContext } from 'react';
import { useParams } from 'react-router';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import { IEvent } from 'utils/models';
import { EVENT_ROUTE, postFormData } from 'utils/requests';
import { getDateWithTime } from 'utils/utils';
import { updateEvents } from 'utils/storage';

interface IPost {
  event: IEvent;
  setIsLoading: (b: boolean) => void;
  handleClose: () => void;
  coverImg: any;
  usedTags: any;
  selectedTagGroup: any;
  contactUserId: any;
  sendEmails?: boolean;
}

const usePostEvent = (props: IPost) => {
  const {
    event,
    setIsLoading,
    handleClose,
    coverImg,
    usedTags,
    selectedTagGroup,
    contactUserId,
    sendEmails,
  } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const { events, setEvents } = useEventsContext();

  async function postNewEvent() {
    setIsLoading(true);

    try {
      // Create the event data object
      const eventData: any = {
        title: event.title,
        description: event.description,
        location: event.location,
        latitude: event.latitude,
        longitude: event.longitude,
        date: event.startDate,
        startTime: event.startTime,
        endTime: event.endTime,
        accessLevelIds: event.accessLevelIds,
        multiDay: event.multiDay,
        startDateString: event.startDate,
        endDateString: event.endDate ?? '',
        url: event.url,
        ecosystemName,
        tags: JSON.stringify(usedTags),
        ...(event.recurring && { recurring: event.recurring }),
        ...(selectedTagGroup && { selectedTagGroup }),
        ...(contactUserId ? { contactUserId } : {}),
        recurringEndDateString: event.recurringEndDate,
        sendEmails: sendEmails ?? false,
      };

      const formData = new FormData();

      const eventDataString = JSON.stringify(eventData);
      formData.append(
        'calendarEventDTO',
        new Blob([eventDataString], { type: 'application/json' }),
      );

      if (coverImg) formData.append('image', coverImg, coverImg.name);

      // Send the event data in the body of the request
      const response = await postFormData(EVENT_ROUTE, formData, false);

      const sortedEvents = [...events, ...response].sort(
        (a, b) => getDateWithTime(a) - getDateWithTime(b),
      );

      setEvents(sortedEvents);
      updateEvents(sortedEvents);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Event created',
      });

      //   reset();
      handleClose();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  return postNewEvent;
};

export default usePostEvent;
