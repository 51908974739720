import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';

interface IDisplay {
  text: string;
  style?: SxProps;
  overrideQuill?: boolean;
}

const RichTextDisplay: FunctionComponent<IDisplay> = (props) => {
  const { text, style, overrideQuill } = props;

  return (
    <Box
      className={overrideQuill ? '' : 'view ql-editor'}
      sx={style}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};

export default RichTextDisplay;
