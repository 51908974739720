import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IEvent, ITag, RecurringOption } from 'utils/models';
import { updateEvents } from 'utils/storage';
import Loading from '../common/Loading';
import AddPhoto from './AddEditComponents/AddPhoto';
import AddEventName from './AddEditComponents/AddEventName';
import AddContactPerson from './AddEditComponents/AddContactPerson';
import SelectAccessLevels from '../common/SelectAccessLevels';
import AddTime from './AddEditComponents/AddTime';
import AddIsWholeDayButton from './AddEditComponents/AddIsWholeDayButton';
import AddChooseLocation from './AddEditComponents/AddChooseLocation';
import AddUrl from './AddEditComponents/AddUrl';
import AddTagGroup from './AddEditComponents/AddTagGroup';
import AddTag from './AddEditComponents/AddTag';
import AddEventDescription from './AddEditComponents/AddEventDescription';
import AddFunctionalButtons from './AddEditComponents/AddFunctionalButtons';
import AddHeader from './AddEditComponents/AddHeader';
import useDeleteEvent from 'hooks/Events/useDeleteEvent';
import useUpdateEvent from 'hooks/Events/useUpdateEvent';
import useDeleteEventImage from 'hooks/Events/useDeleteEventImage';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import AddNewEventButton from './AddEditComponents/AddNewEventButton';
import usePostEvent from 'hooks/Events/usePostEvent';
import AddRecurringOption from './AddEditComponents/AddRecurringOption';
import { urlToImagePNGFile } from 'utils/utils';
import CommonModal from '../common/Modal/CommonModal';

interface EditEventProps {
  isOpen: boolean;
  handleClose: () => void;
  eventToEdit: IEvent;
}

const EditEvent: FunctionComponent<EditEventProps> = (props) => {
  const { isOpen, handleClose, eventToEdit } = props;

  const { events, setEvents, isDuplicate, setIsDuplicate } = useEventsContext();

  const [event, setEvent] = useState<IEvent>(eventToEdit);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [contactUserId, setContactUserId] = useState<number>(NaN); // It could and should be moved in the future, but its too much hustle for now <requires creation of additional props on IEvent

  const [selectedTagGroup, setSelectedTagGroup] = useState<number | null>(
    event?.tagGroup?.id || null, //Would love to move tags, but also pretty complex for now
  );
  const [tags, setTags] = useState<ITag[]>([]);
  const [usedTags, setUsedTags] = useState<ITag[]>(event?.tags || []);

  const [coverImg, setCoverImg] = useState<any>(null);

  const [initializeDuplicate, setInitializeDuplicate] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isDuplicate) return;

    setInitializeDuplicate(true);
  }, []);

  useEffect(() => {
    setIsDuplicate(false);
    if (!event.photo) return;
    urlToImagePNGFile(event.photo, setCoverImg);
  }, [initializeDuplicate]);

  if (event.isDuplicate) {
    event.isDuplicate = false;

    const updatedEvents = events.map((e: IEvent) =>
      e.id === event.id ? event : e,
    );

    setEvents(updatedEvents);
    updateEvents(updatedEvents);
  }

  const isAllRequiredFilled =
    event.title !== '' &&
    event.description !== '' &&
    event.startDate !== '' &&
    event.accessLevelIds.length > 0 &&
    (!event.multiDay || event.endDate) &&
    (event.multiDay || (!event.multiDay && event.startTime && event.endTime)) &&
    (!initializeDuplicate ||
      event.recurring === "Doesn't Repeat" ||
      !event.recurring ||
      (event.recurring && event.recurringEndDate));

  function removePhoto() {
    setCoverImg(null);
    deleteEventImage();
  }

  function handleIsWholeDayButton() {
    const isMultiDay = event.multiDay;
    const updatedEvent = { ...event, multiDay: !isMultiDay };
    setEvent(updatedEvent);
  }

  function duplicateEvent() {
    setInitializeDuplicate(true);
  }

  function handleAccessGroupChange(selectedGroupIds: any[]) {
    const updatedEvent = { ...event, accessLevelIds: selectedGroupIds };
    setEvent(updatedEvent);
  }

  const deleteEventImage = useDeleteEventImage({ event });

  const updateProps = {
    event,
    setIsLoading,
    handleClose,
    coverImg,
    usedTags,
    selectedTagGroup,
    contactUserId,
  };
  const updateEvent = (recurringOption?: RecurringOption) =>
    useUpdateEvent({ ...updateProps, recurringOption });

  const deleteProps = { event, setIsLoading, handleClose };
  const deleteEvent = (recurringOption?: RecurringOption) =>
    useDeleteEvent({ ...deleteProps, recurringOption });

  const postProps = {
    event,
    setIsLoading,
    handleClose,
    coverImg,
    usedTags,
    selectedTagGroup,
    contactUserId,
  };
  const postEvent = usePostEvent(postProps);

  return (
    <CommonModal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '37rem',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '2rem',
        }}>
        <Box>
          <AddHeader
            isLoading={isLoading}
            handleClose={handleClose}
            isNew={initializeDuplicate}
          />
          <Box
            style={{ maxHeight: '37.5rem', overflowY: 'auto' }}
            marginRight={-2}>
            {isLoading ? (
              <Loading height={545} />
            ) : (
              <Box
                sx={{
                  marginRight: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                }}>
                <AddPhoto
                  event={event}
                  setCoverImg={setCoverImg}
                  removePhoto={removePhoto}
                />
                <AddEventName
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
                <AddContactPerson
                  setContactUserId={setContactUserId}
                  event={event}
                  setEvent={setEvent}
                />
                <SelectAccessLevels
                  accessLevelIds={event.accessLevelIds}
                  onAccessGroupChange={handleAccessGroupChange}
                />
                <AddTime event={event} setEvent={setEvent} />
                <AddIsWholeDayButton
                  isMultiDay={event.multiDay}
                  handleIsWholeDayButton={handleIsWholeDayButton}
                />
                <AddChooseLocation
                  isLoading={isLoading}
                  event={event}
                  setEvent={setEvent}
                />
                <AddUrl
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
                <AddTagGroup
                  setTags={setTags}
                  selectedTagGroup={selectedTagGroup}
                  setSelectedTagGroup={setSelectedTagGroup}
                />
                <AddTag
                  event={event}
                  setEvent={setEvent}
                  selectedTagGroup={selectedTagGroup}
                  tags={tags}
                  usedTags={usedTags}
                  setUsedTags={setUsedTags}
                />
                {initializeDuplicate && (
                  <AddRecurringOption event={event} setEvent={setEvent} />
                )}
                <AddEventDescription
                  event={event}
                  setEvent={setEvent}
                  isLoading={isLoading}
                />
              </Box>
            )}
            {initializeDuplicate ? (
              <AddNewEventButton
                isAllRequiredFilled={isAllRequiredFilled}
                isLoading={isLoading}
                saveNewEvent={postEvent}
              />
            ) : (
              <AddFunctionalButtons
                event={event}
                isAllRequiredFilled={isAllRequiredFilled}
                updateEvent={updateEvent}
                isLoading={isLoading}
                duplicateEvent={duplicateEvent}
                deleteEvent={deleteEvent}
              />
            )}
          </Box>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default EditEvent;
