import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import { primary } from 'theme';
import { Button, Typography, useTheme } from '@mui/material';
import { IAvailability, IAvailableTimeslots } from 'utils/models';
import {
  formatDateYMD,
  isAvailableTimeslots,
  isAvailablity,
} from 'utils/utils';

interface DayProps {
  day: any;
  monthIndex: number;
  availability: IAvailability | IAvailableTimeslots[];
  handleHourDateClick: (selectedDay: Date) => void;
  selectedDate?: Date;
  setSelectedDate: (date: Date) => void;
}

const BookingDay: FunctionComponent<DayProps> = (props) => {
  const {
    day,
    monthIndex,
    availability,
    handleHourDateClick,
    selectedDate,
    setSelectedDate,
  } = props;

  const theme = useTheme();

  const dayString = new Date(day.$d)
    .toLocaleString('en', { weekday: 'short' })
    .toLowerCase();
  const dayDate = new Date(day.$d);

  let condition;
  if (isAvailablity(availability)) {
    condition = availability.weeklyHours[dayString].active;
  } else if (isAvailableTimeslots(availability)) {
    condition = availability.some(function (item) {
      return item.date === formatDateYMD(dayDate);
    });
  }

  const isInThePast = day < new Date().setHours(0, 0, 0, 0);
  const isDayChosen =
    selectedDate !== undefined &&
    selectedDate.toString().substring(0, 15) ===
      dayDate.toString().substring(0, 15);

  function handleClickOnDate() {
    handleHourDateClick(dayDate);
    setSelectedDate(dayDate);
  }

  if (day.$d.getMonth() !== ((monthIndex % 12) + 12) % 12) {
    return <Box></Box>;
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height={'3.75rem'}
      sx={{
        '&:hover': { cursor: condition && !isInThePast ? 'pointer' : 'normal' },
      }}>
      {condition ? (
        <Button
          sx={{
            position: 'absolute',
            borderRadius: 10,
            bgcolor: isInThePast
              ? '#E8EBED'
              : isDayChosen
              ? theme.palette.primary.dark
              : theme.palette.primary.contrastText,
            minWidth: 'unset',
            width: '4.063rem',
            height: '2.5rem',
            '&:hover': {
              bgcolor: isDayChosen
                ? theme.palette.primary.main
                : theme.palette.primary.light,
            },
          }}
          onClick={() => handleClickOnDate()}
          disabled={isInThePast}>
          <Typography
            fontSize={'1.5rem'}
            fontWeight='bold'
            sx={{
              color: isInThePast
                ? primary.natural6
                : isDayChosen
                ? primary.pureWhite
                : theme.palette.primary.main,
              fontWeight: 'bold',
            }}>
            {day.$D}
          </Typography>
        </Button>
      ) : (
        <Typography fontSize={'1.5rem'} sx={{ color: 'black' }}>
          {day.$D}
        </Typography>
      )}
    </Box>
  );
};

export default BookingDay;
