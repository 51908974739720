import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Fade, Grid, Typography, useTheme } from '@mui/material';
import InputField from 'components/common/InputField';
import { IListButton, ITagGroup } from 'utils/models';
import { Clear, EditOff, ModeEdit } from '@mui/icons-material';
import { useTagGroupContext } from './TagGroupContext';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';
import { FlexBox } from 'utils/styledComponents';
import { colorPallete, naturalPallete } from 'theme';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';

interface ITagGroupProps {
  tagGroup: ITagGroup;
  allowCustomTags: boolean;
  onCheckboxChange: (tagGroupName: string, newValue: boolean) => void;
}

const TagGroup: FunctionComponent<ITagGroupProps> = (props) => {
  const { tagGroup, allowCustomTags, onCheckboxChange } = props;
  const {
    updateTagsInGroup,
    removeTag,
    loading,
    updateTagGroupName,
    deleteTagGroup,
  } = useTagGroupContext();
  const { tags } = tagGroup;
  const [newTag, setNewTag] = useState<string>('');
  const [newTagGroupName, setNewTagGroupName] = useState(tagGroup.name);
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const buttonList: IListButton[] = [
    {
      name: isEditing ? 'Cancel' : 'Edit name',
      onClick: () => {
        setPopoverAnchorEl(null);
        setIsEditing(!isEditing);
      },
      icon: isEditing ? EditOff : ModeEdit,
    },
    {
      name: 'Delete',
      onClick: () => deleteTagGroup(tagGroup),
      textColor: colorPallete.warningRed,
      icon: DeleteIcon,
    },
  ];

  const handleCheckboxChange = () => {
    onCheckboxChange(tagGroup.name, !allowCustomTags);
  };

  function renderTags() {
    return tags.map((tag, i) => {
      const tagName = `# ${tag.name.replace(/#/g, '')}`;

      return (
        <Fade key={`tag-${i}`} in={true}>
          <FlexBox
            sx={{
              justifyContent: 'center',
              borderRadius: '12px',
              px: '0.5rem',
              color: naturalPallete.natural1,
              backgroundColor: theme.palette.primary.light,
            }}>
            {tagName}
            <FlexBox
              onClick={() => {
                if (!loading) {
                  removeTag(i, tagGroup);
                }
              }}
              sx={{
                cursor: loading ? 'not-allowed' : 'pointer',
              }}>
              <Clear
                sx={{ color: naturalPallete.natural2, height: '1.1rem' }}
              />
            </FlexBox>
          </FlexBox>
        </Fade>
      );
    });
  }

  return (
    <Box>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <Box>
          {isEditing ? (
            <InputField
              value={newTagGroupName}
              placeholder={'Name of the tag group'}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNewTagGroupName(event.target.value)
              }
              onBlur={async () => {
                await updateTagGroupName(tagGroup, newTagGroupName);
                setIsEditing(false);
              }}
              readonly={loading}
            />
          ) : (
            <Typography variant='h4'>{tagGroup.name}</Typography>
          )}
        </Box>
        <MoreOptionsOverlay
          popoverAnchorEl={popoverAnchorEl}
          setPopoverAnchorEl={setPopoverAnchorEl}>
          <MoreOptionsButtons buttonList={buttonList} />
        </MoreOptionsOverlay>
      </FlexBox>

      <Box sx={{ mt: '1rem', mb: '2rem' }}>
        <FlexBox sx={{ justifyContent: 'space-between', mb: '1rem' }}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={5}>
              <InputField
                value={newTag}
                placeholder={'Enter your new tag here'}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setNewTag(event.target.value)
                }
                readonly={loading}
                onSubmit={() => {
                  newTag && updateTagsInGroup(tagGroup, newTag);
                  setNewTag('');
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <CommonButton
                text={'Add tag'}
                variant={ButtonVariant.FILLED}
                isDisabled={newTag === '' || loading}
                onClick={() => {
                  newTag && updateTagsInGroup(tagGroup, newTag);
                  setNewTag('');
                }}
                bgcolor={colorPallete.additionalDarkGreen}
                sx={{
                  '&:hover': {
                    bgcolor: colorPallete.additionalGreen,
                    color: naturalPallete.mainBlackFont,
                  },
                }}
              />
            </Grid>
          </Grid>
        </FlexBox>
        <FlexBox
          sx={{
            gap: '0.5rem',
            flexWrap: 'wrap',
          }}>
          {renderTags()}
        </FlexBox>
      </Box>
    </Box>
  );
};

export default TagGroup;
