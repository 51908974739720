import React, { useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { IconPicker } from './IconPicker';
import { primary } from 'theme';
import { IApplicant } from '../../utils/models';

interface IStarRating {
  stars: number;
  applicant?: IApplicant;
  setRating?: boolean;
  onRatingClick?: (rating: number) => void;
  sx?: SxProps;
  sxIcon?: SxProps;
}

export const StarRating = (props: IStarRating): React.ReactElement => {
  const { stars, setRating, onRatingClick, sx, sxIcon, applicant } = props;
  const [selectedRating, setSelectedRating] = useState(0);

  const handleClick = (rating: number) => {
    if (setRating && onRatingClick) {
      setSelectedRating(rating);
      onRatingClick(rating);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        color: primary.yellowStar,
        ...sx,
      }}>
      {Array.from({ length: 5 }, (_, index) => (
        <Box
          key={index}
          onClick={() => (setRating ? handleClick(index + 1) : undefined)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: setRating ? 'pointer' : '',
          }}>
          <IconPicker
            name={
              index < (selectedRating || stars)
                ? 'StarRounded'
                : 'StarOutlineRounded'
            }
            sx={{ ...sxIcon }}
          />
        </Box>
      ))}
    </Box>
  );
};
