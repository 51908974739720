import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import InformationModal from './Modal/InformationModal';
import {
  getAccount,
  getStoredTemporaryTimezone as getStoredTemporaryTimezone,
  setStoredTimezone,
  updateAccount,
} from 'utils/storage';
import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import { getOffset } from 'utils/utils';
import { ACCOUNT_UPDATE_TIMEZONE_ROUTE, patchData } from 'utils/requests';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import Loading from './Loading';
import { Box } from '@mui/system';

interface IDetectChange {}

const TimeZoneDetector: FunctionComponent<IDetectChange> = (props) => {
  const {} = props;
  const { addAlert } = useContext(AlertContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentTimezone = moment.tz.guess(true);

  const locallySavedTimezone = getStoredTemporaryTimezone();

  const savedTimezone = getAccount().timezone;

  useEffect(() => {
    if (!savedTimezone) return;

    const hasLocationChanged = currentTimezone !== savedTimezone;
    if (!hasLocationChanged) return;

    const isLocationSkipped = currentTimezone === locallySavedTimezone;
    if (isLocationSkipped) return;

    setIsOpen(true);
  }, [savedTimezone]);

  async function patchTimezone() {
    if (!getAccount() || !currentTimezone) return;
    setIsLoading(true);

    try {
      await patchData(ACCOUNT_UPDATE_TIMEZONE_ROUTE, [
        {
          name: 'timezone',
          value: currentTimezone,
        },
      ]);

      updateAccount({
        timezone: currentTimezone,
      });
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Timezone updated',
      });
      setIsOpen(false);
      window.location.reload();
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }
    setIsLoading(false);
  }

  function handleNoUpdate() {
    setStoredTimezone(moment.tz.guess());
    setIsOpen(false);
  }

  return (
    <>
      <InformationModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        headerText='New timezone was detected'
        primaryText='Yes'
        primaryOnClick={patchTimezone}
        secondaryText='No'
        secondaryOnClick={handleNoUpdate}
        minButtonWidth={'12rem'}
        headerSize={'1.75rem'}
        width={'40rem'}
        noCloseButton>
        {isLoading ? (
          <Box sx={{ height: '3.75rem' }}>
            <Loading height={50} />
          </Box>
        ) : (
          <Typography sx={{ py: '1rem' }}>
            Would you like to update your timezone to: {''}
            <span style={{ fontWeight: 'bold' }}>
              {currentTimezone} {getOffset(currentTimezone)}
            </span>{' '}
            ?
          </Typography>
        )}
      </InformationModal>
    </>
  );
};

export default TimeZoneDetector;
