import { Box, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { IEvent } from 'utils/models';
import { createDateFromTime, formatDateYMD, formatDateTime } from 'utils/utils';

interface IAddTime {
  event: IEvent;
  setEvent: (event: IEvent) => void;
}

const AddTime: FunctionComponent<IAddTime> = (props) => {
  const { event, setEvent } = props;

  const { multiDay, startDate, endDate, startTime, endTime } = event;

  const [dateOfStart, setStartDate] = useState<Date | null>(
    startDate ? new Date(startDate) : null,
  );
  const [dateOfEnd, setDateOfEnd] = useState<Date | null>(
    endDate ? new Date(endDate) : null,
  );
  const [dateFromTime, setDateFromTime] = useState<Date | null>(
    startTime ? createDateFromTime(startTime) : null,
  );
  const [dateToTime, setDateToTime] = useState<Date | null>(
    endTime ? createDateFromTime(endTime) : null,
  );

  useEffect(() => {
    if (!multiDay) {
      const updatedEvent = { ...event, endDate: '' };
      setEvent(updatedEvent);
    }
  }, [multiDay]);

  useEffect(() => {
    if (!dateOfStart) return;

    const updatedEvent = { ...event, startDate: formatDateYMD(dateOfStart) };
    setEvent(updatedEvent);
  }, [dateOfStart]);

  useEffect(() => {
    if (!dateOfEnd) return;

    const updatedEvent = { ...event, endDate: formatDateYMD(dateOfEnd) };
    setEvent(updatedEvent);
  }, [dateOfEnd]);

  useEffect(() => {
    if (!dateFromTime) return;

    const updatedEvent = { ...event, startTime: formatDateTime(dateFromTime) };
    setEvent(updatedEvent);
  }, [dateFromTime]);

  useEffect(() => {
    if (!dateToTime) return;

    const updatedEvent = { ...event, endTime: formatDateTime(dateToTime) };
    setEvent(updatedEvent);
  }, [dateToTime]);

  return (
    <div>
      <Typography
        variant='body1'
        sx={{ marginTop: '1rem', fontWeight: 'bold', marginBottom: 1 }}>
        {multiDay ? 'Start - end date*' : 'Date and time*'}
      </Typography>
      <Box display='flex' justifyContent='left'>
        <DatePicker
          format='dd/MM/yyyy'
          value={dateOfStart}
          onChange={setStartDate}
          sx={{ marginRight: 2, maxWidth: 180 }}
          disablePast={true}
        />
        {!multiDay ? (
          <Box display='flex'>
            <TimePicker
              value={dateFromTime}
              onChange={setDateFromTime}
              sx={{ maxWidth: 110 }}
              ampm={false}
              closeOnSelect
            />
            <Typography
              variant='body1'
              sx={{ fontWeight: 'bold', fontSize: 24, marginX: 2 }}>
              _
            </Typography>
            <TimePicker
              value={dateToTime}
              onChange={setDateToTime}
              sx={{ maxWidth: 110 }}
              ampm={false}
              closeOnSelect
            />
          </Box>
        ) : (
          <Box display='flex'>
            <TimePicker
              value={dateFromTime}
              onChange={setDateFromTime}
              sx={{ maxWidth: 110 }}
              ampm={false}
              closeOnSelect
            />
            <Typography
              variant='body1'
              sx={{ fontWeight: 'bold', fontSize: 24, marginX: 2 }}>
              _
            </Typography>
            <TimePicker
              value={dateToTime}
              onChange={setDateToTime}
              sx={{ maxWidth: 110 }}
              ampm={false}
              closeOnSelect
            />
            <DatePicker
              format='dd/MM/yyyy'
              value={dateOfEnd}
              onChange={setDateOfEnd}
              sx={{ marginRight: 2, marginLeft: 2, maxWidth: 180 }}
              disablePast={true}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default AddTime;
