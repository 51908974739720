import React, { FunctionComponent, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Theme } from '@mui/material';
import SideMenu from '../SideMenu/SideMenu';
import SideMenuBackButton from '../Admin/AdminBackButton';
import JourneyStageWrapper from '../common/JourneyStageWrapper';
import JourneyStage from './JourneyStage';
import { useJourneyContext } from './JourneyContext';
import { IJourneyStage } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { LockRounded, LockOpenRounded } from '@mui/icons-material';
import { getEcosystemInfo } from 'utils/storage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  stages: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.5rem',
    marginTop: '1rem',
    transition: '0.3s ease-in-out all',
  },
}));

interface IJourneyMenu {}

const JourneyMenu: FunctionComponent<IJourneyMenu> = (props) => {
  const {
    stages,
    activeStageId,
    setStage,
    isEditJourneyContext,
    updateLockedStatus,
  } = useJourneyContext();
  const { addAlert } = useContext(AlertContext);
  const classes = useStyles();
  const theme: any = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleStageChange(stage: IJourneyStage) {
    if (isEditJourneyContext) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Cannot change the stage in edit mode',
      });
    } else {
      setStage(stage);
    }
  }

  function renderStages() {
    return stages.map((stage, index) => (
      <Box
        key={`stage-${stage.id}`}
        sx={{ display: 'flex', alignItems: 'center' }}>
        <JourneyStageWrapper
          locked={stage.locked}
          key={`stage-${stage.id}`}
          index={index}
          selected={stage.id === activeStageId}
          onClick={() => !stage.locked && handleStageChange(stage)}>
          <JourneyStage {...stage} />
        </JourneyStageWrapper>
        {stage.locked ? (
          <IconButton
            sx={{
              color: '#000',
              cursor: getEcosystemInfo().accessLevel.canManageJourneys
                ? 'pointer'
                : 'default!important',
            }}
            disabled={!getEcosystemInfo().accessLevel.canManageJourneys}
            onClick={() => updateLockedStatus(stage, false)}>
            <LockRounded />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              color: '#000',
              cursor: getEcosystemInfo().accessLevel.canManageJourneys
                ? 'pointer'
                : 'default!important',
            }}
            disabled={!getEcosystemInfo().accessLevel.canManageJourneys}
            onClick={() => updateLockedStatus(stage, true)}>
            <LockOpenRounded />
          </IconButton>
        )}
      </Box>
    ));
  }

  return isMobileScreen ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}>
      <SideMenuBackButton text={'Company'} />
      <Box sx={{ margin: '2rem' }}>{renderStages()}</Box>
    </Box>
  ) : (
    <SideMenu>
      <SideMenuBackButton text={'Company'} />
      <div className={classes.stages}>{renderStages()}</div>
    </SideMenu>
  );
};

export default JourneyMenu;
