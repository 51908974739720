import React, { FunctionComponent } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AboutSettings from 'components/Account/AboutSettings';
import AccountSettings from 'components/Account/AccountSettings';
import { primary, mainFont } from 'theme';
import { getAccount } from 'utils/storage';
import CalendarSync from 'components/Booking/Availability/CalendarSync';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '37.5rem',
    margin: '2rem auto',

    '& h4': {
      fontWeight: 600,
      fontSize: '1.65rem !important',
      marginBottom: '0.4rem !important',
      color: `${primary.pureWhite} !important`,
    },

    '& h5': {
      marginBottom: '1.25rem !important',
      fontSize: '1.35rem !important',
      fontWeight: 400,
      fontFamily: `${mainFont} !important`,
      color: `${primary.pureWhite} !important`,
    },
  },
  cont: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    padding: '1.5rem 5rem',
    paddingBottom: '2rem',
    position: 'relative',
    marginBottom: '2rem',

    '& h6': {
      marginTop: '0.85rem !important',
      marginBottom: '0.3rem !important',
      fontSize: '1rem !important',
    },
    '& h5': {
      color: `${primary.dark} !important`,
    },
  },
}));
interface OwnProps {}

const Account: FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles();
  const account = getAccount();

  return (
    <div className={classes.root}>
      <Typography variant={'h4'}>Account Settings</Typography>
      <Typography variant={'h5'}>About you</Typography>
      <div className={classes.cont}>
        <AboutSettings account={account} />
      </div>
      <Typography variant={'h5'}>Settings</Typography>
      <div className={classes.cont}>
        <AccountSettings account={account} />
      </div>
      <div className={classes.cont}>
        <CalendarSync />
      </div>
    </div>
  );
};

export default Account;
