import React, { FunctionComponent } from 'react';
import ManageEcosystem from 'components/Admin/ManageEcosystem';

interface OwnProps {}

const Admin: FunctionComponent<OwnProps> = (props) => {
  return <ManageEcosystem />;
};

export default Admin;
