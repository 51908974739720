import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import React, { FunctionComponent, useContext, useState } from 'react';
import { acterioTheme, colorPallete } from 'theme';
import MoreOptionsOverlay from 'components/common/MoreOptionsButton/MoreOptionsOverlay';
import {
  ApplicationStatus,
  IApplicationProccess,
  IListButton,
} from 'utils/models';
import {
  ContentCopyRounded,
  ControlPointDuplicate,
  DeleteRounded,
  DoubleArrowRounded,
  ModeRounded,
  PlayCircle,
  RocketLaunch,
  StopCircle,
} from '@mui/icons-material';
import MoreOptionsButtons from 'components/common/MoreOptionsButton/MoreOptionsButtons';
import { useNavigate, useParams } from 'react-router';
import RichTextDisplay from 'components/common/RichTextDisplay';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import useDeleteApplicationProcess from 'hooks/Applications/useDeleteApplicationProcess';
import { formatDateEuropean } from 'utils/utils';
import usePauseOrResumeProcess from 'hooks/Applications/usePauseOrResumeProcess';
import useStartProcessNow from 'hooks/Applications/useStartProcessNow';
import { AdminMenuKeys } from '../AdminSideMenu';
import { FlexBox } from '../../../utils/styledComponents';
import ErrorIcon from '@mui/icons-material/Error';
import { getEcosystemInfo } from '../../../utils/storage';
import InformationModal from '../../common/Modal/InformationModal';
import { useApplicationMenuContext } from 'services/contexts/Applications/ApplicationMenuContext';

const ApplicationProcessCard: FunctionComponent<IApplicationProccess> = ({
  id,
  name,
  description,
  applicantsInProgress,
  startDate,
  endDate,
  active,
  url,
  processStatus,
}) => {
  const navigate = useNavigate();
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const {
    applicationProcesses,
    setApplicationProcesses,
    setIsLoading,
    setSelectedApplicationId,
    setIsDuplicate,
  } = useApplicationContext();

  const { setSelectedOption } = useApplicationMenuContext();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [typeOfModal, setTypeOfModal] = useState<
    keyof typeof modalsData | undefined
  >();

  const pauseOrResume = usePauseOrResumeProcess({
    selectedApplicationProcessId: id,
    setApplicationProcesses,
    setIsLoading,
  });

  const startProcessNow = useStartProcessNow({
    selectedApplicationProcessId: id,
    setApplicationProcesses,
    setIsLoading,
  });
  //
  // const duplicateProcess = useDuplicateApplicationProcess({
  //   selectedApplicationProcessId: id,
  //   setApplicationProcesses,
  //   setIsLoading,
  // });

  const enableDuplicateEditMode = () => {
    setSelectedApplicationId(id);
    setSelectedOption(AdminMenuKeys.EditorDuplicateApplication);
  };

  const deleteProps = {
    id,
    setIsLoading,
    applicationProcesses,
    setApplicationProcesses,
    handleClose: () => setPopoverAnchorEl(null),
  };
  const deleteApplicationProcess = useDeleteApplicationProcess(deleteProps);

  const isActive = processStatus !== ApplicationStatus.INACTIVE;
  const isScheduled = processStatus === ApplicationStatus.SCHEDULED;
  const isPaused = processStatus === ApplicationStatus.PAUSED;

  const modalsData = {
    pause: {
      title: 'Pause the application?',
      description: (
        <Typography>
          The application <b>{name}</b> will be paused, and applicants won’t be
          able to submit their forms during this time. After you confirm this
          action, application will be moved to "<b>Paused</b> Applications" .
          You can resume it at any time from there.
        </Typography>
      ),
      mainButtonText: 'Pause',
      secondaryButtonText: 'Cancel',
      action: pauseOrResume,
    },
    resume: {
      title: 'Resume application process?',
      description: (
        <Typography>
          The application <b>{name}</b> will be resumed. Applicants will be able
          to access and submit their forms. After you confirm this action, the
          application will move to "<b>Active</b> Applications".
        </Typography>
      ),
      mainButtonText: 'Resume',
      secondaryButtonText: 'Cancel',
      action: pauseOrResume,
    },
    delete: {
      title: 'Are you sure you want to delete this application?',
      description: (
        <FlexBox
          sx={{
            alignItems: 'start',
            gap: '0.5rem',
          }}>
          <ErrorIcon sx={{ color: colorPallete.warningRed }} />
          <Typography>
            Application <b>{name}</b> will be permanently deleted. All data will
            be permanently lost. Please confirm your choice carefully.
          </Typography>
        </FlexBox>
      ),
      mainButtonText: 'Delete',
      secondaryButtonText: 'Cancel',
      action: deleteApplicationProcess,
    },
    start: {
      title: 'Start application process early?',
      description: (
        <Box>
          <Typography>
            Would you like to start application <b>{name}</b> early? After you
            confirm this action, application will be moved to "<b>Active</b>
            Applications", and applicants will be able to access and submit
            their forms.
          </Typography>
        </Box>
      ),
      mainButtonText: 'Start',
      secondaryButtonText: 'Cancel',
      action: startProcessNow,
    },
    duplicate: {
      title: 'Duplicating application',
      description: (
        <Box>
          <Typography>
            Your application <b>{name}</b> will be duplicated. To avoid
            conflicts, you'll need to <b>update the name and dates</b> for the
            duplicated application.
          </Typography>
        </Box>
      ),
      mainButtonText: 'Continue',
      secondaryButtonText: 'Cancel',
      action: () => {
        setIsDuplicate(true);
        enableDuplicateEditMode();
      },
    },
    edit: {
      title: 'Important notice',
      description: (
        <FlexBox
          sx={{
            flexDirection: 'column',
            gap: '1.5rem',
          }}>
          <Typography>
            You can edit text, dates and add new sections or questions to this
            application form. However, please note the following restrictions to
            ensure consistency for current applicants:
          </Typography>

          <FlexBox
            sx={{
              flexDirection: 'column',
            }}>
            <Typography fontWeight={'700'}>
              <li>
                Deleting existing sections and questions may disrupt the
                consistency of applications already submitted.
              </li>
            </Typography>
            <Typography fontWeight={'700'}>
              <li>
                Changing the type of existing questions can also affect
                previously submitted responses.
              </li>
            </Typography>
          </FlexBox>

          <Typography>
            These limitations are in place to maintain the integrity of
            applications already submitted and future work with the existing
            version of this form.
          </Typography>
        </FlexBox>
      ),
      mainButtonText: 'Continue to edit',
      secondaryButtonText: 'Cancel edit',
      action: enableDuplicateEditMode,
    },
  };

  function renderInfoModal(type: keyof typeof modalsData | undefined) {
    const modalData = modalsData[type!];
    return (
      <InformationModal
        width={'39.5rem'}
        headerSize={'2rem'}
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
        primaryText={modalData.mainButtonText}
        primaryBgColor={type === 'delete' ? colorPallete.warningRed : undefined}
        primaryOnClick={modalData.action}
        secondaryText={modalData.secondaryButtonText}
        secondaryOnClick={() => setIsInfoModalOpen(false)}
        headerText={modalData.title}
        headerIcon={
          type === 'edit' ? (
            <ErrorIcon
              sx={{
                width: '1.875rem',
                height: '1.875rem',
                color: colorPallete.yellowStar,
                marginBottom: '0.438rem',
              }}
            />
          ) : undefined
        }
        footerButtonsSx={{
          marginTop: '1.875rem',
        }}
        buttonSx={{
          height: '3rem',
          ...(type === 'delete' && {
            '&:hover': {
              color: '#CC2D2D',
              backgroundColor: '#F6D5D5',
            },
          }),
        }}>
        <Box sx={{ marginRight: '1.5rem', marginTop: '1rem' }}>
          {modalData.description}
        </Box>
      </InformationModal>
    );
  }

  let buttonList: IListButton[] = [
    {
      name: 'Copy link',
      onClick: copyText,
      icon: ContentCopyRounded,
    },
    {
      name: 'Edit',
      onClick: () => {
        setTypeOfModal('edit');
        setIsInfoModalOpen(true);
      },
      icon: ModeRounded,
    },
    {
      name: 'Duplicate',
      onClick: () => {
        setTypeOfModal('duplicate');
        setIsInfoModalOpen(true);
      },
      icon: ControlPointDuplicate,
    },
    {
      name: 'Start now',
      onClick: () => {
        setTypeOfModal('start');
        setIsInfoModalOpen(true);
      },
      icon: RocketLaunch,
    },
    {
      name: isPaused ? 'Continue' : 'Pause',
      onClick: () => {
        if (processStatus === ApplicationStatus.ACTIVE) {
          setTypeOfModal('pause');
        } else {
          setTypeOfModal('resume');
        }
        setIsInfoModalOpen(true);
      },
      icon: isPaused ? PlayCircle : StopCircle,
    },
    {
      name: 'Delete',
      onClick: () => {
        setTypeOfModal('delete');
        setIsInfoModalOpen(true);
      },
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  if (!isActive) {
    buttonList = buttonList.filter((button) => button.name !== 'Copy link');
  }

  if (!isScheduled) {
    buttonList = buttonList.filter((button) => button.name !== 'Start now');
  }

  if (!isActive || isScheduled) {
    buttonList = buttonList.filter(
      (button) => button.onClick !== pauseOrResume,
    );
  }

  const buttonStyles = createButtonStyles(processStatus);

  function createButtonStyles(applicationStatus: ApplicationStatus) {
    switch (applicationStatus) {
      case 'INACTIVE':
        return {
          backgroundColor: colorPallete.greyButtonDefault,

          '&:hover': {
            backgroundColor: colorPallete.greyButtonHover,
            color: 'white',
          },
        };
      case 'SCHEDULED':
        return {
          backgroundColor: colorPallete.additionalLightOrange,

          '&:hover': {
            backgroundColor: colorPallete.additionalDarkOrange,
            color: 'white',
          },
        };
      case 'PAUSED':
        return {
          backgroundColor: colorPallete.additionalLightPink,

          '&:hover': {
            backgroundColor: colorPallete.additionalDarkPink,
            color: 'white',
          },
        };
      default:
        return {
          backgroundColor: colorPallete.additionalLightGreen,

          '&:hover': {
            backgroundColor: colorPallete.additionalDarkGreen,
            color: 'white',
          },
        };
    }
  }

  const cardStyles = createCardStyles(processStatus);

  function createCardStyles(applicationStatus: ApplicationStatus) {
    switch (applicationStatus) {
      case 'INACTIVE':
        return {
          backgroundColor: colorPallete.greyButtonDefault,
        };
      case 'SCHEDULED':
        return {
          backgroundColor: colorPallete.additionalOrange,
        };
      case 'PAUSED':
        return {
          backgroundColor: colorPallete.additionalPink,
        };
      default:
        return {
          backgroundColor: colorPallete.additionalGreen,
        };
    }
  }

  const textStyles = createTextStyles(processStatus);

  function createTextStyles(applicationStatus: ApplicationStatus) {
    switch (applicationStatus) {
      case 'INACTIVE':
        return {
          color: colorPallete.greyButtonHover,
        };
      case 'SCHEDULED':
        return {
          color: colorPallete.additionalDarkOrange,
        };
      case 'PAUSED':
        return {
          color: colorPallete.additionalDarkPink,
        };
      default:
        return {
          color: colorPallete.additionalDarkGreen,
        };
    }
  }

  function copyText() {
    navigator.clipboard.writeText(url);
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: 'Link copied successfully!',
    });
    setPopoverAnchorEl(null);
  }

  return (
    <Box
      sx={{
        width: '26rem',
        height: '29.5rem',
        dropShadow: '0px 0px 4px 0px #000000',
        borderRadius: '30px',
        padding: '1.563rem 1.875rem',
        margin: '0.5rem',
        ...cardStyles,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '23.5rem',
        }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '0.875rem',
            lineHeight: '1.313rem',
          }}>
          {formatDateEuropean(new Date(startDate), 'short')}
        </Typography>
        {getEcosystemInfo().accessLevel.canManageApplications && (
          <MoreOptionsOverlay
            sx={{ bgcolor: 'inherit' }}
            popoverAnchorEl={popoverAnchorEl}
            setPopoverAnchorEl={setPopoverAnchorEl}>
            <MoreOptionsButtons buttonList={buttonList} />
          </MoreOptionsOverlay>
        )}
      </Box>
      <Box sx={{ minHeight: '4.5rem', height: '4.5rem' }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '1.25rem',
            lineHeight: '2.344rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
          }}>
          {name}
        </Typography>
      </Box>
      <Box sx={{ height: '10rem' }}>
        <RichTextDisplay
          style={{
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.2rem',
            maxHeight: '8.2rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 6,
            WebkitBoxOrient: 'vertical',
            wordWrap: 'break-word',
          }}
          text={description}
        />
      </Box>
      <Box
        sx={{
          width: '21.875rem',
          height: '5.2rem',
          backgroundColor: '#fff',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '1.563rem',
              margin: '0.5rem',
              ...textStyles,
            }}>
            {processStatus.charAt(0) +
              processStatus.slice(1).toLocaleLowerCase()}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '0.75rem',
              margin: '0.5rem',
              color: isActive ? 'black' : acterioTheme.warning,
            }}>
            {isActive
              ? `Ends on: ${formatDateEuropean(new Date(endDate), 'long')}`
              : `Ended on: ${formatDateEuropean(new Date(endDate), 'long')}`}
          </Typography>
        </Box>
        {isActive && (
          <Box sx={{ margin: '0 0.5rem 0.5rem 0' }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '1.563rem',
                color: 'black',
                textAlign: 'center',
              }}>
              {applicantsInProgress}
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '1rem',
                color: 'black',
                textAlign: 'center',
              }}>
              applicants
            </Typography>
            <Typography
              sx={{
                color: '#4D4D4D',
                fontWeight: '500',
                fontSize: '0.8rem',
                textAlign: 'center',
              }}>
              in process
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        onClick={() => navigate(`${id}`)}
        sx={{
          width: '21.875rem',
          height: '2rem',
          color: 'black',
          fontSize: '1rem',
          fontWeight: '600',
          paddingY: '1.3rem',
          marginTop: '1rem',
          ...buttonStyles,
        }}>
        <DoubleArrowRounded /> View all applications
      </Button>
      {typeOfModal && renderInfoModal(typeOfModal)}
    </Box>
  );
};

export default ApplicationProcessCard;
