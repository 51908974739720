import React, { FunctionComponent, useState } from 'react';
import { ICompanyField, ICompanyPeople, IPeople } from 'utils/models';
import PeopleDisplay from '../ViewCompany/PeopleDisplay';
import CommonModal from 'components/common/Modal/CommonModal';
import { Box } from '@mui/system';
import { primary } from 'theme';
import ModalHeader from 'components/common/Modal/ModalHeader';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import AccessLevelTagCard from 'components/common/AccessLevelTagCard';
import { useCompanyContext } from 'services/contexts/Companies/CompanyContext';
import SearchUsers from 'components/common/SearchUsers';
import { Close } from '@mui/icons-material';
import CommonButton, { ButtonVariant } from 'components/common/CommonButton';

interface ICompanyEditPeopleFieldProps {
  field: ICompanyField;
}

const CompanyEditPeopleField: FunctionComponent<
  ICompanyEditPeopleFieldProps
> = (props) => {
  const { field } = props;
  const { setCompanyProfile } = useCompanyContext();
  const [peopleCopy, setPoepleCopy] = useState<ICompanyPeople[]>(
    field.people ? [...field.people] : [],
  );
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  function saveChanges() {
    setPoepleCopy(field.people ? [...field.people] : []);
    setIsModalOpen(false);
  }

  function revertChanges() {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        sidebar: prevProfile.sidebar.map((prevField) => {
          if (prevField.id !== field.id) return prevField;

          return {
            ...prevField,
            people: peopleCopy,
          };
        }),
      };
      return updatedProfile;
    });
  }

  function addUserToCompanyField(value: IPeople) {
    const newPerson: any = {
      id: value.id,
      fullName: value.fullName,
      profilePic: value.profilePic,
      accessLevelColor: value.accessLevelColor,
      accessLevelName: value.accessLevelName,
      subtitle: value.subtitle,
      primaryCompany: value.primaryCompany,
      locationString: value.locationString,
      primaryCompanyLogo: value.primaryCompanyLogo,
      timezone: value.timezone,
      usedTags: value.usedTags,
      role: value.subtitle,
    };

    if (!newPerson.id) return;

    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        sidebar: prevProfile.sidebar.map((prevField) => {
          if (prevField.id !== field.id) return prevField;

          if (prevField.people?.find(({ id }) => id === newPerson.id))
            return prevField;

          return {
            ...prevField,
            people: prevField.people
              ? [...prevField.people, newPerson as ICompanyPeople]
              : [newPerson as ICompanyPeople],
          };
        }),
      };
      return updatedProfile;
    });
  }

  function removeUserFromCompanyField(userToRemoveId: number) {
    setCompanyProfile((prevProfile) => {
      if (!prevProfile) return null;

      const updatedProfile = {
        ...prevProfile,
        sidebar: prevProfile.sidebar.map((prevField) => {
          if (prevField.id !== field.id) return prevField;

          return {
            ...prevField,
            people: prevField.people?.filter(({ id }) => id !== userToRemoveId),
          };
        }),
      };
      return updatedProfile;
    });
  }

  function renderEditPeopleModal() {
    const people = field.people ?? [];
    return (
      <CommonModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            width: '45rem',
            maxHeight: '50rem',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: primary.natural5 + ' transparent',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            paddingY: '1.5rem',
            paddingX: '2rem',
            background: primary.pureWhite,
            borderRadius: '10px',
          }}>
          <ModalHeader
            handleClose={() => setIsModalOpen(false)}
            text={`Add/remove ${field.name}`}
            textColor={primary.mainBlackFont}
          />
          <Typography
            sx={{ color: primary.natural2, mb: '1rem', fontSize: '0.9rem' }}>
            Search and select people you wish to add to your company profile.
          </Typography>
          <SearchUsers
            onSearch={(value: IPeople) => addUserToCompanyField(value)}
            fullWidth={false}
          />
          <Typography
            sx={{
              color: primary.mainBlackFont,
              mt: '2rem',
              mb: '1rem',
              fontSize: '1.1rem',
              fontWeight: 'bold',
            }}>
            {`${field.name} in your company:`}
          </Typography>
          <Grid
            container
            sx={{
              textAlign: 'center',
              my: '2rem',
              maxHeight: '28rem',
              overflowY: 'auto',
              overflowX: 'none',
            }}>
            {people.map((people) => (
              <Grid
                item
                xs={12}
                key={people.id}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', mb: '0.5rem' }}>
                  <img
                    src={people.profilePic}
                    style={{
                      height: '3rem',
                      width: '3rem',
                      borderRadius: '50%',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: '1rem',
                      mr: '1rem',
                      textAlign: 'start',
                    }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                      {people.fullName}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '0.75rem',
                        color: primary.natural2,
                      }}>
                      {people.subtitle || people.role || 'No title'}
                    </Typography>
                  </Box>
                  <AccessLevelTagCard
                    accessLevelName={people.accessLevelName || 'Default'}
                    accessLevelColor={people.accessLevelColor}
                    height={'1.3rem'}
                  />
                </Box>
                <Button
                  sx={{
                    minWidth: 'unset',
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '100%',
                    color: primary.natural1,
                  }}
                  onClick={() => removeUserFromCompanyField(people.id)}>
                  <Close sx={{ fontSize: '1.15rem' }} />
                </Button>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
            }}>
            <CommonButton
              text='Revert changes'
              variant={ButtonVariant.BLANK}
              onClick={revertChanges}
              sx={{ mr: '1rem' }}
            />
            <CommonButton
              text='Save'
              variant={ButtonVariant.FILLED}
              onClick={saveChanges}
            />
          </Box>
        </Box>
      </CommonModal>
    );
  }

  return (
    <>
      <PeopleDisplay
        people={field.people ?? []}
        buttonText='Add/remove'
        buttonOnClick={() => setIsModalOpen(true)}
      />
      {renderEditPeopleModal()}
    </>
  );
};

export default CompanyEditPeopleField;
