import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent, ReactElement } from 'react';
import { primary } from 'theme';

interface IModalBox {
  children: ReactElement;
  sx?: SxProps;
  width?: string | number;
}

const ModalBox: FunctionComponent<IModalBox> = (props) => {
  const { children, sx, width } = props;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '12px',
        width: width ?? '46rem',
        bgcolor: primary.pureWhite,
        p: '2rem',

        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: primary.natural5 + ' transparent',

        ...sx,
      }}>
      {children}
    </Box>
  );
};

export default ModalBox;
