import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/common/InputField';
import React, { FunctionComponent } from 'react';
import {
  Controller,
  FieldErrors,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { primary } from 'theme';
import {
  IApplicationAssessmentOption,
  IApplicationAssessmentSubSection,
  IApplicationAssessmentTemplate,
} from 'utils/models';
import { FlexBox } from 'utils/styledComponents';
import { ControlButtonStyled } from '../ApplicationBuilder';
import { AddCircle, Close, ErrorRounded } from '@mui/icons-material';
import AssessmentSubsectionQuestion from './AssessmentSubsectionQuestion';
import AssessmentPercentageInput from './AssessmentPercentageInput';

interface IAssessmentBuilderSubSections {
  control: any;
  errors: FieldErrors<IApplicationAssessmentTemplate>;
  sectionIndex: number;
  resetField: any;
  newOptions: IApplicationAssessmentOption[];
  isWeighted: boolean;
}

const AssessmentBuilderSubSections: FunctionComponent<
  IAssessmentBuilderSubSections
> = (props) => {
  const { control, sectionIndex, errors, resetField, newOptions, isWeighted } =
    props;
  const theme = useTheme();

  const {
    fields: subSectionFields,
    append: appendSubSection,
    remove: removeSubSection,
  } = useFieldArray<any>({
    name: `sections.${sectionIndex}.subSections`,
    control,
  });

  const subSections = useWatch({
    control,
    name: `sections.${sectionIndex}.subSections`,
    defaultValue: [],
  });

  const anySubSectionWeightProvided = subSections.some(
    (subSection: IApplicationAssessmentSubSection) =>
      subSection.weight !== undefined && subSection.weight > 0,
  );

  const totalSubSectionWeight = anySubSectionWeightProvided
    ? subSections.reduce(
        (total: number, subSection: IApplicationAssessmentSubSection) => {
          if (subSection.weight === undefined || subSection.weight === 0) {
            return total;
          }
          return total + parseFloat(subSection.weight?.toString() || '0');
        },
        0,
      )
    : null;

  const isWeightsValid =
    totalSubSectionWeight === 0 ||
    totalSubSectionWeight === 100 ||
    totalSubSectionWeight === null;

  return (
    <>
      {subSectionFields.map((subSection, subSectionIndex) => {
        const errorPath =
          errors?.sections?.[sectionIndex]?.subSections?.[subSectionIndex]
            ?.name;

        return (
          <Box
            key={subSection.id}
            sx={{
              paddingLeft: '3.125rem',
              marginTop: '1.25rem',
            }}>
            <FlexBox
              sx={{
                gap: '1.563rem',
              }}>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  alignItems: 'start',
                  borderRadius: '12px',
                  boxShadow: theme.shadows[1],
                  padding: '1.563rem 1.875rem',
                  width: '100%',
                }}>
                <FlexBox
                  className={'between'}
                  sx={{
                    alignItems: 'start',
                    width: '100%',
                    gap: '1.625rem',
                  }}>
                  <FlexBox
                    className={'between'}
                    sx={{ alignItems: 'start', width: '100%' }}>
                    <Controller
                      name={`sections.${sectionIndex}.subSections.${subSectionIndex}.name`}
                      control={control}
                      rules={{
                        required: 'Please, type the question',
                      }}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          onChange={(
                            e: React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                          ) => {
                            field.onChange(e);
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              opacity:
                                sectionIndex === 0 && subSectionIndex < 2
                                  ? '200%'
                                  : '100%',
                              '& .MuiInputBase-input': {
                                WebkitTextFillColor: primary.mainBlackFont,
                              },
                            },
                          }}
                          helperText={errorPath && errorPath.message}
                          helperIcon={errors.sections && 'warning'}
                          error={!!errorPath}
                          maxLength={255}
                          placeholder={'Subsection name'}
                          width={'70%'}
                        />
                      )}
                    />
                    {isWeighted && (
                      <FlexBox sx={{ gap: '1.25rem' }}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Typography
                            variant={'body1'}
                            sx={{
                              color: primary.natural3,
                              ml: '1.25rem',
                              fontSize: '0.75rem',
                            }}>
                            All criteria in a subsection must be equal to 100%
                            in weight
                          </Typography>
                          <ErrorRounded
                            sx={{ color: primary.yellowStar, ml: '0.25rem' }}
                          />
                        </Box>
                        <AssessmentPercentageInput
                          control={control}
                          errors={errors}
                          sectionIndex={sectionIndex}
                          subSectionIndex={subSectionIndex}
                          isWeightsValid={isWeightsValid}
                          bgColor={primary.light}
                          border={`1px solid ${primary.main}`}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>
                  <ControlButtonStyled
                    disabled={sectionIndex === 0 && subSectionIndex < 1}
                    onClick={() => removeSubSection(subSectionIndex)}>
                    <Close />
                  </ControlButtonStyled>
                </FlexBox>
                <AssessmentSubsectionQuestion
                  resetField={resetField}
                  errors={errors}
                  control={control}
                  sectionIndex={sectionIndex}
                  subSectionIndex={subSectionIndex}
                  newOptions={newOptions}
                  isWeighted={isWeighted}
                />
              </FlexBox>
            </FlexBox>
          </Box>
        );
      })}
      <ControlButtonStyled
        sx={{
          marginTop: '0.5rem',
          paddingLeft: '3.125rem',
        }}
        onClick={() =>
          appendSubSection({
            name: '',
            questions: [
              {
                name: '',
                options: newOptions,
              },
            ],
          })
        }>
        <AddCircle />{' '}
        <Typography
          sx={{
            marginLeft: '0.625rem',
          }}>
          Add a subsection
        </Typography>
      </ControlButtonStyled>
    </>
  );
};

export default AssessmentBuilderSubSections;
