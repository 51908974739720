import { Box } from '@mui/system';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Typography, useTheme } from '@mui/material';
import { Close, Place, RemoveRedEye } from '@mui/icons-material';
import { primary } from 'theme';
import { IMeeting } from 'utils/models';
import MeetingDetails from './MeetingDetails';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import {
  MEETING_CANCEL_MENTOR,
  MEETING_CANCEL_USER,
  deleteData,
} from 'utils/requests';
import ConfirmationForm from 'components/common/ConfirmationForm';

interface MeetingProps {
  meeting: IMeeting;
  setMeetings: (meetings: any) => void;
  isAttendingCalendar: boolean;
}

const Meeting: FunctionComponent<MeetingProps> = (props) => {
  const { meeting, setMeetings, isAttendingCalendar } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();

  const [showMeetingDetails, setShowMeetingDetails] = useState<boolean>(false);
  const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function showDetails() {
    setShowMeetingDetails(true);
  }

  function cancelMeeting() {
    setShowMeetingDetails(false);
    setIsShowConfirmForm(true);
  }

  async function requestCancelMeeting() {
    setIsLoading(true);

    try {
      await deleteData(
        isAttendingCalendar ? MEETING_CANCEL_USER : MEETING_CANCEL_MENTOR,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'meetingId', value: meeting.id },
          { name: 'id', value: 'The meeting has been canceled by the user.' },
        ],
      );

      setMeetings((prevMeetings: IMeeting[]) =>
        prevMeetings.filter((m) => m.id !== meeting.id),
      );

      setShowMeetingDetails(false);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Meeting canceled successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderPhoto() {
    return (
      <Box
        sx={{
          height: '100%',
          width: '5rem',
          minWidth: 80,
          cursor: 'pointer',
          marginRight: 2,
        }}>
        <Box
          sx={{
            borderRadius: '6px',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          onClick={() => setShowMeetingDetails(true)}>
          <img
            src={
              isAttendingCalendar
                ? meeting.bookedUser.profilePic
                : meeting.bookingUser.profilePic
            }
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
    );
  }

  function renderMeetingDetails() {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        <Typography
          fontWeight='bold'
          fontSize={'1rem'}
          color={theme.palette.primary.dark}>
          {meeting.topic}
        </Typography>
        <Typography
          color={theme.palette.primary.main}
          fontSize={'0.875rem'}
          gutterBottom>
          {`with ${
            isAttendingCalendar
              ? meeting.bookedUser.fullName
              : meeting.bookingUser.fullName
          }`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}>
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              paddingX: 1,
              paddingY: 0.2,
              borderRadius: 0.3,
              marginLeft: 0,
              marginRight: 1,
              maxHeight: 22,
            }}>
            <Typography
              fontSize={'0.75rem'}
              fontWeight='bold'
              color={
                theme.palette.primary.main
              }>{`${meeting.startTime} - ${meeting.endTime}`}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginTop: 0.2,
              maxWidth: 111,
              overflow: 'hidden',
            }}>
            {meeting.platform && (
              <>
                <Place
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: '0.75rem',
                    marginRight: 0.5,
                    marginTop: 0.2,
                  }}
                />
                <Typography
                  fontSize={'0.75rem'}
                  fontWeight='bold'
                  color={theme.palette.primary.main}>
                  <a href={meeting.virtualMeetingLink} target='_blank'>
                    {meeting.platform}
                  </a>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  function renderButtons() {
    return (
      <Box
        sx={{
          height: '100%',
          minWidth: 46,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          sx={{
            minWidth: 'unset',
            width: 30,
            height: 30,
            bgcolor: theme.palette.primary.dark,
            borderRadius: '6px',
            marginY: 0.4,
            '&:hover': { bgcolor: theme.palette.primary.main },
          }}
          onClick={showDetails}>
          <RemoveRedEye sx={{ color: primary.pureWhite }} />
        </Button>
        <Button
          sx={{
            minWidth: 'unset',
            width: 30,
            height: 30,
            bgcolor: '#FFE7E6',
            borderRadius: '6px',
            marginY: 0.4,
            border: '1px solid #DB9F9D',
            '&:hover': { bgcolor: '#DB9F9D' },
          }}
          onClick={cancelMeeting}>
          <Close sx={{ color: '#D25B58' }} />
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 90,
        bgcolor: '#FFFFFF',
        border: 1,
        borderColor: '#D4D4D4',
        borderRadius: '6px',
        marginY: 2,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {renderPhoto()}
      {renderMeetingDetails()}
      {renderButtons()}
      {showMeetingDetails && (
        <MeetingDetails
          isOpen={showMeetingDetails}
          handleClose={() => {
            setShowMeetingDetails(false);
          }}
          meeting={meeting}
          cancelMeeting={cancelMeeting}
        />
      )}
      {isShowConfirmForm && (
        <ConfirmationForm
          isOpen={isShowConfirmForm}
          handleClose={() => {
            setIsShowConfirmForm(false);
          }}
          funcExe={requestCancelMeeting}
          text={'cancel this meeting?'}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default Meeting;
