import React, { FunctionComponent, useState } from 'react';
import { Box } from '@mui/material';
import Avatar from '../common/Avatar';
import CreateNewAnnouncement from './CreateNewAnnouncement';
import MainButton, { ButtonType } from '../common/MainButton';
import { getAccount } from 'utils/storage';
import { IAnnouncement } from 'utils/models';

interface IWriteAnnouncement {
  announcements: IAnnouncement[];
  setAnnouncements: (announcement: IAnnouncement[]) => void;
}

const WriteAnnouncement: FunctionComponent<IWriteAnnouncement> = (props) => {
  const { announcements, setAnnouncements } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { profilePicture } = getAccount();

  return (
    <>
      <Box sx={{ padding: '1rem 1rem 1rem 0' }}>
        <Box
          sx={{
            margin: '0 1rem 0 0',
            marginRight: '0.313rem',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            width:'100%'
          }}>
          <Avatar source={profilePicture} height={40} width={40} radius={40} />
          <MainButton
            onClick={() => setOpenModal(true)}
            text={'Write an announcement here...'}
            type={ButtonType.BLANK}
            style={{
              height: '2.5rem',
              border: 'none',
              fontSize: '1rem',
              fontWeight: 400,
              backgroundColor: '#F2F2F2 !important',
              color: '#555E68 !important',
              paddingLeft: '1rem',
              justifyContent: 'flex-start',
              flexGrow: 1
           
            }}
          />
        </Box>
        <CreateNewAnnouncement
          announcements={announcements}
          setAnnouncements={setAnnouncements}
          open={openModal}
          close={() => setOpenModal(false)}
        />
      </Box>
    </>
  );
};

export default WriteAnnouncement;
